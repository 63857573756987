import "../../../../components/react-form-builder2/dist/app.css";
import React, { useEffect, useState } from "react";
import {
  ReactFormBuilder,
  ReactFormGenerator,
} from "../../../../components/react-form-builder2/lib";
import * as variables from "../../../../components/react-form-builder2/variables";
import { useDispatch, useSelector } from "react-redux";
import { addForm, apiAddForm } from "../../../../reduxPie/formsSlice";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import { apiFindAllEvents } from "../../../../reduxPie/eventsSlice";
import store from "../../../../components/react-form-builder2/lib/stores/store";
import { FormTitleBar } from "./FormTitleBar";
import { FormInput } from "../../../../components/FormInput";

export function AddForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.selected);
  const { isLoading, data } = useSelector((state) => state.events);
  const clientsSlice = useSelector((state) => state.clients);
  const sitesSlice = useSelector((state) => state.sites);
  const [events, setEvents] = useState([]);
  const EventTypes = new Set(data.map((item) => item.type));
  const [state, setState] = useState([]);
  const [view, setView] = useState("tags");

  // STATE FORM VALUES
  const [clientId, setClientId] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [name, setName] = useState("");
  const [eventType, setEventType] = useState("");
  const [event, setEvent] = useState("");
  const [clientSites, setClientSites] = useState([]);

  useEffect(() => {
    if (clientId) {
      const selectedClientSites = sitesSlice.data.filter((x) =>
        clientId === "All" || null ? true : x.client === clientId && x.active
      );
      if (selectedClientSites.length) {
        setClientSites(selectedClientSites);
      } else {
        setSiteId("");
        setClientSites([]);
      }
    }
  }, [clientId, sitesSlice.data]);

  store.subscribe((thisState) => {
    setState([...thisState.data]);
  });

  // AUTO FETCH EVENTS
  useEffect(() => {
    if (!data.length) dispatch(apiFindAllEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // EVENT FILTER
  useEffect(() => {
    setEvents(data.filter((x) => x.type === eventType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType, data]);

  const toolbarItems = [
    { key: "Header" },
    { key: "Label" },
    { key: "TextInput" },
    { key: "RadioButtons" },
    { key: "Checkboxes" },
    // { key: "Image" },
    // { key: "Signature" },
    { key: "Dropdown" },
    // { key: "NumberInput" },
    { key: "DatePicker" },
    // { key: "Camera" },
    { key: "TextArea" },
    // { key: "HyperLink" },
  ];

  // ON ADD NEW FORM
  const onAddForm = (newData) => {
    if (name && event !== "0") {
      // NEW FORM
      const newForm = {
        customer: loggedIn.customer,
        // region: loggedIn.region,
        // branch: loggedIn.branch,
        client: clientId,
        site: siteId,
        event,
        name,
        data: newData,
        createdBy: loggedIn._id,
        createdOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
      };
      dispatch(addForm(newForm));
      dispatch(apiAddForm(newForm));
      window.alert(`Form - ${name} has been Added`);
      navigate("/manage/forms");
    } else {
      window.alert("Please Check Form and Try Again");
    }
  };

  if (isLoading) return <Loading title="Forms" />;
  return (
    <div className="d-flex flex-column flex-fill">
      {/* TITLE BAR */}
      <FormTitleBar
        view={view}
        setView={setView}
        name={name}
        eventType={eventType}
        event={event}
      />

      {/* TAGS VIEW */}
      {view === "tags" && (
        <div className="d-flex flex-fill centered bg-secondary">
          {/* TITLE BAR */}
          <div className="d-flex flex-column col-5 p-3">
            {/* CLIENT */}
            <div className="card bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Client</label>
              <select
                type="text"
                value={clientId}
                className="form-select"
                onChange={(x) => setClientId(x.target.value)}
              >
                <option value={null}>All</option>
                {clientsSlice.data.map((item, key) => (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {/* SITE */}
            <div className="card bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Site</label>
              <select
                type="text"
                value={siteId}
                className="form-select"
                onChange={(x) => setSiteId(x.target.value)}
              >
                <option value={null}>All</option>
                {clientSites.map((item, key) => (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {/* FORM NAME */}
            <FormInput
              className="card border-danger border-3 bg-dark text-light p-2 mb-3"
              placeholder="Enter Form Name"
              label="Form Name *"
              value={name}
              setValue={setName}
            />

            {/* EVENT TYPE */}
            <div className="card border-danger border-3 bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Type *</label>
              <select
                className="form-select"
                value={eventType}
                onChange={(x) => setEventType(x.target.value)}
              >
                <option value="">Select Event Type</option>
                {[...EventTypes].map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            {/* EVENT NAME */}
            <div className="card border-danger border-3 bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Event *</label>
              <select
                className="form-select"
                value={event}
                onChange={(x) => setEvent(x.target.value)}
              >
                <option value="">Select Event</option>
                {events.map((item, key) => (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {/* FORM VIEW */}
      {view === "form" && (
        <ReactFormBuilder
          data={state}
          variables={variables}
          toolbarItems={toolbarItems}
        />
      )}

      {/* PREVIEW POP-UP */}
      <div className="modal fade" id="previewModal" data-bs-backdrop="static">
        <div
          className="modal-dialog modal-lg modal-dialog-scrollable"
          style={{ maxWidth: `75vw` }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="display-6">{name}</h1>
              <button
                className="btn btn-close bg-danger me-1"
                data-bs-dismiss="modal"
              />
            </div>

            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="d-flex">
                  {/* CLIENT */}
                  <div className="p-2 mb-3 w-100">
                    <label className="lead fw-bold">Client</label>
                    <select
                      type="text"
                      value={clientId}
                      className="form-select"
                      disabled
                    >
                      <option value={null}>All</option>
                      {clientsSlice.data.map((item, key) => (
                        <option key={key} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* SITE */}
                  <div className="p-2 mb-3 w-100">
                    <label className="lead fw-bold">Site</label>
                    <select
                      type="text"
                      value={siteId}
                      className="form-select"
                      disabled
                    >
                      <option value={null}>All</option>
                      {clientSites.map((item, key) => (
                        <option key={key} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="d-flex">
                  {/* EVENT TYPE */}
                  <div className="p-2 mb-3 w-100">
                    <label className="lead fw-bold">Type</label>
                    <select className="form-select" value={eventType} disabled>
                      <option value="">Select Event Type</option>
                      {[...EventTypes].map((item, key) => (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* EVENT NAME */}
                  <div className="p-2 mb-3 w-100">
                    <label className="lead fw-bold">Event</label>
                    <select className="form-select" value={event} disabled>
                      <option value="">Select Event</option>
                      {events.map((item, key) => (
                        <option key={key} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* FORM NAME */}
                <div className="p-2 mb-3">
                  <label className="lead fw-bold">Form Name</label>
                  <input
                    type="text"
                    value={name}
                    className="form-control form-control-lg"
                    disabled
                  />
                </div>
              </div>
              <ReactFormGenerator
                // download_path=''
                // back_action='/'
                // back_name='Back'
                // answer_data={answers}
                // action_name='Save'
                // form_action='/api/form'
                // form_method='POST'
                skip_validations={true}
                onSubmit={onAddForm}
                // onNotValid={() => console.log("NOT VALID")}
                variables={variables}
                data={state}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
