import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading.js';
import { TableTitleBar } from '../../components/TableTitleBar.js';
import { apiFindAllByType } from '../../reduxPie/personsSlice.js';
import { setPursuer } from '../../reduxPie/selectedSlice.js';

export function Pursuers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const personsSlice = useSelector((state) => state.persons);
  const [pursuers, setPursuers] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'name', flex: 1, filter: true, sortable: true },
    { field: 'driverLicense', flex: 1, filter: true, sortable: true },
  ]);

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setPursuer(item.data));
    navigate('/pursuers/edit-pursuer');
  };

  // ON REFRESH PURSUERS
  const onRefreshPursuers = () => {
    dispatch(apiFindAllByType('Pursuer'));
  };

  // AUTO FETCH PURSUERS ( PERSONS )
  useEffect(() => {
    onRefreshPursuers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SEARCH BY
  useEffect(() => {
    setPursuers(personsSlice.data.filter((x) => x.type === 'Pursuer'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personsSlice.data]);

  // RENDER
  if (personsSlice.isLoading) return <Loading title="Pursuers" />;
  return (
    <div className="d-flex flex-fill text-danger flex-column">
      {/* TITLE BAR */}
      <TableTitleBar
        title="Pursuers"
        addLabel="Pursuer"
        addLink="add-pursuer"
        hideSearch={true}
        onRefresh={onRefreshPursuers}
        lastRefresh={personsSlice.lastRefresh}
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
        <AgGridReact rowData={pursuers} rowClass="bg-danger" columnDefs={columnDefs} onRowClicked={onViewDetails} />
      </div>
    </div>
  );
}
