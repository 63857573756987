/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiLink } from "./apiConfig";

const initialState = {
  isLoading: false,
  lastRefresh: "",
  data: [],
};
axios.defaults.withCredentials = true;

// API FIND AUDIT LOGS BY LOG ID
export const apiFindAuditLogsByLogId = createAsyncThunk("audit-logs/find/byLogId", async ({ logid }) => {
  console.log("LOG ID: ", logid);
  const auditLogs = await axios
    .get(`${apiLink}/auditLogs/${logid}`)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR API AUDIT LOGS FIND BY LOG ID: ", err));

  return auditLogs;
});

const auditLogsSlice = createSlice({
  name: "auditLogsSlice",
  initialState,
  reducers: {
    clearAuditLogs() {
      return initialState;
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND AUDIT LOGS BY LOG ID
      .addCase(apiFindAuditLogsByLogId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(apiFindAuditLogsByLogId.fulfilled, (state, action) => {
        return {
          isLoading: false,
          lastRefresh: new Date(),
          data: action.payload ? action.payload.reverse() : [],
        };
      })
      .addCase(apiFindAuditLogsByLogId.rejected, (state) => {
        state.isLoading = false;
      }),
});

export const { clearAuditLogs } = auditLogsSlice.actions;

export default auditLogsSlice.reducer;
