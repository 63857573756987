import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setClient, setSite } from "../../reduxPie/selectedSlice";

export function VisitorSearch() {
  const dispatch = useDispatch();
  // CLIENT LIST
  const clientsSlice = useSelector((state) => state.clients);
  const [clientSelect, setClientSelect] = useState({ name: "All Clients" });
  const Clients = clientsSlice.data;
  // SITE LIST
  const sitesSlice = useSelector((state) => state.sites);
  const [siteSelect, setSiteSelect] = useState({ name: "All Sites" });
  const Sites = sitesSlice.data;
  const [sites, setSites] = useState(Sites);

  // CLEARS SELECTED CLIENT & SITE ON REFRESH
  useEffect(() => {
    dispatch(setClient(clientSelect));
    dispatch(setSite(siteSelect));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CLIENT FILTER
  useEffect(() => {
    dispatch(setClient(clientSelect));
    if (clientSelect.name === "All Clients") return setSites(Sites);
    else setSites(Sites.filter((x) => x.client._id.$oid === clientSelect._id.$oid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelect]);

  // SITE FILTER
  useEffect(() => {
    dispatch(setSite(siteSelect));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelect]);

  return (
    <div className='d-flex flex-fill centered'>
      <div className='card bg-dark text-light p-5'>
        <h1 className='card-title display-6 mb-4'>Visitor Search</h1>

        {/* CLIENT FILTER */}
        <div className='d-flex flex-column mb-5'>
          {/* CLIENT FILTER */}
          <div className='d-grid mb-3'>
            <button className='btn btn-secondary dropdown-toggle' data-bs-toggle='dropdown' disabled>
              {clientSelect.name}
            </button>
            <ul className='dropdown-menu dropdown-menu-dark'>
              <li>
                <button className='dropdown-item' onClick={() => setClientSelect({ name: "All Clients" })}>
                  All Clients
                </button>
              </li>
              {Clients.map((item, key) => (
                <li key={key}>
                  <button className='dropdown-item' onClick={() => setClientSelect(item)}>
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* SITE FILTER */}
          <div className='d-grid mb-3'>
            <button className='btn btn-secondary dropdown-toggle' data-bs-toggle='dropdown' disabled>
              {siteSelect.name}
            </button>
            <ul className='dropdown-menu dropdown-menu-dark'>
              <li>
                <button className='dropdown-item' onClick={() => setSiteSelect({ name: "All Sites" })}>
                  All Sites
                </button>
              </li>
              {sites.map((item, key) => (
                <li key={key}>
                  <button className='dropdown-item' onClick={() => setSiteSelect(item)}>
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <Link to='/visitors' className='btn btn-success'>
          Search
        </Link>
      </div>
    </div>
  );
}
