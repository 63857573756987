import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInput } from "../../../../components/FormInput";
import { Loading } from "../../../../components/Loading";
import { TableTitleBar } from "../../../../components/TableTitleBar";
import { addBranch, apiAddBranch, apiFindAllBranches } from "../../../../reduxPie/branchesSlice";

export function Branches() {
  const table = useRef();
  const dispatch = useDispatch();
  const regionsSlice = useSelector((state) => state.regions);
  const branchesSlice = useSelector((state) => state.branches);
  const { loggedIn } = useSelector((state) => state.selected);
  const { isLoading, lastRefresh, data } = useSelector((state) => state.branches);
  const [branches, setBranches] = useState([]);
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [region, setRegion] = useState("0");

  // COLUMN DEFS
  const [columnDefs] = useState([
    {
      field: "region",
      flex: 1,
      sortable: true,
      filter: true,
      valueFormatter: ({ value }) => regionInfoPull(value),
    },
    { field: "name", flex: 1, sortable: true, filter: true },
  ]);

  // ON ROW CLICKED
  const onRowClicked = () => {};

  // ON REFRESH BRANCHES
  const onRefreshBranches = () => {
    dispatch(apiFindAllBranches());
  };

  // REGION INFO PULL
  const regionInfoPull = (region) => {
    const regionInfo = regionsSlice?.data?.find((x) => x._id === region);
    if (regionInfo) return regionInfo.name;
  };

  // ON ADD NEW BRANCH
  const onAddBranch = () => {
    if (name.length !== 0 && region !== "0") {
      let newBranch = {
        customer: loggedIn.customer,
        region,
        name,
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
      };
      dispatch(addBranch(newBranch));
      dispatch(apiAddBranch(newBranch));
      window.alert(`The ${name} Branch Has Been Added`);
    } else {
      window.alert("Please Try Again\nInvalid Values Found");
    }
    setRegion("0");
    setName("");
  };

  // AUTO FETCH BRANCHES
  useEffect(() => {
    if (!data.length) onRefreshBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO REFRESH REDUX
  useEffect(() => {
    setBranches(branchesSlice.data);
  }, [branchesSlice.data]);

  // RENDER
  if (isLoading) return <Loading title='Branches' />;
  else if (regionsSlice.isLoading) return <Loading title='Regions' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Branches'
        search={search}
        setSearch={setSearch}
        onRefresh={onRefreshBranches}
        addLabel='Branch'
        modalId='addBranchModal'
        lastRefresh={lastRefresh}
      />

      {/* ADD BRANCH POP UP */}
      <div id='addBranchModal' className='modal fade' data-bs-backdrop='static'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content bg-black text-light'>
            <div className='modal-header'>
              <h1 className='display-6 fs-2 fw-bold'>Add New Branch</h1>
              <button className='btn btn-close bg-danger me-1' data-bs-dismiss='modal' />
            </div>

            <div className='modal-body'>
              {/* REGION SELECTOR */}
              <FormInput
                type='select'
                label='Region'
                value={region}
                setValue={setRegion}
                defaultLabel='Select Region'
                options={regionsSlice?.data?.map((x) => ({ label: x.name, value: x._id }))}
              />

              {/* ADD NAME INPUT */}
              <FormInput label='Name' placeholder='Enter Name for Branch' value={name} setValue={setName} />
            </div>

            {/* ADD BRANCH FOOTER */}
            <div className='modal-footer'>
              <div className='d-flex w-100 centered'>
                <button className='btn btn-success' data-bs-dismiss='modal' onClick={onAddBranch}>
                  Save New Branch
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TABLE  */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact
          ref={table}
          rowData={branches}
          defaultColDef={{ resizable: true }}
          columnDefs={columnDefs}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}
