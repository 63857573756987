import React from 'react';
import { IST } from '../ist/IST';
import { tabs } from './tabs';
import { Link } from 'react-router-dom';
import { DropdownMenu } from './DropdownMenu';
import { useSelector } from 'react-redux';

export function SideBar() {
  // HEIGHT FOR MARGIN TOP
  const headerHeight = window.innerHeight * 0.15;

  // SIDEBAR MENU HEIGHT
  const height = window.innerHeight * 0.775;

  // BTN CLASSNAME
  const btnClass = 'btn btn-outline-light border-0 mb-2';

  // SIDEBAR MENU WIDTH
  const width = window.innerWidth * 0.15;
  const { loggedIn, site } = useSelector((state) => state.selected);

  // RENDER
  return (
    <div
      className="fixed-top d-flex flex-column bg-black"
      style={{
        marginTop: headerHeight,
        width,
      }}>
      {/* IST BTN */}
      <IST />
      <div>
        {/* SIDE BAR NAV MENU */}
        <div className="d-flex flex-column align-items-start ps-3 py-3 overflow-auto shadow" style={{ height }}>
          {/* HOME */}
          <Link to="/" className={btnClass}>
            Home
          </Link>

          {/* MANAGE */}
          {loggedIn.level === '1' && <DropdownMenu title="Manage" menu={tabs.manage} />}

          {/* CLIENTS & SITES */}
          {loggedIn.level === '4' && (
            <Link to="/manage/clients" className={btnClass}>
              Clients & Sites
            </Link>
          )}

          {/* PURSUERS */}
          <Link to="/pursuers" className={btnClass}>
            Pursuers
          </Link>

          {/* VEHICLES */}
          <Link to="/vehicles" className={btnClass}>
            Vehicles
          </Link>

          {/* SHORTCUT LINK LIST  */}
          {site?._id ? (
            <div className="d-flex flex-column text-light">
              <span className="lead fw-bold my-2">{site?.name}</span>
              <Link to="/manage/clients/sites/visitor-check-in" className={btnClass}>
                Visitor Check-In
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
