import React, { useMemo } from "react";
import { Link } from "react-router-dom";

export function FormTitleBar({
  view,
  setView,
  name,
  eventType,
  event,
  onEditForm,
  onDeleteForm,
}) {
  const btnSelected = "btn btn-sm btn-dark";
  const btnUnselected = "btn btn-sm btn-outline-secondary";

  const renderActionButtons = useMemo(() => (
        <>
        <button className="btn btn-sm me-2 btn-danger" onClick={onDeleteForm}>
          Delete Form
        </button>
        <button className="btn btn-sm me-2 btn-success" onClick={onEditForm}>
          Save Form
        </button>
      </>
  ), [onDeleteForm, onEditForm]);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex centered">
        {/* BACK BTN */}
        <Link to={-1} className="btn btn-sm btn-dark me-3">
          Back
        </Link>

        {/* TITLE */}
        <h1 className="display-6 fs-2 fw-bold user-select-none">
          Form Builder
        </h1>
      </div>

      {/* TOGGLE VIEW */}
      <div className="btn-group" role="group">
        <button
          className={view === "tags" ? btnSelected : btnUnselected}
          onClick={() => setView("tags")}
        >
          Tags
        </button>
        <button
          className={view === "form" ? btnSelected : btnUnselected}
          onClick={() => setView("form")}
        >
          Form
        </button>
      </div>

      {/* PREVIEW BTN */}
      <div>
        {onEditForm && renderActionButtons}
        <button
          className="btn btn-sm btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#previewModal"
          disabled={!name || !eventType || !event}
        >
          Preview Form
        </button>
      </div>
    </div>
  );
}
