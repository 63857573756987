import React from "react";

export function AddMultipleInputs({
  height = window.innerHeight * 0.2,
  protectee = false,
  disabled = false,
  title,
  details = [],
  setDetails,
  value,
  setValue,
  label,
  categories = [],
  category,
  setCategory,
}) {
  // ON STRING ADD ROW
  const onAddStringRowClick = () => {
    if (value.length !== 0) {
      setDetails([...details, value]);
      setValue("");
    }
  };

  // ON OBJECT ADD ROW
  const onAddObjectRowClick = () => {
    if (value.length !== 0) {
      setDetails([...details, { name: value, status: "IR" }]);
      setValue("");
    }
  };

  // ON ADD NOTE
  const onAddNote = () => {
    if (value.length !== 0) {
      setDetails([...details, { data: `${category} - ${value}`, submittedOn: new Date().toLocaleString() }]);
      setValue("");
    }
  };

  //   ON DELETE LAST ROW
  const onDeleteRowClick = () => {
    setDetails([...details.slice(0, details.length - 1)]);
    setValue("");
  };

  if (protectee)
    return (
      <div className='col'>
        <div className='d-flex flex-column bg-light text-dark rounded p-2'>
          {/* TITLE */}
          <h1 className='lead fw-bold'>{title}</h1>
          <div
            className='d-flex flex-column card bg-secondary shadow rounded overflow-auto p-3 mb-3'
            style={{ height }}>
            {/* LIST */}
            {details.map((item, key) => (
              <div key={key} className='input-group input-group-sm mb-2'>
                <span className='input-group-text'>{label}</span>
                <input type='text' className='form-control' value={item.name} disabled />
                <span className='input-group-text'>{item.status}</span>
              </div>
            ))}

            {/* REMOVE LAST BTN */}
            <button
              className='btn btn-sm btn-outline-danger border-0 danger'
              type='button'
              onClick={() => onDeleteRowClick()}
              disabled={details.length === 0 || disabled}>
              Remove Last {label}
            </button>
          </div>
          <div className='input-group input-group-sm border border-2 border-secondary rounded-3'>
            {/* INPUT */}
            <input
              value={value}
              className='form-control'
              placeholder={`Enter ${label}`}
              disabled={disabled}
              onChange={(e) => setValue(e.target.value)}
            />

            {/* ADD BTN */}
            <button className='btn btn-sm btn-primary' type='button' onClick={() => onAddObjectRowClick()}>
              Add {label}
            </button>
          </div>
        </div>
      </div>
    );

  if (categories && setCategory)
    return (
      <div className='col'>
        <div className='d-flex flex-column bg-light text-dark rounded p-2'>
          {/* TITLE */}
          <h1 className='lead fw-bold'>{title}</h1>
          <div
            className='d-flex flex-column card bg-secondary shadow rounded overflow-auto p-3 mb-3'
            style={{ height }}>
            {/* LIST */}
            {details.map((item, key) => (
              <div key={key} className='input-group input-group-sm mb-2'>
                <span className='input-group-text'>{item.submittedOn}</span>
                <textarea
                  rows={item.data.length / 44 + 1 <= 4 ? item.data.length / 44 + 1 : 4}
                  type='text'
                  className='form-control'
                  value={item.data}
                  disabled
                />
              </div>
            ))}
            {/* REMOVE LAST BTN */}
            <button
              className='btn btn-sm btn-outline-danger border-0 danger'
              type='button'
              onClick={() => onDeleteRowClick()}
              disabled={details.length === 0 || disabled}>
              Remove Last {label}
            </button>
          </div>
          <div className='input-group input-group-sm border border-2 border-secondary rounded-3'>
            {/* CATEGORIES */}
            <div className='col-3'>
              <select
                className='form-select h-100 form-select-sm text-wrap'
                value={category}
                onChange={(x) => setCategory(x.target.value)}>
                {categories.map((item, key) => (
                  <option key={key}>{item}</option>
                ))}
              </select>
            </div>

            {/* INPUT */}
            <div className='col'>
              <textarea
                rows={value.length / 44 + 1 <= 4 ? value.length / 44 + 1 : 4}
                value={value}
                className='form-control h-100 form-control-sm'
                placeholder={`Enter ${label}`}
                disabled={disabled}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>

            {/* ADD BTN */}
            <div className='col-3 d-grid'>
              <button className='btn btn-sm btn-primary' type='button' onClick={() => onAddNote()}>
                Add {label}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className='col'>
      <div className='d-flex flex-column bg-light text-dark rounded p-2'>
        {/* TITLE */}
        <h1 className='lead fw-bold'>{title}</h1>
        <div className='d-flex flex-column card bg-secondary shadow rounded overflow-auto p-3 mb-3' style={{ height }}>
          {/* LIST */}
          {details.map((item, key) => (
            <div key={key} className='input-group input-group-sm mb-2'>
              <span className='input-group-text'>{label}</span>
              <input type='text' className='form-control' value={item} disabled />
            </div>
          ))}
          {/* REMOVE LAST BTN */}
          <button
            className='btn btn-sm btn-outline-danger border-0 danger'
            type='button'
            onClick={() => onDeleteRowClick()}
            disabled={details.length === 0 || disabled}>
            Remove Last {label}
          </button>
        </div>
        <div className='input-group input-group-sm border border-2 border-secondary rounded-3'>
          {/* INPUT */}
          <div className='col'>
            <input
              value={value}
              className='form-control form-control-sm'
              placeholder={`Enter ${label}`}
              disabled={disabled}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>

          {/* ADD BTN */}
          <div className='col-3 d-grid'>
            <button className='btn btn-sm btn-primary' type='button' onClick={() => onAddStringRowClick()}>
              Add {label}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
