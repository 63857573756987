import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FormInput } from "../../../../../components/FormInput";
import { s3Link } from "../../../../../reduxPie/apiConfig";
import { apiDeleteManual, apiUpdateManual } from "../../../../../reduxPie/manualsSlice";
import { binderTabs } from "../CCManuals";
import { EditTitleBar } from "../../../../../components/EditTitleBar";
import { Loading } from "../../../../../components/Loading";

export function EditManual() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { manual, site, loggedIn } = useSelector((state) => state.selected);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState(manual.name.slice(0, manual.name.indexOf(".")));
  const [group, setGroup] = useState(manual.group);
  const [selectedGroup, setSelectedGroup] = useState(manual.group);
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // ON UPDATE MANUAL
  const onUpdateManual = () => {
    setIsLoading(true);
    const updatedManual = {
      id: manual._id,
      customer: manual.customer,
      client: manual.client,
      site: manual.site,
      name: name + ".pdf",
      group: selectedGroup === "Other" ? group : selectedGroup,
      createdOn: manual.createdOn,
      createdBy: manual.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };

    if (
      !name?.includes(".") &&
      ((selectedGroup && selectedGroup !== "Other") || (selectedGroup === "Other" && group)) &&
      file
    ) {
      let formData = new FormData();
      formData.append("collection", "Manuals");
      formData.append("id", site._id);
      formData.append("type", selectedGroup === "Other" ? group : selectedGroup);
      formData.append("file", file, name + ".pdf");
      dispatch(apiUpdateManual({ updatedManual, formData }));

      setTimeout(() => {
        navigate(-1);
      }, 5000);
    } else {
      window.alert("Required:\n\n- File Name ( No Spaces or Periods )\n- Tab\n- File");
    }
  };

  // ON DELETE MANUAL
  const onDeleteManual = () => {
    const updatedManual = {
      id: manual._id,
      customer: manual.customer,
      client: manual.client,
      site: manual.site,
      name: manual.name,
      group: manual.group,
      active: false,
      createdOn: manual.createdOn,
      createdBy: manual.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };

    const res = window.confirm(
      `*** DELETE WARNING ***\n\nAre you sure you want to delete\nCC Manual: ${manual.name.slice(
        0,
        manual.name.indexOf(".")
      )} ?\n`
    );

    if (res === true && manual?._id) {
      dispatch(apiDeleteManual({ updatedManual, s3Link: `Manuals/${site?._id}/${manual?.group}//${manual?.name}` }));
      window.alert(
        `*** DELETE CONFIRMATION ***\n\n${manual.name.slice(
          0,
          manual.name.indexOf(".")
        )} has been archived.\nContact Tech Support if a mistake has been made.`
      );
      navigate(-1);
    } else {
      window.alert("*** DELETE CANCELED ***");
    }
  };

  if (isLoading) return <Loading title='Manuals' />;
  return (
    <div className='d-flex flex-fill flex-column card bg-black text-light'>
      {/* TITLE BAR */}
      <EditTitleBar
        title={manual.name.slice(0, manual.name.indexOf("."))}
        onUpdate={onUpdateManual}
        backLink={-1}
        disabled={disabled}
        setDisabled={setDisabled}
      />

      <div className='d-flex flex-fill'>
        {/* FILE DETAILS */}
        <div className='d-flex'>
          {/* FILE NAME */}
          <div className='col-4 w-100 px-3'>
            {/* NAME */}
            <FormInput
              label='Name'
              placeholder='Enter Name for File'
              value={name}
              setValue={setName}
              disabled={disabled}
            />

            {/* TAB */}
            <FormInput
              type='select'
              label='Tab'
              value={selectedGroup}
              setValue={setSelectedGroup}
              defaultLabel='Select Tab to Place Manual'
              defaultValue={""}
              options={binderTabs.map((x) => ({ label: x, value: x }))}
              disabled={disabled}
            />
            {selectedGroup === "Other" && (
              <input
                className='form-control form-control-sm mb-3'
                placeholder='Enter New Tab'
                value={group}
                onChange={(x) => setGroup(x.target.value)}
              />
            )}

            {/* FILE */}
            <label htmlFor='file' className='fw-bold lead'>
              File
            </label>
            <input
              id='file'
              type='file'
              className='form-control form-control-sm'
              accept='.pdf'
              disabled={disabled}
              onChange={(x) => setFile(x.target.files[0])}
            />

            {/* DELETE BTN */}
            <div className='d-flex centered my-5'>
              <button className='btn btn-sm btn-danger' onClick={() => onDeleteManual()} disabled={disabled}>
                Delete {manual.name.slice(0, manual.name.indexOf("."))}
              </button>
            </div>
          </div>
        </div>
        <div className='col d-flex flex-fill bg-black'>
          {/* PDF VIEW */}
          <iframe
            src={`https://${s3Link}/Manuals/${site?._id}/${manual?.group}//${manual?.name}`}
            className='d-flex flex-fill'
            title={manual?.name}
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}
