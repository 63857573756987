import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import {
  ReactFormBuilder,
  ReactFormGenerator,
} from "../../../../components/react-form-builder2/lib";
import store from "../../../../components/react-form-builder2/lib/stores/store";
import * as variables from "../../../../components/react-form-builder2/variables";
import { apiFindAllEvents } from "../../../../reduxPie/eventsSlice";
import {
  apiDeleteForm,
  apiUpdateForm,
  deleteForm,
  updateForm,
} from "../../../../reduxPie/formsSlice";
import { FormTitleBar } from "./FormTitleBar";
import { apiFindAllSites } from "../../../../reduxPie/sitesSlice";

export function EditForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, form } = useSelector((state) => state.selected);
  const eventsSlice = useSelector((state) => state.events);
  const clientsSlice = useSelector((state) => state.clients);
  const sitesSlice = useSelector((state) => state.sites);
  const Event = eventsSlice.data.find((x) => x._id === form.event);
  const [events, setEvents] = useState([]);
  const EventTypes = new Set(eventsSlice.data.map((item) => item.type));
  const [state, setState] = useState(form.data);
  const [view, setView] = useState("tags");

  // FORM STATE VALUES
  const [clientId, setClientId] = useState(form.client);
  const [siteId, setSiteId] = useState(form.site);
  const [name, setName] = useState(form.name);
  const [eventType, setEventType] = useState("");
  const [event, setEvent] = useState(form.event);
  const [clientSites, setClientSites] = useState([]);

  useEffect(() => {
    if (clientId) {
      const selectedClientSites = sitesSlice.data.filter((x) =>
        clientId === "All" || null ? true : x.client === clientId && x.active
      );
      if (selectedClientSites.length) {
        setClientSites(selectedClientSites);
      } else {
        setSiteId("");
        setClientSites([]);
      }
    }
  }, [clientId, sitesSlice.data]);

  store.subscribe((thisState) => {
    setState([...thisState.data]);
  });

  //   TOOLBAR ITEMS
  const toolbarItems = [
    { key: "Header" },
    { key: "Label" },
    { key: "TextInput" },
    { key: "RadioButtons" },
    { key: "Checkboxes" },
    // { key: "Image" },
    // { key: "Signature" },
    { key: "Dropdown" },
    // { key: "NumberInput" },
    { key: "DatePicker" },
    // { key: "Camera" },
    { key: "TextArea" },
    // { key: "HyperLink" },
  ];

  // ON SUBMIT ( EDIT FORM )
  const onEditForm = () => {
    // FORM OBJECT
    const updatedForm = {
      id: form._id,
      customer: form.customer,
      // region: form.region,
      // branch: form.branch,
      client: clientId,
      site: siteId,
      event,
      name,
      data: store.state.data,
      createdBy: form.createdBy,
      createdOn: form.createdOn,
      modifiedBy: loggedIn._id,
      modifiedOn: new Date().toISOString(),
    };
    try {
      dispatch(
        updateForm({
          ...form,
          event,
          name,
          client: clientId,
          site: siteId,
          data: store.state.data,
          modifiedBy: loggedIn._id,
          modifiedOn: new Date().toISOString(),
        })
      );
      dispatch(apiUpdateForm(updatedForm));
      window.alert(`${form.name} has been Updated`);
      navigate("/manage/forms");
    } catch (err) {
      console.error("ERROR FORM BUILDER: ", err);
      dispatch(
        updateForm({
          ...form,
          event,
          name,
          client: clientId,
          site: siteId,
          data: store.state.data,
          modifiedBy: loggedIn._id,
          modifiedOn: new Date().toISOString(),
        })
      );
      dispatch(apiUpdateForm(updatedForm));
      window.alert(`${form.name} has been Updated`);
      navigate("/manage/forms");
    }
  };

  const onDeleteForm = () => {
    const result = window.confirm(
      `*** DELETE WARNING ***\n\nYou will delete Form: ${form?.name}.\nAre you sure?`
    );
    if (result) {
      dispatch(deleteForm(form._id));
      dispatch(apiDeleteForm(form._id));
      window.alert(`Form: ${form.name} has been deleted.`);
      setTimeout(() => navigate("/manage/forms"), 500);
    } else {
      window.alert("*** DELETE CANCELLED ***");
    }
  };

  // AUTO FETCH EVENTS
  useEffect(() => {
    setEvent(Event ? Event._id : "");
    setEventType(Event ? Event.type : "");
    dispatch(apiFindAllSites());
    if (!eventsSlice.data.length) dispatch(apiFindAllEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // EVENT FILTER
  useEffect(() => {
    setEvents(eventsSlice.data.filter((x) => x.type === eventType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType, eventsSlice.data]);

  if (eventsSlice.isLoading) return <Loading title="Forms" />;
  return (
    <div className="d-flex flex-column flex-fill">
      {/* TITLE BAR */}
      <FormTitleBar
        view={view}
        setView={setView}
        name={name}
        eventType={eventType}
        event={event}
        onEditForm={onEditForm}
        onDeleteForm={onDeleteForm}
      />

      {/* TAGS VIEW */}
      {view === "tags" && (
        <div className="d-flex flex-fill centered bg-secondary">
          {/* TITLE BAR */}
          <div className="d-flex flex-column col-5 p-3">
            {/* CLIENT */}
            <div className="card bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Client</label>
              <select
                type="text"
                value={clientId}
                className="form-select"
                onChange={(x) => setClientId(x.target.value)}
              >
                <option value={null}>All</option>
                {clientsSlice.data.map((item, key) => (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {/* SITE */}
            <div className="card bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Site</label>
              <select
                type="text"
                value={siteId}
                className="form-select"
                onChange={(x) => setSiteId(x.target.value)}
              >
                <option value={null}>All</option>
                {clientSites.map((item, key) => (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {/* FORM NAME */}
            <div className="card border-danger border-3 bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Form Name *</label>
              <input
                type="text"
                placeholder="Enter Form Name"
                value={name}
                className="form-control"
                onChange={(x) => setName(x.target.value)}
              />
            </div>

            {/* EVENT TYPE */}
            <div className="card border-danger border-3 bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Type *</label>
              <select
                className="form-select"
                value={eventType}
                onChange={(x) => setEventType(x.target.value)}
              >
                <option value="">Select Event Type</option>
                {[...EventTypes].map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            {/* EVENT NAME */}
            <div className="card border-danger border-3 bg-dark p-2 mb-3">
              <label className="lead fw-bold text-light">Event *</label>
              <select
                className="form-select"
                value={event}
                onChange={(x) => setEvent(x.target.value)}
              >
                <option value="">Select Event</option>
                {events.map((item, key) => (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {view === "form" && (
        <ReactFormBuilder
          data={state}
          variables={variables}
          onChange={(e) => console.log(e, "eeee")}
          toolbarItems={toolbarItems}
        />
      )}

      {/* PREVIEW POP-UP */}
      <div className="modal fade" id="previewModal" data-bs-backdrop="static">
        <div
          className="modal-dialog modal-lg modal-dialog-scrollable"
          style={{ maxWidth: `75vw` }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="display-6">{name}</h1>
              <button
                className="btn btn-close bg-danger me-1"
                data-bs-dismiss="modal"
              />
            </div>

            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="d-flex">
                  {/* CLIENT */}
                  <div className="p-2 mb-3 w-100">
                    <label className="lead fw-bold">Client</label>
                    <select
                      type="text"
                      value={clientId}
                      className="form-select"
                      disabled
                    >
                      <option value={null}>All</option>
                      {clientsSlice.data.map((item, key) => (
                        <option key={key} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* SITE */}
                  <div className="p-2 mb-3 w-100">
                    <label className="lead fw-bold">Site</label>
                    <select type="text" value={siteId} className="form-select" disabled>
                      <option value={null}>All</option>
                      {clientSites.map((item, key) => (
                        <option key={key} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="d-flex">
                  {/* EVENT TYPE */}
                  <div className="p-2 mb-3 w-100">
                    <label className="lead fw-bold">Type</label>
                    <select className="form-select" value={eventType} disabled>
                      <option value="">Select Event Type</option>
                      {[...EventTypes].map((item, key) => (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* EVENT NAME */}
                  <div className="p-2 mb-3 w-100">
                    <label className="lead fw-bold">Event</label>
                    <select className="form-select" value={event} disabled>
                      <option value="">Select Event</option>
                      {events.map((item, key) => (
                        <option key={key} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* FORM NAME */}
                <div className="p-2 mb-3">
                  <label className="lead fw-bold">Form Name</label>
                  <input
                    type="text"
                    value={name}
                    className="form-control form-control-lg"
                    disabled
                  />
                </div>
              </div>
              <ReactFormGenerator
                // download_path=''
                // back_action='/'
                // back_name='Back'
                // answer_data={answers}
                // action_name='Save'
                // form_action='/api/form'
                // form_method='POST'
                skip_validations={true}
                onSubmit={onEditForm}
                // onNotValid={() => console.log("NOT VALID")}
                variables={variables}
                data={state}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
