import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddTitleBar } from "../../../../components/AddTitleBar";
import { FormInput } from "../../../../components/FormInput";
import { addSite, apiAddSite } from "../../../../reduxPie/sitesSlice";

export function AddClientSite() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, client } = useSelector((state) => state.selected);
  const formDefault = "form-control form-control-sm ";
  const formValid = formDefault + "is-valid ";
  const formInvalid = formDefault + "is-invalid ";

  // FORM VALIDATION STATE
  const [formName, setFormName] = useState(formDefault);

  // FORM VALUE STATE
  const [name, setName] = useState("");
  const [phrase, setPhrase] = useState("");
  const [active, setActive] = useState(true);
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [firstShift, setFirstShift] = useState("");
  const [leadCC, setLeadCC] = useState(false);
  const [manned, setManned] = useState(false);
  const [policeDep, setPoliceDep] = useState("");
  const [fireDep, setFireDep] = useState("");
  const [hospital, setHospital] = useState("");

  // NAME VALIDATION
  useEffect(() => {
    // Only Alphabet Characters
    if (name.length > 0 && name.length === name.trim().length && name.match("(^[a-zA-Z0-9\\s]*$)")) {
      setFormName(formValid);
    } else if (name.length === 0) {
      setFormName(formDefault);
    } else {
      setFormName(formInvalid);
    }
  }, [name, formValid, formInvalid]);

  //   ON ADD SITE
  const onAddSite = () => {
    if (
      formName === formValid &&
      // phrase && // TEMP VALIDATION
      firstShift // TEMP VALIDATION
      // formContactName === formValid &&
      // streetAddress && // TEMP VALIDATION
      // city && // TEMP VALIDATION
      // state && // TEMP VALIDATION
      // zip // TEMP VALIDATION
    ) {
      const newSite = {
        customer: loggedIn.customer,
        client: client._id,
        region: client.region,
        branch: client.branch,
        name,
        phrase,
        active,
        policeDep,
        hospital,
        fireDep,
        fullAddress: {
          streetAddress,
          city,
          state,
          zip,
        },
        firstShift,
        allowedPersons: [],
        leadCC,
        manned,
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };
      dispatch(addSite(newSite));
      dispatch(apiAddSite(newSite));
      window.alert(`Site ${name} Has Been Added`);
      navigate("/manage/clients/sites");
    } else {
      window.alert('Please Fill Out "Add New Site" Form');
    }
  };

  // RENDER
  if (loggedIn.level === "1")
    return (
      <div className='d-flex flex-fill flex-column py-1'>
        <div className='card d-flex flex-fill bg-black text-light shadow-lg'>
          {/* TITLE BAR */}
          <AddTitleBar onAdd={onAddSite} title='Site' backLink='/manage/clients/sites' />

          <div className='d-flex flex-fill px-3 overflow-auto'>
            {/* LEFT COL */}
            <div className='col'>
              <h1 className='lead fw-bold fs-2 text-center'>Basic Info</h1>

              <div className='d-flex mb-3'>
                {/* NAME  */}
                <FormInput label='Name' className='me-3' formClass={formName} value={name} setValue={setName} />

                {/* ACTIVE STATUS */}
                <div className='w-100'>
                  <span className='lead fw-bold'>Active Status</span>
                  <select
                    className='form-select form-select-sm'
                    value={active}
                    onChange={(x) => setActive(x.target.value)}>
                    <option value={true}>Active</option>
                    <option value={false}>Deactivated</option>
                  </select>
                </div>
              </div>

              <div className='d-flex'>
                {/* PHRASE */}
                <FormInput label='Phrase' className='me-3' value={phrase} setValue={setPhrase} />

                {/* FIRST SHIFT */}
                <FormInput type='time' label='First Shift' value={firstShift} setValue={setFirstShift} />
              </div>

              <div className='d-flex justify-content-around mb-3'>
                {/* LEAD CC */}
                <div className='form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='leadCC'
                    checked={leadCC}
                    onChange={(x) => setLeadCC(x.target.checked)}
                  />
                  <span forhtml='leadCC' className='form-check-span'>
                    Lead CC
                  </span>
                </div>

                {/* MANNED SITE */}
                <div className='form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='siteManned'
                    checked={manned}
                    onChange={(x) => setManned(x.target.checked)}
                  />
                  <span forhtml='siteManned' className='form-check-span'>
                    This Site is Manned
                  </span>
                </div>
              </div>

              {/* FULL ADDRESS */}
              <h1 className='lead fw-bold fs-2 text-center'>Full Address</h1>

              <div className='d-flex'>
                {/* STREET ADDRESS */}
                <FormInput label='Street Address' className='me-3' value={streetAddress} setValue={setStreetAddress} />

                {/* CITY */}
                <FormInput label='City' value={city} setValue={setCity} />
              </div>

              <div className='d-flex'>
                {/* STATE */}
                <FormInput label='State' className='me-3' value={state} setValue={setState} />

                {/* ZIP CODE */}
                <FormInput label='Zip Code' value={zip} setValue={setZip} />
              </div>
            </div>

            {/* COL-1 DIVIDER */}
            <div className='col-1' />

            {/* RIGHT COL */}
            <div className='col'>
              {/* EMERGENCY FACILITIES */}
              <h1 className='lead fw-bold fs-2 text-center'>Emergency Facilities</h1>

              {/* POLICE DEPARTMENT */}
              <FormInput label='Police Department' value={policeDep} setValue={setPoliceDep} />

              {/* FIRE DEPARTMENT */}
              <FormInput label='Fire Department' value={fireDep} setValue={setFireDep} />

              {/* HOSPITAL */}
              <FormInput label='Hospital' value={hospital} setValue={setHospital} />
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className='d-flex flex-fill centered'>
        <h1 className='display-4 text-danger'>Access Denied</h1>
      </div>
    );
}
