import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import { TableTitleBar } from "../../../../components/TableTitleBar";
import { apiFindSiteManuals } from "../../../../reduxPie/manualsSlice";
import { setManual } from "../../../../reduxPie/selectedSlice";

export const binderTabs = [
  "CR & SO Outline / Welcome Letter",
  "Protectee(s)",
  "Staff & Vendors",
  "Access Control",
  "Alarm & Security Systems",
  "Utilities",
  "Vehicles",
  "Auxiliary / Remote Monitored Properties (Assets) ",
  "C24 P&G / Employee Handbook",
  "Other",
];

export function CCManuals() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { site } = useSelector((state) => state.selected);
  const manualsSlice = useSelector((state) => state.manuals);
  const [manuals, setManuals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columnDefs] = useState([
    {
      field: "name",
      flex: 1,
      sortable: true,
      filter: true,
    },
    { field: "group", headerName: "Tab", flex: 1, sortable: true, filter: true },
  ]);

  //  ON VIEW DETAILS
  const onViewDetails = (item) => {
    console.log(item.data);
    dispatch(setManual(item.data));
    navigate("edit-manual");
  };

  // ON REFRESH MANUALS
  const onRefreshManuals = () => {
    dispatch(apiFindSiteManuals(site));
  };

  // AUTO FETCH MANUALS
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      onRefreshManuals();
      setIsLoading(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO REFRESH REDUX
  useEffect(() => {
    console.log(manualsSlice?.data);
    setManuals(manualsSlice.data?.filter((x) => x?.active !== false));
  }, [manualsSlice.data]);

  if (isLoading) return <Loading title='Manuals' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Manuals'
        addLabel='Manual'
        addLink='add-manual'
        onRefresh={onRefreshManuals}
        lastRefresh={manualsSlice.lastRefresh}
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact rowData={manuals} columnDefs={columnDefs} onRowClicked={onViewDetails} />
      </div>
    </div>
  );
}
