import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
};
axios.defaults.withCredentials = true;

// API FIND ALL CUSTOMERS
export const apiFindAllCustomers = createAsyncThunk("customers/findAll", async () => {
  const customers = await axios
    .get("https://c24pro.net/customers")
    .then((res) => res.data)
    .catch((err) => console.error("ERROR API FIND ALL CUSTOMERS: ", err));
  return customers;
});

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    clearCustomers(state, action) {
      return initialState;
    },
  },
});

export const { clearCustomers } = customerSlice.actions;

export default customerSlice.reducer;
