import axios from 'axios'
import React from 'react'

function Logout(props) {
  const handleLogout = async () => {
    try {
      window.location.replace(`${process.env.REACT_APP_PRO_API}/logout`)
      const res = await axios.get(`${process.env.REACT_APP_PRO_API}/logout`, { withCredentials: true })
      console.log(res.data)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  return (
    <button type='button' className='btn btn-sm btn-outline-secondary border-0' onClick={() => handleLogout()}>
      Logout
    </button>
  )
}

export default Logout
