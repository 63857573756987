export const tabs = {
  manage: [
    {
      title: "System Settings",
      link: "/manage/system-settings/events",
    },
    {
      title: "Users",
      link: "/manage/users",
    },
    {
      title: "Clients & Sites",
      link: "/manage/clients",
    },
    {
      title: "Form Builder",
      link: "/manage/forms",
    },
    {
      title: "Management Reports",
      link: "/manage/management-reports",
    },
  ],
  personsVehicles: [
    {
      title: "Persons Log",
      link: "/persons-vehicles/persons-log",
    },
    {
      title: "Persons Report",
      link: "/persons-vehicles/persons-report",
    },
    {
      title: "Vehicles Log",
      link: "/persons-vehicles/vehicles-log",
    },
    {
      title: "Vehicles Report",
      link: "/persons-vehicles/vehicles-report",
    },
  ],
};
