import { PDFViewer } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../../components/Loading';
import { apiLogsFindAllBySite } from '../../../../reduxPie/logsSlice';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import { LogsPDF } from '../../../../components/LogsPDF';
import { TableTitleBar } from '../../../../components/TableTitleBar';
import { useNavigate } from 'react-router-dom';
import { setLog } from '../../../../reduxPie/selectedSlice';

export function SiteLogs() {
  const TODAY = new Date().toISOString();
  const tomorrow = new Date(TODAY);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const TMRW = tomorrow.toISOString();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client, site } = useSelector((state) => state.selected);
  const usersSlice = useSelector((state) => state.users);
  const logsSlice = useSelector((state) => state.logs);
  const [logs, setLogs] = useState([]);
  const [fromDate, setFromDate] = useState(TODAY.slice(0, TODAY.indexOf('T')));
  const [toDate, setToDate] = useState(TMRW.slice(0, TMRW.indexOf('T')));
  const [view, setView] = useState('table');

  // PDF DATA
  const [PDFData, setPDFData] = useState([]);

  // CHART OPTIONS
  const [chartOptions, setChartOptions] = useState({});

  // COLUMN DEFS
  const [columnDefs] = useState([
    {
      field: 'submittedOn',
      headerName: 'Time',
      filter: true,
      sortable: true,
    },
    {
      field: 'data',
      headerName: 'Log Info',
      flex: 1,
      filter: true,
      sortable: true,
    },
    { field: 'submittedBy', headerName: 'Created By', filter: true, sortable: true },
  ]);

  // ROW CLASS RULES
  const rowClassRules = {
    'bg-danger': 'data.active === false',
  };

  // ON ROW CLICKED
  const onRowClicked = ({ data }) => {
    if (data?.active === false) {
      window.alert("*** DEACTIVATED LOG ***\n\nCorrections have been made here.\nPlease refer to the Replacement Log.");
    } else {
      dispatch(setLog(data));
      navigate('edit');
    }
  };

  // USER INFO PULL
  const userInfoPull = (item) => {
    // MONGO DB GET => $oid
    const user = usersSlice.data.find((x) => x._id === item.createdBy);

    if (user) return user.name;
    else if (item?.createdBy?.$oid) return item?.createdBy?.$oid;
    else return 'N/A';
  };

  // ON SEARCH LOGS
  const onSearchLogs = () => {
    const logReq = {
      site: site._id,
      fromDate,
      toDate,
    };

    if (fromDate <= toDate) dispatch(apiLogsFindAllBySite(logReq));
    else window.alert('Error With Date Range.\n\nPlease Enter a Valid Date Range.');
  };

  // REDUX FORCED REFRESH
  useEffect(() => {
    setLogs(
      logsSlice.data.map((item) => ({
        ...item,
        submittedBy: userInfoPull(item),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsSlice.data]);

  // AUTO SET CHART DATA
  useEffect(() => {
    const VisitorCheckIns = logs.filter(
      (x) => x.data.includes('Guest') || x.data.includes('Vendor') || x.data.includes('Staff')
    );
    const ProtectorStatus = logs.filter((x) => x.user);
    const ClientStatus = logs.filter((x) => x.data.includes('IR') || x.data.includes('OR'));
    const Reports = logs.filter((x) => x.form);
    const Deliveries = logs.filter((x) => x.delivery);
    const Keys = logs.filter((x) => x.key);
    const ActivityEntries = logs.filter((x) => x.data.includes(`Activity Entry -`) && x.site);
    const formatter = ({ value }) => (value ? value : '');
    const firstLog = new Date(logs[logs.length - 1]?.createdOn).toLocaleDateString();
    const lastLog = new Date(logs[0]?.createdOn).toLocaleDateString();

    const chartData = [
      {
        logType: 'Visitor Activity Logs',
        logCount: VisitorCheckIns.length,
      },
      {
        logType: 'Protector Status Logs',
        logCount: ProtectorStatus.length,
      },
      {
        logType: 'Protectee Status Logs',
        logCount: ClientStatus.length,
      },
      {
        logType: 'Report Logs',
        logCount: Reports.length,
      },
      {
        logType: 'Delivery Logs',
        logCount: Deliveries.length,
      },
      {
        logType: 'Key Activity Logs',
        logCount: Keys.length,
      },
      {
        logType: 'Activity Entry Logs',
        logCount: ActivityEntries.length,
      },
    ];

    const pdfData = [
      { title: 'Visitor Status Activity', data: VisitorCheckIns },
      { title: 'Key Status Activity', data: Keys },
      { title: 'Protector Status Activity', data: ProtectorStatus },
      { title: 'Protectee Status Activity', data: ClientStatus },
      { title: 'Report Logs', data: Reports },
      { title: 'Delivery Logs', data: Deliveries },
      { title: 'Custom Activity Entries', data: ActivityEntries },
    ];

    setPDFData(pdfData);

    setChartOptions({
      title: { text: `${client.name}'s ${site?.name === client.name ? site.name + ' Site' : site.name} Logs` },
      subtitle: {
        text: `${firstLog !== 'Invalid Date' ? firstLog : ''}${firstLog !== lastLog ? ` - ${lastLog}` : ''}`,
      },
      theme: {
        baseTheme: 'ag-vivid',
      },
      autoSize: true,
      data: chartData,
      series: [
        {
          type: 'column',
          xKey: 'logType',
          yKey: 'logCount',
          yName: 'Log Count',
          label: { formatter },
        },
      ],
      legend: {
        position: 'bottom',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);

  // AUTO FETCH DATA
  useEffect(() => {
    onSearchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RENDER
  if (logsSlice.isLoading) return <Loading title="Logs" />;
  return (
    <div className="d-flex flex-fill flex-column">
      {/* TITLE BAR */}
      <TableTitleBar
        title="Site Logs"
        view={view}
        setView={setView}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        onSearchDateRange={onSearchLogs}
        addLabel="Activity Entry"
        addLink="add-entry"
      />

      {/* BODY VIEW ( TABLE, PDF, CHART ) */}
      {view === 'table' && (
        <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
          <AgGridReact
            rowData={logs}
            columnDefs={columnDefs}
            onRowClicked={onRowClicked}
            rowClassRules={rowClassRules}
          />
        </div>
      )}
      {view === 'pdf' && (
        <div className="d-flex flex-fill w-100">
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <LogsPDF PDFData={PDFData} logs={logs} client={client} site={site} />
          </PDFViewer>
        </div>
      )}
      {/* PIE CHART FOR INCIDENT TYPES */}
      {view === 'chart' && (
        <div className="m-auto" style={{ width: '94%', height: '94%' }}>
          <AgChartsReact options={chartOptions} />
        </div>
      )}
    </div>
  );
}
