import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn } from "../../../../../reduxPie/selectedSlice";
import { apiUpdateUser, updateUser } from "../../../../../reduxPie/usersSlice";
import { ReactFormGenerator } from "../../../../../components/react-form-builder2/lib";
import * as variables from "../../../../../components/react-form-builder2/variables";
import { addReport, apiAddReport } from "../../../../../reduxPie/reportsSlice";
import { addLog, apiAddLog } from "../../../../../reduxPie/logsSlice";
import { FormInput } from "../../../../../components/FormInput";

export function ReadinessLog() {
  const MemoizedReactFormGenerator = React.memo(ReactFormGenerator);

  const dispatch = useDispatch();
  const { loggedIn, client, site } = useSelector((state) => state.selected);
  const [onDuty, setOnDuty] = useState(loggedIn.onDuty);
  const eventsSlice = useSelector((state) => state.events);
  const formsSlice = useSelector((state) => state.forms);
  const usersSlice = useSelector((state) => state.users);
  const [options, setOptions] = useState([]);
  const [personSelected, setPersonSelected] = useState("");
  const [userSelected, setUserSelected] = useState("");
  const [dataWithAnswers, setDataWithAnswers] = useState([]);
  const ReadinessLog = formsSlice?.data?.find(
    (x) => x._id === "620149868744885e0d4695c2"
  )
    ? formsSlice.data.find((x) => x._id === "620149868744885e0d4695c2")
    : { data: [] };
  const [filledReadinessLog, setFilledReadinessLog] = useState([]);

  useEffect(() => {
    if (!ReadinessLog.data || !filledReadinessLog || !userSelected) {
      return;
    }
  
    const filledDataMap = filledReadinessLog.reduce((map, filledItem) => {
      map[filledItem.name] = filledItem.value;
      return map;
    }, {});
  
    const filledData = ReadinessLog.data.map((item) => ({
      ...item,
      value: filledDataMap[item.label] || item.value,
    }));
  
    setDataWithAnswers(filledData);
  }, [ReadinessLog.data, filledReadinessLog, userSelected, usersSlice]);

  // ON CHANGE DUTY
  const onChangeDuty = (dutyStatus) => {
    setOnDuty(dutyStatus);

    // LOG
    const newLog = {
      customer: loggedIn.customer,
      client: client._id,
      site: site._id,
      data: `@${site?.name}: Protector - ${loggedIn.name} - ${
        dutyStatus ? "ON DUTY" : "OFF DUTY"
      }`,
      submittedOn: new Date().toLocaleString(),
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
    };

    // UPDATED onDuty, currentClient, currentSite
    const updatedUser = {
      id: loggedIn._id,
      customer: loggedIn.customer,
      region: loggedIn.region,
      branch: loggedIn.branch,
      salt: loggedIn.salt,
      passHash: loggedIn.passHash,
      name: loggedIn.name,
      email: loggedIn.email,
      onDuty: dutyStatus,
      level: loggedIn.level,
      scanOption: loggedIn.scanOption,
      phone: loggedIn.phone,
      status: loggedIn.status,
      active: loggedIn.active,
      imgLink: loggedIn.imgLink,
      currentClient: client._id,
      assignedClients: loggedIn.assignedClients,
      currentSite: site._id,
      assignedSites: loggedIn.assignedSites,
      createdOn: loggedIn.createdOn,
      createdBy: loggedIn.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    dispatch(
      setLoggedIn({
        ...loggedIn,
        onDuty: dutyStatus,
        currentSite: site._id,
        currentClient: client._id,
      })
    );
    dispatch(apiUpdateUser({ updatedUser }));
    dispatch(addLog(newLog));
    dispatch(apiAddLog(newLog));
  };

  const handleUserSelect = (selectedUserId) => {
    // UPDATED onDuty, currentClient, currentSite
    const updatedUser = {
      id: selectedUserId,
      customer: loggedIn.customer,
      region: loggedIn.region,
      branch: loggedIn.branch,
      salt: loggedIn.salt,
      passHash: loggedIn.passHash,
      name: loggedIn.name,
      email: loggedIn.email,
      onDuty: onDuty,
      level: loggedIn.level,
      scanOption: loggedIn.scanOption,
      phone: loggedIn.phone,
      status: loggedIn.status,
      active: loggedIn.active,
      imgLink: loggedIn.imgLink,
      currentClient: client._id,
      assignedClients: loggedIn.assignedClients,
      currentSite: site._id,
      assignedSites: loggedIn.assignedSites,
      createdOn: loggedIn.createdOn,
      createdBy: loggedIn.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    setPersonSelected(selectedUserId);
    const foundSelectedUser = usersSlice.data.filter(
      (item) => item._id === selectedUserId
    );
    setUserSelected(foundSelectedUser[0]);
    selectedUserId && setFilledReadinessLog(foundSelectedUser[0]?.readinessLog);
    selectedUserId && dispatch(apiUpdateUser({ updatedUser }));
  };

  // ON SUBMIT ( ADD REPORT )
  const onSubmit = (data) => {
    const event = eventsSlice.data.find((x) => x._id === ReadinessLog.event);
    // LOG
    const newLog = {
      customer: loggedIn.customer,
      client: client._id,
      site: site._id,
      event: ReadinessLog.event,
      form: ReadinessLog._id,
      data: `@${site?.name}: ${event.type} - ${event.name} - ${ReadinessLog.name} - ${loggedIn.name}`,
      submittedOn: new Date().toLocaleString(),
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
    };

    // REPORT OBJECT
    const newReport = {
      customer: loggedIn.customer,
      region: loggedIn.region,
      branch: loggedIn.branch,
      client: client._id,
      site: site._id,
      form: ReadinessLog._id,
      event: event._id,
      formName: ReadinessLog.name,
      eventName: event.name,
      eventType: event.type,
      data,
      createdBy: personSelected,
      submittedOn: new Date().toLocaleString(),
      createdOn: new Date().toISOString(),
    };
    const updatedUser = {
      id: userSelected._id,
      customer: userSelected.customer,
      region: userSelected.region,
      branch: userSelected.branch,
      salt: userSelected.salt,
      passHash: userSelected.passHash,
      name: userSelected.name,
      email: userSelected.email,
      onDuty: onDuty,
      level: userSelected.level,
      scanOption: userSelected.scanOption,
      phone: userSelected.phone,
      status: userSelected.status,
      active: userSelected.active,
      createdOn: userSelected.createdOn,
      modifiedOn: new Date().toISOString(),
      modifiedBy: userSelected._id,
      readinessLog: data,
    };

    dispatch(addReport(newReport));
    dispatch(apiAddReport(newReport));
    dispatch(addLog(newLog));
    dispatch(apiAddLog(newLog));
    dispatch(updateUser({...userSelected, readinessLog: data}))
    dispatch(apiUpdateUser({ updatedUser }));
    setFilledReadinessLog([])
    setPersonSelected('')
    window.alert(`${ReadinessLog.name} has been submitted `);
  };

  // ON NOT VALID
  const onNotValid = (errors) => {
    window.alert(`FORM NOT SUBMITTED:\n${errors.map((item) => `\n-${item}`)}`);
  };

  // AUTO FILL PERSONNEL DROPDOWN
  useEffect(() => {
    const userOptions = usersSlice.data
      .filter((x) => x.assignedClients?.includes(client._id))
      .map((x) => ({ label: x.name, value: x._id }));
    setOptions(userOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-fill">
      <div className="card container shadow bg-light p-3">
        {/* PROTECTOR ATTENDANCE */}
        <div className="d-grid mb-5">
          <h1 className="display-6 fw-bold text-center">{loggedIn.name}</h1>
          <div className="btn-group btn-group-lg my-3" role="group">
            <button
              className={`btn btn-${onDuty ? "" : "outline-"}primary`}
              onClick={() => onChangeDuty(true)}
            >
              On-Duty
            </button>
            <button
              className={`btn btn-${onDuty ? "outline-" : ""}danger`}
              onClick={() => onChangeDuty(false)}
            >
              Off-Duty
            </button>
          </div>
        </div>

        <h1 className="display-6 text-center">
          <span className="fw-bold">{client?.name} </span>
          {site?.name}
        </h1>

        {/* PROTECTOR FOR READINESS LOG */}
        <FormInput
          type="select"
          label="Protector Being Checked"
          value={personSelected}
          setValue={handleUserSelect}
          defaultLabel="Select Protector"
          options={options}
          required
        />

        {/* FORM */}
        {filledReadinessLog?.length ? (
          <MemoizedReactFormGenerator
            isFromReadinessLog
            onNotValid={onNotValid}
            onSubmit={onSubmit}
            submitType="fill"
            variables={variables}
            data={ReadinessLog.data}
            answer_data={dataWithAnswers}
          />
        ) : (
          <MemoizedReactFormGenerator
            isFromReadinessLog
            onNotValid={onNotValid}
            onSubmit={onSubmit}
            submitType="fill"
            variables={variables}
            data={ReadinessLog.data}
          />
        )}
      </div>
    </div>
  );
}
