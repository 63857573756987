import { PDFViewer } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../components/Loading'
import { apiLogsFindAllByClient } from '../../../../reduxPie/logsSlice'
import { AgChartsReact } from 'ag-charts-react'
import { AgGridReact } from 'ag-grid-react'
import { LogsPDF } from '../../../../components/LogsPDF'
import { TableTitleBar } from '../../../../components/TableTitleBar'

export function ClientLogs() {
  const TODAY = new Date().toISOString()
  const tomorrow = new Date(TODAY)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const TMRW = tomorrow.toISOString()
  const dispatch = useDispatch()
  const { client } = useSelector((state) => state.selected)
  const usersSlice = useSelector((state) => state.users)
  const sitesSlice = useSelector((state) => state.sites)
  const { isLoading, data } = useSelector((state) => state.logs)
  const [logs, setLogs] = useState([])
  const [fromDate, setFromDate] = useState(TODAY.slice(0, TODAY.indexOf('T')))
  const [toDate, setToDate] = useState(TMRW.slice(0, TMRW.indexOf('T')))
  const [view, setView] = useState('table')

  // PDF DATA
  const [PDFData, setPDFData] = useState([])

  // CHART OPTIONS
  const [chartOptions, setChartOptions] = useState({})

  // COLUMN DEFS
  const [columnDefs] = useState([
    {
      field: 'submittedOn',
      valueSetter: () => {},
      headerName: 'Time',
      filter: true,
      sortable: true,
    },
    // { field: "siteName", valueSetter: () => {}, headerName: "Site", filter: true, sortable: true },
    { field: 'data', valueSetter: () => {}, headerName: 'Log Info', flex: 1, filter: true, sortable: true },
    { field: 'submittedBy', valueSetter: () => {}, headerName: 'Created By', filter: true, sortable: true },
  ])

  // ROW CLASS RULES
  const rowClassRules = {
    'bg-danger': 'data.active === false',
  }

  // USER INFO PULL
  const userInfoPull = (item) => {
    // MONGO DB GET => $oid
    const user = usersSlice.data.find((x) => x._id === item.createdBy)

    if (user) return user.name
    else if (item?.createdBy?.$oid) return item?.createdBy?.$oid
    else return 'N/A'
  }

  // SITE INFO PULL
  const siteInfoPull = (item) => {
    // MONGO DB GET => $oid
    const site = sitesSlice.data?.find((x) => x._id === item.site)

    if (site) return site.name
    else if (item.site.$oid) return item.site.$oid
    else return 'N/A'
  }

  // ON SEARCH LOGS
  const onSearchLogs = () => {
    const logReq = {
      client: client._id,
      fromDate,
      toDate,
    }

    if (fromDate <= toDate) dispatch(apiLogsFindAllByClient(logReq))
    else window.alert('Error With Date Range.\n\nPlease Enter a Valid Date Range.')
  }

  // REDUX FORCED REFRESH
  useEffect(() => {
    setLogs(
      data.map((item) => ({
        ...item,
        submittedBy: userInfoPull(item),
        siteName: item.site ? siteInfoPull(item) : client.name,
      }))
    )
    console.log('LOGS: ', logs)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // AUTO FETCH LOGS
  useEffect(() => {
    // MONGO REALM GET
    onSearchLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // AUTO SET CHART DATA
  useEffect(() => {
    const VisitorCheckIns = logs.filter(
      (x) => x.data.includes('Guest') || x.data.includes('Vendor') || x.data.includes('Staff')
    )
    const ProtectorStatus = logs.filter((x) => x.user)
    const ClientStatus = logs.filter((x) => x.data.includes('IR') || x.data.includes('OR'))
    const Reports = logs.filter((x) => x.form)
    const Deliveries = logs.filter((x) => x.delivery)
    const Keys = logs.filter((x) => x.key)
    const ActivityEntries = logs.filter((x) => x.data.includes(`Activity Entry -`) && x.site)
    const clientSites = sitesSlice.data?.filter((x) => x.client === client._id)
    const formatter = ({ value }) => (value ? value : '')
    const firstLog = new Date(logs[logs.length - 1]?.createdOn).toLocaleDateString()
    const lastLog = new Date(logs[0]?.createdOn).toLocaleDateString()

    const chartData = [
      { siteName: client.name, clientStatus: ClientStatus.length },
      ...clientSites.map((site) => ({
        siteName: site.name === client.name ? site.name + ' Site' : site.name,
        visitorCheckIns: VisitorCheckIns.filter((x) => x.site === site._id).length,
        protectorStatus: ProtectorStatus.filter((x) => x.site === site._id).length,
        reports: Reports.filter((x) => x.site === site._id).length,
        deliveries: Deliveries.filter((x) => x.site === site).length,
        keys: Keys.filter((x) => x.site === site._id).length,
        activityEntries: ActivityEntries.filter((x) => x.site === site._id).length,
      })),
    ]

    const pdfData = [
      { title: 'Visitor Status Activity', data: VisitorCheckIns },
      { title: 'Key Status Activity', data: Keys },
      { title: 'Protector Status Activity', data: ProtectorStatus },
      { title: 'Protectee Status Activity', data: ClientStatus },
      { title: 'Report Logs', data: Reports },
      { title: 'Delivery Logs', data: Deliveries },
      { title: 'Custom Activity Entries', data: ActivityEntries },
    ]

    setPDFData(pdfData)

    setChartOptions({
      title: { text: `${client.name}'s Logs` },
      subtitle: {
        text: `${firstLog !== 'Invalid Date' ? firstLog : ''}${firstLog !== lastLog ? ` - ${lastLog}` : ''}`,
      },
      theme: {
        baseTheme: 'ag-pastel',
      },
      autoSize: true,
      data: chartData,
      series: [
        {
          type: 'bar',
          xKey: 'siteName',
          yKey: 'visitorCheckIns',
          yName: 'Visitor Activity',
          stacked: true,
          label: { formatter },
        },
        {
          type: 'bar',
          xKey: 'siteName',
          yKey: 'protectorStatus',
          yName: 'Protector Status',
          stacked: true,
          label: { formatter },
        },
        {
          type: 'bar',
          xKey: 'siteName',
          yKey: 'clientStatus',
          yName: 'Protectee Status',
          stacked: true,
          label: { formatter },
        },
        {
          type: 'bar',
          xKey: 'siteName',
          yKey: 'reports',
          yName: 'Reports',
          stacked: true,
          label: { formatter },
        },
        {
          type: 'bar',
          xKey: 'siteName',
          yKey: 'deliveries',
          yName: 'Deliveries',
          stacked: true,
          label: { formatter },
        },
        {
          type: 'bar',
          xKey: 'siteName',
          yKey: 'keys',
          yName: 'Keys',
          stacked: true,
          label: { formatter },
        },
        {
          type: 'bar',
          xKey: 'siteName',
          yKey: 'activityEntries',
          yName: 'Activity Entries',
          stacked: true,
          label: { formatter },
        },
      ],
      axes: [
        {
          type: 'category',
          position: 'left',
        },
        {
          type: 'number',
          position: 'top',
        },
      ],
      legend: {
        position: 'top',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs])

  // RENDER
  if (isLoading) return <Loading title='Logs' />
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Client Logs'
        view={view}
        setView={setView}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        onSearchDateRange={onSearchLogs}
      />

      {/* BODY VIEW ( TABLE, PDF, CHART ) */}
      {view === 'table' && (
        <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
          <AgGridReact readOnlyEdit={true} rowData={logs} columnDefs={columnDefs} rowClassRules={rowClassRules} />
        </div>
      )}
      {view === 'pdf' && (
        <div className='d-flex flex-fill w-100'>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <LogsPDF
              PDFData={PDFData}
              logs={logs}
              client={client}
              firstLog={new Date(parseInt(logs[logs.length - 1]?.createdOn?.$date?.$numberLong)).toLocaleDateString()}
              lastLog={new Date(parseInt(logs[0]?.createdOn?.$date?.$numberLong)).toLocaleDateString()}
            />
          </PDFViewer>
        </div>
      )}
      {/* PIE CHART FOR INCIDENT TYPES */}
      {view === 'chart' && (
        <div style={{ height: `100%`, width: `100%` }}>
          <AgChartsReact options={chartOptions} />
        </div>
      )}
    </div>
  )
}
