import { AgGridReact } from "ag-grid-react";
import { React, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInput } from "../../../../components/FormInput";
import { Loading } from "../../../../components/Loading";
import { TableTitleBar } from "../../../../components/TableTitleBar";
import { addRegion, apiAddRegion, apiFindAllRegions } from "../../../../reduxPie/regionsSlice";

export function Regions() {
  const table = useRef();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.selected);
  const regionsSlice = useSelector((state) => state.regions);
  const [regions, setRegions] = useState([]);
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");

  // COLUMN DEFS
  const [columnDefs] = useState([{ field: "name", flex: 1, sortable: true, filter: true }]);

  // ON ROW CLICKED
  const onRowClicked = () => {};

  // ON REFRESH REGIONS
  const onRefreshRegions = () => {
    dispatch(apiFindAllRegions());
  };

  // AUTO FETCH REGIONS
  useEffect(() => {
    if (!regionsSlice?.data?.length) onRefreshRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO REFRESH REDUX
  useEffect(() => {
    setRegions(regionsSlice?.data?.filter((x) => x.name.toLowerCase().includes(search.toLowerCase())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsSlice.data]);

  // ON ADD NEW REGION
  const onAddNewRegion = () => {
    if (name.length !== 0) {
      let newRegion = {
        customer: loggedIn.customer,
        name,
        createdOn: new Date().toLocaleString(),
        createdBy: loggedIn._id,
      };
      dispatch(addRegion(newRegion));
      dispatch(apiAddRegion(newRegion));
    } else {
      window.alert("Please Enter Valid Region Name");
    }
    setName("");
  };

  // RENDER
  if (regionsSlice?.isLoading) return <Loading title='Regions' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Regions'
        addLabel='Region'
        modalId='addRegionModal'
        search={search}
        setSearch={setSearch}
        onRefresh={onRefreshRegions}
        lastRefresh={regionsSlice?.lastRefresh}
      />

      {/* ADD NEW REGION MODAL */}
      <div id='addRegionModal' className='modal fade' data-bs-backdrop='static'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content bg-black text-light'>
            <div className='modal-header'>
              <h1 className='display-6 fs-2 fw-bold'>Add New Region</h1>
              <button className='btn btn-close bg-danger me-1' data-bs-dismiss='modal' />
            </div>

            {/* ADD REGION MODAL BODY */}
            <div className='modal-body'>
              {/* REGION NAME */}
              <FormInput label='Name' placeholder='Enter Name for Region' value={name} setValue={setName} />
            </div>

            {/* ADD REGION FOOTER */}
            <div className='modal-footer'>
              <div className='d-flex w-100 centered'>
                <button className='btn btn-success' data-bs-dismiss='modal' onClick={onAddNewRegion}>
                  Save New Region
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TABLE  */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact
          ref={table}
          rowData={regions}
          defaultColDef={{ resizable: true }}
          columnDefs={columnDefs}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}
