import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiLink } from "./apiConfig";

const initialState = {
  isLoading: false,
  lastRefresh: "",
  data: [],
};
axios.defaults.withCredentials = true;

// API FIND ALL EMAIL GROUPS
export const apiFindAllEmailGroups = createAsyncThunk("emailGroups/findAll", async () => {
  const emailGroups = await axios
    .get(`${apiLink}/emailgroups`)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR API FIND ALL EMAIL GROUPS:", err));
  return emailGroups;
});

// API ADD NEW EMAIL GROUP
export const apiAddEmailGroup = createAsyncThunk("emailGroups/create", async (newEmailGroup) => {
  const data = await axios
    .post(`${apiLink}/emailgroups/add`, newEmailGroup)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR API ADD NEW EMAIL GROUP:", err));
  return data;
});

// API REMOVE EMAIL GROUP
export const apiRemoveEmailGroup = createAsyncThunk("emailGroups/update", async (emailGroupId) => {
  const data = await axios
    .delete(`${apiLink}/emailgroups/remove`, emailGroupId)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR API REMOVE EMAIL GROUP:", err));
  return data;
});

const emailGroupsSlice = createSlice({
  name: "emailGroupsSlice",
  initialState,
  reducers: {
    addBranch(state, action) {
      state.data.push(action.payload);
    },
    clearEmailGroups(state, action) {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // API FIND ALL EMAIL GROUPS
      .addCase(apiFindAllEmailGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(apiFindAllEmailGroups.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data;
        state.isLoading = false;
      })
      .addCase(apiFindAllEmailGroups.rejected, (state) => {
        state.isLoading = false;
      })
      // API ADD NEW EMAIL GROUP
      .addCase(apiAddEmailGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(apiAddEmailGroup.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(apiAddEmailGroup.rejected, (state) => {
        state.isLoading = false;
      })
      // API REMOVE EMAIL GROUP
      .addCase(apiRemoveEmailGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(apiRemoveEmailGroup.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
      })
      .addCase(apiRemoveEmailGroup.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { addBranch, clearEmailGroups } = emailGroupsSlice.actions;

export default emailGroupsSlice.reducer;
