import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../components/Loading";
import { TableTitleBar } from "../../components/TableTitleBar";
import { apiFindAllReports } from "../../reduxPie/reportsSlice";
import { apiFindAllUsers } from "../../reduxPie/usersSlice";
import { Bar, Pie } from "react-chartjs-2";
import { PDFViewer } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import { setReport } from "../../reduxPie/selectedSlice";
import { apiFindAllSites, apiFindAssignedSites } from "../../reduxPie/sitesSlice";
import { apiFindAllClients } from "../../reduxPie/clientsSlice";
import { AgGridReact } from "ag-grid-react";
import { ReportsPDF } from "../../components/ReportsPDF";

export function ManagementReports() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn } = useSelector((state) => state.selected);
  const { isLoading, lastRefresh, data } = useSelector((state) => state.reports);
  const usersSlice = useSelector((state) => state.users);
  const clientsSlice = useSelector((state) => state.clients);
  const sitesSlice = useSelector((state) => state.sites);
  const [reports, setReports] = useState([]);
  const [view, setView] = useState("table");

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "clientName", headerName: "Client", flex: 1, filter: true, sortable: true },
    { field: "siteName", headerName: "Site", flex: 1, filter: true, sortable: true },
    { field: "formName", headerName: "Form", flex: 1, filter: true, sortable: true },
    { field: "eventName", headerName: "Event", flex: 1, filter: true, sortable: true },
    { field: "eventType", headerName: "Type", flex: 1, filter: true, sortable: true },
    { field: "submittedBy", flex: 1, filter: true, sortable: true },
    { field: "submittedOn", flex: 1, filter: true, sortable: true },
  ]);

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setReport(item.data));
    navigate("details");
  };

  // ON REFRESH REPORTS
  const onRefreshReports = () => {
    dispatch(apiFindAllReports());
  };

  // CHART DATA
  const chartData = {
    labels: ["Activity", "Incident", "NZR", "Other"],

    datasets: [
      {
        label: "Reports",

        data: [
          reports.filter((x) => x.eventType === "Activity").length,
          reports.filter((x) => x.eventType === "Incident").length,
          reports.filter((x) => x.eventType === "NZR").length,
          reports.filter((x) => x.eventType === "Other").length,
        ],
        backgroundColor: ["rgba(0, 0, 200, 1)", "rgba(200, 0, 0, 1)", "rgba(0, 200, 0, 1)", "rgba(200, 200, 200, 1)"],
        borderColor: ["rgba(0, 0, 0, 1)"],
        borderWidth: 1,
      },
    ],
  };

  // AUTO FETCH MAIN DATA
  useEffect(() => {
    if (!data.length) onRefreshReports();
    if (!usersSlice.data.length) dispatch(apiFindAllUsers());
    if (!clientsSlice.data.length) dispatch(apiFindAllClients());
    if (!sitesSlice.data?.length)
      loggedIn?.assignedClients?.length ? dispatch(apiFindAssignedSites(loggedIn.assignedClients)) : dispatch(apiFindAllSites());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REDUX FORCED REFRESH
  useEffect(() => {
    const Reports = data
      .filter((x) => (loggedIn?.assignedClients?.length ? loggedIn.assignedClients.includes(x.client) : true))
      .map((item) => {
        const client = clientsSlice.data.find((x) => x._id === item.client);
        const site = sitesSlice.data?.find((x) => x._id === item.site);
        const user = usersSlice.data.find((x) => x._id === item.createdBy);

        return {
          ...item,
          clientName: client ? client.name : "N/A",
          siteName: site ? site.name : "N/A",
          submittedBy: user ? user.name : "N/A",
        };
      })
      .reverse();
    setReports(Reports);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // RENDER
  if (isLoading) return <Loading title='Reports' />;
  else if (usersSlice.isLoading) return <Loading title='Protectors' />;
  else if (clientsSlice.isLoading) return <Loading title='Clients' />;
  else if (sitesSlice.isLoading) return <Loading title='Sites' />;

  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Management Reports'
        hideSearch={true}
        onRefresh={onRefreshReports}
        lastRefresh={lastRefresh}
        view={view}
        setView={setView}
      />
      {view === "table" && (
        <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
          <AgGridReact rowData={reports} columnDefs={columnDefs} onRowClicked={onViewDetails} />
        </div>
      )}
      {view === "pdf" && (
        <div className='d-flex flex-fill w-100'>
          <PDFViewer style={{ width: "100%", height: "100%" }}>
            <ReportsPDF PDFData={reports} />
          </PDFViewer>
        </div>
      )}
      {/* PIE CHART FOR INCIDENT TYPES */}
      {view === "chart" && (
        <div className='d-flex h-100 w-100'>
          <div className='col-3'>
            <Pie data={chartData} className='w-100' />
          </div>
          <div className='col-1' />
          <div className='col'>
            <Bar data={chartData} className='w-100' />
          </div>
        </div>
      )}
    </div>
  );
}
