import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL VEHICLES
export const apiFindAllVehicles = createAsyncThunk('vehicles/findAll', async () => {
  const vehicles = await axios
    .get(`${apiLink}/vehicles`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL VEHICLES: ', err))
  return vehicles
})

// API ADD NEW VEHICLE
export const apiAddVehicle = createAsyncThunk('vehicles/create', async (newVehicle) => {
  const data = await axios
    .post(`${apiLink}/vehicles/add`, newVehicle)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD VEHICLE: ', err))
  return data
})

// API UPDATE VEHICLE
export const apiUpdateVehicle = createAsyncThunk('vehicles/update', async ({ updatedVehicle, formData }) => {
  if (formData) {
    // eslint-disable-next-line no-unused-vars
    const imgLink = await axios
      .post(`${apiLink}/upload`, formData)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPLOAD VEHICLE IMAGE:', err))

    const vehicleRes = await axios
      .put(`${apiLink}/vehicles/update`, updatedVehicle)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPDATE VEHICLE: ', err))
    return vehicleRes
  } else {
    const vehicleRes = await axios
      .put(`${apiLink}/vehicles/update`, updatedVehicle)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPDATE VEHICLE: ', err))
    return vehicleRes
  }
})

const vehiclesSlice = createSlice({
  name: 'vehiclesSlice',
  initialState,
  reducers: {
    addVehicle(state, action) {
      state.data.unshift(action.payload)
    },
    updateVehicle(state, action) {
      state.data = [action.payload, ...state.data.filter((x) => x?._id !== action.payload?.id)]
    },
    clearVehicles(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND ALL VEHICLES
      .addCase(apiFindAllVehicles.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindAllVehicles.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllVehicles.rejected, (state) => {
        state.isLoading = false
      })
      // API ADD NEW VEHICLE
      .addCase(apiAddVehicle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddVehicle.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddVehicle.rejected, (state) => {
        state.isLoading = false
      })
      // API UPDATE VEHICLE
      .addCase(apiUpdateVehicle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiUpdateVehicle.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiUpdateVehicle.rejected, (state) => {
        state.isLoading = false
      }),
})

export const { addVehicle, updateVehicle, clearVehicles } = vehiclesSlice.actions

export default vehiclesSlice.reducer
