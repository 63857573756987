import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL EVENTS
export const apiFindAllEvents = createAsyncThunk('events/findAll', async () => {
  const events = await axios
    .get(`${apiLink}/events`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR FIND ALL EVENTS: ', err))

  return events
})

// API ADD NEW EVENT
export const apiAddEvent = createAsyncThunk('events/add', async (newEvent) => {
  const data = await axios
    .post(`${apiLink}/events/add`, newEvent)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW EVENT: ', err))

  return data
})

// API UPDATE EVENT
export const apiUpdateEvent = createAsyncThunk('events/update', async (updatedEvent) => {
  const data = await axios
    .put(`${apiLink}/events/update`, updatedEvent)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE EVENT: ', err))

  return data
})

const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {
    addEvent(state, action) {
      return {
        ...state,
        data: [action.payload, ...state.data],
      }
    },
    updateEvent(state, action) {
      state.data = [action.payload, ...state.data.filter((x) => x._id !== action.payload._id)]
    },
    changeEventColor(state, action) {
      const { _id, color } = action.payload

      state.data.forEach((event) => {
        if (event._id.$oid === _id.$oid) {
          event.color = color
          event.modifiedOn.$date.$numberLong = new Date().getTime().toString()
        }
      })
    },
    clearEvents(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND ALL EVENTS
      .addCase(apiFindAllEvents.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindAllEvents.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllEvents.rejected, (state) => {
        state.isLoading = false
      })
      // API ADD NEW EVENT
      .addCase(apiAddEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddEvent.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddEvent.rejected, (state) => {
        state.isLoading = false
      })
      // API UPDATE EVENT
      .addCase(apiUpdateEvent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiUpdateEvent.fulfilled, (state, action) => {
        console.log(action.payload)
        state.isLoading = false
      })
      .addCase(apiUpdateEvent.rejected, (state) => {
        state.isLoading = false
      }),
})

export const { addEvent, changeEventColor, updateEvent, clearEvents } = eventsSlice.actions

export default eventsSlice.reducer
