import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../../../../components/Loading.js";
import { iconSearch } from "../../../../../components/TableTitleBar.js";
import { setSite } from "../../../../../reduxPie/selectedSlice.js";
import {
  apiUpdateSite,
  updateSite,
} from "../../../../../reduxPie/sitesSlice.js";
import { apiFindAllForVisitorsListByType } from "../../../../../reduxPie/personsSlice";

export function AllowedVisitors() {
  // TABLE BODY HEIGHT
  const height = window.innerHeight * 0.56;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const table = useRef();
  const tableAllowed = useRef();
  const { loggedIn, site } = useSelector((state) => state.selected);
  const [allowedPersons, setAllowedPersons] = useState(site.allowedPersons);
  const personsSlice = useSelector((state) => state.persons);
  const [visitors, setVisitors] = useState([]);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(apiFindAllForVisitorsListByType(["Guest", "Vendor", "Staff"]));
  }, [dispatch]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    {
      field: "name",
      flex: 2,
      filter: true,
      sortable: true,
      checkboxSelection: true,
    },
    { field: "type", flex: 1, filter: true, sortable: true },
    { field: "group", flex: 2, filter: true, sortable: true },
    { field: "driverLicense", flex: 2, filter: true, sortable: true },
  ]);

  const columnDefsAllowed = [
    {
      field: "_id",
      headerName: "Allowed Visitors",
      flex: 1,
      filter: true,
      sortable: true,
      checkboxSelection: true,
      valueFormatter: ({ value }) => {
        const foundPerson = personsSlice?.visitorsList?.find(
          (x) => x._id === value
        );
        const personName = foundPerson
          ? foundPerson.name
          : "*Archived Profile*";
        return personName;
      },
    },
  ];

  // ON VISITOR SEARCH
  const onVisitorSearch = () => {
    if (searchName.length) {
      setVisitors(
        personsSlice.visitorsList.filter((item) =>
          item.name.toLowerCase().includes(searchName.toLowerCase())
        )
      );
    } else {
      setVisitors(personsSlice.visitorsList);
    }
  };

  // ON ASSIGN PERSONS
  const onAssignPersons = () => {
    const data = table.current.api.getSelectedRows().map((x) => x._id);
    // ONLY ACCEPTS UNIQUE VALUES
    setAllowedPersons([...new Set([...allowedPersons, ...data])]);
  };

  // ON REMOVE VISITOR
  const onRemoveVisitor = () => {
    const data = tableAllowed.current.api.getSelectedRows().map((x) => x._id);

    setAllowedPersons(allowedPersons.filter((x) => !data.includes(x)));
  };

  // ON UPDATE SITE ALLOWED PERSONS
  const onUpdateSite = () => {
    // UPDATED SITE
    const updatedSite = {
      id: site._id,
      customer: site.customer,
      client: site.client,
      name: site.name,
      phrase: site.phrase,
      active: site.active,
      fullAddress: {
        streetAddress: site.fullAddress.streetAddress,
        apt: site.fullAddress.apt,
        city: site.fullAddress.city,
        state: site.fullAddress.state,
        zip: site.fullAddress.zip,
      },
      firstShift: site.firstShift,
      allowedPersons,
      leadCC: site.leadCC,
      manned: site.manned,
      createdOn: site.createdOn,
      createdBy: site.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    dispatch(
      setSite({
        ...site,
        allowedPersons,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      })
    );
    dispatch(
      updateSite({
        ...site,
        allowedPersons,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      })
    );
    dispatch(apiUpdateSite(updatedSite));
    window.alert(`${site?.name}'s Visitor List Has Been Updated`);
    navigate("/manage/clients/sites/visitor-check-in");
  };

  // AUTO REFRESH REDUX
  useEffect(() => {
    setVisitors(
      personsSlice.visitorsList?.filter((x) => x?.type !== "Pursuer")
    );
  }, [personsSlice.visitorsList]);

  if (personsSlice.isLoading) return <Loading title="Visitors" />;
  return (
    <div className="d-flex flex-column flex-fill">
      {/* TITLE BAR */}
      <div className="d-flex justify-content-between align-items-center pb-3 px-1">
        {/* UPDATE */}
        <button
          className="btn btn-sm btn-success border-light"
          onClick={() => onUpdateSite()}
        >
          Save
        </button>

        {/* CANCEL */}
        <Link to={-1} className="btn btn-sm btn-danger border-light">
          Cancel
        </Link>
      </div>

      {/* BODY */}
      <div className="d-flex flex-fill">
        {/* LEFT COL */}
        <div className="col-7">
          {/* TITLE BAR */}
          <div className="d-flex flex-fill align-items-center justify-content-between px-2">
            {/* TITLE */}
            <h1 className="display-6 fs-3">Visitor Search</h1>

            {/* SEARCH BAR */}
            <form
              onSubmit={(x) => {
                x.preventDefault();
                onVisitorSearch();
              }}
              className="input-group input-group-sm w-50"
            >
              <span className="input-group-text">Name Search</span>
              <input
                className="form-control"
                placeholder="Enter Full Name for Search"
                value={searchName}
                onChange={(x) => setSearchName(x.target.value)}
              />
              <button className="btn btn-secondary" type="submit">
                {iconSearch}
              </button>
            </form>

            {/* ALLOW VISITOR(S) */}
            <button
              className="btn btn-sm btn-primary"
              onClick={() => onAssignPersons()}
            >
              Allow
            </button>
          </div>

          <div
            style={{ height, width: `100%` }}
            className="ag-theme-alpine-dark"
          >
            <AgGridReact
              ref={table}
              rowData={visitors?.filter(
                (x) => !allowedPersons.includes(x?._id)
              )}
              columnDefs={columnDefs}
              rowSelection="multiple"
            />
          </div>
        </div>

        {/* COL SPACER */}
        <div className="col" />

        {/* RIGHT COL */}
        <div className="col-4">
          {/* TITLE BAR */}
          <div className="d-flex flex-fill align-items-center justify-content-between px-2">
            {/* TITLE */}
            <h1 className="display-6 fs-3">Allowed Visitors</h1>

            {/* REMOVE ALLOWED VISITORS */}
            <button
              className="btn btn-sm btn-danger"
              onClick={() => onRemoveVisitor()}
            >
              Remove
            </button>
          </div>

          {/* ALLOWED VISITORS TABLE */}
          <div
            style={{ height, width: `100%` }}
            className="ag-theme-alpine-dark"
          >
            <AgGridReact
              ref={tableAllowed}
              rowData={allowedPersons.map((x) => ({ _id: x }))}
              columnDefs={columnDefsAllowed}
              rowSelection="multiple"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
