import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API ADD NEW MANUAL
export const apiAddManual = createAsyncThunk('manuals/create', async ({ newManual, formData }) => {
  const res = await axios
    .post(`${apiLink}/manuals/add`, newManual)
    .then((res) => res.data)
    .catch((err) => console.error('ERRORA API ADD MANUAL: ', err))
  await axios
    .post(`${apiLink}/upload`, formData)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPLOAD MANUAL:', err))
  return res
})

// API UPDATE MANUAL
export const apiUpdateManual = createAsyncThunk('manuals/update', async ({ updatedManual, formData }) => {
  const res = await axios
    .put(`${apiLink}/manuals/update`, updatedManual)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR UPDATE MANUAL: ', err))
  await axios
    .post(`${apiLink}/upload`, formData)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE MANUAL:', err))
  return res
})

// API DELETE MANUAL
export const apiDeleteManual = createAsyncThunk('manuals/delete', async ({ updatedManual, s3Link }) => {
  const res = await axios
    .put(`${apiLink}/manuals/update`, updatedManual)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR DELETE MANUAL: ', err))
  await axios
    .post(`${apiLink}/delete/s3/object`, { key: s3Link })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR DELETE MANUAL: ', err))
  return res
})

// API FIND SITE MANUALS
export const apiFindSiteManuals = createAsyncThunk('manuals/findBy/site', async ({ _id }) => {
  const manuals = await axios
    .get(`${apiLink}/manuals/${_id}`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR FIND SITE MANUALS: ', err))
  return manuals
})

const manualsSlice = createSlice({
  name: 'manualsSlice',
  initialState,
  reducers: {
    addManual(state, action) {
      state.data.unshift(action.payload)
    },
    updateManual(state, action) {
      state.data = [action.payload, ...state.data.filter((x) => x._id !== action.payload._id)]
    },
    clearManuals() {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND SITE MANUALS
      .addCase(apiFindSiteManuals.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindSiteManuals.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state?.data?.length ? state.data : initialState.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindSiteManuals.rejected, (state) => {
        state.isLoading = false
      })
      // API ADD NEW MANUAL
      .addCase(apiAddManual.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddManual.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
        // console.log('SUCCESS MANUALS', action);
      })
      .addCase(apiAddManual.rejected, (state) => {
        state.isLoading = false
      })
      // API UPDATE MANUAL
      .addCase(apiUpdateManual.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiUpdateManual.fulfilled, (state, action) => {
        state.isLoading = false
        // console.log('SUCCESS MANUALS', action);
      })
      .addCase(apiUpdateManual.rejected, (state) => {
        state.isLoading = false
      }),
})

export const { addManual, updateManual, clearManuals } = manualsSlice.actions

export default manualsSlice.reducer
