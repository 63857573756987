import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL FORMS
export const apiFindAllForms = createAsyncThunk('forms/findAll', async () => {
  const forms = await axios
    .get(`${apiLink}/forms`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR FIND ALL FORMS: ', err))
  return forms
})

// API ADD NEW FORM
export const apiAddForm = createAsyncThunk('forms/add', async (newForm) => {
  const resData = await axios
    .post(`${apiLink}/forms/add`, newForm)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW FORM: ', err))

  return resData
})

// API DELETE FORM
export const apiDeleteForm = createAsyncThunk('form/delete', async (_id) => {
  const res = await axios
    .delete(`${apiLink}/forms/${_id}`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API DELETE FORM: ', err))
  return res
})

// API UPDATE FORM
export const apiUpdateForm = createAsyncThunk('forms/update', async (updatedForm) => {
  const resData = await axios
    .put(`${apiLink}/forms/update`, updatedForm)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE FORM: ', err))
  console.log('UPDATE RES: ', resData)
  return resData
})

const formsSlice = createSlice({
  name: 'formsSlice',
  initialState,
  reducers: {
    addForm(state, action) {
      state.data.unshift(action.payload)
    },
    updateForm(state, action) {
      state.data = state.data.map((x) => (x._id === action.payload._id ? action.payload : x))
    },
    clearForms(state, action) {
      return initialState
    },
    deleteForm(state, action) {
      state.data = state.data.filter((x) => x._id !== action.payload)
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND ALL FORMS
      .addCase(apiFindAllForms.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindAllForms.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllForms.rejected, (state) => {
        state.isLoading = false
      })
      // API ADD NEW FORM
      .addCase(apiAddForm.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddForm.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiAddForm.rejected, (state) => {
        state.isLoading = false
      })
      // API UPDATE FORM
      .addCase(apiUpdateForm.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiUpdateForm.fulfilled, (state, action) => {
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiUpdateForm.rejected, (state) => {
        state.isLoading = false
      })
      // API UPDATE FORM
      .addCase(apiDeleteForm.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiDeleteForm.fulfilled, (state, action) => {
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiDeleteForm.rejected, (state) => {
        state.isLoading = false
      }),
})

export const { addForm, updateForm, clearForms, deleteForm } = formsSlice.actions

export default formsSlice.reducer
