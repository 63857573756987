import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL BRANCHES
export const apiFindAllBranches = createAsyncThunk('branches/findAll', async () => {
  const branches = await axios
    .get(`${apiLink}/branches`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL BRANCHES:', err))
  return branches
})

// API ADD NEW BRANCH
export const apiAddBranch = createAsyncThunk('branches/create', async (newBranch) => {
  const branches = await axios
    .post(`${apiLink}/branches/add`, newBranch)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL BRANCHES:', err))
  return branches
})

const branchesSlice = createSlice({
  name: 'branchesSlice',
  initialState,
  reducers: {
    addBranch(state, action) {
      state.data.push(action.payload)
    },
    clearBranches(state, action) {
      state = initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND ALL BRANCHES
      .addCase(apiFindAllBranches.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindAllBranches.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllBranches.rejected, (state) => {
        state.isLoading = false
      })
      // API ADD NEW BRANCH
      .addCase(apiAddBranch.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddBranch.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddBranch.rejected, (state) => {
        state.isLoading = false
      }),
})

export const { addBranch, clearBranches } = branchesSlice.actions

export default branchesSlice.reducer
