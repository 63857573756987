import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  // {
  //   tourAlert: "NZR TOUR 1 - SBR",
  //   protector: "Ray Shaddick",
  //   pastDue: "0:53",
  //   exception: "UNKNOWN",
  //   protectorCount: "9",
  //   checkpointType: "NZR",
  //   color: "col table-primary",
  //   checkpoints: [
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //   ],
  // },
  // {
  //   tourAlert: "NZR TOUR 2 - SBR PERIMETER",
  //   protector: "Jerry Parr",
  //   pastDue: "1:12",
  //   exception: "UNKNOWN",
  //   protectorCount: "3",
  //   checkpointType: "NZR",
  //   color: "col table-primary",
  //   checkpoints: [
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //     {
  //       name: "Sample",
  //       type: "",
  //     },
  //   ],
  // },
  // {
  //   tourAlert: "NZR TOUR 3 - SBR INTERNAL",
  //   protector: "Dennis McCarthy",
  //   pastDue: "5:01",
  //   exception: "UNKNOWN",
  //   protectorCount: "20",
  //   checkpointType: "NFC",
  //   color: "col table-danger",
  //   checkpoints: [
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for rear entrance",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //   ],
  // },
  // {
  //   tourAlert: "NZR TOUR 1 - RANCH PERIMETER",
  //   protector: "Richard Marcinko",
  //   pastDue: "13:13",
  //   exception: "UNKNOWN",
  //   protectorCount: "12",
  //   checkpointType: "QRC",
  //   color: "col table-success",
  //   checkpoints: [
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for rear entrance",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for rear entrance",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //   ],
  // },
  // {
  //   tourAlert: "NZR TOUR 1 - PLO INTERNAL",
  //   protector: "Albert Calland",
  //   pastDue: "21:10",
  //   exception: "Assisting Protectee",
  //   protectorCount: "8",
  //   checkpointType: "QRC",
  //   color: "col table-success",
  //   checkpoints: [
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for rear entrance",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //     {
  //       name: "Guest house north entrance door 0013",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for main entrance",
  //       type: "NFC",
  //     },
  //     {
  //       name: "Check for rear entrance",
  //       type: "NZR",
  //     },
  //     {
  //       name: "Check for side entrance",
  //       type: "QR",
  //     },
  //   ],
  // },
];

const toursSlice = createSlice({
  name: "tourSlice",
  initialState,
  reducers: {
    clearTours(state, action) {},
  },
});

export const { clearTours } = toursSlice.actions;

export default toursSlice.reducer;
