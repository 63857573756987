import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { TableTitleBar } from "../../../../components/TableTitleBar";
import { apiFindAllEvents } from "../../../../reduxPie/eventsSlice";
import { setEvent } from "../../../../reduxPie/selectedSlice";

export function Events() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, lastRefresh, data } = useSelector((state) => state.events);

  const [events, setEvents] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "name", flex: 1, filter: true, sortable: true },
    { field: "type", flex: 1, filter: true, sortable: true },
    { field: "color", flex: 1, filter: true, sortable: true },
  ]);

  const getRowStyle = ({ data }) => {
    return { color: data.color };
  };

  // ON REFRESH EVENTS
  const onRefreshEvents = () => {
    dispatch(apiFindAllEvents());
  };

  // ON SET EVENT
  const onRowClicked = ({ data }) => {
    dispatch(setEvent(data));
    navigate("edit-event");
  };

  // AUTO FETCH EVENTS
  useEffect(() => {
    if (!data.length) onRefreshEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO SET REDUX
  useEffect(() => {
    setEvents(data);
  }, [data]);

  // RENDER
  if (isLoading) return <Loading title='Events' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Events'
        addLabel='Event'
        addLink='add-event'
        onRefresh={onRefreshEvents}
        lastRefresh={lastRefresh}
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine'>
        <AgGridReact
          rowData={events}
          columnDefs={columnDefs}
          onRowClicked={onRowClicked}
          getRowStyle={getRowStyle}
          rowSelection='single'
        />
      </div>
    </div>
  );
}
