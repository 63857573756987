import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddTitleBar } from "../../../../../components/AddTitleBar";
import { FormInput } from "../../../../../components/FormInput";
import { addKey, apiAddKey } from "../../../../../reduxPie/keysSlice";

export function AddKey() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, client, site } = useSelector((state) => state.selected);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [details, setDetails] = useState("");
  const [timeLimit, setTimeLimit] = useState({ hr: "", min: "" });

  // ON ADD NEW KEY
  const onAddKey = () => {
    if (name && timeLimit) {
      // NEW KEY
      const newKey = {
        customer: loggedIn.customer,
        client: client._id,
        site: site._id,
        name,
        type,
        details,
        timeLimit: `${timeLimit.hr}:${timeLimit.min}`,
        checkedOut: false,
        checkedOutTo: "",
        lastChecked: "",
        checkedBy: null,
        createdBy: loggedIn._id,
        createdOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
      };
      dispatch(addKey(newKey));
      dispatch(apiAddKey(newKey));
      window.alert(`New Key: ${name} has been Added`);
      navigate("/manage/clients/sites/keys");
    }
  };

  return (
    <div className='d-flex flex-fill flex-column'>
      <div className='card d-flex flex-column flex-fill bg-black text-light'>
        {/* TITLE BAR */}
        <AddTitleBar title='Key' onAdd={onAddKey} backLink={-1} />

        {/* BODY */}
        <div className='d-flex flex-fill'>
          {/* LEFT COL */}
          <div className='col-3'></div>

          {/* CENTER COL */}
          <div className='col my-auto'>
            {/* NAME */}
            <FormInput label='Name' placeholder='Enter Key Name' value={name} setValue={setName} />

            {/* TYPE */}
            <FormInput label='Section' placeholder='Enter Key Section' value={type} setValue={setType} />

            {/* DETAILS */}
            <FormInput label='Details' placeholder='Enter Description of Key' value={details} setValue={setDetails} />

            {/* TIME LIMIT */}
            <h4 className='fw-bold text-center'>Time Limit</h4>
            <div className='d-flex mb-3'>
              {/* HR */}
              <FormInput
                label='Hour'
                placeholder='00'
                className='me-3'
                value={timeLimit.hr}
                setValue={(x) =>
                  x.target.value >= 0 && x.target.value < 13 ? setTimeLimit({ ...timeLimit, hr: x.target.value }) : ""
                }
              />

              {/* MIN */}
              <FormInput
                label='Minute'
                placeholder='00'
                className=''
                value={timeLimit.min}
                setValue={(x) =>
                  x.target.value >= 0 && x.target.value < 60 ? setTimeLimit({ ...timeLimit, min: x.target.value }) : ""
                }
              />
            </div>
          </div>

          {/* RIGHT COL */}
          <div className='col-3'></div>
        </div>
      </div>
    </div>
  );
}
