import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { addLog, apiAddLog } from '../../reduxPie/logsSlice';

export function IST() {
  // BTN HEIGHT
  const height = window.innerHeight * 0.09;

// ----- PENDING DEV ----- //

  // const { loggedIn, client, site, form } = useSelector((state) => state.selected);
  // const dispatch = useDispatch();
  // const [timeoutIST, setTimeoutIST] = useState('');
  // const [pending, setPending] = useState(false);

  // // ON IST ALERT
  // const onISTAlert = () => {
  //   setPending(true);
  //   const ISTLog = {
  //     customer: loggedIn.customer,
  //     client: client?._id ? client?._id : null,
  //     site: site?._id ? site?._id : null,
  //     form: form?._id ? form?._id : null,
  //     data: `IST Alert${client?.name ? ` - ${client?.name}` : ""}${site?.name ? ` - ${site?.name}` : ""}${
  //       form?.name ? form?.name : ""
  //     }`,
  //     submittedOn: new Date().toLocaleString(),
  //     createdOn: new Date().toISOString(),
  //     createdBy: loggedIn._id,
  //   };

  //   const ISTEmail = {
  //     emailGroup: "",
  //     clientName: client?.name ?? "No Client Specified",
  //     siteName: site?.name ?? " No Site Specified ",
  //     userName: loggedIn?.name ?? " No User Specified ",
  //     createdOn: new Date().toISOString(),
  //   };

  //   setTimeoutIST(
  //     setTimeout(() => {
  //       dispatch(addLog(ISTLog));
  //       dispatch(apiAddLog(ISTLog));
  //       // dispatch(apiISTAlert(ISTEmail));
  //       console.log(ISTEmail);
  //       alert("Distribution List has been alerted!");
  //       setPending(false);
  //     }, 3000)
  //   );
  // };

  // // ON CANCEL IST
  // const onCancelIST = () => {
  //   clearTimeout(timeoutIST);
  //   setPending(false);
  // };

  // if (pending)
  //   return (
  //     <div className='d-grid'>
  //       <button className='btn btn-lg btn-secondary' style={{ height }} onClick={() => onCancelIST()}>
  //         <span className='display-6 fw-bold'>CANCEL</span>
  //       </button>
  //     </div>
  //   );

  // return (
  //   <div className='d-grid'>
  //     <button className='btn btn-lg btn-outline-danger border-0' style={{ height }} onClick={() => onISTAlert()}>
  //       <span className='display-6 fw-bold'>IST</span>
  //     </button>
  //   </div>
  // );

  return (
    <div className="d-flex flex-column centered bg-danger text-light rounded-3 user-select-none" style={{ height }}>
      <span className="display-6 fw-bold">IST</span>
      <span className="fw-bold">( Coming Soon )</span>
    </div>
  );
}
