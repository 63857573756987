import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { Loading } from '../../components/Loading'
import { setUser } from '../../reduxPie/selectedSlice'
import { apiFindAllUsers } from '../../reduxPie/usersSlice'
import { AgGridReact } from 'ag-grid-react'

export function Users() {
  const table = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const btnSelected = 'btn btn-sm btn-dark'
  const btnUnselected = 'btn btn-sm btn-secondary border-dark'
  const { loggedIn } = useSelector((state) => state.selected)
  const { isLoading, lastRefresh, data } = useSelector((state) => state.users)
  const [users, setUsers] = useState([])
  const [userLevel, setUserLevel] = useState('1')
  const [onlyActive, setOnlyActive] = useState(true)

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'name', flex: 1, filter: true, sortable: true },
    { field: 'email', flex: 1, filter: true, sortable: true },
    { field: 'phone', flex: 1, filter: true, sortable: true },
    {
      field: 'status',
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: ({ data }) => (data.active ? data.status : 'Deactivated'),
    },
    // { field: "active" },
  ])

  // ROW CLASS RULES
  const rowClassRules = {
    'bg-danger': '!data.active',
  }

  const popupParent = useMemo(() => {
    return document.body
  }, [])

  // ON REFRESH USERS
  const onRefreshUsers = () => {
    dispatch(apiFindAllUsers())
  }

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setUser(item.data))
    navigate('/manage/users/edit-user')
  }

  // ON EXPORT CSV
  const onExportCSV = useCallback(() => {
    const fileName = userLevel === '1' ? 'Managers' : 'Protectors'
    table.current.api.exportDataAsCsv({ fileName })
  }, [userLevel])

  // AUTO FETCH USERS
  useEffect(() => {
    if ((new Date().getTime() - new Date(lastRefresh).getTime()) / 1000 / 60 > 15 || !data?.length) onRefreshUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ACTIVE TOGGLE
  useEffect(() => {
    if (userLevel === '1') {
      setUsers(
        onlyActive ? data.filter((x) => x.level === '1').filter((x) => x.active) : data.filter((x) => x.level === '1')
      )
    } else {
      setUsers(
        onlyActive ? data.filter((x) => x.level === '4').filter((x) => x.active) : data.filter((x) => x.level === '4')
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, onlyActive, userLevel])

  // RENDER
  if (isLoading) return <Loading title='Users' />
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <div className='d-flex justify-content-between align-items-center px-3 py-1'>
        <div className='d-flex align-items-center'>
          {/* TITLE */}
          <h1 className='display-6 fs-2 fw-bold me-4'>Users</h1>

          {/* REFRESH USERS BTN */}
          <button className='btn btn-sm btn-outline-dark border-0' onClick={() => onRefreshUsers()}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-arrow-clockwise'
              viewBox='0 0 16 16'
            >
              <path fillRule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z' />
              <path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z' />
            </svg>
            <small className='p-3'>
              {`${Math.floor((new Date().getTime() - new Date(lastRefresh).getTime()) / 1000 / 60)} mins ${
                Math.floor((new Date().getTime() - new Date(lastRefresh).getTime()) / 1000) % 60
              } secs ago`}
            </small>
          </button>
        </div>

        {/* USER TYPE TOGGLE */}
        <div className='btn-group' role='group'>
          <button className={userLevel === '1' ? btnSelected : btnUnselected} onClick={() => setUserLevel('1')}>
            Managers
          </button>
          <button className={userLevel === '4' ? btnSelected : btnUnselected} onClick={() => setUserLevel('4')}>
            Protectors
          </button>
        </div>

        {/* ACTIVE TOGGLE */}
        <button className='btn btn-sm btn-dark' onClick={() => setOnlyActive(!onlyActive)}>
          {onlyActive ? 'Show All' : 'Show Active'}
        </button>

        {/* EXPORT CSV */}
        {loggedIn.name === 'Super Admin' && (
          <button className='btn btn-sm btn-info' onClick={() => onExportCSV()}>
            Download CSV
          </button>
        )}

        {/* ADD NEW USER BTN */}
        <Link to='add-user' className='btn btn-sm btn-primary'>
          Add New User
        </Link>
      </div>

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact
          ref={table}
          suppressExcelExport={true}
          popupParent={popupParent}
          columnDefs={columnDefs}
          rowData={users}
          rowClassRules={rowClassRules}
          onRowClicked={onViewDetails}
        />
      </div>
    </div>
  )
}
