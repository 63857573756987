import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';
import logo from '../media/logo.png';
import { apiFindAllClients } from '../reduxPie/clientsSlice';
import { apiFindAllUsers } from '../reduxPie/usersSlice';
import { apiFindAllSites } from '../reduxPie/sitesSlice';
import { FormInput } from './FormInput';
import { apiDeactivateReport, removeReport } from '../reduxPie/reportsSlice';

export function ReportDetails() {
  const height = window.innerHeight * 0.45;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { report, loggedIn } = useSelector((state) => state.selected);
  const usersSlice = useSelector((state) => state.users);
  const clientsSlice = useSelector((state) => state.clients);
  const sitesSlice = useSelector((state) => state.sites);
  const [viewPDF, setViewPDF] = useState(false);

  // ON DELETE REPORT
  const onDeleteReport = () => {
    const res = window.confirm(
      `*** DELETE WARNING ***\n\nAre you sure you want to delete \nreport created on ${report?.submittedOn} ?\n`
    );

    if (res === true && report?._id) {
      dispatch(removeReport(report));
      dispatch(apiDeactivateReport(report));
      navigate(-1);
    }
  };

  // AUTO FETCH
  useEffect(() => {
    if (!clientsSlice.data.length) dispatch(apiFindAllClients());
    if (!sitesSlice.data?.length) dispatch(apiFindAllSites());
    if (!usersSlice.data.length) dispatch(apiFindAllUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REPORT PDF
  const ReportPDF = () => {
    const thisClient = clientsSlice.data.find((x) => x._id === report.client);
    const thisSite = sitesSlice.data?.find((x) => x._id === report.site);
    const thisUser = usersSlice.data.find((x) => x._id === report.createdBy);

    return (
      <Document>
        <Page size="A4">
          {/* DOCUMENT HEADER */}
          <View>
            <View style={styles.header}>
              {/* LOGO */}
              <View style={styles.headerCol}>
                <Image src={logo} />
              </View>
              {/* TITLE & DATE */}
              <View style={{ width: `100%`, alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontSize: 20 }}>{report.formName}</Text>
                <Text style={{ fontSize: 16 }}>{`( ${report.eventType} ) ${report.eventName}`}</Text>
                <Text style={{ fontSize: 12 }}>{`( ${thisClient ? thisClient.name : 'N/A'} ) ${
                  thisSite ? thisSite.name : 'N/A'
                }`}</Text>
                <Text style={{ fontSize: 12 }}>{`${thisUser ? thisUser.name : 'N/A'} - ${report.submittedOn}`}</Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                borderBottomColor: 'black',
                borderBottomWidth: 1,
                marginBottom: '10px',
              }}
            />

            {/* DOCUMENT BODY  */}
            {report.data.map((item, key) => {
              if (item.custom_name.includes('header'))
                return (
                  <View key={key}>
                    <Text style={{ fontSize: 24, textAlign: 'center', marginBottom: 10 }}>{item.name}</Text>
                  </View>
                );
              else if (item.custom_name.includes('date_picker'))
                return (
                  <View style={{ display: 'flex', paddingHorizontal: 20, justifyContent: 'center' }}>
                    <Text style={styles.labelText}>{item.name}</Text>
                    <View>
                      <Text style={styles.valueText}>{new Date(item.value).toLocaleString()}</Text>
                    </View>
                  </View>
                );
              else if (typeof item.value === 'string')
                return (
                  <View style={{ display: 'flex', paddingHorizontal: 20, justifyContent: 'center' }}>
                    <Text style={styles.labelText}>{item.name}</Text>
                    <View>
                      <Text style={styles.valueText}>{item.value}</Text>
                    </View>
                  </View>
                );
              else if (item.value)
                return (
                  <View style={{ display: 'flex', paddingHorizontal: 20, justifyContent: 'center' }}>
                    <Text style={styles.labelText}>{item.name}</Text>
                    <View style={styles.valueText}>
                      {item.value.map((item, key) => (
                        <Text key={key}>- {item}</Text>
                      ))}
                    </View>
                  </View>
                );
              else
                return (
                  <View key={key}>
                    <Text style={{ fontSize: 14, paddingHorizontal: 20, marginBottom: 6 }}>{item.name}</Text>
                  </View>
                );
            })}
          </View>
        </Page>
      </Document>
    );
  };

  // OG REPORT
  const OriginalReport = () => (
    <div className="d-flex flex-fill flex-column align-items-center py-4 overflow-auto bg-secondary" style={{ height }}>
      <div className="w-75 mb-3">
        <div className="card shadow p-3">
          <div className="d-flex mb-3">
            {/* FORM */}
            <FormInput className="me-3" label="Form" value={report.formName} disabled={true} />

            {/* EVENT */}
            <FormInput className="me-3" label="Event" value={report.eventName} disabled={true} />

            {/* TYPE */}
            <FormInput label="Type" value={report.eventType} disabled={true} />
          </div>

          <div className="d-flex">
            {/* CLIENT */}
            <FormInput
              className="me-3"
              label="Client"
              value={clientsSlice.data.find((x) => x._id === report.client).name}
              disabled={true}
            />

            {/* SITE */}
            <FormInput label="Site" value={sitesSlice.data?.find((x) => x._id === report.site).name} disabled={true} />
          </div>
        </div>
      </div>
      {report.data.map((item, key) => {
        if (item.custom_name.includes('header'))
          return (
            <div key={key} className="w-75 mb-3">
              <h1 className="display-6 text-center">{item.name}</h1>
            </div>
          );
        else if (item.custom_name.includes('date_picker'))
          return (
            <div key={key} className="w-75 mb-3">
              <div className="d-flex flex-column card shadow p-3">
                <span className="lead fw-bold text-wrap">{item.name}</span>
                <input type="datetime-local" className="form-control" value={item.value} readOnly />
              </div>
            </div>
          );
        else if (typeof item.value === 'string')
          return (
            <div key={key} className="w-75 mb-3">
              <div className="d-flex flex-column card shadow p-3">
                <span className="lead fw-bold text-wrap">{item.name}</span>
                <input type="text" className="form-control" value={`${item.value}`} readOnly />
              </div>
            </div>
          );
        else if (item.value)
          return (
            <div key={key} className="w-75 mb-3">
              <div className="d-flex flex-column card shadow p-3">
                <span className="lead fw-bold text-wrap">{item.name}</span>
                <p className="d-flex flex-column flex-fill ">
                  {item.value.map((item, key) => (
                    <span key={key}>- {item}</span>
                  ))}
                </p>
              </div>
            </div>
          );
        else
          return (
            <div key={key} className="w-50 mb-3">
              <span className="lead">{item.name}</span>
            </div>
          );
      })}
    </div>
  );

  return (
    <div className="d-flex flex-fill flex-column p-2">
      <div className="card d-flex flex-fill shadow border-dark">
        {/* TITLE BAR */}
        <div className="d-flex justify-content-between align-items-center px-4 py-2 border">
          {/* BACK BTN */}
          <Link to={-1} className="btn btn-sm btn-secondary ">
            Back
          </Link>

          {/* DEACTIVATE BTN */}
          {loggedIn?.level === '1' && (
            <button className="btn btn-sm btn-danger" onClick={() => onDeleteReport()}>
              Delete Report
            </button>
          )}

          {/* PDF BTN */}
          <button onClick={() => setViewPDF(!viewPDF)} className="btn btn-sm btn-primary">
            {`${viewPDF ? 'Hide' : 'View'} PDF`}
          </button>
        </div>

        {/* PDF VIEW || REPORT DETAILS */}
        {viewPDF ? (
          <div className="d-flex flex-fill w-100">
            <PDFViewer style={{ width: '100%' }}>
              <ReportPDF />
            </PDFViewer>
          </div>
        ) : (
          <OriginalReport />
        )}
      </div>
    </div>
  );
}
// ------------- STYLES
const styles = StyleSheet.create({
  header: {
    height: 80,
    backgroundColor: 'black',
    flexDirection: 'row',
    color: 'whitesmoke',
  },
  headerCol: {
    justifyContent: 'center',
    width: '30%',
  },
  labelText: {
    padding: 6,
    fontSize: 14,
    borderBottomLeftRadius: '3px',
    borderTopLeftRadius: '3px',
  },
  valueBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  valueText: {
    fontSize: 12,
    backgroundColor: '#eaeaea',
    width: '90vw',
    padding: 5,
    marginBottom: 20,
    border: 1,
    borderRadius: '3px',
    borderColor: 'grey',
  },
  eventType: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  eventTime: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 14,
  },
});
