import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiFindAllowedPersons, apiMassCheckIn, clearPersons } from '../../../../reduxPie/personsSlice'
import { Loading } from '../../../../components/Loading.js'
import { Link, useNavigate } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import { setVisitor } from '../../../../reduxPie/selectedSlice'
import { FormInput } from '../../../../components/FormInput'

export function VisitorCheckIn() {
  const table = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { site, loggedIn, client } = useSelector((state) => state.selected)
  const personsSlice = useSelector((state) => state.persons)
  const [lastChecked, setLastChecked] = useState('')
  const [visitors, setVisitors] = useState([])
  const [localVisitor, setLocalVisitor] = useState()
  const [checked, setChecked] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'name', flex: 1, filter: true, sortable: true, checkboxSelection: true },
    { field: 'type', flex: 1, filter: true, sortable: true },
    { field: 'group', flex: 1, filter: true, sortable: true },
    {
      field: 'checkedIn',
      headerName: 'Checked',
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: ({ data }) => (data.checkedIn ? 'IN' : 'OUT'),
    },
    { field: 'lastChecked', flex: 1, filter: true, sortable: true },
  ])

  // ROW CLASS RULES
  const rowClassRules = {
    'bg-success': 'data.checkedIn === true',
  }

  // ON CHECK IN/OUT
  const onCheckInOut = (e) => {
    // e.preventDefault()
    setLastChecked(new Date().toLocaleString())
    const selectedRows = table.current.api.getSelectedRows()
    if (selectedRows.length) {
      setChecked(selectedRows)
      setLocalVisitor(selectedRows[0])
    } else {
      setLocalVisitor({ name: 'No Visitor Selected' })
    }
  }

  function onRowSelected(event) {
    setLocalVisitor(event.data)
  }

  // function onSelectionChanged(event) {
  //   console.log(event) // verify that the method is fired upon selection
  //   // do the rest
  // }

  //   ON VIEW DETAILS
  const onViewDetails = () => {
    const selectedRows = table.current.api.getSelectedRows()

    if (selectedRows.length === 1) {
      dispatch(setVisitor(selectedRows[0]))
      navigate('/visitors/edit-visitor')
    } else {
      window.alert('ERROR\n\nOne Visitor Required')
    }
  }

  // ON REFRESH VISITORS ( PERSONS )
  const onRefreshVisitors = async () => {
    if (site?.allowedPersons?.length) dispatch(apiFindAllowedPersons(site?.allowedPersons))
    else setVisitors([])
  }

  // ON MASS VISITOR CHECK-IN
  const onMassVisitorCheckIn = () => {
    if (checked?.length) {
      const massCheckReq = {
        visitors: checked?.map((x) => ({ _id: x?._id, checkedIn: !x?.checkedIn })),
        newLogs: checked?.map((x) => ({
          customer: loggedIn?.customer,
          client: client?._id,
          site: site?._id,
          person: x?._id,
          data: `@${site?.name}: ${x?.type} - ${x?.name} - ${
            x?.checkedIn ? 'Checked OUT' : 'Checked IN'
          } ${lastChecked}`,
          submittedOn: new Date().toLocaleString(),
          createdOn: new Date().toISOString(),
          createdBy: loggedIn?._id,
        })),
        lastChecked,
        recentSite: site?._id,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn?._id,
      }

      dispatch(apiMassCheckIn(massCheckReq))
      dispatch(clearPersons())
    }
  }

  // ON ROW CLICKED
  const onRowClicked = ({ data }) => {
    setLocalVisitor(data)
  }

  // AUTO FETCH VISITORS ( PERSONS )
  useEffect(() => {
    onRefreshVisitors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site])

  // AUTO FETCH VISITORS ( PERSONS )
  useEffect(() => {
    if (personsSlice?.data?.length === 0) {
      setIsLoading(true)
      setTimeout(() => {
        onRefreshVisitors()
        setIsLoading(false)
      }, 4000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personsSlice.data])

  // REDUX FORCED REFRESH
  useEffect(() => {
    // FIND VISITORS FROM allowedPersons THEN FILTERS OUT VISITORS CHECKED IN ELSEWHERE THEN SORTS CHECKEDIN ON TOP
    try {
      const list = personsSlice.data
        .filter((x) => site?.allowedPersons?.includes(x?._id))
        .filter((x) => !x.checkedIn || x.recentSite === site?._id)
        .sort((x) => (x.checkedIn ? !x.checkedIn : 1))
      setVisitors(list)
    } catch (err) {
      console.error('ERROR CHECKED IN BUG: ', err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personsSlice.data])

  // RENDER
  if (personsSlice.isLoading || isLoading) return <Loading title='Visitors' />
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <div className='d-flex align-items-center justify-content-between px-3 py-1'>
        <div className='d-flex centered'>
          {/* TITLE */}
          <h1 className='display-6 fs-2 fw-bold text-primary'>Visitor Check-In</h1>

          {/* REFRESH BTN */}
          <button className='btn btn-sm btn-outline-dark border-0 ms-3' onClick={() => onRefreshVisitors()}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-arrow-clockwise'
              viewBox='0 0 16 16'
            >
              <path fillRule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z' />
              <path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z' />
            </svg>
            <small className='p-3'>
              {`${Math.floor((new Date().getTime() - new Date(personsSlice.lastRefresh).getTime()) / 1000 / 60)} mins ${
                Math.floor((new Date().getTime() - new Date(personsSlice.lastRefresh).getTime()) / 1000) % 60
              } secs ago`}
            </small>
          </button>
        </div>

        <div className='btn-group'>
          {/* CHECK IN/OUT BTN */}
          <button
            className='btn btn-sm btn-success'
            onClick={(e) => onCheckInOut(e)}
            data-bs-toggle='modal'
            data-bs-target='#checkInPopup'
          >
            Check Visitor In/Out
          </button>

          {/* VIEW DETAILS BTN */}
          <button className='btn btn-sm btn-dark' onClick={() => onViewDetails()}>
            View Details
          </button>
        </div>

        {/* ADD ALLOWED VISITOR */}
        <Link to='/manage/clients/sites/allowed-visitors' className='btn btn-sm btn-secondary'>
          Allowed Visitors
        </Link>

        {/* ADD NEW VISITOR BTN */}
        <Link to='/visitors/add-visitor' className='btn btn-sm btn-primary'>
          Add New Visitor
        </Link>
      </div>

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact
          ref={table}
          rowData={visitors}
          columnDefs={columnDefs}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          rowClassRules={rowClassRules}
          onRowSelected={onRowSelected}
        />
      </div>

      {/* CHECK IN POP UP */}

      {localVisitor ? (
        <div id='checkInPopup' className='modal fade' data-bs-backdrop='static'>
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h1 className='display-6 fs-2 my-0'>Visitor Check In/Out</h1>
                <button className='btn btn-close bg-danger me-1' data-bs-dismiss='modal' />
              </div>
              <div className='modal-body'>
                {/* CHECK IN/OUT COUNT DISPLAY */}
                <div className='d-flex text-center mb-3'>
                  {/* CHECKING IN */}
                  <div className='col border border-success border-3 rounded'>
                    {/* TITLE */}
                    <h1 className='display-6 fw-bold text-success'>CHECKING IN</h1>

                    {/* COUNT */}
                    <span className='display-6 fs-3 fw-bold text-success'>
                      {checked?.filter((x) => x?.checkedIn === false).length}
                    </span>
                  </div>

                  <div className='col-1' />
                  {/* CHECKING OUT */}
                  <div className='col border border-danger border-3 rounded'>
                    {/* TITLE */}
                    <h1 className='display-6 fw-bold text-danger'>CHECKING OUT</h1>

                    {/* COUNT */}
                    <span className='display-6 fs-3 fw-bold text-danger'>
                      {checked?.filter((x) => x?.checkedIn === true).length}
                    </span>
                  </div>
                </div>

                {/* CHECK IN/OUT TIME */}
                <div className='d-flex flex-column'>
                  <div className='d-flex flex-column w-100'>
                    <div className='d-flex flex-column centered mb-3'>
                      <span className='lead text-muted'>Time Format</span>
                      <span className='text-success mb-3'>mm/dd/yyyy, HH:MM:SS AM (or PM) </span>
                      <small className='text-muted'>Ex.</small>
                      <span className='text-success'>1/1/2022, 12:00:00 AM </span>
                    </div>

                    <FormInput
                      label={`Check ${localVisitor.checkedIn ? 'Out' : 'In'} Time`}
                      value={lastChecked}
                      setValue={setLastChecked}
                    />
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <div className='d-flex justify-content-around w-100'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    data-bs-dismiss='modal'
                    disabled={!checked?.length}
                    onClick={() => onMassVisitorCheckIn()}
                  >
                    Confirm Checking
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
