import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditTitleBar } from '../../../components/EditTitleBar';
import { useNavigate } from 'react-router-dom';
import { updateVehicle, apiUpdateVehicle } from '../../../reduxPie/vehiclesSlice';
import { FormInput } from '../../../components/FormInput';
import { s3Link } from '../../../reduxPie/apiConfig';
import { AgGridReact } from 'ag-grid-react';
import { apiFindPersonById, apiFindPersonByName, clearPersons } from '../../../reduxPie/personsSlice';
import { iconSearch } from '../../../components/TableTitleBar';
import { Loading } from '../../../components/Loading';

export function EditVehicle() {
  const table = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, vehicle } = useSelector((state) => state.selected);
  const personsSlice = useSelector((state) => state.persons);
  const vehicleTypes = ['Staff', 'Vendor', 'Guest', 'Pursuer', 'Suspicious'];
  const [disabled, setDisabled] = useState(true);
  const [persons, setPersons] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [view, setView] = useState('VEHICLE');

  // ----- FORM STATE VALUES START ----- //
  const [name, setName] = useState(vehicle.name);
  const [person, setPerson] = useState(vehicle.person ? vehicle.person : '');
  const [type, setType] = useState(vehicle.type);
  const [make, setMake] = useState(vehicle.make);
  const [model, setModel] = useState(vehicle.model);
  const [color, setColor] = useState(vehicle.color);
  const [licensePlate, setLicensePlate] = useState(vehicle.licensePlate);
  const [img, setImg] = useState('');
  const [imgFile, setImgFile] = useState('');
  // ----- FORM STATE VALUES START ----- //

  // VEHICLE CARD STYLE
  const cardStyle = (type) => {
    if (type === 'Pursuer') return 'danger';
    else if (type === 'Suspicious') return 'warning';
    else if (type === 'Guest' || type === 'Staff' || type === 'Vendor') return 'primary';
  };

  // ON UPDATE VEHICLE
  const onUpdateVehicle = () => {
    // UPDATED VEHICLE
    const updatedVehicle = {
      id: vehicle._id,
      customer: vehicle.customer,
      person: person?._id || vehicle?.person || '',
      name,
      type,
      make,
      model,
      color,
      licensePlate,
      createdOn: vehicle.createdOn,
      createdBy: vehicle.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    dispatch(
      updateVehicle({
        ...vehicle,
        name,
        person: person?._id || vehicle?.person || '',
        type,
        make,
        model,
        color,
        licensePlate,
        modifiedOn: new Date().toLocaleString(),
        modifiedBy: loggedIn._id,
      })
    );
    if (img && name) {
      var extention = imgFile.name.split('.')[1].toLowerCase();
      let formData = new FormData();
      formData.append('collection', 'Vehicles');
      formData.append('id', vehicle._id);
      formData.append('type', 'images');
      formData.append('file', imgFile, 'vehicle.' + extention);

      dispatch(apiUpdateVehicle({ updatedVehicle, formData }));
      window.alert(`Vehicle - ${name} Has Been Updated`);
      navigate('/vehicles');
    } else if (name) {
      dispatch(apiUpdateVehicle({ updatedVehicle, formData: null }));
      window.alert(`Vehicle - ${name} Has Been Updated`);
      navigate('/vehicles');
    } else {
      window.alert(`Please Fill Out "Edit Vehicles" Form`);
    }
  };

  // ON IMG UPLOAD
  const onImgUpload = (e) => {
    const file = e.target.files[0] ? e.target.files[0] : '';
    setImgFile(file ? file : '');
    setImg(file ? URL.createObjectURL(file) : '');
  };

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'name', flex: 2, filter: true, sortable: true, checkboxSelection: true },
    { field: 'type', flex: 1, filter: true, sortable: true },
    { field: 'group', flex: 2, filter: true, sortable: true },
    { field: 'driverLicense', flex: 2, filter: true, sortable: true },
  ]);

  // ROW CLASS RULES
  const rowClassRules = useMemo(() => {
    return {
      'bg-danger': 'data.type === "Pursuer"',
    };
  }, []);

  // ON PERSON SEARCH
  const onPersonSearch = () => {
    dispatch(apiFindPersonByName(searchName?.trim()));
  };

  // ON ASSIGN OWNER
  const onAssignOwner = () => {
    const selectedRows = table.current.api.getSelectedRows();
    setPerson(selectedRows[0]);
    setView('VEHICLE');
  };

  // AUTO-CLEAR PERSONS CACHE
  useEffect(() => {
    dispatch(clearPersons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO-CLEAR FETCH OWNER INFO
  useEffect(() => {
    if (vehicle?.person) dispatch(apiFindPersonById(vehicle.person));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO REFRESH REDUX
  useEffect(() => {
    setPersons(personsSlice.data);
    setPerson(personsSlice.data?.find((x) => x?._id === vehicle?.person));
  }, [personsSlice.data, vehicle]);

  // PERSON SERACH LOADING
  if (personsSlice.isLoading) return <Loading title="Person Search" />;
  return (
    <div className="d-flex flex-fill py-1">
      <div
        className={`card d-flex flex-fill border border-${cardStyle(
          vehicle.type
        )} border-5 bg-black text-light shadow-lg`}>
        {/* TITLE BAR */}
        <EditTitleBar
          backLink="/vehicles"
          title={`${vehicle.name}`}
          onUpdate={onUpdateVehicle}
          disabled={disabled}
          setDisabled={setDisabled}
        />

        {/* CARD BODY */}
        <div className="card-body d-flex flex-fill flex-column overflow-auto pb-5 px-5">
          {/* VEHICLE VIEW */}
          {view === 'VEHICLE' && (
            <>
              {/* PICTURE */}
              <div className="d-flex flex-column centered">
                <div
                  className={`d-flex flex-column mb-3 overflow-auto border border-${cardStyle(
                    vehicle.type
                  )} rounded p-3`}
                  style={{ height: window.innerHeight * 0.425 }}>
                  {img ? (
                    <img
                      className={`img-thumbnail bg-black border-0`}
                      src={img}
                      alt="..."
                      style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                    />
                  ) : (
                    <>
                      <img
                        src={`https://${s3Link}/Vehicles/${vehicle._id}/images//vehicle.jpg`}
                        title=".jpg"
                        alt=""
                        className={`img-thumbnail bg-black border-0`}
                        style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                      />
                      <img
                        src={`https://${s3Link}/Vehicles/${vehicle._id}/images//vehicle.jpeg`}
                        title=".jpeg"
                        alt=""
                        className={`img-thumbnail bg-black border-0`}
                        style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                      />
                      <img
                        src={`https://${s3Link}/Vehicles/${vehicle._id}/images//vehicle.png`}
                        title=".png"
                        alt=""
                        className={`img-thumbnail bg-black border-0`}
                        style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                      />
                    </>
                  )}
                </div>

                {/* IMG UPLOAD */}
                <div className="d-flex flex-column w-100">
                  <label className="fw-bold lead">Profile Picture</label>
                  <input
                    className="form-control form-control-sm"
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    onChange={onImgUpload}
                    disabled={vehicle._id ? disabled : true}
                  />
                </div>
                <small className="text-white mb-3">Formats: .jpg .jpeg .png</small>
              </div>
              <div className="d-flex">
                {/* NAME */}
                <FormInput
                  disabled={disabled}
                  label="Name for Vehicle *"
                  className="me-5"
                  value={name}
                  setValue={setName}
                />

                {/* MODEL */}
                <FormInput disabled={disabled} label="Model *" value={model} setValue={setModel} />
              </div>

              <div className="d-flex">
                {/* LICENSE PLATE */}
                <FormInput
                  disabled={disabled}
                  label="License Plate *"
                  className="me-5"
                  value={licensePlate}
                  setValue={setLicensePlate}
                />

                {/* MAKE */}
                <FormInput disabled={disabled} label="Make *" value={make} setValue={setMake} />
              </div>

              <div className="d-flex">
                {/* TYPE */}
                <FormInput
                  disabled={disabled}
                  type="select"
                  label="Type *"
                  className="me-5"
                  defaultLabel="Select Vehicle Type"
                  defaultValue={null}
                  value={type}
                  setValue={setType}
                  options={vehicleTypes.map((x) => ({ label: x, value: x }))}
                />

                {/* COLOR */}
                <FormInput disabled={disabled} label="Color *" value={color} setValue={setColor} />
              </div>

              <div className="d-flex align-items-end mt-5">
                {/* PERSON NAME */}
                <FormInput
                  label="Owner's Name"
                  className="me-5"
                  value={person?.name || 'N/A'}
                  setValue={setName}
                  disabled={true}
                />

                {/* PERSON DRIVER LICENSE */}
                <FormInput
                  label="Owner's License Number"
                  className="me-5"
                  value={person?.driverLicense || 'N/A'}
                  setValue={setName}
                  disabled={true}
                />

                {/* TOGGLE BTN */}
                <button className="btn btn-sm btn-primary w-50" onClick={() => setView('OWNER')}>
                  Assign Owner to Vehicle
                </button>
                <div className="d-flex centered"></div>
              </div>
            </>
          )}

          {/* OWNER VIEW */}
          {view === 'OWNER' && (
            <>
              {/* TOGGLE BTN */}
              <div className="d-flex centered mb-5">
                <button className="btn btn-sm btn-secondary" onClick={() => setView('VEHICLE')}>
                  Back to Vehicle Details
                </button>
              </div>

              {/* TITLE BAR */}
              <div className="d-flex flex-fill align-items-center justify-content-between px-2">
                {/* TITLE */}
                <h1 className="display-6 fs-3">Assign Vehicle</h1>

                {/* SEARCH BAR */}
                <form
                  onSubmit={(x) => {
                    x.preventDefault();
                    onPersonSearch();
                  }}
                  className="input-group input-group-sm w-50">
                  <span className="input-group-text">Name Search</span>
                  <input
                    className="form-control"
                    placeholder="Enter Full Name for Search"
                    value={searchName}
                    onChange={(x) => setSearchName(x.target.value)}
                  />
                  <button className="btn btn-secondary" type="submit">
                    {iconSearch}
                  </button>
                </form>

                {/* ASSIGN BTN */}
                <button className="btn btn-sm btn-primary" onClick={() => onAssignOwner()}>
                  Assign as Owner
                </button>
              </div>

              <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
                <AgGridReact
                  ref={table}
                  rowData={persons}
                  rowClassRules={rowClassRules}
                  columnDefs={columnDefs}
                  rowSelection="single"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
