import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL SITES
export const apiFindAllSites = createAsyncThunk('sites/findAll', async () => {
  const sites = await axios
    .get(`${apiLink}/sites`)
    .then((res) => res.data)
    .catch((err) => {
      console.log({ err })
      console.error('ERROR API FIND SITES: ', err)
    })
  return sites
})

// API FIND ASSIGNED SITES
export const apiFindAssignedSites = createAsyncThunk('sites/assigned', async (assignedClients) => {
  const data = await axios
    .post(`${apiLink}/sites`, { ids: assignedClients })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ASSIGNED SITES: ', err))
  return data
})

// API ADD NEW SITE
export const apiAddSite = createAsyncThunk('sites/add', async (newSite) => {
  const data = await axios
    .post(`${apiLink}/sites/add`, newSite)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW SITE: ', err))
  return data
})

// API UPDATE SITE
export const apiUpdateSite = createAsyncThunk('sites/update', async (updatedSite) => {
  const data = await axios
    .put(`${apiLink}/sites/update`, updatedSite)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE SITE: ', err))
  return data
})

const sitesSlice = createSlice({
  name: 'sitesSlice',
  initialState,
  reducers: {
    addSite(state, action) {
      state.data.unshift(action.payload)
    },
    updateSite(state, action) {
      state.data = state.data.map((x) => (x._id === action.payload._id ? action.payload : x))
    },
    clearSites(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // API FIND ALL SITES
      .addCase(apiFindAllSites.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindAllSites.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllSites.rejected, (state, action) => {
        state.isLoading = false
      })
      // API FIND ASSIGNED SITES
      .addCase(apiFindAssignedSites.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindAssignedSites.fulfilled, (state, action) => {
        state.isLoading = false
        state.lastRefresh = new Date().toISOString()
        state.data = action.payload
      })
      .addCase(apiFindAssignedSites.rejected, (state, action) => {
        state.isLoading = false
      })
      // API ADD NEW SITE
      .addCase(apiAddSite.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiAddSite.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddSite.rejected, (state, action) => {
        state.isLoading = false
      })
      // API UPDATE SITE
      .addCase(apiUpdateSite.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiUpdateSite.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiUpdateSite.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export const { addSite, updateSite, clearSites } = sitesSlice.actions

export default sitesSlice.reducer
