import { AgGridReact } from "ag-grid-react";
import React, { useRef, useState } from "react";
import { isExpired, isQtyBelow } from "../SiteInventory";

// PLUS ICON
const iconPlus = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    fill='currentColor'
    className='bi bi-plus-lg'
    viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z'
    />
  </svg>
);

// MINUS ICON
const iconMinus = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    fill='currentColor'
    className='bi bi-dash-lg'
    viewBox='0 0 16 16'>
    <path fillRule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z' />
  </svg>
);

export function CategoryItems({ category, changesCount, setChangesCount, onDeleteItem }) {
  const table = useRef();

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "name", flex: 3, sortable: true, filter: true, checkboxSelection: true },
    { field: "sub", headerName: "Sub-Category", flex: 2, sortable: true, filter: true },
    {
      field: "min",
      flex: 1,
      sortable: true,
      valueSetter: ({ newValue, data }) => {
        if (parseInt(newValue) >= 0) {
          data.min = newValue;
          return true;
        } else return false;
      },
      valueFormatter: ({ value }) => (!value ? "N/A" : value),
    },
    {
      field: "qty",
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: ({ data }) => {
        if (parseInt(data.qty) === 0) return "text-danger";
        else if (isQtyBelow(data)) return "text-warning";
      },
      valueSetter: ({ newValue, data }) => {
        if (parseInt(newValue) >= 0) {
          data.qty = newValue;
          return true;
        } else return false;
      },
    },
    { field: "serial", flex: 3, sortable: true, filter: true, valueFormatter: ({ value }) => (value ? value : "N/A") },
    {
      field: "expDate",
      flex: 2,
      sortable: true,
      filter: true,
      valueSetter: ({ newValue, data }) => {
        if ((newValue.indexOf("-") === 4 && newValue.indexOf("-", newValue.indexOf("-") + 1) === 7) || !newValue) {
          data.expDate = newValue;
          return true;
        } else return false;
      },
      valueFormatter: ({ value }) =>
        value
          ? isExpired({ expDate: value })
            ? "EXPIRED"
            : new Date(`${value}T00:00:00`).toLocaleDateString()
          : "YYYY-MM-DD",
      cellClass: ({ value }) => (isExpired({ expDate: value }) ? "text-danger" : ""),
    },
  ]);

  // DEFAULT COL DEF
  const defaultColDef = {
    editable: true,
  };

  // ON CELL VALUE CHANGED
  const onCellValueChanged = async ({ data, oldValue }) => {
    setChangesCount(table.current.api.getCurrentUndoSize());
  };

  // ON UNDO
  const onUndo = () => {
    table.current.api.undoCellEditing();
    const undoSize = table.current.api.getCurrentUndoSize();
    setChangesCount(undoSize);
  };

  // ON DELETE ITEM
  const onDeleteItemClicked = () => {
    const selectedRows = table.current.api.getSelectedRows();
    if (selectedRows.length) {
      setChangesCount(0);
      onDeleteItem(selectedRows[0], category.name);
    } else window.alert("No Item Selected");
  };

  return (
    <div className='d-flex flex-column h-100'>
      {/* GROUP TITLE BAR*/}
      <div className='d-flex justify-content-between align-items-center bg-secondary p-2'>
        {/* DELETE GROUP BTN */}
        <button className='btn btn-sm btn-danger' data-bs-toggle='modal' data-bs-target='#deleteCategoryPopUp'>
          Delete {category.name}
        </button>

        {/* TITLE */}
        <h1 className='display-6 fs-3 text-light text-center mb-1 user-select-none'>{category.name}</h1>

        {/* UNDO BTN */}
        <button className='btn btn-sm btn-warning' onClick={() => onUndo()} disabled={changesCount === 0}>
          Undo ({changesCount})
        </button>

        <div className='btn-group bg-light rounded'>
          {/* DELETE ITEM BTN */}
          <button
            className='btn btn-outline-danger p-0 pb-1 px-2'
            onClick={() => onDeleteItemClicked()}
            disabled={changesCount !== 0}>
            {iconMinus}
          </button>

          {/* ADD NEW ITEM BTN */}
          <button
            className='btn btn-outline-success p-0 pb-1 px-2'
            data-bs-toggle='modal'
            data-bs-target='#addNewItemPopUp'
            disabled={changesCount !== 0}>
            {iconPlus}
          </button>
        </div>
      </div>

      {/* GROUP LIST */}
      <div className='ag-theme-alpine-dark' style={{ height: `100%`, width: `100%` }}>
        <AgGridReact
          ref={table}
          selection='single'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={category.items}
          onCellValueChanged={(node) => onCellValueChanged(node)}
          undoRedoCellEditing={true}
          enableCellChangeFlash={true}
          stopEditingWhenCellsLoseFocus={true}
        />
      </div>
    </div>
  );
}
