import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";

export function SystemSettings() {
  const [tab, setTab] = useState(window.location.pathname.split("/")[3]);
  const btnSelected = "btn btn-sm btn-dark";
  const btnUnselected = "btn btn-sm btn-secondary border-dark";

  const TabBtn = ({ link, label }) => (
    <Link
      to={link}
      className={tab === link || tab === label ? btnSelected : btnUnselected}
      onClick={() => setTab(link)}>
      {label}
    </Link>
  );
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <div className='d-flex'>
        <div className='d-flex w-50 flex-column'>
          {/* TITLE */}
          <div className='d-flex p-2'>
            <h1 className='display-6'>System Settings</h1>
          </div>

          {/* TAB BAR */}
          <div className='btn-group'>
            <TabBtn link='events' label='Events' />
            <TabBtn link='regions' label='Regions' />
            <TabBtn link='branches' label='Branches' />
            <TabBtn link='email-groups' label='Email Groups' />

            {/* <TabBtn link='email-schedules' label='Email Schedules' /> */}
            <button className={btnUnselected} disabled>
              Email Schedules
            </button>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
}
