import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiUpdateClient, changeClientProtecteeStatus } from '../../../../../reduxPie/clientsSlice'
import { addLog, apiAddLog } from '../../../../../reduxPie/logsSlice'

export function ProtecteeRow({ protectee, keyNum = 'N/A', submittedOn = '', onSubmit }) {
  const { name, status } = protectee
  const { loggedIn, client, site } = useSelector((state) => state.selected)
  const dispatch = useDispatch()
  const rowSafe = 'd-flex table-primary'
  const rowCaution = 'd-flex table-warning'
  const [rowStyle, setRowStyle] = useState(rowCaution)
  const [currentStatus, setCurrentStatus] = useState(status)
  const [notes, setNotes] = useState('')
  const statusList = ['IR', 'OR']

  // ON STATUS CHANGE
  const onStatusChange = (newStatus) => {
    // LOG
    const newLog = {
      customer: loggedIn.customer,
      client: client ? client._id : '',
      site: site ? site?._id : '',
      data: `@${site?.name}: ${name} - ${newStatus}${notes ? ' - ' + notes : ''}`,
      submittedOn,
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
    }

    // UPDATED CLIENT
    const updatedClient = {
      id: client._id,
      customer: client.customer,
      region: client.region,
      branch: client.branch,
      name: client.name,
      status: name === client.name ? newStatus : client.status,
      protectees:
        name !== client.name
          ? client.protectees.map((x) =>
              x.name === name ? { name: x.name, status: newStatus } : { name: x.name, status: x.status }
            )
          : client.protectees,
      contactName: client.contactName,
      phone: client.phone,
      visitorGroups: client.visitorGroups,
      email: client.email,
      active: client.active,
      createdOn: client.createdOn,
      createdBy: client.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    }
    if (!submittedOn) {
      console.log('SUBMITTED ON: ', submittedOn)
      window.alert('*** STATUS CHANGE ERROR ***\n\nTime of Status Change is Invalid')
    } else {
      setCurrentStatus(newStatus)
      onSubmit(new Date().toLocaleString())
      setNotes('')
      dispatch(apiUpdateClient(updatedClient))
      dispatch(changeClientProtecteeStatus({ _id: client._id, name, status: newStatus }))
      dispatch(addLog(newLog))
      dispatch(apiAddLog(newLog))
    }
  }

  // ROW STYLE
  useEffect(() => {
    if (currentStatus === 'IR') setRowStyle(rowSafe)
    else setRowStyle(rowCaution)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus])

  // RENDER
  if (keyNum === 'N/A')
    return (
      <tr className={rowStyle}>
        {/* CLIENT NAME */}
        <td className='col-4 d-flex align-items-center'>{name}</td>

        {/* CLIENT NOTES */}
        <td className='col'>
          <input
            type='text'
            className='form-control form-control-sm'
            value={notes}
            placeholder='Enter Notes'
            onChange={(x) => setNotes(x.target.value)}
          />
        </td>

        {/* CLIENT STATUS */}
        <td className='col-2'>
          <select
            className='form-select form-select-sm'
            value={currentStatus}
            onChange={(x) => onStatusChange(x.target.value)}
          >
            {statusList.map((item, key) => (
              <option key={key}>{item}</option>
            ))}
          </select>
        </td>
      </tr>
    )

  return (
    <tr key={keyNum} className={rowStyle}>
      {/* PROTECTEE NAME */}
      <td className='col-4 d-flex align-items-center'>{name}</td>

      {/* PROTECTEE NOTES */}
      <td className='col'>
        <input
          type='text'
          className='form-control form-control-sm'
          value={notes}
          placeholder='Enter Notes'
          onChange={(x) => setNotes(x.target.value)}
        />
      </td>

      {/* PROTECTEE STATUS */}
      <td className='col-2'>
        <select
          className='form-select form-select-sm'
          value={currentStatus}
          onChange={(x) => onStatusChange(x.target.value)}
        >
          {statusList.map((item, key) => (
            <option key={key}>{item}</option>
          ))}
        </select>
      </td>
    </tr>
  )
}
