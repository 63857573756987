import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function ProtectorColors() {
  const { client } = useSelector((state) => state.selected);
  const sitesSlice = useSelector((state) => state.sites);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    setSites(sitesSlice?.data?.filter((x) => x.client === client._id).filter((x) => x.active));
  }, [client._id, sitesSlice.data]);

  return (
    <div className='d-flex flex-fill flex-column'>
      {sites?.map((item, key) => (
        <div key={key} className='card border-dark bg-light p-3 d-flex flex-column mb-3'>
          <h1 className='display-5 text-center'>{item.name}</h1>
          <div className='input-group  mb-3'>
            <span className='input-group-text'>Fire Dept</span>
            <input className='form-control' value={item.fireDep} readOnly />
          </div>
          <div className='input-group mb-3'>
            <span className='input-group-text'>Hospital</span>
            <input className='form-control' value={item.hospital} readOnly />
          </div>
          <div className='input-group'>
            <span className='input-group-text'>Police Dept</span>
            <input className='form-control' value={item.policeDep} readOnly />
          </div>
        </div>
      ))}
    </div>
  );
}
