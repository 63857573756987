import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddTitleBar } from '../../../../../components/AddTitleBar';
import { FormInput } from '../../../../../components/FormInput';
import { addManual, apiAddManual } from '../../../../../reduxPie/manualsSlice';
import { binderTabs } from '../CCManuals';
import { Loading } from '../../../../../components/Loading';

export function AddManual() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client, site, loggedIn } = useSelector((state) => state.selected);
  const [name, setName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [group, setGroup] = useState('');
  const [file, setFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // ON ADD NEW MANUAL
  const onAddNewManual = () => {
    setIsLoading(true);
    const newManual = {
      customer: loggedIn.customer,
      client: client._id,
      site: site._id,
      name: name + '.pdf',
      group: selectedGroup === 'Other' ? group : selectedGroup,
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };

    if (
      !name?.includes('.') &&
      ((selectedGroup && selectedGroup !== 'Other') || (selectedGroup === 'Other' && group)) &&
      file
    ) {
      let formData = new FormData();
      formData.append('collection', 'Manuals');
      formData.append('id', site._id);
      formData.append('type', selectedGroup === 'Other' ? group : selectedGroup);
      formData.append('file', file, name + '.pdf');
      dispatch(addManual(newManual));
      dispatch(apiAddManual({ newManual, formData }));

      setTimeout(() => {
        navigate(-1);
      }, 5000);
    } else {
      window.alert('Required:\n\n- File Name ( No Spaces or Periods )\n- Tab\n- File');
    }
  };

  if (isLoading) return <Loading title="Manuals" />;
  return (
    <div className="d-flex flex-fill flex-column card bg-black text-light">
      {/* TITLE BAR */}
      <AddTitleBar title="Manual" onAdd={onAddNewManual} backLink={-1} />

      {/* BODY */}
      <div className="d-flex flex-fill centered">
        <div className="col-6">
          {/* FILE NAME */}
          <FormInput label="Name" placeholder="Enter Name for Manual" value={name} setValue={setName} />

          {/* TAB */}
          <FormInput
            type="select"
            label="Tab"
            value={selectedGroup}
            setValue={setSelectedGroup}
            defaultLabel="Select Tab to Place Manual"
            defaultValue={''}
            options={binderTabs.map((x) => ({ label: x, value: x }))}
          />
          {selectedGroup === 'Other' && (
            <input
              className="form-control form-control-sm mb-3"
              placeholder="Enter New Tab"
              value={group}
              onChange={(x) => setGroup(x.target.value)}
            />
          )}

          {/* FILE */}
          <div className="mb-3">
            <label htmlFor="file" className="fw-bold lead">
              File
            </label>
            <input
              id="file"
              type="file"
              className="form-control form-control-sm"
              accept=".pdf"
              onChange={(x) => setFile(x.target.files[0])}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
