import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditTitleBar } from "../../../../../../components/EditTitleBar";
import { FormInput } from "../../../../../../components/FormInput";
import { apiUpdateEvent, updateEvent } from "../../../../../../reduxPie/eventsSlice";

export function EditEvent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, event } = useSelector((state) => state.selected);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState(event.name);
  const [type, setType] = useState(event.type);
  const [color, setColor] = useState(event.color);
  const eventTypes = ["Incident", "Activity", "Other"];

  // ON EDIT EVENT
  const onEditEvent = () => {
    // UPDATED EVENT
    const updatedEvent = {
      id: event._id,
      customer: event.customer,
      name,
      type,
      color,
      emails: event.emails,
      createdOn: event.createdOn,
      createdBy: event.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    if (name && type && color) {
      dispatch(
        updateEvent({ ...event, name, type, color, modifiedOn: new Date().toISOString(), modifiedBy: loggedIn._id })
      );
      dispatch(apiUpdateEvent(updatedEvent));
      navigate(-1);
    } else {
      window.alert("Required:\n - Name\n - Type\n - Color");
    }
  };

  return (
    <div className='d-flex flex-fill py-1'>
      <div className='card d-flex flex-fill bg-black text-light shadow-lg '>
        {/* TITLE BAR */}
        <EditTitleBar
          title={`Event: ${event?.name}`}
          backLink={-1}
          disabled={disabled}
          setDisabled={setDisabled}
          onUpdate={onEditEvent}
        />

        <div className='card-body container-fluid overflow-auto' style={{ height: window.innerHeight * 0.5 }}>
          <div className='d-flex h-100 centered'>
            <div className='col' />

            <div className='col'>
              {/* TYPE */}
              <FormInput
                type='select'
                label='Type'
                defaultLabel='Select Event Type'
                defaultValue=''
                value={type}
                setValue={setType}
                options={eventTypes.map((x) => ({ label: x, value: x }))}
                disabled={disabled}
              />

              {/* NAME */}
              <FormInput
                label='Name'
                placeholder='Enter Event Name'
                value={name}
                setValue={setName}
                disabled={disabled}
              />

              {/* COLOR */}
              <FormInput type='color' label='Color' value={color} setValue={setColor} disabled={disabled} />
            </div>
            <div className='col' />
          </div>
        </div>
      </div>
    </div>
  );
}
