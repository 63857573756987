import React from 'react';
import logo from '../media/logo.png';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

export function LogsPDF({ PDFData, logs, site = '', client }) {
  const firstLogDate = new Date(logs[logs.length - 1]?.createdOn).toLocaleDateString();
  const lastLogDate = new Date(logs[0]?.createdOn).toLocaleDateString();

  return (
    <Document>
      <Page>
        <View style={styles.table}>
          {PDFData?.map((item, key) =>
            item?.data?.length ? (
              <View key={key} style={{ marginBottom: 40 }}>
                {/* LOG CATEGORY */}
                <View style={styles.header} wrap={false}>
                  {/* LOGO */}
                  <View style={styles.headerCol}>
                    <Image src={logo} />
                  </View>
                  <View style={{ width: `100%`, color: 'whitesmoke', paddingVertical: 10 }}>
                    {/* CLIENT AND/OR SITE NAME W/ CATEGORY */}
                    <Text style={{ margin: 'auto', fontSize: 16 }}>
                      {client?.name}'s{' '}
                      {site?.name ? (site.name === client.name ? site.name + ' Site ' : `${site?.name} `) : ''}
                      {item?.title}
                    </Text>

                    <Text style={{ margin: 'auto', fontSize: 14 }}>
                      {logs?.length ? `${firstLogDate} - ${lastLogDate}` : ''}
                    </Text>
                  </View>
                </View>

                {/* TABLE HEADER */}
                <View style={styles.titleRow}>
                  <View style={[styles.titleCol, styles.col3]}>
                    <Text style={styles.titleCell}>Submitted On</Text>
                  </View>
                  <View style={[styles.titleCol, styles.col10]}>
                    <Text style={styles.titleCell}>Log Info</Text>
                  </View>
                  <View style={[styles.titleCol, styles.col3]}>
                    <Text style={styles.titleCell}>Created By</Text>
                  </View>
                </View>
                {/* LOGS */}
                {item?.data
                  ?.filter((x) => x?.active !== false)
                  ?.map((item, key) => {
                    let backgroundColor = key % 2 === 0 ? 'rgb(220, 220, 220)' : 'rgb(250, 250, 250)';
                    return (
                      <View key={key} style={[styles.tableRow, { backgroundColor }]} wrap={false}>
                        <View style={[styles.tableCol, styles.col3]}>
                          <Text style={styles.tableCell}>{item?.submittedOn}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.col10]}>
                          <Text style={[styles.tableCell, { marginHorizontal: 0 }]}>{item?.data}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.col3]}>
                          <Text style={styles.tableCell}>{item?.submittedBy}</Text>
                        </View>
                      </View>
                    );
                  })}
              </View>
            ) : (
              <></>
            )
          )}
        </View>
      </Page>
    </Document>
  );
}

// ------------- STYLES
const styles = StyleSheet.create({
  header: {
    height: 80,
    backgroundColor: 'black',
    flexDirection: 'row',
  },
  headerCol: {
    justifyContent: 'center',
    width: '30%',
  },
  titleRow: {
    flexDirection: 'row',
    width: '100%',
    color: 'white',
    backgroundColor: '#DC3545',
  },
  titleCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  titleCell: {
    margin: 'auto',
    padding: '5px',
    fontSize: 12,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    padding: '5px',
    fontSize: 8,
  },
  col1: {
    width: `${(1 / 12) * 100}%`,
  },
  col2: {
    width: `${(2 / 12) * 100}%`,
  },
  col3: {
    width: `${(3 / 12) * 100}%`,
  },
  col4: {
    width: `${(4 / 12) * 100}%`,
  },
  col5: {
    width: `${(5 / 12) * 100}%`,
  },
  col6: {
    width: `${(6 / 12) * 100}%`,
  },
  col10: {
    width: `${(10 / 12) * 100}%`,
  },
});
