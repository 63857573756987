import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
axios.defaults.withCredentials = true;

// API FIND ALL EMAIL SCHEDULES
export const apiFindAllEmailSchedules = createAsyncThunk("emailSchedules/findAll", async () => {
  const requestOptions = {
    method: "GET",
  };
  const emailSchedules = await axios("https://c24pro.net/emailschedules", requestOptions)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR API FIND ALL EMAIL SCHEDULES:", err));
  return emailSchedules;
});

// API ADD NEW EMAIL SCHEDULE
export const apiAddEmailGroup = createAsyncThunk("emailSchedules/create", async (emailSchedule) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: emailSchedule,
  };
  const data = await axios("https://c24pro.net/emailschedules/add", requestOptions)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR API ADD NEW EMAIL SCHEDULE:", err));
  return data;
});

const initialState = {
  isLoading: false,
  data: [],
};

const emailSchedulesSlice = createSlice({
  name: "emailSchedulesSlice",
  initialState,
  reducers: {
    addEmailSchdule(state, action) {
      state.data.push(action.payload);
    },
    clearEmailSchedules(state, action) {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // API FIND ALL EMAIL SCHEDULES
      .addCase(apiFindAllEmailSchedules.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(apiFindAllEmailSchedules.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data;
        state.isLoading = false;
      })
      .addCase(apiFindAllEmailSchedules.rejected, (state) => {
        state.isLoading = false;
      })
      // API ADD NEW EMAIL SCHEDULE
      .addCase(apiAddEmailGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(apiAddEmailGroup.fulfilled, (state, action) => {
        console.log(action.payload);
        state.isLoading = false;
      })
      .addCase(apiAddEmailGroup.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { addEmailSchdule, clearEmailSchedules } = emailSchedulesSlice.actions;

export default emailSchedulesSlice.reducer;
