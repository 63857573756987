import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL REGIONS
export const apiFindAllRegions = createAsyncThunk('regions/findAll', async () => {
  const regions = await axios
    .get(`${apiLink}/regions`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL REGIONS:', err))
  return regions
})

// API ADD NEW REGION
export const apiAddRegion = createAsyncThunk('regions/create', async (newRegion) => {
  const data = await axios
    .post(`${apiLink}/regions/add`, newRegion)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW REGION:', err))
  return data
})

const regionsSlice = createSlice({
  name: 'regionsSlice',
  initialState,
  reducers: {
    addRegion(state, action) {
      state.data.unshift(action.payload)
    },
    clearRegions(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND ALL REGIONS
      .addCase(apiFindAllRegions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindAllRegions.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllRegions.rejected, (state) => {
        state.isLoading = false
      })
      // API ADD NEW REGION
      .addCase(apiAddRegion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddRegion.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddRegion.rejected, (state) => {
        state.isLoading = false
      }),
})

export const { addRegion, clearRegions } = regionsSlice.actions

export default regionsSlice.reducer
