import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL USERS
export const apiFindAllUsers = createAsyncThunk('users/findAll', async () => {
  const users = await axios
    .get(`${apiLink}/users`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL USERS: ', err))
  return users
})

// API ADD NEW USER
export const apiAddUser = createAsyncThunk('users/create', async (newUser) => {
  const data = await axios
    .post(`${apiLink}/users/add`, newUser)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD USER: ', err))
  return data
})

// API UPDATE USER
export const apiUpdateUser = createAsyncThunk('users/update', async ({ updatedUser, formData }) => {
  if (formData) {
    await axios
      .post(`${apiLink}/upload`, formData)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPLOAD USER IMAGE:', err))
    const userRes = await axios
      .put(`${apiLink}/users/update`, updatedUser)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPDATE USER: ', err))
    return userRes
  } else {
    const userRes = await axios
      .put(`${apiLink}/users/update`, updatedUser)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPDATE USER: ', err))
    return userRes
  }
})

// API UPDATE PASSWORD
export const apiUpdatePassword = createAsyncThunk('users/update/password', async (reqObject) => {
  const res = await axios
    .post(`${apiLink}/changepassword`, reqObject)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE PASSWORD: ', err))
  return res
})

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    addUser(state, action) {
      state.data.unshift(action.payload)
    },
    updateUser(state, action) {
      state.data = [action.payload, ...state.data.filter((x) => x._id !== action.payload._id)]
    },
    clearUsers(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // API FIND ALL USERS
      .addCase(apiFindAllUsers.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindAllUsers.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllUsers.rejected, (state, action) => {
        state.isLoading = false
      })
      // API ADD NEW USER
      .addCase(apiAddUser.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiAddUser.fulfilled, (state, action) => {
        let user = action.payload
        if (user) {
          const { _id } = action.payload
          state.data.forEach((x) => {
            if (!x._id) {
              x._id = _id
            }
          })
          window.alert(`New ${user.level === '1' ? 'Manager' : 'Protector'}: ${user.name} has been added`)
        } else {
          window.alert('ERROR: A User has this email already')
        }
        state.isLoading = false
      })
      .addCase(apiAddUser.rejected, (state, action) => {
        state.isLoading = false
      })
      // API UPDATE USER
      .addCase(apiUpdateUser.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiUpdateUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiUpdateUser.rejected, (state, action) => {
        state.isLoading = false
      })
      // API UPDATE PASSWORD
      .addCase(apiUpdatePassword.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiUpdatePassword.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiUpdatePassword.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export const { addUser, clearUsers, updateUser, updateUserPassword } = usersSlice.actions

export default usersSlice.reducer
