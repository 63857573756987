import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../components/Loading";
import { TableTitleBar } from "../../../components/TableTitleBar";
import { apiLogsFindAllByPerson } from "../../../reduxPie/logsSlice";
import { apiFindAllUsers } from "../../../reduxPie/usersSlice";

export function AuditVisitor({ visitor }) {
  // const TODAY = new Date().toISOString();
  const yesterday = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const YSDY = yesterday.toISOString();
  const TMRW = tomorrow.toISOString();
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.logs);
  const usersSlice = useSelector((state) => state.users);
  const [fromDate, setFromDate] = useState(YSDY.slice(0, YSDY.indexOf("T")));
  const [toDate, setToDate] = useState(TMRW.slice(0, TMRW.indexOf("T")));
  const [logs, setLogs] = useState([]);
  const [hoursSheet, setHoursSheet] = useState([]);

  // USER INFO PULL
  const userInfoPull = (item) => {
    // MONGO DB GET => $oid
    const user = usersSlice.data.find((x) => x._id === item.createdBy);

    if (user) return user.name;
    else if (item?.createdBy?.$oid) return item?.createdBy?.$oid;
    else return "N/A";
  };

  // COLUMN DEFS
  const [columnDefs] = useState([
    {
      field: "submittedOn",
      valueSetter: () => {},
      headerName: "Time",
      filter: true,
      sortable: true,
    },
    {
      field: "data",
      valueSetter: () => {},
      headerName: "Log Info",
      flex: 1,
      filter: true,
      sortable: true,
    },
    { field: "submittedBy", valueSetter: () => {}, headerName: "Created By", filter: true, sortable: true },
  ]);

  // HOURS COLUMN DEFS
  const [hoursColumnDefs] = useState([
    {
      field: "in",
      flex: 1,
      filter: true,
      sortable: true,
    },
    {
      field: "out",
      flex: 1,
      filter: true,
      sortable: true,
    },
    {
      field: "totalHours",
      flex: 1,
      filter: true,
      sortable: true,
    },
  ]);

  // DEFAULT COL DEF
  const defaultColDef = {
    editable: true,
  };

  // ON SEARCH LOGS
  const onSearchLogs = () => {
    const logReq = {
      person: visitor._id,
      fromDate,
      toDate,
    };

    if (fromDate <= toDate) dispatch(apiLogsFindAllByPerson(logReq));
    else window.alert("Error With Date Range.\n\nPlease Enter a Valid Date Range.");
  };

  useEffect(() => {
    if (!usersSlice?.data?.length) dispatch(apiFindAllUsers());
    onSearchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REDUX FORCED REFRESH
  useEffect(() => {
    setLogs(
      data.map((item) => ({
        ...item,
        submittedBy: userInfoPull(item),
        data:
          item.data.slice(0, item.data.indexOf(":") + 1) +
          item.data.slice(item.data.indexOf(visitor?.name) + visitor?.name?.length + 2),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //  AUTO HOUR SHEET REFRESH
  useEffect(() => {
    let hours = [];
    for (let x = 0; x < logs.length; x++) {
      if (logs[x].data.includes("OUT")) {
        let timeOut = logs[x].data;
        let timeIn = logs[x + 1]?.data;

        if (timeOut && timeIn) {
          timeOut = timeOut.slice(timeOut.indexOf("OUT") + 4);
          timeIn = timeIn.slice(timeIn.indexOf("IN") + 3);
          let totalHours = `${(new Date(timeOut).getTime() - new Date(timeIn).getTime()) / 1000 / 60 / 60}`;
          totalHours = totalHours.slice(0, totalHours.indexOf(".") + 3);
          hours.push({ out: timeOut, in: timeIn, totalHours });
        }
      }
    }
    setHoursSheet(hours);
  }, [logs]);

  if (isLoading) return <Loading title='Logs' />;
  return (
    <div className='d-flex flex-column flex-fill'>
      {/* TITLE BAR */}
      <TableTitleBar
        title={`${visitor.name}'s Logs`}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        onSearchDateRange={onSearchLogs}
        miscId='viewHoursPopUp'
        miscLabel='View Hours'
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact defaultColDef={defaultColDef} rowData={logs} columnDefs={columnDefs} />
      </div>

      {/* HOURS POP UP */}
      <div id='viewHoursPopUp' className='modal fade' data-bs-backdrop='static'>
        <div className='modal-dialog modal-lg modal-dialog-centered' style={{ maxWidth: `75vw` }}>
          <div className='modal-content text-dark'>
            {/* HEADER */}
            <div className='modal-header'>
              <div className='d-flex w-100 justify-content-between align-items-center'>
                <h1>{visitor.name}'s Hours</h1>
                <button className='btn btn-close bg-danger me-1' data-bs-dismiss='modal' />
              </div>
            </div>

            {/* BODY */}
            <div className='modal-body'>
              <div style={{ height: `50vh`, width: `100%` }} className='ag-theme-alpine-dark'>
                <AgGridReact rowData={hoursSheet} columnDefs={hoursColumnDefs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
