/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink, logsLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true;

// API ADD LOG
export const apiAddLog = createAsyncThunk('logs/add', async (newLog) => {
  const data = await axios
    .post(`${apiLink}/logs/add`, newLog)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD LOG: ', err))
  return data
})

// API UPDATE LOG
export const apiUpdateLog = createAsyncThunk('logs/update', async ({ logId, updatedLog, auditLog }) => {
  const data = await axios
    .put(`${apiLink}/logs`, { logId, updatedLog, auditLog })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE LOG: ', err))
  return data
})

// API FIND ALL BY CLIENT
export const apiLogsFindAllByClient = createAsyncThunk('logs/find/byClient', async ({ client, fromDate, toDate }) => {
  const req = { client, fromDate, toDate }

  const dailyLog = await axios
    .post(logsLink + '/client', req)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API LOGS FIND BY CLIENT: ', err))

  return dailyLog
})

// API FIND ALL BY SITE
export const apiLogsFindAllBySite = createAsyncThunk('logs/find/bySite', async ({ site, fromDate, toDate }) => {
  const req = { site, fromDate, toDate }

  const siteLogs = await axios
    .post(logsLink + '/site', req)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API LOGS FIND BY SITE: ', err))

  return siteLogs
})

// API FIND ALL BY PERSON
export const apiLogsFindAllByPerson = createAsyncThunk('logs/find/byPerson', async ({ person, fromDate, toDate }) => {
  const req = { person, fromDate, toDate }

  const dailyLog = await axios
    .post(logsLink + '/person', req)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API LOGS FIND BY PERSON: ', err))

  return dailyLog
})

// API DEACTIVATE LOG
export const apiDeactivateLog = createAsyncThunk('logs/deactivate', async ({ _id }) => {
  const res = await axios
    .get(`${apiLink}/logs/${_id}/deactivate`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API LOGS DEACTIVATE: ', err))

  return res
})

const logsSlice = createSlice({
  name: 'logsSlice',
  initialState,
  reducers: {
    addLog(state, action) {
      state.data.unshift(action.payload)
    },
    clearLogs(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API LOGS FIND ALL BY CLIENT
      .addCase(apiLogsFindAllByClient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiLogsFindAllByClient.fulfilled, (state, action) => {
        return {
          isLoading: false,
          lastRefresh: new Date(),
          data: action.payload ? action.payload.reverse() : [],
        }
      })
      .addCase(apiLogsFindAllByClient.rejected, (state) => {
        state.isLoading = false
      })
      // API LOGS FIND ALL BY SITE
      .addCase(apiLogsFindAllBySite.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiLogsFindAllBySite.fulfilled, (state, action) => {
        return {
          isLoading: false,
          lastRefresh: new Date(),
          data: action.payload ? action.payload.reverse() : [],
        }
      })
      .addCase(apiLogsFindAllBySite.rejected, (state) => {
        state.isLoading = false
      })
      // API LOGS FIND ALL BY PERSON
      .addCase(apiLogsFindAllByPerson.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiLogsFindAllByPerson.fulfilled, (state, action) => {
        return {
          isLoading: false,
          lastRefresh: new Date(),
          data: action.payload ? action.payload.reverse() : [],
        }
      })
      .addCase(apiLogsFindAllByPerson.rejected, (state) => {
        state.isLoading = false
      })
      // API UPDATE LOG
      .addCase(apiUpdateLog.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiUpdateLog.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(apiUpdateLog.rejected, (state) => {
        state.isLoading = false
      })
      // API LOGS DEACTIVATE
      .addCase(apiDeactivateLog.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiDeactivateLog.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(apiDeactivateLog.rejected, (state) => {
        state.isLoading = false
      }),
})

export const { addLog, clearLogs } = logsSlice.actions

export default logsSlice.reducer
