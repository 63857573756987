import React from "react";
import { useSelector } from "react-redux";

export function ProtectorTestMgmt() {
    const tests = useSelector(state => state.tests);

    return (
        <div className="d-flex flex-fill flex-column">
            <div className="d-flex justify-content-between align-items-center p-3">
                <h1 className="display-6">Protector Test Management</h1>

                {/* Test Popup */ }
                <div className="modal fade" id="testModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">August Monthly Challenge</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex flex-column">
                                    <h1 className="lead fw-bold">How many rounds are required per shift?</h1>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q1" id="Q1A" />
                                        <label className="form-check-label" for="Q1A">11-12 (approx. 1 round every hour) </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q1" id="Q1B" />
                                        <label className="form-check-label" for="Q1B">6 (1 round every 2 hours) </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q1" id="Q1C" />
                                        <label className="form-check-label" for="Q1C">8-10 (appox. 1 round every 1.5 hours)</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q1" id="Q1D" />
                                        <label className="form-check-label" for="Q1D">None (We have good camera coverage and therefore do not need to conduct rounds)</label>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="lead fw-bold">What is #1/#2 Primary Care Physician's name?</h1>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q2" id="Q2A" />
                                        <label className="form-check-label" for="Q2A">Dr. Sanjay Gupta</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q2" id="Q2B" />
                                        <label className="form-check-label" for="Q2B">Dr. Philip Meyers</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q2" id="Q2C" />
                                        <label className="form-check-label" for="Q2C">Dr. Eduardo Dothun</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q2" id="Q2D" />
                                        <label className="form-check-label" for="Q2D">Dr. Gerardo Lopez</label>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="lead fw-bold">Who is Rawhide's SAM Cam partner?</h1>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q3" id="Q3A" />
                                        <label className="form-check-label" for="Q3A">Top Spin</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q3" id="Q3B" />
                                        <label className="form-check-label" for="Q3B">Adventuer Main</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q3" id="Q3C" />
                                        <label className="form-check-label" for="Q3C">Arcadia</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q3" id="Q3D" />
                                        <label className="form-check-label" for="Q3D">Editor</label>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="lead fw-bold">What is the poison control hotline?</h1>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q4" id="Q4A" />
                                        <label className="form-check-label" for="Q4A">(800)222-1222</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q4" id="Q4B" />
                                        <label className="form-check-label" for="Q4B">(800)211-1222</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q4" id="Q4C" />
                                        <label className="form-check-label" for="Q4C">(800)222-1212</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q4" id="Q4D" />
                                        <label className="form-check-label" for="Q4D">(800)222-2222</label>
                                    </div>
                                </div>

                                <div>
                                    <h1 className="lead fw-bold">What is the client duress word?</h1>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q5" id="Q5A" />
                                        <label className="form-check-label" for="Q5A">Banana</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q5" id="Q5B" />
                                        <label className="form-check-label" for="Q5B">Orange</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q5" id="Q5C" />
                                        <label className="form-check-label" for="Q5C">Pineapple</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="Q5" id="Q5D" />
                                        <label className="form-check-label" for="Q5D">Shirley Temple</label>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Submit</button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* TABLE HEADER */ }
            <div className="d-flex">
                <table className="table table-dark m-0">
                    <thead>
                        <tr className="d-flex">
                            <th className="col">Name</th>
                            <th className="col">Status</th>
                            <th className="col">Deadline</th>
                            <th className="col"># of Questions</th>
                            <th className="col">Grade</th>
                        </tr>
                    </thead>
                </table>
            </div>

            {/* TABLE BODY */ }
            <div className="d-flex flex-fill overflow-auto" style={ { height: window.innerHeight * .6 } }>
                <table className="table table-striped table-hover">
                    <tbody>
                        { tests.map((item, key) => (
                            <tr key={ key } className="d-flex" data-bs-toggle="modal" data-bs-target="#testModal">
                                <td className="col">{ item.name }</td>
                                <td className="col">{ item.status }</td>
                                <td className="col">{ item.deadline }</td>
                                <td className="col">{ item.numberOfQuestions }</td>
                                <td className="col">{ item.grade }</td>
                            </tr>
                        )) }
                    </tbody>
                </table>
            </div>
        </div>
    );
}