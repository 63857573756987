import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import clientsReducer from './reduxPie/clientsSlice'
import personsReducer from './reduxPie/personsSlice'
import selectedReducer from './reduxPie/selectedSlice'
import sitesReducer from './reduxPie/sitesSlice'
import usersReducer from './reduxPie/usersSlice'
import vehiclesReducer from './reduxPie/vehiclesSlice'
import formsReducer from './reduxPie/formsSlice'
import eventsReducer from './reduxPie/eventsSlice'
import reportsReducer from './reduxPie/reportsSlice'
import customerReducer from './reduxPie/customerSlice'
import regionsReducer from './reduxPie/regionsSlice'
import branchesReducer from './reduxPie/branchesSlice'
import emailGroupsReducer from './reduxPie/emailGroupsSlice'
import emailSchedulesReducer from './reduxPie/emailSchedulesSlice'
import logsReducer from './reduxPie/logsSlice'
import deliveriesReducer from './reduxPie/deliveriesSlice'
import keysReducer from './reduxPie/keysSlice'
import inventoryReducer from './reduxPie/inventorySlice'
import manualsReducer from './reduxPie/manualsSlice'
import auditLogsSlice from './reduxPie/auditLogsSlice'
import localforage from 'localforage'

const persistConfig = {
  key: 'root',
  version: 1,
  storage: localforage,
  // whitelist: [],
  whitelist: ['selected', 'persons', 'clients', 'sites', 'inventory', 'users', 'forms', 'events'],
}

const rootReducer = combineReducers({
  selected: selectedReducer,
  branches: branchesReducer,
  clients: clientsReducer,
  customer: customerReducer,
  emailGroups: emailGroupsReducer,
  emailSchedules: emailSchedulesReducer,
  events: eventsReducer,
  logs: logsReducer,
  auditLogs: auditLogsSlice,
  forms: formsReducer,
  keys: keysReducer,
  persons: personsReducer,
  regions: regionsReducer,
  reports: reportsReducer,
  sites: sitesReducer,
  users: usersReducer,
  vehicles: vehiclesReducer,
  deliveries: deliveriesReducer,
  inventory: inventoryReducer,
  manuals: manualsReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  // FIXES SERIALIZATION ISSUE
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      // FIXES HEAVY REDUX DATA BUG
      immutableCheck: false,
    }),
})
