import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddTitleBar } from "../../../../../../components/AddTitleBar";
import { FormInput } from "../../../../../../components/FormInput";
import { addEvent, apiAddEvent } from "../../../../../../reduxPie/eventsSlice";

export function AddEvent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn } = useSelector((state) => state.selected);
  const [name, setName] = useState("");
  const [type, setType] = useState("Incident");
  const [color, setColor] = useState("#000000");
  // const [emails, setEmails] = useState([]);
  const eventTypes = ["Incident", "Activity", "Other"];

  // ON ADD EVENT
  const onAddEvent = () => {
    if (name) {
      const newEvent = {
        customer: loggedIn.customer,
        name,
        type,
        color,
        // emails ,
        createdOn: new Date(),
        createdBy: loggedIn._id,
        modifiedOn: new Date(),
        modifiedBy: loggedIn._id,
      };
      dispatch(apiAddEvent(newEvent));
      dispatch(addEvent(newEvent));
      window.alert(`${type} - ${name} has been added to Events`);
      navigate("/manage/system-settings/event-types");
    } else {
      window.alert("Please Check Form and Try Again");
    }
  };

  return (
    <div className='d-flex flex-fill py-1'>
      <div className='card d-flex flex-fill bg-black text-light shadow-lg '>
        {/* TITLE BAR */}
        <AddTitleBar onAdd={onAddEvent} title='Event' backLink={-1} />

        <div className='card-body container-fluid overflow-auto' style={{ height: window.innerHeight * 0.5 }}>
          <div className='d-flex h-100 centered'>
            <div className='col' />

            <div className='col'>
              {/* TYPE */}
              <FormInput
                type='select'
                label='Type'
                defaultLabel='Select Event Type'
                value={type}
                setValue={setType}
                options={eventTypes.map((x) => ({ label: x, value: x }))}
              />

              {/* NAME */}
              <FormInput label='Name' placeholder='Enter Event Name' value={name} setValue={setName} />

              {/* COLOR */}
              <FormInput type='color' label='Color' value={color} setValue={setColor} />

              {/* EMAILS */}
              {/* <div className='input-group'>
                <span className='input-group-text'>Email List</span>
                <input
                  type='text'
                  className='form-control'
                  value={emails}
                  onChange={(x) => setEmails(x.target.value)}
                  disabled
                />
              </div> */}
            </div>
            <div className='col' />
          </div>
        </div>
      </div>
    </div>
  );
}
