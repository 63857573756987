import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSite } from "../../../../reduxPie/selectedSlice";
import { apiUpdateSite, updateSite } from "../../../../reduxPie/sitesSlice";
import { EditTitleBar } from "../../../../components/EditTitleBar";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../../../../components/FormInput";

export function EditClientSite() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { site, loggedIn } = useSelector((state) => state.selected);
  const formDefault = "form-control form-control-sm ";
  const formValid = formDefault + "is-valid ";
  const formInvalid = formDefault + "is-invalid ";
  const [disabled, setDisabled] = useState(true);

  // FORM VALIDATION STATE
  const [formName, setFormName] = useState(formDefault);

  // FORM STATE VALUES
  const [name, setName] = useState(site.name);
  const [phrase, setPhrase] = useState(site.phrase);
  const [active, setActive] = useState(site.active);
  const [streetAddress, setStreetAddress] = useState(site.fullAddress.streetAddress);
  const [city, setCity] = useState(site.fullAddress.city);
  const [state, setState] = useState(site.fullAddress.state);
  const [zip, setZip] = useState(site.fullAddress.zip);
  const [firstShift, setFirstShift] = useState(site.firstShift);
  const [leadCC, setLeadCC] = useState(site.leadCC);
  const [manned, setManned] = useState(site.manned);
  const [policeDep, setPoliceDep] = useState(site.policeDep);
  const [fireDep, setFireDep] = useState(site.fireDep);
  const [hospital, setHospital] = useState(site.hospital);

  //  ON CANCEL
  const onCancel = () => {
    setName(site.name);
    setPhrase(site.phrase);
    setLeadCC(site.leadCC);
    setManned(site.manned);
    setFirstShift(site.firstShift);
    setPoliceDep(site.policeDep);
    setHospital(site.hospital);
    setFireDep(site.fireDep);
    setDisabled(true);
  };

  // ON SITE UPDATE
  const onSiteUpdate = () => {
    const updatedSite = {
      id: site._id,
      customer: site.customer,
      client: site.client,
      region: site.region,
      branch: site.branch,
      name,
      phrase,
      active,
      policeDep,
      hospital,
      fireDep,
      fullAddress: {
        streetAddress,
        city,
        state,
        zip,
      },
      firstShift,
      allowedPersons: site.allowedPersons,
      leadCC,
      manned,
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    dispatch(
      updateSite({
        ...site,
        name,
        phrase,
        active,
        policeDep,
        hospital,
        fireDep,
        fullAddress: {
          streetAddress,
          city,
          state,
          zip,
        },
        firstShift,
        leadCC,
        manned,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      })
    );
    dispatch(apiUpdateSite(updatedSite));
    dispatch(
      setSite({
        ...site,
        name,
        phrase,
        active,
        policeDep,
        hospital,
        fireDep,
        fullAddress: {
          streetAddress,
          city,
          state,
          zip,
        },
        firstShift,
        leadCC,
        manned,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      })
    );
    window.alert(`Site - ${name} Has Been Updated`);
    navigate(-1);
  };

  // NAME VALIDATION
  useEffect(() => {
    // Only Alphabet Characters
    if (name.length > 0 && name.length === name.trim().length && name.match("(^[a-zA-Z0-9\\s]*$)")) {
      setFormName(formValid);
    } else if (name.length === 0) {
      setFormName(formDefault);
    } else {
      setFormName(formInvalid);
    }
  }, [name, formValid, formInvalid]);

  return (
    <div className='card d-flex flex-fill bg-black text-light overflow-auto'>
      {/* TITLE BAR */}
      <EditTitleBar
        title={site?.name}
        onUpdate={onSiteUpdate}
        onCancel={onCancel}
        backLink={-1}
        disabled={disabled}
        setDisabled={setDisabled}
      />

      <div className='d-flex flex-fill px-3 overflow-auto'>
        {/* LEFT COL */}
        <div className='col'>
          <h1 className='lead fw-bold fs-2 text-center'>Basic Info</h1>

          <div className='d-flex mb-3'>
            {/* NAME  */}
            <FormInput
              label='Name'
              className='me-3'
              formClass={formName}
              value={name}
              setValue={setName}
              disabled={disabled}
            />

            {/* ACTIVE STATUS */}
            <div className='w-100'>
              <span className='lead fw-bold'>Active Status</span>
              <select
                className='form-select form-select-sm'
                value={active}
                onChange={(x) => setActive(x.target.value)}
                disabled={disabled}>
                <option value={true}>Active</option>
                <option value={false}>Deactivated</option>
              </select>
            </div>
          </div>

          <div className='d-flex'>
            {/* PHRASE */}
            <FormInput label='Phrase' className='me-3' value={phrase} setValue={setPhrase} disabled={disabled} />

            {/* FIRST SHIFT */}
            <FormInput
              type='time'
              label='First Shift'
              value={firstShift}
              setValue={setFirstShift}
              disabled={disabled}
            />
          </div>

          <div className='d-flex justify-content-around mb-3'>
            {/* LEAD CC */}
            <div className='form-check'>
              <input
                type='checkbox'
                className='form-check-input'
                id='leadCC'
                checked={leadCC}
                onChange={(x) => setLeadCC(x.target.checked)}
                disabled={disabled}
              />
              <span forhtml='leadCC' className='form-check-span'>
                Lead CC
              </span>
            </div>

            {/* MANNED SITE */}
            <div className='form-check'>
              <input
                type='checkbox'
                className='form-check-input'
                id='siteManned'
                checked={manned}
                onChange={(x) => setManned(x.target.checked)}
                disabled={disabled}
              />
              <span forhtml='siteManned' className='form-check-span'>
                This Site is Manned
              </span>
            </div>
          </div>

          {/* FULL ADDRESS */}
          <h1 className='lead fw-bold fs-2 text-center'>Full Address</h1>

          <div className='d-flex'>
            {/* STREET ADDRESS */}
            <FormInput
              label='Street Address'
              className='me-3'
              value={streetAddress}
              setValue={setStreetAddress}
              disabled={disabled}
            />

            {/* CITY */}
            <FormInput label='City' value={city} setValue={setCity} disabled={disabled} />
          </div>

          <div className='d-flex mb-5'>
            {/* STATE */}
            <FormInput label='State' className='me-3' value={state} setValue={setState} disabled={disabled} />

            {/* ZIP CODE */}
            <FormInput label='Zip Code' value={zip} setValue={setZip} disabled={disabled} />
          </div>
        </div>

        {/* COL-1 DIVIDER */}
        <div className='col-1' />

        {/* RIGHT COL */}
        <div className='col'>
          {/* EMERGENCY FACILITIES */}
          <h1 className='lead fw-bold fs-2 text-center'>Emergency Facilities</h1>

          {/* POLICE DEPARTMENT */}
          <FormInput label='Police Department' value={policeDep} setValue={setPoliceDep} disabled={disabled} />

          {/* FIRE DEPARTMENT */}
          <FormInput label='Fire Department' value={fireDep} setValue={setFireDep} disabled={disabled} />

          {/* HOSPITAL */}
          <FormInput label='Hospital' value={hospital} setValue={setHospital} disabled={disabled} />
        </div>
      </div>
    </div>
  );
}
