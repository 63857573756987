import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { TableTitleBar } from "../../components/TableTitleBar";
import { apiFindAllForms } from "../../reduxPie/formsSlice";
import { setForm } from "../../reduxPie/selectedSlice";

export function Forms() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lastRefresh, data } = useSelector((state) => state.forms);
  const eventsSlice = useSelector((state) => state.events);
  const [forms, setForms] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "name", flex: 1, filter: true, sortable: true },
    { field: "eventName", headerName: "Event", flex: 1, filter: true, sortable: true },
    { field: "eventType", flex: 1, filter: true, sortable: true },
    { field: "createdOn", flex: 1, filter: true, valueFormatter: ({ value }) => new Date(value).toDateString() },
  ]);

  // ON REFRESH FORMS
  const onRefreshForms = () => {
    dispatch(apiFindAllForms());
  };

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    // FIXES DUPLICATE FORM ELEMENTS
    const formElementIds = [...new Set(item.data.data.map((x) => x.id))];
    const formElementList = formElementIds.map((y) => ({ ...item.data.data.find((x) => x.id === y) }));
    dispatch(setForm({ ...item.data, data: formElementList }));
    navigate("edit-form");
  };

  // AUTO FETCH FORMS
  useEffect(() => {
    if (!data.length) onRefreshForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  // REDUX FORCED REFRESH
  useEffect(() => {
    const Forms = data.map((item) => {
      let event = eventsSlice.data.find((x) => x._id === item.event);
      let { name, type } = event ? event : { name: "N/A", type: "N/A" };
      return { ...item, eventName: name, eventType: type };
    });
    setForms(Forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // RENDER
  if (!forms.length) return <Loading title='Forms' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Forms'
        addLabel='Form'
        addLink='add-form'
        hideSearch={true}
        onRefresh={onRefreshForms}
        lastRefresh={lastRefresh}
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact columnDefs={columnDefs} rowData={forms} onRowClicked={onViewDetails} />
      </div>
    </div>
  );
}
