import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "./NotFound";

// ----- TABS ----- //
import { Home } from "./tabs/home/Home";

// ----- MANAGE
import { SystemSettings } from "./tabs/manage/SystemSettings";
import { Clients } from "./tabs/manage/Clients";
import { ClientDashBoard } from "./tabs/manage/components/client/ClientDashBoard";
import { ClientStatus } from "./tabs/manage/components/sites/ClientStatus";
import { ClientReports } from "./tabs/manage/components/client/ClientReports";
import { ClientReportDetails } from "./tabs/manage/components/client/ClientReportDetails";
import { ClientProtectors } from "./tabs/manage/components/client/ClientProtectors";
import { ClientManagers } from "./tabs/manage/components/client/ClientManagers";
import { ClientSites } from "./tabs/manage/components/client/ClientSites";
import { AddUser } from "./tabs/manage/components/users/AddUser";
import { EditUser } from "./tabs/manage/components/users/EditUser";
import { AddClient } from "./tabs/manage/components/client/AddClient";
import { EditClient } from "./tabs/manage/components/client/EditClient";
import { AddForm } from "./tabs/manage/components/forms/AddForm";
import { SiteForms } from "./tabs/manage/components/sites/SiteForms";
import { AddReport } from "./tabs/manage/components/sites/AddReport";
import { SiteReports } from "./tabs/manage/components/sites/SiteReports";
import { SiteReportDetails } from "./tabs/manage/components/sites/SiteReportDetails";
import { SiteDashBoard } from "./tabs/manage/components/sites/SiteDashBoard";
import { SiteLogs } from "./tabs/manage/components/sites/SiteLogs";
import { SiteDeliveries } from "./tabs/manage/components/sites/SiteDeliveries";
import { AddDelivery } from "./tabs/manage/components/sites/components/AddDelivery";
import { EditDelivery } from "./tabs/manage/components/sites/components/EditDelivery";
import { AllSiteDeliveries } from "./tabs/manage/components/sites/components/AllSiteDeliveries";
import { SiteInventory } from "./tabs/manage/components/sites/SiteInventory";
import { AllowedVisitors } from "./tabs/manage/components/sites/components/AllowedVisitors";
import { VisitorCheckIn } from "./tabs/manage/components/sites/VisitorCheckIn";
import { EditForm } from "./tabs/manage/components/forms/EditForm";
import { AddKey } from "./tabs/manage/components/sites/components/AddKey";
import { AddEntry } from "./tabs/manage/components/sites/components/AddEntry";
import { EditKey } from "./tabs/manage/components/sites/components/EditKey";
import { ManagementReportDetails } from "./tabs/manage/components/management-reports/ManagementReportDetails";
import { SiteKeys } from "./tabs/manage/components/sites/SiteKeys";
import { CCManuals } from "./tabs/manage/components/sites/CCManuals";
import { AddClientSite } from "./tabs/manage/components/sites/AddClientSite";
import { Forms } from "./tabs/manage/Forms";
import { Users } from "./tabs/manage/Users";
import { ManagementReports } from "./tabs/manage/ManagementReports";
import { ClientLogs } from "./tabs/manage/components/client/ClientLogs";
import { ChangePassword } from "./tabs/manage/components/users/ChangePassword";

// ----- SYSTEM SETTINGS
import { EditEvent } from "./tabs/manage/components/systems-settings/components/events/EditEvent";

import { Events } from "./tabs/manage/components/systems-settings/Events";
import { AddEvent } from "./tabs/manage/components/systems-settings/components/events/AddEvent";
import { Branches } from "./tabs/manage/components/systems-settings/Branches";
import { EmailGroups } from "./tabs/manage/components/systems-settings/EmailGroups";
import { Regions } from "./tabs/manage/components/systems-settings/Regions";

// ----- VEHICLES
import { Vehicles } from "./tabs/vehicles/Vehicles";
import { EditVehicle } from "./tabs/vehicles/components/EditVehicle";
import { AddVehicle } from "./tabs/vehicles/components/AddVehicle";

// ----- VISITORS
import { Visitors } from "./tabs/visitors/Visitors";
import { VisitorSearch } from "./tabs/visitors/VisitorSearch";
import { EditVisitor } from "./tabs/visitors/components/EditVisitor";
import { AddVisitor } from "./tabs/visitors/components/AddVisitor";

// ----- PURSUERS
import { Pursuers } from "./tabs/pursuers/Pursuers";
import { EditPursuer } from "./tabs/pursuers/components/EditPursuer";
import { AddPursuer } from "./tabs/pursuers/components/AddPursuer";

// ----- PROTECTOR TEST MGMT
import { ProtectorTestMgmt } from "./tabs/protector-test-management/ProtectorTestMgmt";

// ----- EMAIL
import { EmailSchedules } from "./tabs/manage/components/systems-settings/EmailSchedules";
import { EditClientSite } from "./tabs/manage/components/sites/EditClientSite";
import { AddManual } from "./tabs/manage/components/sites/components/AddManual";
import { EditManual } from "./tabs/manage/components/sites/components/EditManual";
import { AddEmailGroup } from "./tabs/manage/components/systems-settings/components/emailGroups/AddEmailGroup";
import { EditEmailGroup } from "./tabs/manage/components/systems-settings/components/emailGroups/EditEmailGroup";
import { useEffect } from "react";
import { forcedLogOut } from ".";

import { SideBar } from "./components/side-bar/SideBar";

export function AppRoutes() {
  const location = useLocation();

  //TOOLBAR WINDOW HEIGHT
  const height = window.innerHeight * 0.849;

  // TOOLBAR WINDOW MARGIN TOP
  const marginTop = window.innerHeight * 0.15;

  // TOOLBAR WINDOW MARGIN LEFT
  const marginLeft = window.innerWidth * 0.15;

  useEffect(() => {
    forcedLogOut();
  }, [location]);

  return (
    <div
      className='d-flex'
      style={{
        height,
        marginTop,
        marginLeft,
      }}>
      {/* SIDE BAR NAV */}
      <SideBar />

      {/* TOOLBAR WINDOW */}
      <div className='d-flex flex-fill overflow-auto' style={{ height }}>
        <Routes>
          {/* ---------- HOME ---------- */}
          <Route index element={<Home />} />

          {/* ---------- MANAGE ---------- */}
          <Route path='/manage'>
            {/* SYSTEM SETTINGS */}
            <Route path='system-settings'>
              {/* INDEX */}
              <Route path='*' element={<SystemSettings />}>
                {/* EVENT TYPES */}
                <Route path='events'>
                  {/* INDEX */}
                  <Route index element={<Events />} />

                  {/* ADD EVENT */}
                  <Route path='add-event' element={<AddEvent />} />

                  {/* EDIT EVENT */}
                  <Route path='edit-event' element={<EditEvent />} />
                </Route>

                {/* REGIONS */}
                <Route path='regions' element={<Regions />} />

                {/* BRANCHES */}
                <Route path='branches' element={<Branches />} />

                {/* EMAIL GROUPS */}
                <Route path='email-groups'>
                  {/* INDEX */}
                  <Route index element={<EmailGroups />} />

                  {/* ADD EMAIL GROUP */}
                  <Route path='add-email-group' element={<AddEmailGroup />} />

                  {/* EDIT EMAIL GROUP */}
                  <Route path='edit-email-group' element={<EditEmailGroup />} />
                </Route>

                {/* EMAIL SCHEDULES */}
                <Route path='email-schedules' element={<EmailSchedules />} />
              </Route>
            </Route>

            {/* USERS */}
            <Route path='users'>
              {/* INDEX */}
              <Route index element={<Users />} />

              {/* ADD USER */}
              <Route path='add-user' element={<AddUser />} />

              {/* EDIT USER */}
              <Route path='edit-user'>
                {/* INDEX */}
                <Route index element={<EditUser />} />

                {/* CHANGE PASSWORD */}
                <Route path='password' element={<ChangePassword />} />
              </Route>
            </Route>

            {/* CLIENTS */}
            <Route path='clients'>
              {/* INDEX */}
              <Route index element={<Clients />} />

              {/* ADD CLIENT */}
              <Route path='add-client' element={<AddClient />} />

              {/* ADD CLIENT SITE */}
              <Route path='add-site' element={<AddClientSite />} />

              {/* CLIENT DASHBOARD */}
              <Route path='*' element={<ClientDashBoard />}>
                {/* CLIENT SITES */}
                <Route path='sites' element={<ClientSites />} exact />

                {/* CLIENT REPORTS */}
                <Route path='reports'>
                  {/* INDEX */}
                  <Route index element={<ClientReports />} />

                  {/* DETAILS */}
                  <Route path='details' element={<ClientReportDetails />} exact />
                </Route>

                {/* CLIENT LOGS */}
                <Route path='logs' exact>
                  {/* INDEX */}
                  <Route index element={<ClientLogs />} />
                </Route>

                {/* CLIENT ASSIGNED MANAGERS */}
                <Route path='managers' element={<ClientManagers />} exact />

                {/* CLIENT ASSIGNED PROTECTORS */}
                <Route path='protectors' element={<ClientProtectors />} exact />

                {/* CLIENT SETTINGS */}
                <Route path='settings' element={<EditClient />} exact />
              </Route>

              {/* SITE DASHBOARD */}
              <Route path='sites/*' element={<SiteDashBoard />}>
                {/* SITE SETTINGS */}
                <Route path='settings' element={<EditClientSite />} exact />

                {/* PROTECTEE STATUS */}
                <Route path='status' element={<ClientStatus />} exact />

                {/* SITE FORMS */}
                <Route path='forms'>
                  {/* INDEX */}
                  <Route index element={<SiteForms />} />

                  {/* DETAILS */}
                  <Route path='details' element={<AddReport />} exact />
                </Route>

                {/* SITE REPORTS */}
                <Route path='reports'>
                  {/* INDEX */}
                  <Route index element={<SiteReports />} />

                  {/* DETAILS */}
                  <Route path='details' element={<SiteReportDetails />} />
                </Route>

                {/* SITE LOGS */}
                <Route path='logs' element={<SiteLogs />} exact />

                <Route path='logs/add-entry' element={<AddEntry />} />

                {/* SITE COMMAND CENTER MANUAL */}
                <Route path='manuals'>
                  {/* INDEX */}
                  <Route index element={<CCManuals />} />

                  {/* ADD FILE */}
                  <Route path='add-manual' element={<AddManual />} />

                  {/* EDIT FILE */}
                  <Route path='edit-manual' element={<EditManual />} />
                </Route>

                {/* SITE INVENTORY */}
                <Route path='inventory' element={<SiteInventory />} exact />

                {/* SITE ADD TO ALLOWED VISITORS */}
                <Route path='allowed-visitors' element={<AllowedVisitors />} />

                {/* SITE VISITOR CHECK-IN */}
                <Route path='visitor-check-in' element={<VisitorCheckIn />} exact />

                {/* SITE KEYS */}
                <Route path='keys'>
                  {/* INDEX */}
                  <Route index element={<SiteKeys />} />

                  {/* ADD KEY */}
                  <Route path='add-key' element={<AddKey />} />

                  {/* EDIT KEY */}
                  <Route path='edit-key' element={<EditKey />} />
                </Route>

                {/* SITE DELIVERIES */}
                <Route path='deliveries'>
                  {/* INDEX */}
                  <Route index element={<SiteDeliveries />} />

                  {/* ADD DELIVERY */}
                  <Route path='add-delivery' element={<AddDelivery />} exact />

                  {/* EDIT DELIVERY */}
                  <Route path='edit-delivery' element={<EditDelivery />} exact />

                  {/* ALL DELIVERIES */}
                  <Route path='all' element={<AllSiteDeliveries />} exact />
                </Route>
              </Route>
            </Route>

            {/* FORMS */}
            <Route path='forms'>
              {/* INDEX */}
              <Route index element={<Forms />} />

              {/* ADD FORM */}
              <Route path='add-form' element={<AddForm />} exact />

              {/* DETAILS */}
              <Route path='edit-form' element={<EditForm />} />
            </Route>

            {/* MANAGEMENT REPORTS */}
            <Route path='management-reports'>
              {/* INDEX */}
              <Route index element={<ManagementReports />} />

              {/* REPORT DETAILS */}
              <Route path='details' element={<ManagementReportDetails />} />
            </Route>
          </Route>

          {/* ---------- VISITORS ---------- */}
          <Route path='/visitors'>
            {/* INDEX */}
            <Route index element={<Visitors />} />

            {/* SEARCH */}
            <Route path='search' element={<VisitorSearch />} exact />

            {/* DETAILS */}
            <Route path='edit-visitor' element={<EditVisitor />} exact />

            {/* ADD VISITOR */}
            <Route path='add-visitor' element={<AddVisitor />} exact />
          </Route>
          {/* ---------- PURSUERS ---------- */}
          <Route path='/pursuers'>
            {/* INDEX */}
            <Route index element={<Pursuers />} />

            {/* DETAILS */}
            <Route path='edit-pursuer' element={<EditPursuer />} exact />

            {/* ADD VISITOR */}
            <Route path='add-pursuer' element={<AddPursuer />} exact />
          </Route>
          {/* ---------- VEHICLES ---------- */}
          <Route path='/vehicles'>
            {/* INDEX */}
            <Route index element={<Vehicles />} />

            {/* DETAILS */}
            <Route path='details' element={<EditVehicle />} />

            {/* ADD NEW VEHICLE */}
            <Route path='add-vehicle' element={<AddVehicle />} />
          </Route>

          {/* ---------- PROTECTOR TEST MANAGEMENT ---------- */}
          <Route path='/protector-test-management' element={<ProtectorTestMgmt />} exact />
          {/* ---------- ERROR PAGE ---------- */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}
