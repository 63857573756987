import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL BY SITE
export const apiFindAllKeysBySite = createAsyncThunk('keys/findAll/site', async (id) => {
  const data = await axios
    .post(`${apiLink}/keys`, id)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL KEYS BY SITE: ', err))

  return data
})

// API ADD NEW KEY
export const apiAddKey = createAsyncThunk('keys/add', async (newKey) => {
  const data = await axios
    .post(`${apiLink}/keys/add`, newKey)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD KEY: ', err))

  return data
})

// API UPDATE KEY
export const apiUpdateKey = createAsyncThunk('keys/update', async (updatedKey) => {
  const data = await axios
    .put(`${apiLink}/keys/update`, updatedKey)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE KEY: ', err))

  return data
})

// API DELETE KEY
export const apiDeleteKey = createAsyncThunk('keys/delete', async (id) => {
  console.log('REQ: ', { data: { id } })
  const data = await axios
    .delete(`${apiLink}/keys/delete`, { data: { id } })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API DELETE KEY: ', err))

  console.log('RES: ', data)
  return data
})

const keysSlice = createSlice({
  name: 'keysSlice',
  initialState,
  reducers: {
    addKey(state, action) {
      state.data = [action.payload, ...state.data]
    },
    updateKey(state, action) {
      state.data = [action.payload, ...state.data.filter((x) => x._id !== action.payload._id)]
    },
    clearKeys(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // API FIND ALL SITES
      .addCase(apiFindAllKeysBySite.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindAllKeysBySite.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllKeysBySite.rejected, (state, action) => {
        state.isLoading = false
      })
      // API ADD NEW SITE
      .addCase(apiAddKey.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiAddKey.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddKey.rejected, (state, action) => {
        state.isLoading = false
      })
      // API UPDATE SITE
      .addCase(apiUpdateKey.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiUpdateKey.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiUpdateKey.rejected, (state, action) => {
        state.isLoading = false
      })
      // API DELETE SITE
      .addCase(apiDeleteKey.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiDeleteKey.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = []
      })
      .addCase(apiDeleteKey.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export const { addKey, updateKey, clearKeys } = keysSlice.actions

export default keysSlice.reducer
