import { React, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import { AgGridReact } from "ag-grid-react";
import { apiFindAllClients } from "../../../../reduxPie/clientsSlice";
import { addUser, apiAddUser } from "../../../../reduxPie/usersSlice";
import { AddTitleBar } from "../../../../components/AddTitleBar";
import { FormInput } from "../../../../components/FormInput";

export function AddUser() {
  const height = window.innerHeight * 0.58;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableAll = useRef();
  const tableAssigned = useRef();
  const { loggedIn } = useSelector((state) => state.selected);
  const clientsSlice = useSelector((state) => state.clients);
  const [showClients, setShowClients] = useState(false);
  const [assignedList, setAssignedList] = useState([]);
  const formDefault = "form-control form-control-sm ";
  const formValid = formDefault + "is-valid ";
  const formInvalid = formDefault + "is-invalid ";

  // FORM VALIDATION STATE
  const [formName, setFormName] = useState(formDefault);
  const [formEmail, setFormEmail] = useState(formDefault);
  const [formPassword, setFormPassword] = useState(formDefault);
  const [formConfirmPassword, setFormConfirmPassword] = useState(formDefault);

  // FORM STATE VALUES
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [level, setLevel] = useState("4");
  const [scanOption, setScanOption] = useState("NZR");
  const [status, setStatus] = useState("OFFSITE");
  const [active, setActive] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [assignedClients, setAssignedClients] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([{ field: "name", flex: 1, filter: true, sortable: true, checkboxSelection: true }]);

  // ON ADD USER
  const onAddUser = () => {
    if (
      formName === formValid &&
      formPassword === formValid &&
      formConfirmPassword === formValid &&
      formEmail === formValid
    ) {
      let newUser = {
        customer: "61f03c32d698c6c3cbaca113", // C24
        region: loggedIn.region,
        branch: loggedIn.branch,
        password,
        name,
        email,
        level,
        scanOption,
        onDuty: false,
        phone: phone,
        status,
        active,
        imgLink: "https://via.placeholder.com/300/C0C0C0/808080/?text=No+ImageCurrently+Uploaded",
        currentClient: null,
        assignedClients: assignedClients.map((x) => x._id),
        currentSite: null,
        lastPasswordChange: new Date().toISOString(),
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };
      dispatch(addUser(newUser));
      dispatch(apiAddUser(newUser));
      navigate("/manage/users");
    } else {
      window.alert("Required: \n- Name\n- Email\n- Password");
    }
  };

  // ON ASSIGN CLIENTS
  const onAssignClients = () => {
    const data = tableAll.current.api.getSelectedRows();
    setAssignedClients([...new Set([...assignedClients, ...data.map((x) => x._id)])]);
  };

  // ON REMOVE CLIENT
  const onRemoveClients = () => {
    const data = tableAssigned.current.api.getSelectedRows();
    const idList = data.map((x) => x._id);
    setAssignedClients(assignedClients.filter((x) => !idList.includes(x)));
  };

  // AUTO FETCH CLIENTS
  useEffect(() => {
    if (clientsSlice.data.length === 0) dispatch(apiFindAllClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NAME VALIDATION
  useEffect(() => {
    // Only Alphabet Characters
    if (name.length > 0 && name.length === name.trim().length && name.match("(^[a-zA-Z\\s]*$)")) {
      setFormName(formValid);
    } else if (name.length === 0) {
      setFormName(formDefault);
    } else {
      setFormName(formInvalid);
    }
  }, [name, formValid, formInvalid]);

  // EMAIL VALIDATION
  useEffect(() => {
    // Email Regex from W3Schools
    if (
      email.match("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$") &&
      email.indexOf("@") < email.lastIndexOf(".")
    ) {
      setFormEmail(formValid);
    } else if (email.length === 0) {
      setFormEmail(formDefault);
    } else {
      setFormEmail(formInvalid);
    }
  }, [email, formValid, formInvalid]);

  // PASSWORD VALIDATION
  useEffect(() => {
    // Atleast One Number
    // Atleast One Lower Case Letter
    // Atleast One Upper Case Letter
    // Atleast One Special Character
    // Atleast 8 Characters
    if (password.match("(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}")) {
      setFormPassword(formValid);
    } else if (password.length === 0) {
      setFormPassword(formDefault);
    } else {
      setFormPassword(formInvalid);
    }
  }, [password, formValid, formInvalid]);

  // ----- CONFIRM PASSWORD VALIDATION
  useEffect(() => {
    if (confirmPassword === password && confirmPassword.length > 0) {
      setFormConfirmPassword(formValid);
    } else if (confirmPassword !== password) {
      setFormConfirmPassword(formInvalid);
    } else {
      setFormConfirmPassword(formDefault);
    }
  }, [password, formValid, confirmPassword, formInvalid]);

  // ASSIGNED CLIENTS REFRESH
  useEffect(() => {
    const list = assignedClients.map((x) => {
      const client = clientsSlice.data.find((y) => y._id === x);
      return { _id: x, name: client ? client.name : "N/A" };
    });

    setAssignedList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedClients]);

  // RENDER
  if (clientsSlice.isLoading) return <Loading title='Clients' />;
  return (
    <div className='d-flex flex-fill py-1'>
      <div className='card d-flex flex-fill bg-black text-light shadow-lg'>
        {/* TITLE BAR */}
        <AddTitleBar backLink='/manage/users' onAdd={onAddUser} title={level === "1" ? "Manager" : "Protector"} />

        {/* ADD CLIENTS TOGGLE */}
        <div className='d-flex centered'>
          <button
            className={`btn btn-sm btn-${showClients ? "secondary" : "primary"} w-50`}
            onClick={() => setShowClients(!showClients)}>
            {showClients
              ? `Back to ${level === "1" ? "Manager" : "Protector"} Details`
              : `Add Clients to ${level === "1" ? "Manager" : "Protector"}`}
          </button>
        </div>

        {/* CARD BODY */}
        {/* TRUE = CLIENT LIST, FALSE = USER FORM */}
        {showClients ? (
          <div className='card-body overflow-auto'>
            <div className='d-flex flex-fill h-100'>
              {/* LEFT COL */}
              <div className='col pe-2'>
                {/* TITLE BAR */}
                <div className='d-flex align-items-center justify-content-between px-2'>
                  {/* TITLE */}
                  <h1 className='lead fw-bold fs-2'>All Clients</h1>

                  {/* ASSIGN CLIENT(S) BTN */}
                  <button className='btn btn-sm btn-primary' onClick={() => onAssignClients()}>
                    Assign Client(s)
                  </button>
                </div>

                {/* ALL CLIENTS TABLE */}
                <div className='ag-theme-alpine-dark' style={{ height, width: `100%` }}>
                  <AgGridReact
                    ref={tableAll}
                    columnDefs={columnDefs}
                    rowData={clientsSlice.data}
                    rowSelection='multiple'
                  />
                </div>
              </div>
              {/* RIGHT COL */}
              <div className='col ps-2'>
                {/* TITLE BAR */}
                <div className='d-flex align-items-center justify-content-between px-2'>
                  {/* TITLE */}
                  <h1 className='lead fw-bold fs-2'>Assigned Clients</h1>

                  {/* REMOVE CLIENT(S) BTN */}
                  <button className='btn btn-sm btn-warning' onClick={() => onRemoveClients()}>
                    Remove Client(s)
                  </button>
                </div>

                {/* ASSIGNED CLIENTS TABLE */}
                <div className='ag-theme-alpine-dark' style={{ height, width: `100%` }}>
                  <AgGridReact
                    ref={tableAssigned}
                    columnDefs={columnDefs}
                    rowData={assignedList}
                    rowSelection='multiple'
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='card-body d-flex overflow-auto'>
            <div className='d-flex flex-fill'>
              {/* LEFT COL */}
              {/* <div className='col'>
                <div className='d-flex centered'>
                  <img src='https://via.placeholder.com/250x300' alt='...' className='img-thumbnail shadow-sm' />
                </div>
              </div> */}

              {/* CENTER COL */}
              <div className='col px-2'>
                <h1 className='lead fs-2 fw-bold text-center'>Basic Info</h1>

                <div className='d-flex mb-4'>
                  {/* NAME  */}
                  <FormInput className='me-3' label='Name *' formClass={formName} value={name} setValue={setName} />

                  {/* PHONE */}
                  <FormInput className='me-3' type='phone' label='Phone' value={phone} setValue={setPhone} />

                  {/* LEVEL  */}
                  <FormInput
                    type='select'
                    label='Level'
                    defaultLabel='Choose One'
                    value={level}
                    setValue={setLevel}
                    options={[
                      { label: "Manager", value: "1" },
                      { label: "Protector", value: "4" },
                    ]}
                  />
                </div>

                <div className='d-flex'>
                  {/* ACTIVE STATUS */}
                  <FormInput
                    type='select'
                    label='Active Status'
                    className='me-3'
                    defaultLabel='Choose One'
                    value={active}
                    setValue={setActive}
                    options={[
                      { label: "Active", value: true },
                      { label: "Deactivated", value: false },
                    ]}
                  />

                  {/* SCAN OPTION DROPDOWN */}
                  <FormInput
                    className='me-3'
                    type='select'
                    label='Scan Options'
                    defaultLabel='Choose One'
                    value={scanOption}
                    setValue={setScanOption}
                    options={[
                      { label: "NZR", value: "NZR" },
                      { label: "QR", value: "QR" },
                      { label: "NFC", value: "NFC" },
                    ]}
                  />

                  {/* PROTECTOR STATUS */}
                  <FormInput
                    type='select'
                    label='Protector Status'
                    defaultLabel='Choose One'
                    value={status}
                    setValue={setStatus}
                    options={[
                      { label: "OFFSITE", value: "OFFSITE" },
                      { label: "ONSITE", value: "ONSITE" },
                      { label: "ON-COVERAGE", value: "ON-COVERAGE" },
                      { label: "OCC", value: "OCC" },
                      { label: "ICC", value: "ICC" },
                    ]}
                  />
                </div>
              </div>

              <div className='col-1' />

              {/* RIGHT COL */}
              <div className='col px-2'>
                <h1 className='lead fs-2 fw-bold text-center'>Login Info</h1>
                <div className='d-flex mb-3'>
                  {/* EMAIL */}
                  <FormInput className='me-3' label='Email' formClass={formEmail} value={email} setValue={setEmail} />

                  {/* PASSWORD  */}
                  <FormInput
                    type='password'
                    label='Password'
                    formClass={formPassword}
                    value={password}
                    setValue={setPassword}
                  />
                </div>

                {/* PASSWORD REQUIREMENTS */}
                <div className='d-flex flex-column centered mb-3'>
                  <small>Password Must Include Atleast:</small>
                  <small>One Number : 0-9 </small>
                  <small>One Lower Case Letter : a-z </small>
                  <small>One Upper Case Letter : A-Z </small>
                  <small>One Special Character : ! ) @ # $ % ( ^ & * </small>
                  <small>8 Characters Long</small>
                </div>

                {/* CONFIRM PASSWORD */}
                <FormInput
                  type='password'
                  label='Confirm Password'
                  formClass={formConfirmPassword}
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
