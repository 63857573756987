import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL CLIENTS
export const apiFindAllClients = createAsyncThunk('clients/findAll', async () => {
  const clients = await axios
    .get(`${apiLink}/clients`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR FIND ALL CLIENTS: ', err))

  return clients
})

// API FIND ASSIGNED CLIENTS
export const apiFindAssignedClients = createAsyncThunk('clients/findAssigned', async (assignedClientIds) => {
  const assignedClients = await axios
    .post(`${apiLink}/clients`, assignedClientIds)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW CLIENT: ', err))

  return assignedClients
})

// API FIND ONE CLIENT
export const apiFindOneClient = createAsyncThunk('clients/findOne', async (clientId) => {
  const client = await axios
    .post(`${apiLink}/clients/findone`, clientId)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW CLIENT: ', err))

  return client
})

// API ADD NEW CLIENT
export const apiAddClient = createAsyncThunk('clients/add', async (newClient) => {
  const data = await axios
    .post(`${apiLink}/clients/add`, newClient)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW CLIENT: ', err))

  return data
})

// API UPDATE CLIENT
export const apiUpdateClient = createAsyncThunk('clients/update', async (updatedClient) => {
  const data = await axios
    .put(`${apiLink}/clients/update`, updatedClient)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE CLIENT: ', err))

  return data
})

// API CHANGE PROTECTEE STATUS
export const apiChangeProtecteeStatus = createAsyncThunk('clients/status', async (statusChange) => {
  const data = await axios
    .patch(`${apiLink}/clients/status`, statusChange)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API CHANGE PROTECTEE STATUS: ', err))
  return data
})

const clientSlice = createSlice({
  name: 'clientsSlice',
  initialState,
  reducers: {
    addClient(state, action) {
      state.data.push(action.payload)
    },
    updateClient(state, action) {
      state.data = state.data.map((x) => (x._id === action.payload._id ? action.payload : x))
    },
    changeClientProtecteeStatus(state, action) {
      const { _id, name, status } = action.payload
      const list = state.data
      var updatedClient = list.find((x) => x._id === _id)
      var protectees = updatedClient.protectees
      if (updatedClient.name === name) {
        updatedClient.status = status
      } else {
        protectees = updatedClient.protectees.map((x) =>
          x.name === name ? { name: x.name, status } : { name: x.name, status: x.status }
        )
      }
      state.data = [{ ...updatedClient, protectees }, ...list.filter((x) => x._id !== _id)]
    },
    clearClients(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // API FIND ALL CLIENTS
      .addCase(apiFindAllClients.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindAllClients.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllClients.rejected, (state) => {
        state.isLoading = false
      })
      // API FIND ASSIGNED CLIENTS
      .addCase(apiFindAssignedClients.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindAssignedClients.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAssignedClients.rejected, (state) => {
        state.isLoading = false
      })
      // API FIND ONE CLIENT
      .addCase(apiFindOneClient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindOneClient.fulfilled, (state, action) => {
        const list = state.data
        state.data = action.payload ? [action.payload, ...list.filter((x) => x._id !== action.payload._id)] : state.data
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindOneClient.rejected, (state) => {
        state.isLoading = false
      })
      // API ADD NEW CLIENT
      .addCase(apiAddClient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddClient.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddClient.rejected, (state) => {
        state.isLoading = false
      })
      // API UPDATE CLIENT
      .addCase(apiUpdateClient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiUpdateClient.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiUpdateClient.rejected, (state) => {
        state.isLoading = false
      })
      // API CHANGE PROTECTEE STATUS
      .addCase(apiChangeProtecteeStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiChangeProtecteeStatus.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiChangeProtecteeStatus.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { addClient, updateClient, clearClients, changeClientProtecteeStatus } = clientSlice.actions

export default clientSlice.reducer
