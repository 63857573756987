import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import { setSite } from "../../../../reduxPie/selectedSlice";
import { apiFindAssignedSites } from "../../../../reduxPie/sitesSlice";
import { TableTitleBar } from "../../../../components/TableTitleBar";
import { AgGridReact } from "ag-grid-react";
import { apiFindAllForms } from "../../../../reduxPie/formsSlice";

export function ClientSites() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client, loggedIn } = useSelector((state) => state.selected);
  const sitesSlice = useSelector((state) => state.sites);
  const formsSlice = useSelector((state) => state.forms);
  const [sites, setSites] = useState([]);
  const [onlyActive, setOnlyActive] = useState(true);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "name", flex: 1, filter: true, sortable: true },
    { field: "firstShift", flex: 1, filter: true, sortable: true },
    {
      field: "modifiedOn",
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: ({ value }) => new Date(value).toLocaleString(),
    },
    {
      field: "active",
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: ({ value }) => (value ? "Active" : "Deactivated"),
    },
  ]);

  // ROW CLASS RULES
  const rowClassRules = {
    "bg-danger": "!data.active",
  };

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setSite(item.data));
    navigate("/manage/clients/sites/visitor-check-in");
  };

  // ON REFRESH SITES
  const onRefreshSites = () => {
    if (loggedIn?.assignedClients?.length) dispatch(apiFindAssignedSites(loggedIn.assignedClients));
    else dispatch(apiFindAssignedSites([client._id]));
  };

  // AUTO FETCH SITES & FORMS
  useEffect(() => {
    onRefreshSites();
    if (!formsSlice.data.length) dispatch(apiFindAllForms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ACTIVE TOGGLE
  useEffect(() => {
    // if (onlyActive) setSites(sitesSlice?.data?.filter((x) => x.active));
    // else setSites(sitesSlice.data);
    if (onlyActive) setSites(sitesSlice?.data?.filter((x) => x?.client === client?._id).filter((x) => x?.active));
    else setSites(sitesSlice?.data?.filter((x) => x?.client === client?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitesSlice.data, onlyActive]);

  // // ONLY THIS CLIENT'S SITES ***
  // useEffect(() => {
  //   if (onlyActive) setSites(sitesSlice.data?.filter((x) => x.client === client._id).filter((x) => x.active));
  //   else setSites(sitesSlice.data?.filter((x) => x.client === client._id));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sitesSlice.data]);

  // RENDER
  if (sitesSlice.isLoading) return <Loading title='Sites' />;
  return (
    <div className='d-flex flex-column flex-fill'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Client Sites'
        addLabel='Site'
        addLink='/manage/clients/add-site'
        hideSearch={true}
        onlyActive={onlyActive}
        setOnlyActive={setOnlyActive}
        onRefresh={onRefreshSites}
        lastRefresh={sitesSlice.lastRefresh}
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact
          rowData={sites}
          columnDefs={columnDefs}
          rowClassRules={rowClassRules}
          onRowClicked={onViewDetails}
        />
      </div>
    </div>
  );
}
