import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  // {
  //   name: "June",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "12",
  //   grade: "100%",
  // },
  // {
  //   name: "May",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "5",
  //   grade: "100%",
  // },
  // {
  //   name: "April",
  //   status: "Take Test",
  //   deadline: "No Deadline",
  //   numberOfQuestions: "5",
  //   grade: "80%",
  // },
  // {
  //   name: "March",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "8",
  //   grade: "100%",
  // },
  // {
  //   name: "February",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "8",
  //   grade: "100%",
  // },
  // {
  //   name: "January",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "8",
  //   grade: "100%",
  // },
  // {
  //   name: "December",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "12",
  //   grade: "100%",
  // },
  // {
  //   name: "November",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "8",
  //   grade: "100%",
  // },
  // {
  //   name: "October",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "8",
  //   grade: "100%",
  // },
  // {
  //   name: "September",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "5",
  //   grade: "85%",
  // },
  // {
  //   name: "August Monthly Challenge",
  //   status: "Take Test",
  //   deadline: "No Deadline",
  //   numberOfQuestions: "5",
  //   grade: "80%",
  // },
  // {
  //   name: "July Monthly Challenge",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "5",
  //   grade: "80%",
  // },
  // {
  //   name: "June Monthly Challenge",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "5",
  //   grade: "80%",
  // },
  // {
  //   name: "May Monthly Challenge",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "5",
  //   grade: "80%",
  // },
  // {
  //   name: "April Monthly Challenge",
  //   status: "Past Deadline",
  //   deadline: new Date().toLocaleString(),
  //   numberOfQuestions: "5",
  //   grade: "80%",
  // },
];

const testsSlice = createSlice({
  name: "testsSlice",
  initialState,
  reducers: {
    clearTests(state, action) {
      state = initialState;
    },
  },
});

export const { clearTests } = testsSlice.actions;

export default testsSlice.reducer;
