import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { apiFindAllForms } from "../../../../reduxPie/formsSlice";
import { apiFindAllReports } from "../../../../reduxPie/reportsSlice";
import { apiFindAllSites } from "../../../../reduxPie/sitesSlice";
import { apiFindAllUsers } from "../../../../reduxPie/usersSlice";
import { HeaderNav } from "./header-nav/HeaderNav";

export function ClientDashBoard() {
  const dispatch = useDispatch();
  const btnSelected = "btn btn-sm btn-dark";
  const btnUnselected = "btn btn-sm btn-secondary border-dark";
  const { client } = useSelector((state) => state.selected);
  const myClient = client ? client : { name: "", contactName: "", phone: "", status: "" };
  const [tab, setTab] = useState(window.location.pathname.split("/")[3]);
  const sitesSlice = useSelector((state) => state.sites);
  const usersSlice = useSelector((state) => state.users);
  const formsSlice = useSelector((state) => state.forms);
  const reportsSlice = useSelector((state) => state.reports);

  // AUTO FETCH MAIN DATA
  useEffect(() => {
    if (!formsSlice?.data?.length) dispatch(apiFindAllForms());
    if (!reportsSlice?.data?.length) dispatch(apiFindAllReports());
    if (!usersSlice?.data?.length) dispatch(apiFindAllUsers());
    if (!sitesSlice?.data?.length) dispatch(apiFindAllSites());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TabBtn = ({ label, link }) => {
    const className = tab === link || link === window.location.pathname.split("/")[3] ? btnSelected : btnUnselected;
    return (
      <Link to={`/manage/clients/${link}`} className={className} onClick={() => setTab(label)}>
        {label.slice(0, 1).toUpperCase() + label.slice(1)}
      </Link>
    );
  };

  // RENDER
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* HEADER NAV */}
      <HeaderNav />

      {/* TITLE BAR */}
      <div className='d-flex'>
        <div className='d-flex flex-fill flex-column'>
          <div className='d-flex align-items-center'>
            {/* ALL CLIENTS BTN */}
            <Link to='/manage/clients' className='btn btn-sm btn-dark me-3'>
              All Clients
            </Link>

            {/* TITLE */}
            <h1 className='display-6 fw-bold'>{myClient.name}</h1>

            {/* CLIENT SETTINGS BTN */}
            <Link to='/manage/clients/settings' className='btn btn-sm btn-secondary mx-3'>
              Client Settings
            </Link>
          </div>

          {/* TAB BAR */}
          <div className='btn-group w-75' role='group'>
            <TabBtn label='Client Sites' link='sites' />
            <TabBtn label='Client Reports' link='reports' />
            <TabBtn label='Client Logs' link='logs' />
            <TabBtn label='Managers' link='managers' />
            <TabBtn label='Protectors' link='protectors' />
            <button className={btnUnselected} disabled>
              Report Distibution
            </button>
          </div>

          {/* DETAILS */}
          <div className='d-flex'>
            <div className='card-under rounded ps-2'>
              <span className='me-3'>
                <span className='text-secondary fw-bold user-select-none'>Contact Name: </span>
                {myClient.contactName}
              </span>
              <span className='me-3'>
                <span className='text-secondary fw-bold user-select-none'>Phone: </span>
                {}
              </span>
              <span className='me-3 user-select-none'>
                <span className='text-secondary fw-bold'>Status: </span>
                {myClient.status}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
}
