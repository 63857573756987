import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditTitleBar } from '../../../../../components/EditTitleBar';
import { FormInput } from '../../../../../components/FormInput';
import { apiUpdateKey, updateKey, apiDeleteKey } from '../../../../../reduxPie/keysSlice';

export function EditKey() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { key, loggedIn } = useSelector((state) => state.selected);
  const [disabled, setDisabled] = useState(true);

  // FORM STATE VALUES
  const [name, setName] = useState(key.name);
  const [type, setType] = useState(key.type);
  const [details, setDetails] = useState(key.details);
  const TimeLimit = key.timeLimit.split(':');
  const [timeLimit, setTimeLimit] = useState({ hr: TimeLimit[0], min: TimeLimit[1] });

  // ON UPDATE KEY
  const onUpdateKey = () => {
    let updatedKey = {
      id: key._id,
      customer: key.customer,
      client: key.client,
      site: key.site,
      name,
      type,
      details,
      timeLimit: `${timeLimit.hr}:${timeLimit.min}`,
      checkedOut: key.checkedOut,
      checkedOutTo: key.checkedOutTo,
      lastChecked: key.lastChecked,
      checkedBy: key.checkedBy,
      createdBy: key.createdBy,
      createdOn: key.createdOn,
      modifiedBy: loggedIn._id,
      modifiedOn: new Date().toISOString(),
    };
    dispatch(
      updateKey({
        ...key,
        name,
        type,
        details,
        timeLimit: `${timeLimit.hr}:${timeLimit.min}`,
        modifiedBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
      })
    );
    dispatch(apiUpdateKey(updatedKey));
    window.alert(`Key: ${name} has been updated.`);
    navigate('/manage/clients/sites/keys');
  };

  // ON DELETE KEY
  const onDeleteKey = () => {
    const res = window.confirm(`*** DELETE WARNING ***\n\nAre you sure you want to delete " ${key?.name} " ?\n`);

    if (res === true && key?._id) dispatch(apiDeleteKey(key?._id));
    navigate(-1);
  };

  return (
    <div className="d-flex flex-fill flex-column">
      <div className="card d-flex flex-column flex-fill bg-black text-light">
        {/* TITLE BAR */}
        <EditTitleBar
          title={key.name}
          disabled={disabled}
          setDisabled={setDisabled}
          onUpdate={onUpdateKey}
          backLink={-1}
        />

        {/* BODY */}
        <div className="d-flex flex-fill">
          {/* LEFT COL */}
          <div className="col-3"></div>

          {/* CENTER COL */}
          <div className="col my-auto">
            {/* NAME */}
            <FormInput label="Name" placeholder="Enter Key Name" value={name} setValue={setName} disabled={disabled} />

            {/* TYPE */}
            <FormInput
              label="Section"
              placeholder="Enter Key Section"
              value={type}
              setValue={setType}
              disabled={disabled}
            />

            {/* DETAILS */}
            <FormInput
              label="Details"
              placeholder="Enter Description of Key"
              value={details}
              setValue={setDetails}
              disabled={disabled}
            />

            {/* TIME LIMIT */}
            <h4 className="fw-bold text-center">Time Limit</h4>
            <div className="d-flex mb-3">
              {/* HR */}
              <FormInput
                label="Hour"
                placeholder="00"
                className="me-3"
                value={timeLimit.hr}
                disabled={disabled}
                setValue={(x) =>
                  x.target.value >= 0 && x.target.value < 13 ? setTimeLimit({ ...timeLimit, hr: x.target.value }) : ''
                }
              />

              {/* MIN */}
              <FormInput
                label="Minute"
                placeholder="00"
                className=""
                value={timeLimit.min}
                disabled={disabled}
                setValue={(x) =>
                  x.target.value >= 0 && x.target.value < 60 ? setTimeLimit({ ...timeLimit, min: x.target.value }) : ''
                }
              />
            </div>

            {/* DELETE BTN */}
            <div className="d-flex centered mt-5">
              <button className="btn btn-lg btn-outline-danger border-0" onClick={() => onDeleteKey()}>
                DELETE
              </button>
            </div>
          </div>

          {/* RIGHT COL */}
          <div className="col-3"></div>
        </div>
      </div>
    </div>
  );
}
