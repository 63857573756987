import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addLog, apiAddLog } from '../../../../../reduxPie/logsSlice'
import { apiUpdateUser, updateUser } from '../../../../../reduxPie/usersSlice'

export function ProtectorRow({ protector, keyNum, submittedOn = '', selectedSite, onSubmit }) {
  const dispatch = useDispatch()
  const rowDefault = 'd-flex'
  const rowSafe = 'd-flex table-primary'
  const rowCaution = 'd-flex table-warning'
  const rowDanger = 'd-flex table-danger'
  const { loggedIn, client, site } = useSelector((state) => state.selected)
  const [rowStyle, setRowStyle] = useState(rowDefault)
  const [currentStatus, setCurrentStatus] = useState(protector.status)
  const [notes, setNotes] = useState('')
  const statusList = ['ONSITE', 'OFFSITE', 'ON-COVERAGE', 'ICC', 'OCC']

  useEffect(() => {
    if (currentStatus === 'ONSITE' || currentStatus === 'ICC') setRowStyle(rowSafe)
    else if (currentStatus === 'ON-COVERAGE') setRowStyle(rowCaution)
    else if (currentStatus === 'OFFSITE' || currentStatus === 'OCC') setRowStyle(rowDanger)
    else setRowStyle(rowDefault)
  }, [currentStatus])

  const onStatusChange = (newStatus) => {
    // LOG
    const newLog = {
      customer: loggedIn.customer,
      client: client ? client._id : '',
      site: site ? site?._id : '',
      user: protector._id,
      data: `@${site?.name}: ${protector.name} - ${newStatus}${notes ? ' - ' + notes : ''}`,
      submittedOn,
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
    }

    // UPDATED PROTECTOR ( USER )
    const updatedUser = {
      id: protector._id,
      customer: protector.customer,
      region: protector.region,
      branch: protector.branch,
      passHash: protector.passHash,
      salt: protector.salt,
      name: protector.name,
      email: protector.email,
      level: protector.level,
      scanOption: protector.scanOption,
      onDuty: protector.onDuty,
      phone: protector.phone,
      status: newStatus,
      active: protector.active,
      imgLink: protector.imgLink,
      currentClient: protector.currentClient,
      currentSite: protector.currentSite,
      createdOn: protector.createdOn,
      createdBy: protector.createdBy,
      assignedSites: [selectedSite._id],
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    }

    let assignedSite = [selectedSite._id]

    if (!submittedOn) {
      window.alert('*** STATUS CHANGE ERROR ***\n\nTime of Status Change is Invalid')
    } else {
      setCurrentStatus(newStatus)
      onSubmit(new Date().toLocaleString())
      setNotes('')
      dispatch(
        updateUser({
          ...protector,
          status: newStatus,
          assignedSites: assignedSite,
          modifiedOn: new Date().toISOString(),
          modifiedBy: loggedIn._id,
        })
      )
      dispatch(apiUpdateUser({ updatedUser, formData: null }))
      dispatch(addLog(newLog))
      dispatch(apiAddLog(newLog))
    }
  }

  return (
    <tr key={keyNum} className={protector.assignedSites.includes(selectedSite._id) ? rowStyle : rowDanger}>
      {/* PROTECTOR NAME */}
      <td className='col-4 d-flex align-items-center'>{protector.name}</td>

      {/* PROTECTOR NOTES */}
      <td className='col'>
        <input
          type='text'
          className='form-control form-control-sm'
          value={notes}
          placeholder='Enter Notes'
          onChange={(x) => setNotes(x.target.value)}
        />
      </td>

      {/* PROTECTOR STATUS */}
      <td className='col-3'>
        <select
          className='form-select form-select-sm'
          onChange={(x) => onStatusChange(x.target.value)}
          value={protector.assignedSites.includes(selectedSite._id) ? currentStatus : statusList[1]}
        >
          {statusList.map((item, key) => (
            <option key={key}>{item}</option>
          ))}
        </select>
      </td>
    </tr>
  )
}
