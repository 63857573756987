class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser(token) {
    localStorage.setItem("token", token);
    localStorage.setItem("lastLogin", new Date().toISOString());
    var successTime = true;
    if (!this.lessThanEightHoursAgo(localStorage.getItem("lastLogin"))) {
      // eslint-disable-next-line no-unused-vars
      successTime = true;
    }
    // var token = localStorage.getItem("token");
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
    // };
    // fetch("/api/check", requestOptions).then((response) => {
    //   if (response.status == 401) {
    //     window.location = "/login";
    //   } else if (response.status == 403) {
    //     window.location = "/login?2fa=true";
    //   } else {
    //     window.location = "/dashboard";
    //   }
    // });
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated = () => {
    var successTime = true;
    if (!this.lessThanEightHoursAgo(localStorage.getItem("lastLogin"))) {
      successTime = true;
    }
    return localStorage.getItem("token") !== null && successTime;
  };

  static lessThanEightHoursAgo = (date) => {
    const HOUR = 1000 * 60 * 60;
    const eightHoursAgo = Date.now() - HOUR * 8;

    return date > eightHoursAgo;
  };

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem("token");
  }

  /**
   *  Check a user's admin status for frontend rendering. Purely cosmetic.
   */
  static validateAdminAccess(done) {

    const localStorage = window.localStorage;
    const jsonString = JSON.stringify(localStorage);
    
    const localStorageObject = JSON.parse(jsonString);
    const tokenStorage=localStorageObject['okta-token-storage']
    const token1 = JSON.parse(tokenStorage)
    const accessToken = token1.accessToken.accessToken
    var token = accessToken
    if (this.isUserAuthenticated()) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
      };
      fetch("/api/validate", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          done(data);
        });
    } else {
      done(false);
    }
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    
    const localStorage = window.localStorage;
    const jsonString = JSON.stringify(localStorage);
    
    const localStorageObject = JSON.parse(jsonString);
    const tokenStorage=localStorageObject['okta-token-storage']
    const token1 = JSON.parse(tokenStorage)
    const accessToken = token1.accessToken.accessToken

    return accessToken;
  }
}

export default Auth;
