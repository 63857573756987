import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddMultipleInputs } from "../../../components/AddMultipleInputs";
import { EditTitleBar } from "../../../components/EditTitleBar";
import { FormInput } from "../../../components/FormInput";
import { s3Link } from "../../../reduxPie/apiConfig";
import { apiUpdatePerson, updatePerson } from "../../../reduxPie/personsSlice";

export function EditPursuer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, pursuer } = useSelector((state) => state.selected);
  const [disabled, setDisabled] = useState(true);

  // STATES FOR ALL INPUTS
  const [mark, setMark] = useState("");
  const [marks, setMarks] = useState(pursuer.marks);
  const [alias, setAlias] = useState("");
  const [aliases, setAliases] = useState(pursuer.aliases);
  const [name, setName] = useState(pursuer.name);
  const [sex, setSex] = useState(pursuer.sex);
  const [height, setHeight] = useState(pursuer.height);
  const [weight, setWeight] = useState(pursuer.weight);
  const [eyeColor, setEyeColor] = useState(pursuer.eyeColor);
  const [hairColor, setHairColor] = useState(pursuer.hairColor);
  const [race, setRace] = useState(pursuer.race);
  const [DOB, setDOB] = useState(pursuer.DOB);
  const [streetAddress, setStreetAddress] = useState(pursuer.fullAddress.streetAddress);
  const [city, setCity] = useState(pursuer.fullAddress.city);
  const [state, setState] = useState(pursuer.fullAddress.state);
  const [zip, setZip] = useState(pursuer.fullAddress.zip);
  const [driverLicense, setDriverLicense] = useState(pursuer.driverLicense);
  const [img, setImg] = useState("");
  const [imgFile, setImgFile] = useState("");

  // ON EDIT PURSUER
  const onEditPursuer = () => {
    if (name && driverLicense) {
      let updatedPerson = {
        id: pursuer._id,
        name,
        driverLicense,
        checkedIn: false,
        recentSite: null,
        fullAddress: {
          streetAddress,
          city,
          state,
          zip,
        },
        sex,
        height,
        weight,
        eyeColor,
        hairColor,
        race,
        DOB,
        aliases,
        marks,
        createdOn: pursuer.createdOn,
        createdBy: pursuer.createdBy,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };
      if (img && name) {
        var extention = imgFile.name.split(".")[1].toLowerCase();
        let formData = new FormData();
        formData.append("collection", "Pursuers");
        formData.append("id", pursuer._id);
        formData.append("type", "images");
        formData.append("file", imgFile, "user." + extention);
        dispatch(
          updatePerson({
            ...pursuer,
            name,
            driverLicense,
            checkedIn: false,
            recentSite: null,
            fullAddress: {
              streetAddress,
              city,
              state,
              zip,
            },
            sex,
            height,
            weight,
            eyeColor,
            hairColor,
            race,
            DOB,
            aliases,
            marks,
            modifiedOn: new Date().toISOString(),
            modifiedBy: loggedIn._id,
          })
        );
        dispatch(apiUpdatePerson({ updatedPerson, formData }));
        window.alert(`Pursuer ${name} Has Been Updated`);
        navigate("/pursuers");
      } else if (name) {
        dispatch(
          updatePerson({
            ...pursuer,
            name,
            driverLicense,
            checkedIn: false,
            recentSite: null,
            fullAddress: {
              streetAddress,
              city,
              state,
              zip,
            },
            sex,
            height,
            weight,
            eyeColor,
            hairColor,
            race,
            DOB,
            aliases,
            marks,
            modifiedOn: new Date().toISOString(),
            modifiedBy: loggedIn._id,
          })
        );
        dispatch(apiUpdatePerson({ updatedPerson, formData: null }));
        window.alert(`Pursuer ${name} Has Been Added`);
        navigate("/pursuers");
      }
    } else {
      window.alert(`Please Fill Out "Edit Pursuer" Form`);
    }
  };

  // ON IMG UPLOAD
  const onImgUpload = (e) => {
    const file = e.target.files[0] ? e.target.files[0] : "";
    setImgFile(file ? file : "");
    setImg(file ? URL.createObjectURL(file) : "");
  };
  return (
    <div className='d-flex flex-fill py-1'>
      <div className='card d-flex flex-fill border border-danger border-5 bg-black text-light shadow-lg '>
        {/* TITLE BAR */}
        <EditTitleBar
          backLink={-1}
          onUpdate={onEditPursuer}
          title={pursuer.name}
          disabled={disabled}
          setDisabled={setDisabled}
        />

        {/* VISITOR FORM */}
        <div className='card-body overflow-auto' style={{ height: window.innerHeight * 0.6 }}>
          <div className='d-flex centered'>
            {/* LEFT COL */}
            <div className='col me-3'>
              {/* PICTURE */}
              <div className='d-flex flex-column centered mb-3'>
                <div
                  className='d-flex flex-column mb-3 overflow-auto border border-danger rounded p-3'
                  style={{ height: window.innerHeight * 0.425 }}>
                  {img ? (
                    <img
                      className='img-thumbnail bg-danger border-0'
                      src={img}
                      alt='...'
                      style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                    />
                  ) : (
                    <>
                      <img
                        src={`https://${s3Link}/Pursuers/${pursuer._id}/images//user.jpg`}
                        title='.jpg'
                        alt=''
                        className='img-thumbnail bg-black border-0'
                        style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                      />
                      <img
                        src={`https://${s3Link}/Pursuers/${pursuer._id}/images//user.jpeg`}
                        title='.jpeg'
                        alt=''
                        className='img-thumbnail bg-black border-0'
                        style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                      />
                      <img
                        src={`https://${s3Link}/Pursuers/${pursuer._id}/images//user.png`}
                        title='.png'
                        alt=''
                        className='img-thumbnail bg-black border-0'
                        style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                      />
                    </>
                  )}
                </div>

                {/* IMG UPLOAD */}
                <div className='d-flex flex-column w-100'>
                  <label className='fw-bold lead'>Profile Picture</label>
                  <input
                    className='form-control form-control-sm'
                    type='file'
                    accept='.jpg,.png,.jpeg'
                    onChange={onImgUpload}
                    disabled={pursuer._id ? disabled : true}
                  />
                </div>
                <small className='text-white mb-3'>Formats: .jpg .jpeg .png</small>
              </div>
            </div>

            {/* RIGHT COL */}
            <div className='col'>
              {/* KEY INFO */}
              <h1 className='fw-bold fs-3 text-center mb-2'>Key Info</h1>
              <div className='d-flex flex-column'>
                <div className='d-flex mb-3'>
                  {/* NAME */}
                  <FormInput className='me-3' label='Name *' value={name} setValue={setName} disabled={disabled} />

                  {/* DOB */}
                  <FormInput
                    className='me-3'
                    label='DOB'
                    type='date'
                    value={DOB}
                    setValue={setDOB}
                    disabled={disabled}
                  />

                  {/* DRIVERS LICENSE */}
                  <FormInput
                    label='Driver License *'
                    value={driverLicense}
                    setValue={setDriverLicense}
                    disabled={disabled}
                  />
                </div>
              </div>
              {/* BASIC INFO */}
              <h1 className='fw-bold fs-3 text-center mb-2'>Basic Info</h1>
              <div className='d-flex mb-3'>
                {/* HEIGHT */}
                <FormInput className='me-3' label='Height' value={height} setValue={setHeight} disabled={disabled} />

                {/* WEIGHT */}
                <FormInput className='me-3' label='Weight' value={weight} setValue={setWeight} disabled={disabled} />

                {/* SEX */}
                <FormInput className='' label='Sex' value={sex} setValue={setSex} disabled={disabled} />
              </div>
              <div className='d-flex mb-3'>
                {/* HAIR COLOR */}
                <FormInput
                  className='me-3'
                  label='Hair Color'
                  value={hairColor}
                  setValue={setHairColor}
                  disabled={disabled}
                />

                {/* EYE COLOR */}
                <FormInput
                  className='me-3'
                  label='Eye Color'
                  value={eyeColor}
                  setValue={setEyeColor}
                  disabled={disabled}
                />

                {/* RACE */}
                <FormInput className='' label='Race' value={race} setValue={setRace} disabled={disabled} />
              </div>

              {/* HOME ADDRESS */}
              <h1 className='fw-bold fs-3 text-center mb-2'>Home Address</h1>
              <div className='d-flex mb-3'>
                {/* STREET ADDRESS */}
                <FormInput
                  className='me-3'
                  label='Street Address'
                  value={streetAddress}
                  setValue={setStreetAddress}
                  disabled={disabled}
                />

                {/* CITY */}
                <FormInput className='' label='City' value={city} setValue={setCity} disabled={disabled} />
              </div>

              <div className='d-flex mb-3'>
                {/* STATE */}
                <FormInput className='me-3' label='State' value={state} setValue={setState} disabled={disabled} />

                {/* ZIP CODE */}
                <FormInput className='' label='Zip' value={zip} setValue={setZip} disabled={disabled} />
              </div>
            </div>
          </div>
          <div className='d-flex'>
            {/* MARKS */}
            <AddMultipleInputs
              title='Marks'
              label='Mark'
              value={mark}
              setValue={setMark}
              details={marks}
              setDetails={setMarks}
              disabled={disabled}
            />
            <div className='col-1' />
            {/* ALIASES */}
            <AddMultipleInputs
              title='Aliases'
              label='Alias'
              value={alias}
              setValue={setAlias}
              details={aliases}
              setDetails={setAliases}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
