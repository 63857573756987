import React from "react";
import { Link } from "react-router-dom";

export function DropdownMenu({ title, menu }) {
  return (
    <div className='dropdown'>
      <button className='btn btn-outline-light border-0 mb-2 dropdown-toggle' data-bs-toggle='dropdown'>
        {title}
      </button>
      <ul className='dropdown-menu dropdown-menu-dark'>
        {menu.map((item, key) => (
          <li key={key} className="my-2">
            <Link to={item.link} className='dropdown-item'>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
