import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddMultipleInputs } from '../../../../../components/AddMultipleInputs';
import { EditTitleBar } from '../../../../../components/EditTitleBar';
import { addLog } from '../../../../../reduxPie/logsSlice';
import { apiDeliveryAuditLog, apiUpdateDelivery, updateDelivery } from '../../../../../reduxPie/deliveriesSlice';
import { Loading } from '../../../../../components/Loading';
import { FormInput } from '../../../../../components/FormInput';

export function EditDelivery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ERROR WITH { delivery }, reserved word
  const { loggedIn, delivery, client, site } = useSelector((state) => state.selected);
  const deliveriesSlice = useSelector((state) => state.deliveries);
  const categories = ['Update', 'Chain of Custody', 'Other'];
  const couriers = ['UPS', 'FedEx', 'USPS', 'Amazon', 'DHL', 'Other'];
  const [selectedCourier, setSelectedCourier] = useState(couriers[0]);
  const [logs, setLogs] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [audit, setAudit] = useState(false);

  //   FORM STATE VALUES
  const [principalRecieved, setPrincipalRecieved] = useState(delivery.principalRecieved);
  const [deliveredOn, setDeliveredOn] = useState(delivery.deliveredOn);
  const [bound, setBound] = useState(delivery.bound);
  const [to, setTo] = useState(delivery.to);
  const [from, setFrom] = useState(delivery.from);
  const [qty, setQty] = useState(delivery.qty);
  const [courier, setCourier] = useState(delivery.courier);
  const [category, setCategory] = useState('Update');
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState(delivery.notes);
  const [trackingId, setTrackingId] = useState('');
  const [trackingIds, setTrackingIds] = useState(delivery.trackingIds);

  // ON UPDATE DELIVERY
  const onUpdateDelivery = () => {
    if (to && from && qty) {
      // LOG
      const newLog = {
        customer: loggedIn.customer,
        client: client._id,
        site: site._id,
        delivery: delivery?._id,
        data: `@${site?.name}: ${selectedCourier === 'Other' ? courier : selectedCourier} Delivery - ${
          notes.length ? '( Last Note ) ' + notes[notes.length - 1].data : 'Updated'
        }${deliveredOn !== delivery.deliveredOn && deliveredOn ? ' - Delivered' : ''}${
          principalRecieved ? ' - Principal Recieved' : ''
        }`,
        submittedOn: new Date().toLocaleString(),
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
      };

      // UPDATED DELIVERIES
      const updatedDelivery = {
        id: delivery?._id,
        client: delivery.client,
        site: delivery.site,
        bound,
        to,
        from,
        qty: parseInt(qty),
        courier: selectedCourier === 'Other' ? courier : selectedCourier,
        deliveredOn,
        principalRecieved,
        notes,
        trackingIds,
        createdOn: delivery.createdOn,
        createdBy: delivery.createdBy,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };

      dispatch(
        updateDelivery({
          ...delivery,
          bound,
          to,
          from,
          qty: parseInt(qty),
          courier: selectedCourier === 'Other' ? courier : selectedCourier,
          deliveredOn,
          principalRecieved,
          notes,
          trackingIds,
          modifiedOn: new Date().toISOString(),
          modifiedBy: loggedIn._id,
        })
      );
      dispatch(apiUpdateDelivery({ newLog, updatedDelivery }));
      dispatch(addLog(newLog));
      window.alert(
        `${selectedCourier === 'Other' ? courier : selectedCourier} Delivery - ${
          notes.length ? '( Last Note )' + notes[notes.length - 1].data : 'Updated'
        }${principalRecieved ? ' - Principal Recieved' : ''}`
      );
      navigate('/manage/clients/sites/deliveries');
    } else {
      window.alert('Not Enough Infomation');
    }
  };

  // AUTO FETCH CLIENT LOGS
  useEffect(() => {
    dispatch(apiDeliveryAuditLog(delivery._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SEARCH
  useEffect(() => {
    setLogs(deliveriesSlice.logs);
  }, [deliveriesSlice.logs]);

  if (deliveriesSlice.isLoading) return <Loading title="Logs" />;
  return (
    <div className="card d-flex flex-fill flex-column bg-black text-light">
      {/* TITLE BAR */}
      <EditTitleBar
        title="Delivery"
        backLink={-1}
        onUpdate={onUpdateDelivery}
        disabled={disabled}
        setDisabled={setDisabled}
      />

      {/* AUDIT TOGGLE */}
      <div className="d-flex justify-content-center mb-3">
        <button className="btn btn-sm btn-primary w-50" onClick={() => setAudit(!audit)}>
          {audit ? 'Back to Details' : 'Audit Log'}
        </button>
      </div>

      {/* CARD BODY */}
      {audit ? (
        <div className="d-flex flex-fill ">
          <div className="col">
            {/* NOTES */}
            <AddMultipleInputs
              label="Note"
              title="Notes"
              height={window.innerHeight * 0.38}
              disabled={disabled}
              categories={categories}
              category={category}
              setCategory={setCategory}
              value={note}
              setValue={setNote}
              details={notes}
              setDetails={setNotes}
            />
          </div>
          <div className="col">
            <div className="d-flex flex-column">
              <h1 className="display-6 text-center">Logs</h1>
              <div className="px-3 overflow-auto" style={{ height: window.innerHeight * 0.425 }}>
                {logs ? (
                  logs.map((item, key) => (
                    <div key={key} className="d-flex flex-column">
                      <span className="fw-bold">{item.submittedOn}</span>
                      <span className="mb-3">{item.data}</span>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex h-100 px-3">
          {/* LEFT COL */}
          <div className="col">
            <div className="d-flex mb-2">
              {/* PRINCIPAL RECEIEVED */}
              <div className="col-4">
                <div className="input-group input-group-sm">
                  <span className="input-group-text">Principal Recieved</span>
                  <span className="input-group-text">
                    <input
                      type="checkbox"
                      className="form-check-input my-auto"
                      checked={principalRecieved}
                      onChange={() => setPrincipalRecieved(!principalRecieved)}
                    />
                  </span>
                </div>
              </div>

              {/* DELIVERED ON */}
              <div className="col">
                <div className="input-group input-group-sm">
                  <span className="input-group-text">Delivered</span>
                  <span className="input-group-text">
                    <input
                      type="checkbox"
                      className="form-check-input my-auto"
                      checked={deliveredOn ? true : false}
                      onChange={() => setDeliveredOn(deliveredOn ? '' : new Date().toLocaleString())}
                    />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    value={deliveredOn}
                    onChange={(x) => setDeliveredOn(x.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex mb-3">
              {/* BOUND */}
              <div className="w-100 me-3">
                <div className="">
                  <label className="fw-bold lead">Bound</label>
                  <select
                    type="checkbox"
                    className="form-select form-select-sm"
                    value={bound}
                    onChange={(x) => setBound(x.target.value)}>
                    <option value={true}>In</option>
                    <option value={false}>Out</option>
                  </select>
                </div>
              </div>

              {/* QTY */}
              <FormInput type="number" label="Qty *" placeholder="0" className="" value={qty} setValue={setQty} />
            </div>

            {/* NOTES */}
            <AddMultipleInputs
              label="Note"
              title="Notes"
              categories={categories}
              category={category}
              setCategory={setCategory}
              value={note}
              setValue={setNote}
              details={notes}
              setDetails={setNotes}
            />
          </div>

          {/* MID COL ( SPACER ) */}
          <div className="col-1" />

          {/* RIGHT COL */}
          <div className="col">
            <div className="d-flex mb-3">
              {/* COURIER */}
              <div className="w-100 me-3">
                <FormInput
                  type="select"
                  label="Courier"
                  className=""
                  value={selectedCourier}
                  defaultLabel="Select Courier"
                  setValue={setSelectedCourier}
                  options={couriers.map((x) => ({ label: x, value: x }))}
                />
                {selectedCourier === 'Other' && (
                  <input
                    type="text"
                    placeholder="Enter Courier"
                    className="form-control form-control-sm w-100"
                    value={courier}
                    onChange={(x) => setCourier(x.target.value)}
                  />
                )}
              </div>

              {/* TO */}
              <FormInput label="To *" className="me-3" value={to} setValue={setTo} />

              {/* FROM */}
              <FormInput label="From *" className="" value={from} setValue={setFrom} />
            </div>

            {/* TRACKING IDS */}
            <AddMultipleInputs
              label="Tracking ID"
              title="Tracking IDs"
              value={trackingId}
              setValue={setTrackingId}
              details={trackingIds}
              setDetails={setTrackingIds}
            />
          </div>
        </div>
      )}
    </div>
  );
}
