import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LOGO from '../../media/whiteBgLogo.png';
import { apiFindAllSites, apiFindAssignedSites } from '../../reduxPie/sitesSlice';
import { apiFindAllForms } from '../../reduxPie/formsSlice';
import { apiFindAllReports } from '../../reduxPie/reportsSlice';
import { apiFindAllUsers } from '../../reduxPie/usersSlice';
import { apiFindAllEvents } from '../../reduxPie/eventsSlice';
import { apiFindAllClients } from '../../reduxPie/clientsSlice';
import { apiFindAllByType } from '../../reduxPie/personsSlice';

export function Home() {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.selected);
  const eventsSlice = useSelector((state) => state.events);
  const formsSlice = useSelector((state) => state.forms);
  const clientsSlice = useSelector((state) => state.clients);
  const sitesSlice = useSelector((state) => state.sites);
  const usersSlice = useSelector((state) => state.users);
  const reportsSlice = useSelector((state) => state.reports);
  const personsSlice = useSelector((state) => state.persons);

  // TESTING GIT PULL

  // AUTO FETCH MAIN DATA
  useEffect(() => {
    const personTypes = personsSlice?.data?.map((x) => x.type);
    if (!eventsSlice?.data) dispatch(apiFindAllEvents());
    if (!formsSlice?.data) dispatch(apiFindAllForms());
    if (!reportsSlice?.data) dispatch(apiFindAllReports());
    if (!usersSlice?.data) dispatch(apiFindAllUsers());
    if (!clientsSlice?.data) dispatch(apiFindAllClients());
    if (!personTypes?.includes('Pursuer')) dispatch(apiFindAllByType('Pursuer'));
    if (!sitesSlice?.data?.length)
      loggedIn?.assignedClients?.length
        ? dispatch(apiFindAssignedSites(loggedIn.assignedClients))
        : dispatch(apiFindAllSites());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-fill bg-white centered">
      <img className="rounded-3" src={LOGO} alt="..." />
    </div>
  );
}
