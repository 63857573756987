import React from "react";

import LOGO from "../../media/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { clearSelected } from "../../reduxPie/selectedSlice";
import { clearSites } from "../../reduxPie/sitesSlice";
import { clearBranches } from "../../reduxPie/branchesSlice";
import { clearCheckpoints } from "../../reduxPie/checkpointsSlice";
import { clearClients } from "../../reduxPie/clientsSlice";
import { clearCustomers } from "../../reduxPie/customerSlice";
import { clearEmailGroups } from "../../reduxPie/emailGroupsSlice";
import { clearEmailSchedules } from "../../reduxPie/emailSchedulesSlice";
import { clearEvents } from "../../reduxPie/eventsSlice";
import { clearForms } from "../../reduxPie/formsSlice";
import { clearKeys } from "../../reduxPie/keysSlice";
import { clearDeliveries } from "../../reduxPie/deliveriesSlice";
import { clearPersons } from "../../reduxPie/personsSlice";
import { clearRegions } from "../../reduxPie/regionsSlice";
import { clearReports } from "../../reduxPie/reportsSlice";
import { clearTests } from "../../reduxPie/testsSlice";
import { clearTours } from "../../reduxPie/toursSlice";
import { clearVehicles } from "../../reduxPie/vehiclesSlice";
import { clearUsers } from "../../reduxPie/usersSlice";
import { clearLogs } from "../../reduxPie/logsSlice";
import { clearInventory } from "../../reduxPie/inventorySlice";
import { clearManuals } from "../../reduxPie/manualsSlice";
import Logout from "../../tabs/Logout";

export function Header() {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.selected);
  // HEADER HEIGHT
  const height = window.innerHeight * 0.15;

  const onLogout = () => {
    dispatch(clearBranches());
    dispatch(clearCheckpoints());
    dispatch(clearClients());
    dispatch(clearUsers());
    dispatch(clearCustomers());
    dispatch(clearEmailGroups());
    dispatch(clearEmailSchedules());
    dispatch(clearEvents());
    dispatch(clearForms());
    dispatch(clearKeys());
    dispatch(clearDeliveries());
    dispatch(clearPersons());
    dispatch(clearRegions());
    dispatch(clearReports());
    dispatch(clearSites());
    dispatch(clearTests());
    dispatch(clearTours());
    dispatch(clearVehicles());
    dispatch(clearLogs());
    dispatch(clearSites());
    dispatch(clearInventory());
    dispatch(clearSelected());
    dispatch(clearManuals());
    localStorage.removeItem("token");
  };

  return (
    <div className='d-flex row bg-black fixed-top' style={{ height }}>
      {/* LOGO */}
      <div className='col-2'>
        <img src={LOGO} alt='...' height={height} />
      </div>

      <div className='d-flex col justify-content-end'>
        {/* USER NAME */}
        <div className='d-flex flex-fill centered'>
          <h1 className='display-6 text-light user-select-none'>{loggedIn ? loggedIn.name : ""}</h1>
        </div>
        <div className='d-flex flex-column justify-content-end h-100 me-4'>
          {/* LOGOUT BUTTON  */}
          <div className='d-flex flex-fill justify-content-end align-items-start mt-2'>
            <Logout/>
          </div>
        </div>
      </div>
    </div>
  );
}
