import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import { TableTitleBar } from "../../../../components/TableTitleBar";
import { apiFindAllEvents } from "../../../../reduxPie/eventsSlice";
import { apiFindAllForms } from "../../../../reduxPie/formsSlice";
import { setForm } from "../../../../reduxPie/selectedSlice";

export function SiteForms() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventsSlice = useSelector((state) => state.events);
  const { client, site } = useSelector((state) => state.selected);
  const { isLoading, lastRefresh, data } = useSelector((state) => state.forms);
  const [forms, setForms] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "name", flex: 1, filter: true, sortable: true },
    { field: "eventName", flex: 1, filter: true, sortable: true },
    { field: "eventType", flex: 1, filter: true, sortable: true },
    { field: "createdOn", flex: 1, filter: true, valueFormatter: ({ value }) => new Date(value).toDateString() },
  ]);

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setForm(item.data));
    navigate("details");
  };

  // ON REFRESH FORMS
  const onRefreshForms = () => {
    dispatch(apiFindAllForms());
  };

  // AUTO FETCH FORMS & EVENTS
  useEffect(() => {
    if (!data.length) onRefreshForms();
    if (!eventsSlice.data.length) dispatch(apiFindAllEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SEARCH AND FILTER FORMS
  useEffect(() => {
    const filteredForms = data.filter((item) => {
      // Check if item.site and item.client meet the conditions
      if (
        (item.site === site._id || (!item.site && typeof item.site === 'undefined')) &&
        (item.client === client._id || (!item.client && typeof item.client === 'undefined'))
      ) {
        let event = eventsSlice.data.find((x) => x._id === item.event);
        let { name, type } = event ? event : { name: "N/A", type: "N/A" };
       return { ...item, eventName: name, eventType: type }; // Include this item in the filteredForms
      } else {
        return false; // Exclude this item from the filteredForms
      }
    });
  
    setForms(filteredForms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, site._id, client._id, eventsSlice.data]);

  // RENDER
  if (isLoading) return <Loading title='Forms' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Forms'
        addLabel='Form'
        onRefresh={onRefreshForms}
        lastRefresh={lastRefresh}
        hideSearch={true}
      />
      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact rowData={forms} columnDefs={columnDefs} onRowClicked={onViewDetails} />
      </div>
    </div>
  );
}
