import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditTitleBar } from '../../../../components/EditTitleBar';
import { apiUpdateUser, updateUser } from '../../../../reduxPie/usersSlice';
import { apiFindAllClients } from '../../../../reduxPie/clientsSlice';
import { Loading } from '../../../../components/Loading';
import { setLoggedIn } from '../../../../reduxPie/selectedSlice';
import { FormInput } from '../../../../components/FormInput';
import { s3Link } from '../../../../reduxPie/apiConfig';

export function EditUser() {
  const height = window.innerHeight * 0.545;
  const tableAll = useRef();
  const tableAssigned = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formDefault = 'form-control form-control-sm ';
  const formValid = formDefault + 'is-valid ';
  const formInvalid = formDefault + 'is-invalid ';
  const { loggedIn, user } = useSelector((state) => state.selected);
  const clientsSlice = useSelector((state) => state.clients);
  const [disabled, setDisabled] = useState(true);
  const [showClients, setShowClients] = useState(false);
  const [assignedList, setAssignedList] = useState([]);

  // FORM VALIDATION STATE
  const [formName, setFormName] = useState(formDefault);
  const [formEmail, setFormEmail] = useState(formDefault);

  // FORM STATE VALUES
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [status, setStatus] = useState(user.status);
  const [level, setLevel] = useState(user.level);
  const [scanOption, setScanOption] = useState(user.scanOption);
  const [active, setActive] = useState(user.active);
  const [img, setImg] = useState('');
  const [imgFile, setImgFile] = useState('');
  const [assignedClients, setAssignedClients] = useState(user.assignedClients);

  // COLUMN DEFS
  const [columnDefs] = useState([{ field: 'name', flex: 1, filter: true, sortable: true, checkboxSelection: true }]);

  // ON UPDATE USER
  const onUpdateUser = () => {
    let updatedUser = {
      id: user._id,
      customer: user.customer,
      region: user.region,
      branch: user.branch,
      passHash: user.passHash,
      salt: user.salt,
      name,
      email,
      level,
      scanOption,
      onDuty: user.onDuty,
      phone,
      status: user.status,
      active,
      currentClient: user.currentClient,
      assignedClients,
      currentSite: user.currentSite,
      createdOn: user.createdOn,
      createdBy: user.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };

    // UPDATE WITH IMG
    if (img && formName === formValid && formEmail === formValid) {
      var extention = imgFile.name.split('.')[1].toLowerCase();
      let formData = new FormData();
      formData.append('collection', 'Users');
      formData.append('id', user._id);
      formData.append('type', 'images');
      formData.append('file', imgFile, 'user.' + extention);
      dispatch(
        updateUser({
          ...user,
          name,
          email,
          level,
          scanOption,
          phone,
          active,
          assignedClients,
          modifiedOn: new Date().toISOString(),
          modifiedBy: loggedIn._id,
        })
      );

      // IF LOGGED IN USER
      if (loggedIn._id === user._id)
        dispatch(
          setLoggedIn({
            ...loggedIn,
            name,
            email,
            level,
            scanOption,
            phone,
            active,
            assignedClients,
            modifiedOn: new Date().toISOString(),
            modifiedBy: loggedIn._id,
          })
        );

      dispatch(apiUpdateUser({ updatedUser, formData }));
      window.alert(`${level === '4' ? 'Protector' : 'Manager'}${' ' + name} Has Been Updated`);
      navigate('/manage/users');

      // UPDATE WITHOUT IMG
    } else if (formName === formValid && formEmail === formValid) {
      dispatch(
        updateUser({
          ...user,
          name,
          email,
          level,
          scanOption,
          phone,
          active,
          assignedClients,
          modifiedOn: new Date().toISOString(),
          modifiedBy: loggedIn._id,
        })
      );

      // IF LOGGED IN USER
      if (loggedIn._id === user._id)
        dispatch(
          setLoggedIn({
            ...loggedIn,
            name,
            email,
            level,
            scanOption,
            phone,
            active,
            assignedClients,
            modifiedOn: new Date().toISOString(),
            modifiedBy: loggedIn._id,
          })
        );
      dispatch(apiUpdateUser({ updatedUser, formData: null }));
      window.alert(`${level === '4' ? 'Protector' : 'Manager'}${' ' + name} Has Been Updated`);
      navigate('/manage/users');
    } else {
      window.alert('Please Complete New User Info');
    }
  };

  // ON ASSIGN CLIENTS
  const onAssignClients = () => {
    const data = tableAll.current.api.getSelectedRows();
    setAssignedClients([...new Set([...assignedClients, ...data.map((x) => x._id)])]);
  };

  // ON REMOVE CLIENT
  const onRemoveClients = () => {
    const data = tableAssigned.current.api.getSelectedRows();
    const idList = data.map((x) => x._id);
    setAssignedClients(assignedClients.filter((x) => !idList.includes(x)));
  };

  // READ FORM
  const readForm = (e) => {
    const file = e.target.files[0] ? e.target.files[0] : '';
    setImgFile(file ? file : '');
    setImg(file ? URL.createObjectURL(file) : '');
  };

  // AUTO FETCH CLIENTS
  useEffect(() => {
    if (!clientsSlice.data.length) dispatch(apiFindAllClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NAME VALIDATION
  useEffect(() => {
    // Only Alphabet Characters
    if (name.length > 0 && name.length === name.trim().length && name.match('(^[a-zA-Z\\s]*$)')) {
      setFormName(formValid);
    } else if (name.length === 0) {
      setFormName(formDefault);
    } else {
      setFormName(formInvalid);
    }
  }, [name, formValid, formInvalid]);

  // EMAIL VALIDATION
  useEffect(() => {
    // Email Regex from W3Schools
    if (
      email.match('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$') &&
      email.indexOf('@') < email.lastIndexOf('.')
    ) {
      setFormEmail(formValid);
    } else if (email.length === 0) {
      setFormEmail(formDefault);
    } else {
      setFormEmail(formInvalid);
    }
  }, [email, formValid, formInvalid]);

  // ASSIGNED CLIENTS REFRESH
  useEffect(() => {
    const list = assignedClients.map((x) => {
      const client = clientsSlice.data.find((y) => y._id === x);
      return { _id: x, name: client ? client.name : 'N/A' };
    });

    if (disabled) {
      setAssignedClients(user.assignedClients);
      setAssignedList(
        user.assignedClients.map((x) => {
          const client = clientsSlice.data.find((y) => y._id === x);
          return { _id: x, name: client ? client.name : 'N/A' };
        })
      );
    } else setAssignedList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedClients, disabled]);

  // RENDER
  if (clientsSlice.isLoading) return <Loading title="Clients" />;
  return (
    <div className="d-flex flex-fill py-1">
      <div className="card d-flex flex-fill bg-black text-light shadow-lg">
        {/* TITLE BAR */}
        <EditTitleBar
          disabled={disabled}
          title={user.name}
          backLink="/manage/users"
          setDisabled={setDisabled}
          onUpdate={onUpdateUser}
        />

        {/* ADD CLIENTS TOGGLE */}
        <div className="d-flex centered">
          <button
            className={`btn btn-sm btn-${showClients ? 'secondary' : 'primary'} w-50`}
            onClick={() => {
              dispatch(apiFindAllClients());
              setShowClients(!showClients);
            }}>
            {showClients ? 'Back to Details' : 'View Assigned Clients'}
          </button>
        </div>

        {/* CARD BODY */}
        {/* TRUE = CLIENT LIST, FALSE = USER FORM */}
        {showClients ? (
          <div className="card-body overflow-auto">
            <div className="d-flex flex-fill h-100">
              {/* LEFT COL */}
              <div className="col pe-2">
                {/* TITLE BAR */}
                <div className="d-flex align-items-center justify-content-between px-2">
                  {/* TITLE */}
                  <h1 className="lead fw-bold fs-2">All Clients</h1>

                  {/* ASSIGN CLIENT(S) BTN */}
                  <button className="btn btn-sm btn-primary" onClick={() => onAssignClients()} disabled={disabled}>
                    Assign Client(s)
                  </button>
                </div>

                {/* ALL CLIENTS TABLE */}
                <div className="ag-theme-alpine-dark" style={{ height, width: `100%` }}>
                  <AgGridReact
                    ref={tableAll}
                    columnDefs={columnDefs}
                    rowData={clientsSlice.data}
                    rowSelection="multiple"
                  />
                </div>
              </div>
              {/* RIGHT COL */}
              <div className="col ps-2">
                {/* TITLE BAR */}
                <div className="d-flex align-items-center justify-content-between px-2">
                  {/* TITLE */}
                  <h1 className="lead fw-bold fs-2">Assigned Clients</h1>

                  {/* REMOVE CLIENT(S) BTN */}
                  <button className="btn btn-sm btn-warning" onClick={() => onRemoveClients()} disabled={disabled}>
                    Remove Client(s)
                  </button>
                </div>

                {/* ASSIGNED CLIENTS TABLE */}
                <div className="ag-theme-alpine-dark" style={{ height, width: `100%` }}>
                  <AgGridReact
                    ref={tableAssigned}
                    columnDefs={columnDefs}
                    rowData={assignedList}
                    rowSelection="multiple"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card-body d-flex centered">
            <div className="d-flex flex-fill">
              {/* LEFT COL */}
              <div className="col">
                {/* PICTURE */}
                <div className="d-flex flex-column centered">
                  <div
                    className="d-flex flex-column mb-3 overflow-auto border border-light rounded p-3"
                    style={{ height: window.innerHeight * 0.425 }}>
                    {img ? (
                      <img
                        className="img-thumbnail bg-black border-0"
                        src={img}
                        alt="..."
                        style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                      />
                    ) : (
                      <>
                        <img
                          src={`https://${s3Link}/Users/${user._id}/images//user.jpg`}
                          title=".jpg"
                          alt=""
                          className="img-thumbnail bg-black border-0"
                          style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                        />
                        <img
                          src={`https://${s3Link}/Users/${user._id}/images//user.jpeg`}
                          title=".jpeg"
                          alt=""
                          className="img-thumbnail bg-black border-0"
                          style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                        />
                        <img
                          src={`https://${s3Link}/Users/${user._id}/images//user.png`}
                          title=".png"
                          alt=""
                          className="img-thumbnail bg-black border-0"
                          style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                        />
                      </>
                    )}
                  </div>

                  <div className="w-100">
                    <label className="lead fw-bold">Profile Picture</label>
                    <input
                      className="form-control form-control-sm mb-3"
                      type="file"
                      accept=".png,.jpeg,.jpg"
                      onChange={readForm}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>

              {/* CENTER COL */}
              <div className="col px-2">
                <h1 className="lead fs-2 fw-bold text-center">Basic Info</h1>

                <div className="d-flex mb-4">
                  {/* NAME  */}
                  <FormInput
                    disabled={disabled}
                    className="me-3"
                    label="Name *"
                    formClass={formName}
                    value={name}
                    setValue={setName}
                  />

                  {/* PHONE */}
                  <FormInput
                    disabled={disabled}
                    className=""
                    type="phone"
                    label="Phone"
                    value={phone}
                    setValue={setPhone}
                  />
                </div>

                <div className="d-flex mb-4">
                  {/* LEVEL  */}
                  <FormInput
                    disabled={disabled}
                    type="select"
                    label="Level"
                    className="me-3"
                    defaultLabel="Choose One"
                    value={level}
                    setValue={setLevel}
                    options={[
                      { label: 'Manager', value: '1' },
                      { label: 'Protector', value: '4' },
                    ]}
                  />

                  {/* ACTIVE STATUS */}
                  <FormInput
                    disabled={disabled}
                    type="select"
                    label="Active Status"
                    defaultLabel="Choose One"
                    value={active}
                    setValue={setActive}
                    options={[
                      { label: 'Active', value: true },
                      { label: 'Deactivated', value: false },
                    ]}
                  />
                </div>

                <div className="d-flex">
                  {/* SCAN OPTION DROPDOWN */}
                  <FormInput
                    disabled={disabled}
                    className="me-3"
                    type="select"
                    label="Scan Options"
                    defaultLabel="Choose One"
                    value={scanOption}
                    setValue={setScanOption}
                    options={[
                      { label: 'NZR', value: 'NZR' },
                      { label: 'QR', value: 'QR' },
                      { label: 'NFC', value: 'NFC' },
                    ]}
                  />

                  {/* PROTECTOR STATUS */}
                  <FormInput
                    disabled={disabled}
                    type="select"
                    label="Protector Status"
                    defaultLabel="Choose One"
                    value={status}
                    setValue={setStatus}
                    options={[
                      { label: 'OFFSITE', value: 'OFFSITE' },
                      { label: 'ONSITE', value: 'ONSITE' },
                      { label: 'ON-COVERAGE', value: 'ON-COVERAGE' },
                      { label: 'OCC', value: 'OCC' },
                      { label: 'ICC', value: 'ICC' },
                    ]}
                  />
                </div>
              </div>

              {/* RIGHT COL */}
              <div className="col px-2">
                <h1 className="lead fw-bold text-center fs-2">Login Info</h1>

                {/* EMAIL */}
                <FormInput
                  type="email"
                  label="Email"
                  formClass={formEmail}
                  value={email}
                  setValue={setEmail}
                  disabled={disabled}
                />

                <div className="d-flex centered">
                  {/* CHANGE PASSWORD */}
                  {loggedIn?.level === '1' && (
                    <button className="btn btn-sm btn-warning" onClick={() => navigate('password')} disabled={disabled}>
                      Change {user.name}'s Password
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
