import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  data: [],
};

const checkpointsSlice = createSlice({
  name: "checkpointsSlice",
  initialState,
  reducers: {
    addCheckpoint(state, action) {
      const { name, type } = action.payload;
      state.push({ name, type });
    },
    clearCheckpoints(state, action) {
      return initialState;
    },
  },
});

export const { addCheckpoint, clearCheckpoints } = checkpointsSlice.actions;

export default checkpointsSlice.reducer;
