import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { HeaderNav } from "./header-nav/HeaderNav";

export function SiteDashBoard() {
  const btnSelected = "btn btn-sm btn-dark";
  const btnUnselected = "btn btn-sm btn-secondary border-dark";
  const navigate = useNavigate();
  const [tab, setTab] = useState(window.location.pathname.split("/")[4]);
  const { client, site } = useSelector((state) => state.selected);

  // TAB BTN
  const TabBtn = ({ label, link }) => {
    const className = tab === link || link === window.location.pathname.split("/")[4] ? btnSelected : btnUnselected;
    return (
      <Link to={`/manage/clients/sites/${link}`} className={className} onClick={() => setTab(label)}>
        {label.slice(0, 1).toUpperCase() + label.slice(1)}
      </Link>
    );
  };

  useEffect(() => {
    if (!site?.name) navigate("/manage/clients/sites");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  return (
    <div className='d-flex flex-fill flex-column'>
      {/* HEADER */}
      <HeaderNav />

      {/* TITLE BAR */}
      <div className='d-flex'>
        <div className='d-flex flex-fill flex-column'>
          <div className='d-flex align-items-center'>
            <button className='btn btn-dark btn-sm me-3' onClick={() => navigate(-1)}>
              Back
            </button>
            <Link to='/manage/clients/sites' className='btn btn-secondary btn-sm me-3'>
              {client?.name}'s Sites
            </Link>
            <h1 className='display-6 fw-bold me-2'>{client?.name}</h1>
            <h1 className='display-6'>{site?.name}</h1>
            <Link to='/manage/clients/sites/settings' className='btn btn-secondary btn-sm mx-3'>
              Site Settings
            </Link>
          </div>

          {/* TAB BAR */}
          <div className='btn-group shadow-sm' role='group' style={{ width: `85%` }}>
            <TabBtn label='Visitor Check-In' link='visitor-check-in' />
            <TabBtn label='Client Status' link='status' />
            <TabBtn label='Forms' link='forms' />
            <TabBtn label='Site Reports' link='reports' />
            <TabBtn label='Site Logs' link='logs' />
            <TabBtn label='Deliveries' link='deliveries' />
            <TabBtn label='Keys' link='keys' />
            <TabBtn label='Inventory' link='inventory' />
            <TabBtn label='CC Manuals' link='manuals' />

            <button className={btnUnselected} disabled>
              Protector Tests
            </button>
          </div>

          {/* DETAILS */}
          <div className='d-flex'>
            <div className='card-under rounded ps-2'>
              <span className='me-3'>
                <span className='text-secondary fw-bold user-select-none'>Contact Name: </span>
                {client?.contactName}
              </span>
              <span className='me-3'>
                <span className='text-secondary fw-bold user-select-none'>Phone: </span>
                {}
              </span>
              <span className='me-3 user-select-none'>
                <span className='text-secondary fw-bold'>Status: </span>
                {client?.status}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
}
