import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClient, setSite } from '../../reduxPie/selectedSlice';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { apiFindAllClients, apiFindAssignedClients } from '../../reduxPie/clientsSlice';
import { TableTitleBar } from '../../components/TableTitleBar';
import { apiFindAllEvents } from '../../reduxPie/eventsSlice';
import { AgGridReact } from 'ag-grid-react';

export function Clients() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.selected);
  const { isLoading, lastRefresh, data } = useSelector((state) => state.clients);
  const eventsSlice = useSelector((state) => state.events);
  const [clients, setClients] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'name', headerName: 'Client Name', flex: 1, filter: true, sortable: true },
    { field: 'contactName', flex: 1, filter: true, sortable: true },
    { field: 'phone', flex: 1, filter: true, sortable: true },
    { field: 'email', flex: 1, filter: true, sortable: true },
    {
      field: 'status',
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: ({ data }) => (data.active ? data.status : 'Deactivated'),
    },
  ]);

  // ROW CLASS RULES
  const rowClassRules = {
    'bg-danger': '!data.active',
  };

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setClient(item.data));
    navigate('/manage/clients/sites');
  };

  // ON REFRESH CLIENTS
  const onRefreshClients = () => {
    dispatch(setSite({}));
    if (loggedIn.level === '1' && !loggedIn?.assignedClients?.length) dispatch(apiFindAllClients());
    if (loggedIn.level === '4' || loggedIn?.assignedClients?.length)
      dispatch(apiFindAssignedClients({ clients: loggedIn?.assignedClients }));
  };

  // AUTO FETCH CLIENTS
  useEffect(() => {
    if (!data.length || loggedIn?.assignedClients?.length !== [...new Set(data?.map((x) => x._id))]?.length)
      onRefreshClients();
    if (!eventsSlice.data.length) dispatch(apiFindAllEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SEARCH BY
  useEffect(() => {
    setClients(data);
  }, [data, loggedIn]);

  if (isLoading) return <Loading title="Clients" />;

  return (
    <div className="d-flex flex-fill flex-column">
      {/* TITLE BAR */}
      <TableTitleBar
        title="Clients"
        addLabel="Client"
        addLink="add-client"
        hideSearch={true}
        onRefresh={onRefreshClients}
        lastRefresh={lastRefresh}
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={clients}
          rowClassRules={rowClassRules}
          onRowClicked={onViewDetails}
        />
      </div>
    </div>
  );
}
