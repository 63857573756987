import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'
axios.defaults.withCredentials = true

// API FIND ALL INVENTORY { site: ObjectId }
export const apiFindAllInventory = createAsyncThunk('inventory/findAll', async ({ site }) => {
  const inventory = await axios
    .get(`${apiLink}/inventories/${site}`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL INVENTORY: ', err))
  return inventory
})

// API ADD NEW GROUP
export const apiAddGroup = createAsyncThunk('inventory/add', async (newGroup) => {
  const res = await axios
    .post(`${apiLink}/inventories/add`, newGroup)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW GROUP: ', err))
  return res
})

// API UPDATE GROUP
export const apiUpdateGroup = createAsyncThunk('inventory/update', async (updatedGroup) => {
  const res = await axios
    .put(`${apiLink}/inventories/update`, updatedGroup)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE GROUP: ', err))
  return res
})

// API DELETE GROUP
export const apiDeleteGroup = createAsyncThunk('inventory/delete', async ({ _id }) => {
  const res = await axios
    .delete(`${apiLink}/inventories/${_id}`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API DELETE GROUP: ', err))
  return res
})

const initialState = {
  lastUpdated: '',
  isEmpty: false,
  isLoading: false,
  data: [{ name: 'Loading...', items: [] }],
}

const inventorySlice = createSlice({
  name: 'inventorySlice',
  initialState,
  reducers: {
    addGroup(state, action) {
      state.data.unshift(action.payload)
    },
    updateGroup(state, action) {
      state.data = state.data.map((x) => (x._id === action.payload._id ? action.payload : x))
    },
    deleteGroup(state, action) {
      state.data = state.data.filter((x) => x._id !== action.payload._id)
    },
    clearInventory(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // API FIND ALL INVENTORY
      .addCase(apiFindAllInventory.pending, (state) => {
        state.lastRefresh = new Date().toISOString()
        state.isLoading = true
      })
      .addCase(apiFindAllInventory.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : []
        state.isEmpty = action.payload?.length ? true : false
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllInventory.rejected, (state) => {
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      // API ADD NEW GROUP
      .addCase(apiAddGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiAddGroup.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddGroup.rejected, (state) => {
        state.isLoading = false
      })
      // API UPDATE GROUP
      .addCase(apiUpdateGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiUpdateGroup.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(apiUpdateGroup.rejected, (state) => {
        state.isLoading = false
      })
      // API DELETE GROUP
      .addCase(apiDeleteGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiDeleteGroup.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(apiDeleteGroup.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { addGroup, updateGroup, deleteGroup, clearInventory } = inventorySlice.actions

export default inventorySlice.reducer
