import React from "react";

export function FormInput({
  className = "mb-3",
  formClass = "form-control form-control-sm",
  type = "text",
  label = "",
  value,
  setValue,
  placeholder = "",
  disabled = false,
  options = [],
  defaultValue = null,
  defaultLabel = "",
  required = false,
}) {
  // LABEL
  const Label = ({ txt }) => {
    if (required)
      return (
        <label className='fw-bold lead'>
          {txt} <span className='text-danger'>*</span>
        </label>
      );
    else return <label className='fw-bold lead'>{txt}</label>;
  };

  if (type === "select")
    return (
      <div className={className + " w-100"}>
        <Label txt={label} />
        <select className='form-select form-select-sm' disabled={disabled} value={value} onChange={(x) => setValue(x.target.value)}>
          <option value={defaultValue}>{defaultLabel}</option>
          {options.map((item, key) => (
            <option key={key} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    );
  return (
    <div className={className + " w-100"}>
      <Label txt={label} />
      <input
        type={type}
        className={formClass}
        placeholder={placeholder}
        value={value}
        onChange={(x) => setValue(x.target.value)}
        disabled={disabled}
      />
    </div>
  );
}
