import { React, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiLogin } from '../reduxPie/selectedSlice';
import loginLogo from './../media/loginLogo.png';

export const VIEW_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-eye"
    a
    viewBox="0 0 16 16">
    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
  </svg>
);

export function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formDefault = 'form-control ';
  const formValid = formDefault + 'is-valid ';
  const formInvalid = formDefault + 'is-invalid ';

  // VALUE STATE
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // FORM STATE
  const [formEmail, setFormEmail] = useState(formDefault);
  const [formPassword, setFormPassword] = useState(formDefault);
  const [formPasswordType, setFormPasswordType] = useState('password');

  // ON LOGIN
  const onLogin = (x) => {
    x.preventDefault();
    if (formEmail === formValid && formPassword === formValid) {
      dispatch(apiLogin({ username: email, password }));
      navigate('/');
    } else {
      window.alert('Invalid Login');
      setFormEmail(formInvalid);
      setFormPassword(formInvalid);
      navigate('/');
    }
  };

  // EMAIL VALIDATION
  useEffect(() => {
    // Email Regex from W3Schools
    if (
      email.match('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$') &&
      email.indexOf('@') < email.lastIndexOf('.')
    ) {
      setFormEmail(formValid);
    } else if (email.length === 0) {
      setFormEmail(formDefault);
    } else {
      setFormEmail(formInvalid);
    }
  }, [email, formValid, formInvalid]);

  // PASSWORD VALIDATION
  useEffect(() => {
    // Atleast One Number
    // Atleast One Lower Case Letter
    // Atleast One Upper Case Letter
    // Atleast One Special Character
    // Atleast 8 Characters
    if (password.match('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}')) {
      setFormPassword(formValid);
    } else if (password.length === 0) {
      setFormPassword(formDefault);
    } else {
      setFormPassword(formInvalid);
    }
  }, [password, formValid, formInvalid]);

  // ----- ON VIEW PASSWORD
  const onToggleView = () =>
    formPasswordType === 'password' ? setFormPasswordType('text') : setFormPasswordType('password');
  return (
    <div className="d-flex flex-fill centered">
      <div className="card  border-0">
        <div className="d-flex">
          {/* LEFT COL */}
          <form className="col p-3" onSubmit={(x) => onLogin(x)}>
            <h1 className="display-6 mb-3">Login</h1>

            {/* EMAIL */}
            <div className="input-group mb-3">
              <input
                type="email"
                className={formEmail}
                placeholder="Enter Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            {/* PASSWORD */}
            <div className="input-group mb-4">
              <input
                type={formPasswordType}
                className={formPassword}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button
                type="button"
                className={`btn ${formPasswordType === 'text' ? 'btn-dark' : 'btn-outline-secondary'}`}
                onClick={() => onToggleView()}>
                {VIEW_ICON}
              </button>
            </div>
            {/* LOGIN BTN */}
            <button type="submit" className="btn btn-dark w-100" onClick={(x) => onLogin(x)}>
              Login
            </button>
          </form>
          {/* RIGHT COL */}
          <div className="col d-flex centered bg-black">
            <div>
              <img src={loginLogo} alt="..." className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
