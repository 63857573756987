import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ReactFormGenerator } from "../../../../components/react-form-builder2/lib";
import * as variables from "../../../../components/react-form-builder2/variables";
import { addReport, apiAddReport } from "../../../../reduxPie/reportsSlice";
import { addLog, apiAddLog } from "../../../../reduxPie/logsSlice";

export function AddReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, client, site, form } = useSelector((state) => state.selected);
  const eventsSlice = useSelector((state) => state.events);

  // ON SUBMIT ( ADD REPORT )
  const onSubmit = (data) => {
    const event = eventsSlice.data.find((x) => x._id === form.event);
    // LOG
    const newLog = {
      customer: loggedIn.customer,
      client: client._id,
      site: site._id,
      event: form.event,
      form: form._id,
      data: `@${site?.name}: ${event.type} - ${event.name} - ${form.name} - ${loggedIn.name}`,
      submittedOn: new Date().toLocaleString(),
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
    };

    // REPORT OBJECT
    const newReport = {
      customer: loggedIn.customer,
      region: loggedIn.region,
      branch: loggedIn.branch,
      client: client._id,
      site: site._id,
      form: form._id,
      event: event._id,
      formName: form.name,
      eventName: event.name,
      eventType: event.type,
      data,
      submittedOn: new Date().toLocaleString(),
      createdBy: loggedIn._id,
      createdOn: new Date().toISOString(),
    };
    dispatch(addReport(newReport));
    dispatch(apiAddReport(newReport));
    dispatch(addLog(newLog));
    dispatch(apiAddLog(newLog));
    window.alert(`${form.name} has been submitted `);
    navigate("/manage/clients/sites/forms");
  };

  // ON NOT VALID
  const onNotValid = (errors) => {
    window.alert(`FORM NOT SUBMITTED:\n${errors.map((item) => `\n-${item}`)}`);
  };
  return (
    <div className='d-flex flex-fill'>
      <div className='card container shadow bg-black'>
        {/* TITLE BAR */}
        <div className='d-flex align-items-center text-light'>
          <Link to='/manage/clients/sites/forms' className='btn btn-sm btn-danger position-absolute'>
            Cancel
          </Link>
          <div className='d-flex centered flex-fill'>
            <h1 className='display-6 fw-bold text-center'>{form.name}</h1>
          </div>
        </div>

        {/* FORM */}
        <ReactFormGenerator
          // download_path=''
          // back_action='/'
          // back_name='Back'
          // answer_data={answers}
          // action_name='Save'
          // form_action='/api/form'
          // form_method='POST'
          // skip_validations={false}
          onNotValid={onNotValid}
          onSubmit={onSubmit}
          submitType='fill'
          variables={variables}
          data={form.data}
        />
      </div>
    </div>
  );
}
