import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AddMultipleInputs } from "../../../../../../components/AddMultipleInputs";
import { EditTitleBar } from "../../../../../../components/EditTitleBar";
import { FormInput } from "../../../../../../components/FormInput";

export function EditEmailGroup() {
  const { loggedIn, emailGroup } = useSelector((state) => state.selected);
  const regionsSlice = useSelector((state) => state.regions);
  const branchesSlice = useSelector((state) => state.branches);
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(true);

  //  EMAIL GROUP STATE VALUES
  const [region, setRegion] = useState(emailGroup.region);
  const [branch, setBranch] = useState(emailGroup.branch);
  const [name, setName] = useState(emailGroup.name);
  const [emails, setEmails] = useState(emailGroup.emails);

  // ON ADD EMAIL GROUP
  const onUpdateEmailGroup = () => {
    const updatedEmailGroup = {
      region,
      branch,
      name,
      emails,
      createdOn: emailGroup.createdOn,
      createdBy: emailGroup.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };

    console.log("REQ: ", updatedEmailGroup);
  };

  return (
    <div className='d-flex flex-column flex-fill card bg-black text-light my-1'>
      {/* TITLE BAR */}
      <EditTitleBar
        title='Email Group'
        backLink={-1}
        onUpdate={onUpdateEmailGroup}
        disabled={disabled}
        setDisabled={setDisabled}
      />

      {/* BODY */}
      <div className='d-flex flex-fill align-items-center'>
        {/* COL SPACER */}
        <div className='col-1' />

        {/* CENTER PANEL */}
        <div className='col'>
          <div className='d-flex mb-3'>
            {/* REGION */}
            <FormInput
              type='select'
              label='Region'
              className='me-3'
              value={region}
              setValue={setRegion}
              defaultLabel='Select Region'
              options={regionsSlice?.data?.map((x) => ({ label: x.name, value: x._id }))}
              disabled={disabled}
            />

            {/* BRANCH */}
            <FormInput
              type='select'
              label='Branch'
              className=''
              value={branch}
              setValue={setBranch}
              defaultLabel='Select Branch'
              options={branchesSlice?.data?.map((x) => ({ label: x.name, value: x._id }))}
              disabled={disabled}
            />
          </div>

          {/* NAME */}
          <div className='pb-3'>
            <FormInput
              label='Name'
              placeholder='Enter Name for Email Group'
              value={name}
              setValue={setName}
              disabled={disabled}
            />
          </div>

          {/* EMAILS */}
          <AddMultipleInputs
            title='Emails'
            label='Email'
            value={email}
            setValue={setEmail}
            details={emails}
            setDetails={setEmails}
            disabled={disabled}
          />
        </div>
        {/* COL SPACER */}
        <div className='col-1' />
      </div>
    </div>
  );
}
