import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddMultipleInputs } from '../../../../../components/AddMultipleInputs';
import { AddTitleBar } from '../../../../../components/AddTitleBar';
import { addLog } from '../../../../../reduxPie/logsSlice';
import { addDelivery, apiAddDelivery } from '../../../../../reduxPie/deliveriesSlice';
import { FormInput } from '../../../../../components/FormInput';

export function AddDelivery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client, site, loggedIn } = useSelector((state) => state.selected);
  const categories = ['Update', 'Chain of Custody', 'Other'];
  const couriers = ['UPS', 'FedEx', 'USPS', 'Amazon', 'DHL', 'Other'];
  const [selectedCourier, setSelectedCourier] = useState(couriers[0]);

  //   FORM STATE VALUES
  const [principalRecieved, setPrincipalRecieved] = useState(false);
  const [deliveredOn, setDeliveredOn] = useState('');
  const [bound, setBound] = useState('true');
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const [qty, setQty] = useState('');
  const [courier, setCourier] = useState('');
  const [category, setCategory] = useState('Update');
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [trackingId, setTrackingId] = useState('');
  const [trackingIds, setTrackingIds] = useState([]);

  // ON ADD DELIVERY
  const onAddDelivery = () => {
    if (to && from && qty) {
      // NEW LOG
      const newLog = {
        customer: loggedIn.customer,
        client: client._id,
        site: site._id,
        delivery: null,
        data: `@${site?.name}: ${selectedCourier === 'Other' ? courier : selectedCourier} Delivery - ${
          bound === 'true' ? 'Inbound' : 'Outbound'
        } - Added${deliveredOn ? ' - Delievered' : ''}`,
        submittedOn: new Date().toLocaleString(),
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
      };

      // NEW DELIVERY
      const newDelivery = {
        client: client._id,
        site: site._id,
        bound,
        to,
        from,
        qty: parseInt(qty),
        courier: selectedCourier === 'Other' ? courier : selectedCourier,
        deliveredOn,
        principalRecieved,
        notes,
        trackingIds,
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };
      dispatch(addDelivery(newDelivery));
      dispatch(apiAddDelivery({ newLog, newDelivery }));
      dispatch(addLog(newLog));
      window.alert(`${selectedCourier === 'Other' ? courier : selectedCourier} Delivery Added to ${site?.name}`);
      navigate('/manage/clients/sites/deliveries');
    } else {
      window.alert('Required: \n- To\n- From\n- Qty');
    }
  };

  return (
    <div className="card d-flex flex-column flex-fill bg-black text-light">
      {/* TITLE BAR */}
      <AddTitleBar title="Delivery" backLink={-1} onAdd={onAddDelivery} />

      {/* CARD BODY */}
      <div className="d-flex h-100 px-3 pt-3">
        {/* LEFT COL */}
        <div className="col">
          <div className="d-flex mb-2">
            {/* PRINCIPAL RECEIEVED */}
            <div className="col-4">
              <div className="input-group input-group-sm">
                <span className="input-group-text">Principal Recieved</span>
                <span className="input-group-text">
                  <input
                    type="checkbox"
                    className="form-check-input my-auto"
                    checked={principalRecieved}
                    onChange={() => setPrincipalRecieved(!principalRecieved)}
                  />
                </span>
              </div>
            </div>

            {/* DELIVERED ON */}
            <div className="col">
              <div className="input-group input-group-sm">
                <span className="input-group-text">Delivered</span>
                <span className="input-group-text">
                  <input
                    type="checkbox"
                    className="form-check-input my-auto"
                    checked={deliveredOn ? true : false}
                    onChange={() => setDeliveredOn(deliveredOn ? '' : new Date().toLocaleString())}
                  />
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={deliveredOn}
                  onChange={(x) => setDeliveredOn(x.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex mb-3">
            {/* BOUND */}
            <div className="w-100 me-3">
              <div className="">
                <label className="fw-bold lead">Bound</label>
                <select
                  type="checkbox"
                  className="form-select form-select-sm"
                  value={bound}
                  onChange={(x) => setBound(x.target.value)}>
                  <option value={true}>In</option>
                  <option value={false}>Out</option>
                </select>
              </div>
            </div>

            {/* QTY */}
            <FormInput type="number" label="Qty *" placeholder="0" className="" value={qty} setValue={setQty} />
          </div>

          {/* NOTES */}
          <AddMultipleInputs
            label="Note"
            title="Notes"
            categories={categories}
            category={category}
            setCategory={setCategory}
            value={note}
            setValue={setNote}
            details={notes}
            setDetails={setNotes}
          />
        </div>

        {/* MID COL ( SPACER ) */}
        <div className="col-1" />

        {/* RIGHT COL */}
        <div className="col">
          <div className="d-flex mb-3">
            {/* COURIER */}
            <div className="w-100 me-3">
              <FormInput
                type="select"
                label="Courier"
                className=""
                value={selectedCourier}
                defaultLabel="Select Courier"
                setValue={setSelectedCourier}
                options={couriers.map((x) => ({ label: x, value: x }))}
              />
              {selectedCourier === 'Other' && (
                <input
                  type="text"
                  placeholder="Enter Courier"
                  className="form-control form-control-sm w-100"
                  value={courier}
                  onChange={(x) => setCourier(x.target.value)}
                />
              )}
            </div>

            {/* TO */}
            <FormInput label="To *" className="me-3" value={to} setValue={setTo} />

            {/* FROM */}
            <FormInput label="From *" className="" value={from} setValue={setFrom} />
          </div>

          {/* TRACKING IDS */}
          <AddMultipleInputs
            label="Tracking ID"
            title="Tracking IDs"
            value={trackingId}
            setValue={setTrackingId}
            details={trackingIds}
            setDetails={setTrackingIds}
          />
        </div>
      </div>
    </div>
  );
}
