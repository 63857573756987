import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddMultipleInputs } from "../../../../components/AddMultipleInputs";
import { AddTitleBar } from "../../../../components/AddTitleBar";
import { FormInput } from "../../../../components/FormInput";
import { addClient, apiAddClient } from "../../../../reduxPie/clientsSlice";

export function AddClient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn } = useSelector((state) => state.selected);
  const formDefault = "form-control form-control-sm ";
  const formValid = formDefault + "is-valid ";
  const formInvalid = formDefault + "is-invalid ";

  // FORM VALIDATION STATE
  const [formName, setFormName] = useState(formDefault);
  const [formEmail, setFormEmail] = useState(formDefault);
  const [formContactName, setFormContactName] = useState(formDefault);

  // FORM VALUE STATE
  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("IR");
  const [active, setActive] = useState(true);
  const [protecteeName, setProtecteeName] = useState("");
  const [protecteeNames, setProtecteeNames] = useState([]);
  const [visitorGroupName, setVisitorGroupName] = useState("");
  const [visitorGroups, setVisitorGroups] = useState([]);

  // ON ADD NEW CLIENT
  const onAddClient = () => {
    if (formName === formValid && formContactName === formValid && formEmail === formValid) {
      const newClient = {
        customer: loggedIn.customer,
        region: loggedIn.region,
        branch: loggedIn.branch,
        name,
        status,
        protectees: protecteeNames.map((item) => ({ name: item, status })),
        contactName,
        phone,
        visitorGroups,
        email,
        active,
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };
      dispatch(addClient(newClient));
      dispatch(apiAddClient(newClient));
      window.alert(`Client ${name} Has Been Added`);
      navigate("/manage/clients");
    } else {
      window.alert("Required:\n\n- Name\n\n- Email\n\n- Contact Name");
    }
  };

  // NAME VALIDATION
  useEffect(() => {
    // Only Alphabet Characters
    if (name.length > 0 && name.length === name.trim().length && name.match("(^[a-zA-Z\\s]*$)")) {
      setFormName(formValid);
    } else if (name.length === 0) {
      setFormName(formDefault);
    } else {
      setFormName(formInvalid);
    }
  }, [name, formValid, formInvalid]);

  // CONTACT NAME VALIDATION
  useEffect(() => {
    // Only Alphabet Characters
    if (
      contactName.length > 0 &&
      contactName.length === contactName.trim().length &&
      contactName.match("(^[a-zA-Z\\s]*$)")
    ) {
      setFormContactName(formValid);
    } else if (contactName.length === 0) {
      setFormContactName(formDefault);
    } else {
      setFormContactName(formInvalid);
    }
  }, [contactName, formValid, formInvalid]);

  // EMAIL VALIDATION
  useEffect(() => {
    // Email Regex from W3Schools
    if (
      email.match("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$") &&
      email.indexOf("@") < email.lastIndexOf(".")
    ) {
      setFormEmail(formValid);
    } else if (email.length === 0) {
      setFormEmail(formDefault);
    } else {
      setFormEmail(formInvalid);
    }
  }, [email, formValid, formInvalid]);

  if (loggedIn.level === "1")
    return (
      <div className='d-flex flex-fill py-1'>
        <div className='card d-flex flex-fill bg-black text-light shadow-lg'>
          {/* TITLE BAR */}
          <AddTitleBar onAdd={onAddClient} title='Client' backLink='/manage/clients' />

          {/* CARD BODY */}
          <div className='row d-flex flex-fill centered px-3 overflow-auto'>
            {/* LEFT COL */}
            <div className='col'>
              <div className='d-flex'>
                {/* NAME  */}
                <FormInput className='me-3' label='Name *' formClass={formName} value={name} setValue={setName} />

                {/* PROTECTEE STATUS */}
                <FormInput
                  type='select'
                  label='Client Status'
                  value={status}
                  defaultLabel='IR'
                  defaultValue='IR'
                  setValue={setStatus}
                  options={[{ label: "OR", value: "OR" }]}
                />
              </div>

              {/* EMAIL */}
              <FormInput type='email' label='Email *' formClass={formEmail} value={email} setValue={setEmail} />
            </div>

            {/* COL-1 DIVIDER */}
            <div className='col-1' />

            {/* RIGHT COL */}
            <div className='col'>
              <div className='d-flex'>
                {/* CONTACT NAME */}
                <FormInput className='me-3' label='Contact Name *' value={contactName} setValue={setContactName} />

                {/* CONTACT NUMBER */}
                <FormInput label='Contact Phone' value={phone} setValue={setPhone} />
              </div>

              {/* ACTIVE STATUS */}
              <div className='w-100'>
                <label className='lead fw-bold'>Active Status</label>
                <select
                  className='form-select form-select-sm'
                  value={active}
                  onChange={(x) => setActive(x.target.value)}>
                  <option value={true}>Active</option>
                  <option value={false}>Deactivated</option>
                </select>
              </div>
            </div>

            <div className='d-flex'>
              {/* PROTECTEES */}
              <AddMultipleInputs
                title='Protectees'
                label='Protectee'
                value={protecteeName}
                setValue={setProtecteeName}
                details={protecteeNames}
                setDetails={setProtecteeNames}
              />
              <div className='col-1' />
              {/* VISITOR GROUPS */}
              <AddMultipleInputs
                title='Visitor Groups'
                label='Group'
                value={visitorGroupName}
                setValue={setVisitorGroupName}
                details={visitorGroups}
                setDetails={setVisitorGroups}
              />
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className='d-flex flex-fill centered'>
        <h1 className='display-4 text-danger'>Access Denied</h1>
      </div>
    );
}
