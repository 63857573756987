import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddTitleBar } from '../../../../../components/AddTitleBar'
import { FormInput } from '../../../../../components/FormInput'
import { addLog, apiAddLog } from '../../../../../reduxPie/logsSlice'

export function AddEntry() {
  const formDefault = 'form-control form-control-sm '
  const formValid = formDefault + 'is-valid '
  const formInvalid = formDefault + 'is-invalid '
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { client, site, loggedIn } = useSelector((state) => state.selected)
  const personsSlice = useSelector((state) => state.persons)

  const [visitors, setVisitors] = useState([])
  const [pursuers, setPursuers] = useState([])

  // FORM VALUE STATE
  const [entry, setEntry] = useState('')
  const [visitor, setVisitor] = useState('')
  const [pursuer, setPursuer] = useState('')

  // FORM VALIDATION STATE
  const [formEntry, setFormEntry] = useState('')

  // ON ADD ACTIVITY ENTRY
  const onAddActivityEntry = () => {
    // NEW LOG
    const newLog = {
      customer: loggedIn.customer,
      client: client._id,
      site: site._id,
      person: visitor || null,
      pursuer: pursuer || null,
      data: `@${site?.name}: Activity Entry - ${entry}`,
      submittedOn: new Date().toLocaleString(),
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
    }
    if (!entry.includes('-')) {
      dispatch(addLog(newLog))
      dispatch(apiAddLog(newLog))
      setEntry('')
      window.alert('New Activity Entry Submitted')
      navigate(-1)
    } else {
      window.alert("Invalid Entry\n\n Hyphens ' - ' are not allowed")
    }
  }

  // REDUX FORCED REFRESH
  useEffect(() => {
    try {
      setVisitors(
        personsSlice.data
          .filter((x) => site?.allowedPersons?.includes(x?._id))
          .filter((x) => !x.checkedIn || x.recentSite === site?._id)
          .sort((x) => (x.checkedIn ? !x.checkedIn : 1))
      )
      setPursuers(personsSlice.data.filter((x) => x.type === 'Pursuer'))
    } catch (err) {
      console.error('ERROR: ', err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personsSlice.data])

  // ENTRY VALIDATION
  useEffect(() => {
    if (entry.includes('-') && formEntry) setFormEntry(formInvalid)
    else if (!entry) setFormEntry(formDefault)
    else setFormEntry(formValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry])

  return (
    <div className='d-flex flex-fill flex-column'>
      <div className='card d-flex flex-column flex-fill bg-black text-light'>
        {/* TITLE BAR */}
        <AddTitleBar title='Activity Entry' onAdd={onAddActivityEntry} backLink={-1} />

        {/* BODY */}
        <form
          className='d-flex flex-column flex-fill p-3'
          onSubmit={(x) => {
            x.preventDefault()
            if (entry) onAddActivityEntry()
          }}
        >
          <div className='d-flex flex-column flex-fill'>
            <div className='d-flex'>
              {/* CLIENT */}
              <FormInput className='me-3' label='Client' value={client.name} disabled={true} />

              {/* SITE */}
              <FormInput label='Site' className='me-3' value={site?.name} disabled={true} />

              {/* VISITOR */}
              <FormInput
                type='select'
                className='me-3'
                label='Visitor'
                value={visitor}
                setValue={setVisitor}
                options={visitors.map((x) => ({ label: x.name, value: x._id }))}
                defaultLabel='Choose One ( If Needed )'
              />

              {/* PURSUER */}
              <FormInput
                type='select'
                className='me-3'
                label='Pursuer'
                value={pursuer}
                setValue={setPursuer}
                options={pursuers.map((x) => ({ label: x.name, value: x._id }))}
                defaultLabel='Choose One ( If Needed )'
              />
            </div>

            {/* ENTRY */}
            <div className='w-50 align-self-center my-auto'>
              <label className='fw-bold lead'>Entry</label>
              <textarea rows={4} className={formEntry} value={entry} onChange={(x) => setEntry(x.target.value)} />

              <div className='d-flex centered'>
                <span>No Hyphens ' - '</span>
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <div className='d-flex'>
            <button className='btn btn-success w-100' type='submit' disabled={!entry || entry.includes('-')}>
              Submit Entry
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
