import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddMultipleInputs } from "../../../../components/AddMultipleInputs";
import { EditTitleBar } from "../../../../components/EditTitleBar";
import { FormInput } from "../../../../components/FormInput";
import { apiUpdateClient, updateClient } from "../../../../reduxPie/clientsSlice";
import { setClient } from "../../../../reduxPie/selectedSlice";

export function EditClient() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const { loggedIn, client } = useSelector((state) => state.selected);
  const formDefault = "form-control form-control-sm ";
  const formValid = formDefault + "is-valid ";
  const formInvalid = formDefault + "is-invalid ";
  const [name, setName] = useState(client.name);
  const [contactName, setContactName] = useState(client.contactName);
  const [phone, setPhone] = useState("");

  // FORM VALIDATION
  const [formName, setFormName] = useState(formDefault);
  const [formEmail, setFormEmail] = useState(formDefault);
  const [formContactName, setFormContactName] = useState(formDefault);

  // FORM VALUES
  const [email, setEmail] = useState(client.email);
  const [status, setStatus] = useState("IR");
  const [active, setActive] = useState(client.active);
  const [visitorGroups, setVisitorGroups] = useState(client.visitorGroups);
  const [protecteeName, setProtecteeName] = useState("");
  const [protecteeNames, setProtecteeNames] = useState([...client.protectees.map((x) => x?.name)]);
  const [visitorGroupName, setVisitorGroupName] = useState("");

  // ON UPDATE CLIENT
  const onUpdateClient = () => {
    if (formName === formValid && formContactName === formValid && formEmail === formValid) {
      // UPDATED CLIENT
      const updatedClient = {
        id: client._id,
        customer: client.customer,
        region: client.region,
        branch: client.branch,
        name,
        status: client.status,
        protectees: protecteeNames.map((x) => ({
          name: x,
          status: client.protectees.find((y) => y?.name === x)?.status ?? status,
        })),
        contactName,
        phone,
        visitorGroups,
        email,
        active,
        createdOn: client.createdOn,
        createdBy: client.createdBy,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };
      dispatch(
        setClient({
          ...client,
          name,
          protectees: protecteeNames.map((x) => ({
            name: x,
            status: client.protectees.find((y) => y?.name === x)?.status ?? status,
          })),
          contactName,
          visitorGroups,
          email,
          active,
          modifiedOn: new Date().toISOString(),
          modifiedBy: loggedIn._id,
        })
      );
      dispatch(
        updateClient({
          ...client,
          name,
          protectees: protecteeNames.map((x) => ({
            name: x,
            status: client.protectees.find((y) => y?.name === x)?.status ?? status,
          })),
          contactName,
          visitorGroups,
          email,
          active,
          modifiedOn: new Date().toISOString(),
          modifiedBy: loggedIn._id,
        })
      );
      dispatch(apiUpdateClient(updatedClient));
      window.alert(`${client.name} Has Been Updated`);
      navigate("/manage/clients/sites");
    } else {
      window.alert("Required:\n\n- Name\n\n- Email\n\n- Contact Name");
    }
  };

  // NAME VALIDATION
  useEffect(() => {
    // Only Alphabet Characters
    if (name.length > 0 && name.length === name.trim().length && name.match("(^[a-zA-Z\\s]*$)")) {
      setFormName(formValid);
    } else if (name.length === 0) {
      setFormName(formDefault);
    } else {
      setFormName(formInvalid);
    }
  }, [name, formValid, formInvalid]);

  // CONTACT NAME VALIDATION
  useEffect(() => {
    // Only Alphabet Characters
    if (
      contactName.length > 0 &&
      contactName.length === contactName.trim().length &&
      contactName.match("(^[a-zA-Z\\s]*$)")
    ) {
      setFormContactName(formValid);
    } else if (contactName.length === 0) {
      setFormContactName(formDefault);
    } else {
      setFormContactName(formInvalid);
    }
  }, [contactName, formValid, formInvalid]);

  // EMAIL VALIDATION
  useEffect(() => {
    // Email Regex from W3Schools
    if (
      email.match("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$") &&
      email.indexOf("@") < email.lastIndexOf(".")
    ) {
      setFormEmail(formValid);
    } else if (email.length === 0) {
      setFormEmail(formDefault);
    } else {
      setFormEmail(formInvalid);
    }
  }, [email, formValid, formInvalid]);

  return (
    <div className='d-flex flex-fill'>
      <div className='d-flex flex-fill flex-column card bg-black text-light shadow-lg'>
        {/* TITLE BAR */}
        <EditTitleBar
          title={client.name}
          backLink={-1}
          disabled={disabled}
          setDisabled={setDisabled}
          onUpdate={onUpdateClient}
        />

        <div className='card-body row mx-0 overflow-auto' style={{ height: window.innerHeight * 0.55 }}>
          {/* LEFT COL */}
          <div className='col'>
            <div className='d-flex'>
              {/* NAME  */}
              <FormInput
                className='me-3'
                label='Name *'
                formClass={formName}
                value={name}
                setValue={setName}
                disabled={disabled}
              />

              {/* PROTECTEE STATUS */}
              <FormInput
                type='select'
                label='Client Status'
                value={status}
                defaultLabel='IR'
                defaultValue='IR'
                setValue={setStatus}
                options={[{ label: "OR", value: "OR" }]}
                disabled={true}
              />
            </div>

            {/* EMAIL */}
            <FormInput
              type='email'
              label='Email *'
              formClass={formEmail}
              value={email}
              setValue={setEmail}
              disabled={disabled}
            />
          </div>

          {/* COL-1 DIVIDER */}
          <div className='col-1' />

          {/* RIGHT COL */}
          <div className='col'>
            <div className='d-flex'>
              {/* CONTACT NAME */}
              <FormInput
                className='me-3'
                label='Contact Name *'
                value={contactName}
                setValue={setContactName}
                disabled={disabled}
              />

              {/* CONTACT NUMBER */}
              <FormInput label='Contact Phone' value={phone} setValue={setPhone} disabled={disabled} />
            </div>

            {/* ACTIVE STATUS */}
            <div className='w-100'>
              <label className='lead fw-bold'>Active Status</label>
              <select
                className='form-select form-select-sm'
                value={active}
                onChange={(x) => setActive(x.target.value)}
                disabled={disabled}>
                <option value={true}>Active</option>
                <option value={false}>Deactivated</option>
              </select>
            </div>
          </div>

          <div className='d-flex mb-3'>
            {/* PROTECTEES */}
            <AddMultipleInputs
              title='Protectees'
              label='Protectee'
              value={protecteeName}
              setValue={setProtecteeName}
              details={protecteeNames}
              setDetails={setProtecteeNames}
              disabled={disabled}
            />
            <div className='col-1' />
            {/* VISITOR GROUPS */}
            <AddMultipleInputs
              title='Visitor Groups'
              label='Group'
              value={visitorGroupName}
              setValue={setVisitorGroupName}
              details={visitorGroups}
              setDetails={setVisitorGroups}
              disabled={disabled}
            />
          </div>

          <div className='d-flex'>
            {/* CREATED ON */}
            <FormInput
              className='me-3'
              label='Created On'
              value={new Date(client.createdOn).toLocaleString()}
              disabled={true}
            />

            {/* LAST UPDATED ON */}
            <FormInput label='Last Updated On' value={new Date(client.modifiedOn).toLocaleString()} disabled={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
