import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  lastRefresh: '',
  data: [],
  visitorsList: [],
}
axios.defaults.withCredentials = true

// API FIND ALLOWED PERSONS
export const apiFindAllowedPersons = createAsyncThunk('persons/find/allowed', async (allowedPersons) => {
  const data = await axios
    .post(`${apiLink}/persons/list`, { ids: allowedPersons })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALLOWED PERSONS: ', err))
  return data
})

// API FIND ALL BY TYPE
export const apiFindAllByType = createAsyncThunk('persons/find/type', async (type) => {
  const data = await axios
    .post(`${apiLink}/persons/find`, { type })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL BY TYPE: ', err))
  return data
})

// API FIND ALL BY TYPE
export const apiFindAllForVisitorsListByType = createAsyncThunk('persons/find/allByType', async (type) => {
  const data = await axios
    .post(`${apiLink}/persons/find`, { type })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL BY TYPE: ', err))
  return data
})

// API FIND PERSON BY NAME
export const apiFindPersonByName = createAsyncThunk('persons/find/name', async (name) => {
  const data = await axios
    .post(`${apiLink}/persons/findbyname`, { name })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND PERSON BY NAME: ', err))
  return data
})

// API FIND PERSON BY ID
export const apiFindPersonById = createAsyncThunk('persons/find/id', async (id) => {
  const data = await axios
    .get(`${apiLink}/persons/${id}`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND PERSON BY ID: ', err))
  return data
})

// API ADD NEW PERSON
export const apiAddPerson = createAsyncThunk('persons/add', async ({ newPerson, updatedSite }) => {
  const res = await axios
    .post(`${apiLink}/persons/add`, newPerson)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW PERSON: ', err))

  if (updatedSite?.id && res?._id) {
    const site = { ...updatedSite, allowedPersons: [res._id, ...updatedSite.allowedPersons] }
    await axios
      .put(`${apiLink}/sites/update`, site)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPDATE SITE: ', err))
  }
  return res
})

// API UPDATE PERSON
export const apiUpdatePerson = createAsyncThunk('persons/update', async ({ updatedPerson, formData }) => {
  if (formData) {
    await axios
      .post(`${apiLink}/upload`, formData)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPLOAD PERSON IMAGE:', err))
    return await axios
      .put(`${apiLink}/persons/update`, updatedPerson)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPDATE PERSON: ', err))
  } else {
    return await axios
      .put(`${apiLink}/persons/update`, updatedPerson)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API UPDATE PERSON: ', err))
  }
})

// API DELETE NDA
export const apiDeleteNDA = createAsyncThunk('person/delete/NDA', async ({ id }) => {
  const s3Link = `Visitors/${id}/files//NDA.pdf`

  const res = await axios
    .post(`${apiLink}/delete/s3/object`, { key: s3Link })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR DELETE NDA: ', err))

  return res
})

// API DELETE PROFILE PICTURES
export const apiDeleteProfilePictures = createAsyncThunk('person/delete/profile-pictures', async ({ id }) => {
  const s3Links = [
    `Visitors/${id}/images//user.jpg`,
    `Visitors/${id}/images//user.jpeg`,
    `Visitors/${id}/images//user.png`,
  ]

  const res1 = await axios
    .post(`${apiLink}/delete/s3/object`, { key: s3Links[0] })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR DELETE VISITOR PROFILE PICTURE(S): ', err))

  const res2 = await axios
    .post(`${apiLink}/delete/s3/object`, { key: s3Links[1] })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR DELETE VISITOR PROFILE PICTURE(S): ', err))

  const res3 = await axios
    .post(`${apiLink}/delete/s3/object`, { key: s3Links[2] })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR DELETE VISITOR PROFILE PICTURE(S): ', err))

  const resList = [res1, res2, res3]

  return resList
})

// API MASS CHECK IN
export const apiMassCheckIn = createAsyncThunk(
  'persons/massCheckIn',
  async ({ visitors, newLogs, recentSite, lastChecked, modifiedOn, modifiedBy }) => {
    const res = await axios
      .post(`${apiLink}/persons/bulkVisitorAction`, {
        visitors,
        newLogs,
        recentSite,
        lastChecked,
        modifiedOn,
        modifiedBy,
      })
      .then((res) => res?.data)
      .catch((err) => console.error('ERROR API POST BULK ACTION PERSON: ', err))
    return res
  }
)

const personsSlice = createSlice({
  name: 'personsSlice',
  initialState,
  reducers: {
    addPerson(state, action) {
      return {
        ...state,
        data: state.data ? [action.payload, ...state.data] : [action.payload],
      }
    },
    updatePerson(state, action) {
      state.data = state.data.map((x) => (x._id === action.payload._id ? action.payload : x))
    },
    clearPersons(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND PERSON BY NAME
      .addCase(apiFindPersonByName.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindPersonByName.fulfilled, (state, action) => {
        state.data = [...action.payload?.filter((x) => !state.data.map((y) => y._id).includes(x._id)), ...state.data]
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindPersonByName.rejected, (state, action) => {
        state.isLoading = false
      })
      // API FIND PERSON BY ID
      .addCase(apiFindPersonById.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindPersonById.fulfilled, (state, action) => {
        state.data = action.payload
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindPersonById.rejected, (state, action) => {
        state.isLoading = false
      })
      // API FIND ALL BY TYPE
      .addCase(apiFindAllByType.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindAllByType.fulfilled, (state, action) => {
        const uniIds = state.data.map((x) => x._id)
        state.data = [...action.payload?.filter((x) => !uniIds.includes(x._id)), ...state.data]
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllByType.rejected, (state, action) => {
        state.isLoading = false
      })
      // API FIND ALL BY FOR VISITORS LIST TYPE
      .addCase(apiFindAllForVisitorsListByType.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindAllForVisitorsListByType.fulfilled, (state, action) => {
        const uniIds = state.data.map((x) => x._id)
        state.visitorsList = [...action.payload?.filter((x) => !uniIds.includes(x._id)), ...state.data].sort(
          (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
        )
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllForVisitorsListByType.rejected, (state, action) => {
        state.isLoading = false
      })
      // API FIND ALLOWED PERSONS
      .addCase(apiFindAllowedPersons.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiFindAllowedPersons.fulfilled, (state, action) => {
        state.data = [...action.payload, ...state.data?.filter((x) => x.type === 'Pursuer')]
        state.lastRefresh = new Date().toISOString()
        state.isLoading = false
      })
      .addCase(apiFindAllowedPersons.rejected, (state, action) => {
        state.isLoading = false
      })
      // API ADD NEW PERSON
      .addCase(apiAddPerson.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiAddPerson.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddPerson.rejected, (state, action) => {
        state.isLoading = false
      })
      // API UPDATE PERSON
      .addCase(apiUpdatePerson.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiUpdatePerson.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiUpdatePerson.rejected, (state, action) => {
        state.isLoading = false
      })
      // API MASS CHECK IN
      .addCase(apiMassCheckIn.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiMassCheckIn.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiMassCheckIn.rejected, (state, action) => {
        state.isLoading = false
      })

      // API DELETE PROFILE PICTURES
      .addCase(apiDeleteProfilePictures.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiDeleteProfilePictures.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(apiDeleteProfilePictures.rejected, (state, action) => {
        state.isLoading = false
      })
      // API DELETE NDA
      .addCase(apiDeleteNDA.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiDeleteNDA.fulfilled, (state, action) => {
        state.isLoading = false
      }),
})

export const { addPerson, updatePerson, clearPersons } = personsSlice.actions

export default personsSlice.reducer
