import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { apiFindAllDeliveriesByClient } from '../../../../../reduxPie/deliveriesSlice';
import { setDelivery } from '../../../../../reduxPie/selectedSlice';
import { AgGridReact } from 'ag-grid-react';
import { PDFViewer } from '@react-pdf/renderer';
import { DeliveriesPDF } from '../../../../../components/DeliveriesPDF';

export function AllSiteDeliveries() {
  const table = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client, site } = useSelector((state) => state.selected);
  const deliveriesSlice = useSelector((state) => state.deliveries);
  const usersSlice = useSelector((state) => state.users);
  const [deliveries, setDeliveries] = useState([]);
  const [view, setView] = useState('table');

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'bound', flex: 0.75, filter: true, sortable: true, valueFormatter: ({ value }) => (value ? 'In' : 'Out') },
    { field: 'courier', flex: 1, sortable: true, filter: true },
    { field: 'qty', flex: 0.5, sortable: true, filter: true },
    { field: 'to', flex: 1, sortable: true, filter: true },
    { field: 'from', flex: 1, sortable: true, filter: true },
    {
      headerName: 'Last Note',
      field: 'notes',
      flex: 3,
      sortable: true,
      filter: true,
      valueFormatter: ({ value }) => (value.length > 0 ? value[value.length - 1].data : 'None'),
    },
    {
      field: 'deliveredOn',
      flex: 1,
      filter: true,
      valueFormatter: ({ value }) => (value ? value : 'Not Delievered'),
    },
  ]);

  // ROW CLASS RULES
  const rowClassRules = {
    'bg-success': 'data.principalRecieved === true',
  };

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setDelivery(item.data));
    navigate('/manage/clients/sites/deliveries/edit-delivery');
  };

  // ON REFRESH DELIVERIES
  const onRefreshDeliveries = () => {
    dispatch(apiFindAllDeliveriesByClient(client._id));
  };

  //  ON VIEW CHANGE
  const onViewChange = (VIEW) => {
    if (VIEW === 'pdf') {
      const count = table.current.api.getDisplayedRowCount();
      console.log(count);
      var filterRes = [];
      for (var x = 0; x < count; x++) filterRes.push(table.current.api.getDisplayedRowAtIndex(x)?.data);
      setDeliveries(filterRes);
    } else {
      setDeliveries(
        deliveriesSlice.data.map((item) => ({
          ...item,
          submittedBy: usersSlice.data.find((x) => x._id === item.createdBy)
            ? usersSlice.data.find((x) => x._id === item.createdBy).name
            : 'N/A',
        }))
      );
    }
    setView(VIEW);
  };

  // AUTO FETCH PACAKAGES
  useEffect(() => {
    if (!deliveriesSlice.data.length) onRefreshDeliveries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SEARCH
  useEffect(() => {
    setDeliveries(deliveriesSlice.data.filter((x) => x.site === site._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveriesSlice.data]);

  return (
    <div className="d-flex flex-fill flex-column">
      {/* TITLE BAR */}
      <div className="d-flex align-items-center justify-content-between px-3 py-1">
        {/* TITLE */}
        <div className="d-flex align-items-center">
          <h1 className="display-6 fs-2 fw-bold me-3">All Deliveries</h1>
          <button className="btn btn-outline-dark border-0" onClick={() => onRefreshDeliveries()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
            <small className="p-3">
              {`${Math.floor(
                (new Date().getTime() - new Date(deliveriesSlice.lastRefresh).getTime()) / 1000 / 60
              )} mins ${
                Math.floor((new Date().getTime() - new Date(deliveriesSlice.lastRefresh).getTime()) / 1000) % 60
              } secs ago`}
            </small>
          </button>
        </div>

        {/* BTN ALL DELIVERIES */}
        <Link to="/manage/clients/sites/deliveries" className="btn btn-sm btn-secondary">
          Delivery Management
        </Link>

        {/* VIEW TOGGLE */}
        <div className="btn-group">
          <button
            className={`btn btn-sm btn-${view === 'table' ? 'dark' : 'outline-secondary'}`}
            onClick={() => onViewChange('table')}>
            Table
          </button>
          <button
            className={`btn btn-sm btn-${view === 'pdf' ? 'dark' : 'outline-secondary'}`}
            onClick={() => onViewChange('pdf')}>
            PDF
          </button>
        </div>
      </div>

      {/* TABLE */}
      {view === 'table' && (
        <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
          <AgGridReact
            ref={table}
            columnDefs={columnDefs}
            rowData={deliveries}
            rowClassRules={rowClassRules}
            onCellClicked={onViewDetails}
          />
        </div>
      )}

      {/* PDF */}
      {view === 'pdf' && (
        <div className="d-flex flex-fill w-100">
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <DeliveriesPDF PDFData={deliveries} client={client} site={site} />
          </PDFViewer>
        </div>
      )}
    </div>
  );
}
