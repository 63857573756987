import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../../components/Loading';
import { TableTitleBar } from '../../../../components/TableTitleBar';
import { apiFindAllReports } from '../../../../reduxPie/reportsSlice';
import { setReport } from '../../../../reduxPie/selectedSlice';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { PDFViewer } from '@react-pdf/renderer';
import { ReportsPDF } from '../../../../components/ReportsPDF';

ChartJS.register(...registerables);

export function ClientReports() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client } = useSelector((state) => state.selected);
  const { isLoading, lastRefresh, data } = useSelector((state) => state.reports);
  const usersSlice = useSelector((state) => state.users);
  const sitesSlice = useSelector((state) => state.sites);
  const [reports, setReports] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [chartOptions, setChartOptions] = useState([]);
  const [view, setView] = useState('table');

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'key', headerName: '#', flex: 0.4, filter: true, sortable: true },
    { field: 'siteName', headerName: 'Site', flex: 1, filter: true, sortable: true },
    { field: 'formName', headerName: 'Form', flex: 1, filter: true, sortable: true },
    { field: 'eventName', headerName: 'Event', flex: 1, filter: true, sortable: true },
    { field: 'eventType', headerName: 'Type', flex: 0.75, filter: true, sortable: true },
    { field: 'submittedBy', flex: 1, filter: true, sortable: true },
    { field: 'submittedOn', flex: 1, filter: true, sortable: true },
  ]);

  // ON REFRESH REPORTS
  const onRefreshReports = () => {
    dispatch(apiFindAllReports());
  };

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setReport(item.data));
    navigate('details');
  };

  // AUTO FETCH CLIENT REPORTS
  useEffect(() => {
    if (!data.length) onRefreshReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO REDUX REFRESH
  useEffect(() => {
    setReports(
      data
        .filter((x) => x.client === client._id)
        .map((item, key) => {
          let user = usersSlice.data.find((x) => x._id === item.createdBy);
          let site = sitesSlice.data?.find((x) => x._id === item.site);
          return { ...item, key, submittedBy: user ? user.name : 'N/A', siteName: site ? site.name : 'N/A' };
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // AUTO CHART DATA REFERSH
  // useEffect(() => {
  //   const formNames = [...new Set(reports.map((x) => x.formName))];
  //   let reportsByForm = [];

  //   formNames.forEach((x) => {
  //     reportsByForm.push({ name: x, data: reports.filter((y) => y.formName === x).length });
  //   });
  // }, [reports]);

  // RENDER
  if (isLoading) return <Loading title="Reports" />;
  return (
    <div className="d-flex flex-fill flex-column">
      {/* TITLE BAR */}
      <TableTitleBar
        title="Clients Reports"
        hideSearch={true}
        onRefresh={onRefreshReports}
        lastRefresh={lastRefresh}
        view={view}
        setView={setView}
      />

      {/* TABLE */}
      {view === 'table' && (
        <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
          <AgGridReact
            rowData={reports}
            defaultColDef={{ resizable: true }}
            columnDefs={columnDefs}
            onRowClicked={onViewDetails}
          />
        </div>
      )}

      {/*  PDF VIEWER */}
      {view === 'pdf' && (
        <div className="d-flex flex-fill w-100">
          <PDFViewer style={{ width: '100%' }}>
            <ReportsPDF PDFData={reports} client={client} />
          </PDFViewer>
        </div>
      )}

      {/* CHARTS */}
      {view === 'chart' && (
        <div style={{ height: `100%`, width: `100%` }}>
          <AgChartsReact options={chartOptions} />
        </div>
      )}
    </div>
  );
}
