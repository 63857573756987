import { PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInput } from "../../../../components/FormInput";
import { Loading } from "../../../../components/Loading";
import { apiLink } from "../../../../reduxPie/apiConfig";
import {
  addGroup,
  apiAddGroup,
  apiDeleteGroup,
  apiFindAllInventory,
  apiUpdateGroup,
  deleteGroup,
  updateGroup,
} from "../../../../reduxPie/inventorySlice";
import { CategoryItems } from "./components/CategoryItems";
import { InventoryReportPDF } from "./components/InventoryReportPDF";

// IS QTY BELOW
export const isQtyBelow = ({ qty, min }) => {
  return parseInt(qty) < parseInt(min);
};

// IS EXPIRED
export const isExpired = ({ expDate }) => {
  const today = new Date();
  return new Date(`${expDate}T00:00:00`).getTime() <= today.getTime();
};

const UpdateCategoryAPI = async (req) =>
  await axios
    .put(`${apiLink}/inventories/update`, req)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR API UPDATE GROUP: ", err));

export function SiteInventory() {
  const dispatch = useDispatch();
  const inventorySlice = useSelector((state) => state.inventory);
  const { loggedIn, client, site } = useSelector((state) => state.selected);
  const [inventory, setInventory] = useState([...inventorySlice.data]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSub, setSelectedSub] = useState("Other");
  const [category, setGroup] = useState({ ...inventorySlice.data[0] });
  const [changesCount, setChangesCount] = useState(0);
  const [view, setView] = useState("table");
  // const [isLoading, setIsLoading] = useState(false);

  // ----- VALUE STATE
  const [name, setName] = useState("");
  const [min, setMin] = useState("");
  const [qty, setQty] = useState("");
  const [expDate, setExpDate] = useState("");
  const [serial, setSerial] = useState("");
  const [sub, setSub] = useState("");
  const [categoryName, setCategoryName] = useState("");

  // ON ADD ITEM
  const onAddItem = () => {
    const reduxAfterAddItem = {
      ...category,
      items: [...category.items, { name, min, qty, serial, sub: selectedSub === "Other" ? sub : selectedSub, expDate }],
    };
    const mongoAfterAddItem = {
      id: category._id,
      customer: category.customer,
      client: category.client,
      site: category.site,
      name: category.name,
      items: [...category.items, { name, min, qty, serial, sub: selectedSub === "Other" ? sub : selectedSub, expDate }],
      createdOn: category.createdOn,
      createdBy: category.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    setGroup(reduxAfterAddItem);
    setInventory(inventory.map((x) => (x._id === reduxAfterAddItem._id ? reduxAfterAddItem : x)));
    dispatch(updateGroup(reduxAfterAddItem));
    dispatch(apiUpdateGroup(mongoAfterAddItem));
    onClearForm();
    window.alert(`${name} has been added to ${category.name}`);
    setTimeout(() => window.location.reload(), 500);
  };

  // ON DELETE ITEM
  const onDeleteItem = (item, cName) => {
    const reduxAfterDeleteItem = { ...category, items: [...category.items.filter((x) => x.name !== item.name)] };
    const mongoAfterDeleteItem = {
      id: category._id,
      customer: category.customer,
      client: category.client,
      site: category.site,
      name: category.name,
      items: [...category.items.filter((x) => x.name !== item.name)],
      createdOn: category.createdOn,
      createdBy: category.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    setGroup(reduxAfterDeleteItem);
    setInventory(inventory.map((x) => (x._id === reduxAfterDeleteItem._id ? reduxAfterDeleteItem : x)));
    dispatch(updateGroup(reduxAfterDeleteItem));
    dispatch(apiUpdateGroup(mongoAfterDeleteItem));
    window.alert(`${item.name} has been removed from ${cName}`);
    setTimeout(() => window.location.reload(), 500);
  };

  // ON ADD CATEGORY
  const onAddCategory = () => {
    const newCategory = {
      customer: loggedIn.customer,
      client: client._id,
      site: site._id,
      name: categoryName,
      items: [],
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    setCategoryName("");
    setInventory([newCategory, ...inventory]);
    dispatch(addGroup(newCategory));
    dispatch(apiAddGroup(newCategory));
    window.alert(`Category: ${newCategory.name} has been added.`);
    setTimeout(() => window.location.reload(), 500);
  };

  // ON DELETE CATEGORY
  const onDeleteCategory = () => {
    setCategoryName("");
    const afterDeleteGroup = inventory.filter((x) => x._id !== category._id);
    setInventory(afterDeleteGroup);
    dispatch(deleteGroup(category));
    dispatch(apiDeleteGroup(category));
    window.alert(`Category: ${category.name} has been deleted.`);
    setTimeout(() => window.location.reload(), 500);
  };

  // ON SAVE CATEGORY
  const onSaveCategory = async () => {
    const mongo = {
      id: category._id,
      customer: category.customer,
      client: category.client,
      site: category.site,
      name: category.name,
      items: [...category.items],
      createdOn: category.createdOn,
      createdBy: category.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };
    // WORK-AROUND FOR AG-GRID/REDUX UPDATE BUG
    const res = await UpdateCategoryAPI(mongo);
    if (res) {
      setChangesCount(0);
      window.alert("Inventory Saved");
      onRefreshInventory();
    }
  };

  // ON CLEAR FORM
  const onClearForm = () => {
    setName("");
    setMin("");
    setQty("");
    setExpDate("");
    setSub("");
    setSerial("");
  };

  // ON REFRESH INVENTORY
  const onRefreshInventory = () => {
    dispatch(apiFindAllInventory({ site: site._id }));
    setTimeout(() => window.location.reload(), 500);
  };

  // ON SELECT CATEGORY
  const onSelectCategory = (id) => {
    const foundGroup = inventory.find((x) => x._id === id);
    setGroup(foundGroup);
  };

  // AUTO FETCH INVENTORY
  useEffect(() => {
    if ((new Date().getTime() - new Date(inventorySlice.lastRefresh).getTime()) / 1000 / 60 > 5) onRefreshInventory();
    else if (inventorySlice.data[0]?.site !== site._id && inventorySlice.data.length > 0) onRefreshInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO SET SUB-CATEGORIES & GROUP
  useEffect(() => {
    if (inventorySlice.data.length) {
      const subs = [...new Set(category.items.map((x) => x.sub)), "Other"];
      setSubCategories(subs);
      setSelectedSub(subs[0] ? subs[0] : "Other");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  // ----- RENDER ----- //
  if (inventorySlice.isLoading) return <Loading title='Inventory' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <div className='d-flex justify-content-between align-items-center py-1 px-3'>
        {/* SAVE INVENTORY BTN */}
        {changesCount !== 0 ? (
          <div className='d-grid flex-fill'>
            <button className='btn btn-lg btn-success' onClick={() => onSaveCategory()}>
              Save Inventory
            </button>
          </div>
        ) : (
          <>
            {/* TITLE BAR */}
            <div className='d-flex align-items-center'>
              {/* TITLE */}
              <h1 className='display-6 fs-2 fw-bold'>Inventory</h1>

              {/* REFRESH BTN */}
              <button className='btn btn-sm btn-outline-dark border-0 ms-3' onClick={() => onRefreshInventory()}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-arrow-clockwise'
                  viewBox='0 0 16 16'>
                  <path fillRule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z' />
                  <path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z' />
                </svg>
                <small className='p-3'>
                  {`${Math.floor(
                    (new Date().getTime() - new Date(inventorySlice.lastRefresh).getTime()) / 1000 / 60
                  )} mins ${
                    Math.floor((new Date().getTime() - new Date(inventorySlice.lastRefresh).getTime()) / 1000) % 60
                  } secs ago`}
                </small>
              </button>
            </div>

            {inventory.length !== 0 && (
              <>
                {/* VIEW TOGGLE */}
                <div className='d-flex'>
                  <div className='btn-group'>
                    <button
                      className={`btn btn-sm btn-${view === "table" ? "dark" : "outline-secondary"}`}
                      onClick={() => setView("table")}>
                      Management
                    </button>
                    <button
                      className={`btn btn-sm btn-${view === "pdf" ? "dark" : "outline-secondary"}`}
                      onClick={() => setView("pdf")}>
                      Report
                    </button>
                  </div>
                </div>

                {/* CATEGORY DROPDOWM */}
                <div className='d-flex'>
                  <div className='input-group input-group-sm'>
                    <span className='input-group-text'>Category</span>
                    <select
                      className='form-select bg-secondary text-light'
                      value={category._id}
                      disabled={view !== "table"}
                      onChange={(x) => onSelectCategory(x.target.value)}>
                      {inventory.map((item, key) => {
                        if (key === 0)
                          return (
                            <option key={key} value={item._id} selected>
                              {item.name}
                            </option>
                          );
                        return (
                          <option key={key} value={item._id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            )}

            {/* ADD NEW CATEGORY */}
            <button className='btn btn-sm btn-primary' data-bs-toggle='modal' data-bs-target='#addNewCategoryPopUp'>
              Add New Category
            </button>
          </>
        )}
      </div>

      {/* GROUP ITEMS */}
      {inventorySlice.data.length ? (
        // TABLE
        view === "table" ? (
          <CategoryItems
            category={category}
            setGroup={setGroup}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            onDeleteItem={onDeleteItem}
          />
        ) : (
          // PDF
          <div className='d-flex flex-fill w-100'>
            <PDFViewer style={{ width: "100%" }}>
              <InventoryReportPDF inventory={inventory} client={client} site={site} loggedIn={loggedIn} />
            </PDFViewer>
          </div>
        )
      ) : (
        <div className='d-flex flex-fill centered'>
          <h1 className='display-1'>No Data</h1>
        </div>
      )}

      {/* ADD NEW ITEM POP-UP */}
      <div id='addNewItemPopUp' className='modal fade' data-bs-backdrop='static'>
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div className='modal-content bg-dark text-light'>
            {/* HEADER */}
            <div className='modal-header p-1 px-3'>
              <h1 className='display-6'>Add New Item</h1>
              <button className='btn btn-close bg-danger' data-bs-dismiss='modal' onClick={() => onClearForm()} />
            </div>

            {/* BODY */}
            <div className='modal-body'>
              {/* CATEGORY */}
              <FormInput label='Category' value={category.name} disabled={true} />

              {/* SUB-CATEGORY */}
              <FormInput
                type='select'
                label='Sub-Category'
                defaultLabel='Select Sub-Category'
                value={selectedSub}
                setValue={setSelectedSub}
                options={subCategories.map((x) => ({ label: x, value: x }))}
                required
              />

              {/* OTHER SUB-CATEGORY */}
              {selectedSub === "Other" ? (
                <FormInput
                  formClass='form-control form-control-sm mt-2 mb-3'
                  placeholder='Enter Sub-Category'
                  value={sub}
                  setValue={setSub}
                />
              ) : (
                <div className='mb-3' />
              )}

              {/* NAME */}
              <FormInput label='Name' placeholder='Enter Item Name' value={name} setValue={setName} required />

              <div className='d-flex mb-3'>
                {/* QTY */}
                <FormInput
                  type='number'
                  label='Quantity'
                  placeholder='0'
                  className='me-3'
                  formClass={`form-control form-control-sm ${
                    parseInt(qty) ? (isQtyBelow({ qty, min }) ? "text-danger is-invalid" : "text-success is-valid") : ""
                  }`}
                  value={qty}
                  setValue={setQty}
                />

                {/* MIN */}
                <FormInput type='number' label='Minimum' placeholder='0' value={min} setValue={setMin} />
              </div>

              <div className='d-flex'>
                {/* SERIAL */}
                <FormInput
                  label='Serial'
                  className='me-3'
                  placeholder='Enter Serial Number'
                  value={serial}
                  setValue={setSerial}
                />

                {/* EXP DATE */}
                <FormInput
                  type='date'
                  label='Exp Date'
                  formClass={`form-control form-control-sm w-100 ${
                    expDate !== "" ? (isExpired({ expDate }) ? "text-danger is-invalid" : "text-success is-valid") : ""
                  }`}
                  value={expDate}
                  setValue={setExpDate}
                />
              </div>
            </div>

            {/* FOOTER */}
            <div className='modal-footer'>
              <button
                className='btn btn-success w-100'
                onClick={() => onAddItem()}
                data-bs-dismiss='modal'
                disabled={!name || !qty || (selectedSub === "other" && !sub)}>
                Add Item
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ADD NEW CATEGORY POP-UP */}
      <div id='addNewCategoryPopUp' className='modal fade' data-bs-backdrop='static'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content bg-dark text-light'>
            {/* HEADER */}
            <div className='modal-header p-1 px-3'>
              <h1 className='display-6'>Add New Category</h1>
              <button className='btn btn-close bg-danger' data-bs-dismiss='modal' onClick={() => setCategoryName("")} />
            </div>

            {/* BODY */}
            <div className='modal-body'>
              {/* CATEGORY NAME */}
              <FormInput label='Category Name' value={categoryName} setValue={setCategoryName} required />
            </div>

            {/* FOOTER */}
            <div className='modal-footer'>
              <button
                className='btn btn-success w-100'
                onClick={() => onAddCategory()}
                data-bs-dismiss='modal'
                disabled={!categoryName}>
                Add Category
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE CATEGORY POP-UP */}
      <div id='deleteCategoryPopUp' className='modal fade' data-bs-backdrop='static'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content bg-dark text-light'>
            {/* HEADER */}
            <div className='modal-header p-1 px-3'>
              <h1 className='display-6 user-select-none'>Delete Category</h1>
              <button className='btn btn-close bg-danger' data-bs-dismiss='modal' onClick={() => setCategoryName("")} />
            </div>

            {/* BODY */}
            <div className='modal-body'>
              <div className='d-flex w-100 flex-column align-items-center user-select-none lead mb-3'>
                <span>
                  You will delete
                  <span className='text-danger fw-bold user-select-none'> {category.name}</span>
                </span>
                <span>Re-Type Category Name to Delete</span>
              </div>

              {/* CATEGORY NAME */}
              <FormInput label='Category Name' value={categoryName} setValue={setCategoryName} required />
            </div>

            {/* FOOTER */}
            <div className='modal-footer'>
              <button
                className='btn btn-danger w-100'
                onClick={() => onDeleteCategory()}
                data-bs-dismiss='modal'
                disabled={categoryName !== category.name}>
                Delete Category
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* SUBMIT INVENTORY POP-UP */}
      <div id='submitInventoryPopUp' className='modal fade' data-bs-backdrop='static'>
        <div className='modal-dialog modal-dialog-centered' style={{ maxWidth: window.innerWidth * 0.75 }}>
          <div className='modal-content bg-dark text-light'>
            {/* HEADER */}
            <div className='modal-header p-1 px-3'>
              <h1 className='display-6'>Inventory Report</h1>
              <button className='btn btn-close bg-danger' data-bs-dismiss='modal' onClick={() => setCategoryName("")} />
            </div>

            {/* BODY */}
            <div className='modal-body'>
              <ul className='list-group'>
                {inventory.map((group, key) => {
                  const lowQty = [...group.items.filter(isQtyBelow)].map((item, key) => (
                    <div key={key}>
                      <span className='lead fs-5 fw-bold'>{item.name}</span>
                    </div>
                  ));
                  const expired = [...group.items.filter(isExpired)].map((item, key) => (
                    <div key={key}>
                      <span className='lead fw-bold'>{item.name}</span>
                    </div>
                  ));
                  if (lowQty.length || expired.length)
                    return (
                      <div key={key} className='mb-3'>
                        <h1 className='display-6 text-center'>{group.name}</h1>
                        <div className='d-flex mb-3'>
                          {/* LOW QTY */}
                          {lowQty.length !== 0 && (
                            <div className='d-flex flex-column col text-center pe-2'>
                              <h1 className='display-6 fs-3 fw-bold bg-warning rounded text-black'>Low Quantity</h1>
                              <div className='text-warning'>{lowQty}</div>
                            </div>
                          )}

                          {/* EXPIRED */}
                          {expired.length !== 0 && (
                            <div className='d-flex flex-column col text-center ps-2'>
                              <h1 className='display-6 fs-3 fw-bold bg-danger rounded'>Expired</h1>
                              <div className='text-danger'>{expired}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  else return <div />;
                })}
              </ul>
            </div>

            {/* FOOTER */}
            <div className='modal-footer'>
              <button className='btn btn-success w-100' data-bs-dismiss='modal' disabled>
                Submit Inventory Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
