import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Auth from '../Auth'
import axios from 'axios'
import { apiLink } from './apiConfig'

const initialState = {
  isLoading: false,
  site: {},
  customer: {},
  client: {},
  delivery: {},
  pursuer: {},
  vehicles: {},
  visitor: {},
  form: {},
  report: {},
  log: {},
  key: {},
  user: {},
  loggedIn: { level: '1' },
  topNav: false,
}
axios.defaults.withCredentials = true;

// API LOGIN
export const apiLogin = createAsyncThunk('users/login', async (loginAttempt) => {
  var token = await Auth.getToken()
  const requestOptions = {
    headers: { Authorization: 'Bearer ' + token },
  }

  const loggedIn = await axios
    .post(`${apiLink}/login`, loginAttempt, requestOptions)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API LOGIN: ', err))

  return loggedIn
})

// API FIND ASSIGNED SITES
export const apiFindSiteById = createAsyncThunk('sites/byId', async (id) => {
  const site = await axios
    .get(`${apiLink}/siteById/${id}`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND SITE: ', err))
  return site
})

const selectedSlice = createSlice({
  name: 'selectedSlice',
  initialState,
  reducers: {
    setSite(state, action) {
      state.site = action.payload
    },
    setClient(state, action) {
      state.client = action.payload
    },
    setProtectee(state, action) {
      state.protectee = action.payload
    },
    setDelivery(state, action) {
      state.delivery = action.payload
    },
    setPursuer(state, action) {
      state.pursuer = action.payload
    },
    setVisitor(state, action) {
      state.visitor = action.payload
    },
    setKey(state, action) {
      state.key = action.payload
    },
    setActivityType(state, action) {
      state.activityType = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    setVehicle(state, action) {
      state.vehicle = action.payload
    },
    setForm(state, action) {
      state.form = action.payload
    },
    setReport(state, action) {
      state.report = action.payload
    },
    setEvent(state, action) {
      state.event = action.payload
    },
    setLog(state, action) {
      state.log = action.payload
    },
    setManual(state, action) {
      state.manual = action.payload
    },
    setGroupItem(state, action) {
      state.groupItem = action.payload
    },
    setEmailGroup(state, action) {
      state.emailGroup = action.payload
    },
    setLoggedIn(state, action) {
      state.loggedIn = action.payload
    },
    setTopNav(state, action) {
      state.topNav = action.payload
    },
    clearSelected(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API LOGIN
      .addCase(apiLogin.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(apiLogin.fulfilled, (state, action) => {
        try {
          const { user, token } = action.payload
          if (token && user) {
            state.loggedIn = user
            localStorage.setItem('token', token)
          }
        } catch (err) {
          window.alert('Invalid Login')
        }
        state.isLoading = false
      })
      .addCase(apiLogin.rejected, (state, action) => {
        state.isLoading = false
      })
      // API FIND SITE BY ID
      .addCase(apiFindSiteById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(apiFindSiteById.fulfilled, (state, action) => {
        try {
          state.site = action.payload[0]
        } catch (err) {
          window.alert('Site Error')
        }
        state.isLoading = false
      })
      .addCase(apiFindSiteById.rejected, (state) => {
        state.isLoading = false
      }),
})

export const {
  setSite,
  setClient,
  setDelivery,
  setProtectee,
  setPursuer,
  setVisitor,
  setKey,
  setActivityType,
  setVehicle,
  setUser,
  setForm,
  setEvent,
  setLog,
  setManual,
  setReport,
  setGroupItem,
  setEmailGroup,
  setLoggedIn,
  clearSelected,
} = selectedSlice.actions

export default selectedSlice.reducer
