import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading.js";
import { TableTitleBar } from "../../components/TableTitleBar.js";
import { setVisitor } from "../../reduxPie/selectedSlice.js";

export function Visitors() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, lastRefresh, data } = useSelector((state) => state.persons);
  const Visitors = data ? data.filter((x) => x.type !== "Pursuer") : [];
  const [visitors, setVisitors] = useState(Visitors);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "name", flex: 1, filter: true, sortable: true },
    { field: "driverLicense", flex: 1, filter: true, sortable: true },
    { field: "type", flex: 1, filter: true, sortable: true },
    { field: "group", flex: 1, filter: true, sortable: true },
  ]);

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setVisitor(item.data));
    navigate("/visitors/edit-visitor");
  };

  // SEARCH BY
  useEffect(() => {
    setVisitors(Visitors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // RENDER
  if (isLoading) return <Loading title='Visitors' />;
  return (
    <div className='d-flex flex-fill text-primary flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Visitors'
        addLabel='Visitor'
        addLink='add-visitor'
        hideSearch={true}
        lastRefresh={lastRefresh}
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact rowData={visitors} rowClass='bg-primary' columnDefs={columnDefs} onRowClicked={onViewDetails} />
      </div>
    </div>
  );
}
