import React from 'react';
// import { Messaging } from "./Messaging";
import { ProtectorColors } from './ProtectorColors';

export function HeaderNav() {
  // OFFCANVAS WIDTH
  const width = window.innerWidth * 0.4;

  return (
    <div className="fixed-top" style={{ marginTop: window.innerHeight * 0.095 }}>
      <div className="d-flex justify-content-end me-3">
        {/* PROTECTOR COLORS */}
        <>
          <button
            className="btn btn-secondary border-light"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSSAColors">
            Protector Colors
          </button>
          <div id="offcanvasSSAColors" className="offcanvas offcanvas-end" style={{ width }}>
            <div className="offcanvas-header bg-dark">
              <div className="d-flex flex-fill justify-content-between align-items-center px-3">
                <h1 className="display-6 text-light">Protector Colors</h1>
                <button className="btn-close bg-danger" data-bs-dismiss="offcanvas" />
              </div>
            </div>
            <div className="offcanvas-body">
              <ProtectorColors />
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
