import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addPerson, apiAddPerson } from '../../../reduxPie/personsSlice';
import { AddTitleBar } from '../../../components/AddTitleBar';
import { AddMultipleInputs } from '../../../components/AddMultipleInputs';
import { FormInput } from '../../../components/FormInput';

export function AddVisitor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, client, site } = useSelector((state) => state.selected);
  const [autoAllowToSite, setAutoAllowToSite] = useState(false);

  // STATES FOR ALL INPUTS
  const [mark, setMark] = useState('');
  const [marks, setMarks] = useState([]);
  const [alias, setAlias] = useState('');
  const [aliases, setAliases] = useState([]);
  const [name, setName] = useState('');
  const [type, setType] = useState(0);
  const [group, setGroup] = useState('');
  const [sex, setSex] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [eyeColor, setEyeColor] = useState('');
  const [hairColor, setHairColor] = useState('');
  const [race, setRace] = useState('');
  const [DOB, setDOB] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [driverLicense, setDriverLicense] = useState('');
  const visitorTypes = ['Guest', 'Vendor', 'Staff'];
  const [imagePreview, setImagePreview] = useState("");

  // ON ADD NEW VISITOR
  const onAddVisitor = () => {
    if (name && type && driverLicense) {
      const updatedSite = {
        id: site._id,
        customer: site.customer,
        client: site.client,
        name: site.name,
        phrase: site.phrase,
        active: site.active,
        fullAddress: {
          streetAddress: site.fullAddress.streetAddress,
          apt: site.fullAddress.apt,
          city: site.fullAddress.city,
          state: site.fullAddress.state,
          zip: site.fullAddress.zip,
        },
        firstShift: site.firstShift,
        allowedPersons: site.allowedPersons,
        leadCC: site.leadCC,
        manned: site.manned,
        createdOn: site.createdOn,
        createdBy: site.createdBy,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };

      // NEW VISITOR ( PERSON )
      const newPerson = {
        customer: loggedIn.customer,
        name,
        type,
        group,
        driverLicense,
        photo: imagePreview,
        checkedIn: false,
        recentSite: null,
        fullAddress: {
          streetAddress,
          city,
          state,
          zip,
        },
        sex,
        height,
        weight,
        eyeColor,
        hairColor,
        race,
        DOB,
        aliases,
        marks,
        lastChecked: '',
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };
      dispatch(addPerson(newPerson));
      if (autoAllowToSite) {
        dispatch(apiAddPerson({ newPerson, updatedSite }));
        window.alert(
          `${name} has been added to ${site?.name}\n\nWait 10 seconds\nIf they do not appear, please refresh`
        );
      } else {
        dispatch(apiAddPerson({ newPerson }));
      }
      navigate(-1);
    } else {
      window.alert('Required:\n- Name\n- Type\n- Driver License\n\nPlease Check Form.');
    }
  };

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file);
    }
  }

  return (
    <div className="d-flex flex-fill py-1">
      <div className="card d-flex flex-fill border border-5 border-primary bg-black text-light shadow-lg">
        {/* TITLE BAR */}
        <AddTitleBar onAdd={onAddVisitor} title="Visitor" backLink={-1} />

        {/* VISITOR FORM */}
        <div className="card-body overflow-auto" style={{ height: window.innerHeight * 0.6 }}>
          <div className="d-flex centered">
            {/* LEFT COL */}
            <div className="col me-3">
              {/* PICTURE */}
              <div className="d-flex flex-column centered mb-4">
                <div
                  className="d-flex flex-column mb-3 border border-primary rounded p-3"
                  style={{ height: window.innerHeight * 0.425 }}>
                  {imagePreview && (
                    <img
                      className='img-thumbnail bg-transparent border-0'
                      src={imagePreview}
                      alt='Visitor'
                      style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                    />
                  )}
                </div>

                <div className='input-group input-group-sm mb-2' style={{maxWidth: window.innerWidth * 0.3}}>
                  <span className='input-group-text'>Profile Picture</span>
                  <input
                    className='form-control'
                    type='file'
                    accept='.jpg,.png,.jpeg'
                    onChange={photoUpload}
                  />
                </div>
                <small className='text-white mb-3'>Formats: .jpg .jpeg .png</small>
              </div>
            </div>

            {/* RIGHT COL */}
            <div className="col d-flex flex-column">
              {/* KEY INFO */}
              <h1 className="fw-bold fs-3 text-center mb-2">Key Info</h1>
              <div className="d-flex mb-3">
                <div className="col-2" />

                {/* AUTO ALLOQ TO SITE */}
                <div className="col">
                  <div className="input-group">
                    <button className="btn btn-secondary" onClick={() => setAutoAllowToSite(!autoAllowToSite)}>
                      {`Auto Allow to `}
                      {`${client.name}'s Site: ${site?.name}`}
                    </button>
                    <span className="input-group-text bg-secondary">
                      <input
                        type="checkbox"
                        className="form-check-input my-auto"
                        checked={autoAllowToSite}
                        onChange={() => setAutoAllowToSite(!autoAllowToSite)}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-2" />
              </div>

              <div className="d-flex flex-column">
                <div className="d-flex mb-3">
                  {/* NAME */}
                  <FormInput className="me-3" label="Name *" value={name} setValue={setName} />

                  {/* TYPE */}
                  <FormInput
                    className="me-3"
                    type="select"
                    label="Type *"
                    value={type}
                    setValue={setType}
                    options={visitorTypes.map((x) => ({ label: x, value: x }))}
                    defaultLabel="Select Visitor Type"
                  />

                  {/* DOB */}
                  <FormInput className="" label="DOB" type="date" value={DOB} setValue={setDOB} />
                </div>
                <div className="d-flex mb-3">
                  {/* DRIVERS LICENSE */}
                  <FormInput
                    className="me-3"
                    label="Driver License *"
                    value={driverLicense}
                    setValue={setDriverLicense}
                  />

                  {/* GROUP */}
                  <FormInput className="" label="Group" value={group} setValue={setGroup} />
                </div>
              </div>
              {/* BASIC INFO */}
              <h1 className="fw-bold fs-3 text-center mb-2">Basic Info</h1>
              <div className="d-flex mb-3">
                {/* HEIGHT */}
                <FormInput className="me-3" label="Height" value={height} setValue={setHeight} />

                {/* WEIGHT */}
                <FormInput className="me-3" label="Weight" value={weight} setValue={setWeight} />

                {/* SEX */}
                <FormInput className="" label="Sex" value={sex} setValue={setSex} />
              </div>
              <div className="d-flex mb-3">
                {/* HAIR COLOR */}
                <FormInput className="me-3" label="Hair Color" value={hairColor} setValue={setHairColor} />

                {/* EYE COLOR */}
                <FormInput className="me-3" label="Eye Color" value={eyeColor} setValue={setEyeColor} />

                {/* RACE */}
                <FormInput className="" label="Race" value={race} setValue={setRace} />
              </div>

              {/* HOME ADDRESS */}
              <h1 className="fw-bold fs-3 text-center mb-2">Home Address</h1>
              <div className="d-flex mb-3">
                {/* STREET ADDRESS */}
                <FormInput className="me-3" label="Street Address" value={streetAddress} setValue={setStreetAddress} />

                {/* CITY */}
                <FormInput className="" label="City" value={city} setValue={setCity} />
              </div>

              <div className="d-flex mb-3">
                {/* STATE */}
                <FormInput className="me-3" label="State" value={state} setValue={setState} />

                {/* ZIP CODE */}
                <FormInput className="" label="Zip" value={zip} setValue={setZip} />
              </div>
            </div>
          </div>
          <div className="d-flex">
            {/* MARKS */}
            <AddMultipleInputs
              title="Marks"
              label="Mark"
              value={mark}
              setValue={setMark}
              details={marks}
              setDetails={setMarks}
            />
            <div className="col-1" />
            {/* ALIASES */}
            <AddMultipleInputs
              title="Aliases"
              label="Alias"
              value={alias}
              setValue={setAlias}
              details={aliases}
              setDetails={setAliases}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
