import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FormInput } from '../../../../components/FormInput';
import { apiFindAllKeysBySite, apiUpdateKey, updateKey } from '../../../../reduxPie/keysSlice';
import { addLog, apiAddLog } from '../../../../reduxPie/logsSlice';
import { setKey } from '../../../../reduxPie/selectedSlice';

export function SiteKeys() {
  const table = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, site, client } = useSelector((state) => state.selected);
  const usersSlice = useSelector((state) => state.users);
  const keysSlice = useSelector((state) => state.keys);
  const [keys, setKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);

  // FORM STATE VALUES
  const [checkedOutTo, setCheckedOutTo] = useState('');

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'name', flex: 1, filter: true, sortable: true, checkboxSelection: true },
    { field: 'type', headerName: 'Section', flex: 1, filter: true, sortable: true },
    { field: 'details', flex: 2, filter: true, sortable: true },
    {
      field: 'checkedOutTo',
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: ({ data }) => (data.checkedOut ? data.checkedOutTo : 'Checked In'),
    },
    {
      field: 'lastChecked',
      headerName: 'Last Checked On',
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: ({ data }) => (data.lastChecked ? data.lastChecked : 'N/A'),
    },
    { field: 'checkedByName', headerName: 'Last Checked By', flex: 1, filter: true, sortable: true },
  ]);

  // ROW CLASS RULES
  const rowClassRules = {
    'bg-success': 'data.checkedOut === true',
    'bg-danger': ({ data }) => isOverdue(data),
  };

  // IS OVERDUE
  const isOverdue = (key) => {
    // [0] => HR, [1] => MIN
    let TimeLimit = key.timeLimit.split(':');
    let HR = Number(TimeLimit[0]) * 60 * 1000;
    let MIN = Number(TimeLimit[1]) * 60 * 1000;
    let MaxChecked = new Date(key.lastChecked).getTime() + HR + MIN;
    let NOW = new Date().getTime();

    return MaxChecked <= NOW && HR + MIN !== 0 && key.checkedOut;
  };

  // ON REFRESH KEYS
  const onRefreshKeys = () => {
    dispatch(apiFindAllKeysBySite({ site: site._id }));
  };

  // ON VIEW DETAILS
  const onViewDetails = () => {
    const selectedRows = table.current.api.getSelectedRows();
    if (selectedRows.length) {
      dispatch(setKey(selectedRows[0]));
      navigate('edit-key');
    }
  };

  // ON CHECK IN/OUT ( UPDATE )
  const onCheckInOut = () => {
    // NEW LOG
    const newLog = {
      customer: loggedIn.customer,
      client: client._id,
      site: site._id,
      key: selectedKey._id,
      data: `@${site?.name}: ${selectedKey.name} - ${
        selectedKey.checkedOut ? `Checked IN` : `Checked OUT - ${checkedOutTo}`
      }${isOverdue(selectedKey) ? ' - **OVERDUE**' : ''}`,
      submittedOn: new Date().toLocaleString(),
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
    };

    // UPDATED KEY
    let updatedKey = {
      id: selectedKey._id,
      customer: selectedKey.customer,
      client: selectedKey.client,
      site: selectedKey.site,
      name: selectedKey.name,
      details: selectedKey.details,
      timeLimit: selectedKey.timeLimit,
      checkedOut: !selectedKey.checkedOut,
      checkedOutTo,
      lastChecked: new Date().toLocaleString(),
      checkedBy: loggedIn._id,
      createdBy: selectedKey.createdBy,
      createdOn: selectedKey.createdOn,
      modifiedBy: selectedKey.modifiedBy,
      modifiedOn: selectedKey.modifiedOn,
    };
    dispatch(
      updateKey({
        ...selectedKey,
        checkedOut: !selectedKey.checkedOut,
        checkedOutTo,
        lastChecked: new Date().toLocaleString(),
        checkedBy: loggedIn._id,
      })
    );
    dispatch(apiUpdateKey(updatedKey));
    dispatch(addLog(newLog));
    dispatch(apiAddLog(newLog));
    window.alert(
      `${selectedKey.name} has been ${selectedKey.checkedOut ? 'checked IN' : `checked OUT to ${checkedOutTo}`}${
        isOverdue(selectedKey) ? ' **OVERDUE**' : ''
      }`
    );
    setCheckedOutTo('');
  };

  // AUTO FETCH KEYS
  useEffect(() => {
    if (!keysSlice.data.length || site._id !== keysSlice.data[0].site) onRefreshKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REDUX FORCED REFRESH
  useEffect(() => {
    const Keys = keysSlice.data.map((item) => {
      let user = usersSlice.data.find((x) => x._id === item.checkedBy);

      return {
        ...item,
        checkedByName: user ? user.name : 'N/A',
      };
    });
    setKeys(Keys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysSlice.data]);

  return (
    <div className="d-flex flex-fill flex-column">
      {/* TITLE BAR */}
      <div className="d-flex justify-content-between align-items-center px-3 py-1">
        <div className="d-flex align-items-center">
          {/* TITLE */}
          <h1 className="display-6 fs-2 fw-bold me-4">Key Management</h1>

          {/* REFRESH BTN */}
          <button className="btn btn-sm btn-outline-dark border-0" onClick={() => onRefreshKeys()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
            <small className="p-3">
              {`${Math.floor((new Date().getTime() - new Date(keysSlice.lastRefresh).getTime()) / 1000 / 60)} mins ${
                Math.floor((new Date().getTime() - new Date(keysSlice.lastRefresh).getTime()) / 1000) % 60
              } secs ago`}
            </small>
          </button>
        </div>

        {/* CHECK IN/OUT BTN */}
        <button
          className="btn btn-sm btn-success"
          data-bs-toggle="modal"
          data-bs-target="#checkInOutPopup"
          onClick={() => {
            let Key = table.current.api.getSelectedRows()[0];
            if (Key) {
              setSelectedKey(Key);
              setCheckedOutTo(Key.checkedOutTo);
            }
          }}>
          Check Key In/Out
        </button>

        {/* EDIT BTN */}
        <button className="btn btn-sm btn-secondary" onClick={() => onViewDetails()}>
          Edit Key
        </button>

        {/* ADD BTN */}
        <Link to="add-key" className="btn btn-sm btn-primary">
          Add New Key
        </Link>
      </div>

      {/* MODAL */}
      <div id="checkInOutPopup" className="modal fade" data-bs-backdrop="static">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-dark text-light">
            <div className="modal-header">
              <h1 className="display-6">{selectedKey ? selectedKey.name : 'No Key Selected'}</h1>
              <button
                className="btn-close bg-danger me-1"
                data-bs-dismiss="modal"
                onClick={() => setCheckedOutTo('')}
              />
            </div>
            <div className="modal-body">
              {/* CHECKED OUT TO */}
              <div className="d-flex flex-column mb-3">
                <label className="fw-bold lead">
                  Check {selectedKey ? (selectedKey.checkedOut ? 'In From' : 'Out To') : ''}
                </label>
                <div className="input-group">
                  <input
                    className="form-control"
                    value={checkedOutTo}
                    disabled={selectedKey ? (selectedKey.checkedOut ? true : false) : true}
                    onChange={(x) => setCheckedOutTo(x.target.value)}
                  />
                  <button
                    className="btn btn-danger"
                    disabled={selectedKey ? (selectedKey.checkedOut ? true : false) : true}
                    onClick={() => setCheckedOutTo('')}>
                    Clear
                  </button>
                </div>
              </div>

              {/* TIME LIMIT */}
              <h4 className="fw-bold text-center">Time Limit</h4>
              <div className="d-flex mb-3">
                {/* HR */}
                <FormInput
                  label="Hour(s)"
                  placeholder="00"
                  className="me-3"
                  value={selectedKey ? selectedKey.timeLimit.split(':')[0] : '00'}
                  disabled={true}
                />

                {/* MIN */}
                <FormInput
                  label="Minute(s)"
                  placeholder="00"
                  className=""
                  value={selectedKey ? selectedKey.timeLimit.split(':')[1] : '00'}
                  disabled={true}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex flex-fill centered">
                <button
                  className="btn btn-success w-75"
                  onClick={() => onCheckInOut()}
                  data-bs-dismiss="modal"
                  disabled={selectedKey && (checkedOutTo.length >= 2 || selectedKey.checkedOut) ? false : true}>
                  Check {selectedKey ? (selectedKey.checkedOut ? 'In' : 'Out') : ''}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
        <AgGridReact
          ref={table}
          rowData={keys}
          columnDefs={columnDefs}
          rowClassRules={rowClassRules}
          rowSelection="single"
        />
      </div>
    </div>
  );
}
