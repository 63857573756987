import React from 'react';
import { Link } from 'react-router-dom';

export function EditTitleBar({ disabled, setDisabled, onUpdate, title, backLink = -1, onCancel }) {
  return (
    <div className="d-flex justify-content-between align-items-center px-3 py-1">
      {/* BACK & SAVE BTN */}
      {disabled ? (
        <Link to={backLink} className="btn btn-sm btn-dark border-light">
          Back
        </Link>
      ) : (
        <button className="btn btn-sm btn-success border-light" onClick={() => onUpdate()}>
          Save
        </button>
      )}

      {/* TITLE */}
      <h1 className="display-6 fw-bold">{disabled ? `${title}'s Details` : `Edit ${title}`}</h1>

      {/* EDIT & CANCEL BTN */}
      {disabled ? (
        <button className="btn btn-sm btn-secondary border-light" onClick={() => setDisabled(!disabled)}>
          Edit
        </button>
      ) : (
        <button
          className="btn btn-sm btn-danger border-light"
          onClick={() => (onCancel ? onCancel() : setDisabled(!disabled))}>
          Cancel
        </button>
      )}
    </div>
  );
}
