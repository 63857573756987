import React from "react";
import logo from "../../../../../media/logo.png";
import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { isExpired, isQtyBelow } from "../SiteInventory";

export const InventoryReportPDF = ({ inventory, client, site, loggedIn }) => {
  return (
    <Document title={`${site?.name}InventoryReport`}>
      <Page>
        {/* DOCUMENT HEADER */}
        <View style={{ flexDirection: "row", backgroundColor: "black" }}>
          {/* LOGO */}
          <View style={{ width: 250 }}>
            <Image src={logo} />
          </View>
          {/* TITLE */}
          <View style={{ width: `100%`, justifyContent: "center", alignItems: "center", color: "white" }}>
            <Text style={{ fontSize: 20, marginBottom: 4 }}>
              ( {client.name} ) {site?.name}
            </Text>
            <Text style={{ fontSize: 16, marginBottom: 4 }}>Inventory Report by {loggedIn.name}</Text>
            <Text style={{ fontSize: 14 }}>{new Date().toLocaleDateString()}</Text>
          </View>
        </View>

        {/* DOCUMENT BODY */}
        <View style={{ paddingVertical: 10 }}>
          {inventory.map((group, key) => {
            const isQtyBelowList = group.items.filter(isQtyBelow);
            const expiredList = group.items.filter(isExpired);
            // LOW QTY ITEM
            const lowQty = [...group.items.filter(isQtyBelow)].map((item, key) => {
              if (key % 2 === 0)
                return (
                  <View key={key} style={[styles.itemRow, { backgroundColor: "#E0E0E0" }]}>
                    <Text style={styles.itemName}>{`${item.name}  `}</Text>
                    <Text style={styles.itemInfo}>
                      {item.qty}/{item.min}
                    </Text>
                  </View>
                );
              else
                return (
                  <View key={key} style={styles.itemRow}>
                    <Text style={styles.itemName}>{`${item.name}  `}</Text>
                    <Text style={styles.itemInfo}>
                      {item.qty}/{item.min}
                    </Text>
                  </View>
                );
            });
            // EXPIRED ITEM
            const expired = [...group.items.filter(isExpired)].map((item, key) => {
              if (key % 2 === 0)
                return (
                  <View key={key} style={[styles.itemRow, { backgroundColor: "#E0E0E0" }]}>
                    <Text style={styles.itemName}>{`${item.name} `}</Text>
                    <Text style={styles.itemInfo}>{new Date(`${item.expDate}T00:00:00`).toLocaleDateString()}</Text>
                  </View>
                );
              else
                return (
                  <View key={key} style={styles.itemRow}>
                    <Text style={styles.itemName}>{`${item.name} `}</Text>
                    <Text style={styles.itemInfo}>{new Date(`${item.expDate}T00:00:00`).toLocaleDateString()}</Text>
                  </View>
                );
            });
            const inStock = [...group.items.filter((a) => {
              return !expiredList.includes(a) && !isQtyBelowList.includes(a)
            })].map((item, key) => {
              if (key % 2 === 0)
                return (
                  <View key={key} style={[styles.itemRow, { backgroundColor: "#E0E0E0" }]}>
                    <Text style={styles.itemName}>{`${item.name}  `}</Text>
                    <Text style={styles.itemInfo}>
                      {item.qty}/{item.min}
                    </Text>
                  </View>
                );
              else
                return (
                  <View key={key} style={styles.itemRow}>
                    <Text style={styles.itemName}>{`${item.name}  `}</Text>
                    <Text style={styles.itemInfo}>
                      {item.qty}/{item.min}
                    </Text>
                  </View>
                );
            });
            if (lowQty.length || expired.length || inStock.length)
              return (
                // ----- RENDER
                <View
                  key={key}
                  style={{
                    marginBottom: 20,
                    marginHorizontal: 20,
                    paddingVertical: 10,
                  }}
                  wrap={false}>
                  {/* CATEGORY NAME */}
                  <Text style={{ fontSize: 20, marginBottom: 10, textDecoration: "underline", textAlign: "center" }}>
                    {group.name}
                  </Text>

                  {/* CATEGORY REPORT */}
                  <View style={{ flexDirection: "row" }}>
                    {/* IN STOCK */}
                    {inStock.length !== 0 && (
                      <View style={styles.col}>
                        <Text style={[styles.lowQtyTitle, { backgroundColor: "#ececec" }]}>In Stock</Text>
                        {inStock}
                      </View>
                    )}
                    {/* LOW QTY */}
                    {lowQty.length !== 0 && (
                      <View style={styles.col}>
                        <Text style={styles.lowQtyTitle}>Low Quantity</Text>
                        {lowQty}
                      </View>
                    )}

                    {/* EXPIRED */}
                    {expired.length !== 0 && (
                      <View style={styles.col}>
                        <Text style={styles.expTitle}>Expired</Text>
                        {expired}
                      </View>
                    )}
                  </View>
                </View>
              );
            else return <View />;
          })}
        </View>
      </Page>
    </Document>
  );
};

// PDF STYLESHEET
const styles = StyleSheet.create({
  docTitleHeader: { width: `100%`, justifyContent: "center", alignItems: "center", color: "white" },
  categoryTitle: {},
  subCategoryTitle: {},
  lowQtyTitle: {
    fontSize: 14,
    textAlign: "center",
    backgroundColor: "#FFC107",
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderRadius: 6,
    marginBottom: 6,
  },
  expTitle: {
    fontSize: 14,
    textAlign: "center",
    backgroundColor: "#DC3545",
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderRadius: 6,
    marginBottom: 6,
    color: "white",
  },
  itemRow: { flexDirection: "row", justifyContent: "space-between", padding: 8 },
  itemName: { fontSize: 12 },
  itemInfo: { fontSize: 12 },
  col: { width: `100%`, marginHorizontal: 3 },
});
