import React from "react";
import { Link } from "react-router-dom";

export function AddTitleBar({ onAdd = () => {}, title = "", backLink = "/" }) {
  return (
    <div className='d-flex justify-content-between align-items-center px-3 py-1'>
      {/* SAVE BTN */}
      <button className='btn btn-sm btn-success border-2 border-light' onClick={() => onAdd()}>
        Save
      </button>

      {/* TITLE */}
      <h1 className='display-6 fw-bold'>Add New {title}</h1>

      {/* CANCEL BTN */}
      <Link to={backLink} className='btn btn-sm btn-danger border-2 border-light'>
        Cancel
      </Link>
    </div>
  );
}
