import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./reduxStore";
import { Loading } from "./components/Loading";
import { BrowserRouter } from "react-router-dom";

const persistor = persistStore(store);

// ------------ WILL WIPE LOCAL STORAGE EVERY 12 HOURS
export const forcedLogOut = () => {
  const rightNow = new Date().getTime();
  // HR * MIN * SECONDS * MILLSECONDS
  const lastPurge = new Date(parseInt(localStorage.getItem("lastPurge"))).getTime() + 12 * 60 * 60 * 1000;

  if (lastPurge < rightNow || !localStorage.getItem("lastPurge")) {
    const res = window.confirm(
      "*** FORCED LOG OUT WARNING ***\n\nYou have been logged in for 12 hours\nWould you like to extend your session?"
    );
    if (res) localStorage.setItem("lastPurge", new Date().getTime());
    else {
      window.alert("*** LOGGING OUT***");
      persistor.purge();
      setTimeout(window.location.reload(), 500);
    }
  }
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loading title='C24' />} persistor={persistor}>
        <BrowserRouter>
        <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
