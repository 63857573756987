/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditTitleBar } from "../../../components/EditTitleBar";
import { apiDeleteNDA, apiDeleteProfilePictures, apiUpdatePerson, updatePerson } from "../../../reduxPie/personsSlice";
import { AuditVisitor } from "./AuditVisitor";
import { AddMultipleInputs } from "../../../components/AddMultipleInputs";
import { FormInput } from "../../../components/FormInput";
import { s3Link } from "../../../reduxPie/apiConfig";

export function EditVisitor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn, visitor } = useSelector((state) => state.selected);
  const [disabled, setDisabled] = useState(true);
  const visitorTypes = ["Guest", "Vendor", "Staff"];
  const [view, setView] = useState("details");
  const btnSelected = "btn btn-sm btn-dark";
  const btnUnselected = "btn btn-sm btn-outline-secondary";

  const profileImage = visitor.photo;
  // STATES FOR ALL INPUTS
  const [mark, setMark] = useState("");
  const [marks, setMarks] = useState(visitor.marks);
  const [alias, setAlias] = useState("");
  const [aliases, setAliases] = useState(visitor.aliases);
  const [name, setName] = useState(visitor.name);
  const [type, setType] = useState(visitor.type);
  const [group, setGroup] = useState(visitor.group);
  const [sex, setSex] = useState(visitor.sex);
  const [height, setHeight] = useState(visitor.height);
  const [weight, setWeight] = useState(visitor.weight);
  const [eyeColor, setEyeColor] = useState(visitor.eyeColor);
  const [hairColor, setHairColor] = useState(visitor.hairColor);
  const [race, setRace] = useState(visitor.race);
  const [DOB, setDOB] = useState(visitor.DOB);
  const [streetAddress, setStreetAddress] = useState(visitor.fullAddress.streetAddress);
  const [city, setCity] = useState(visitor.fullAddress.city);
  const [state, setState] = useState(visitor.fullAddress.state);
  const [zip, setZip] = useState(visitor.fullAddress.zip);
  const [driverLicense, setDriverLicense] = useState(visitor.driverLicense);
  const [NDAFile, setNDAFile] = useState("");
  const [active] = useState(true);
  const [imagePreview, setImagePreview] = useState("");

  let updatedPerson = {
    id: visitor._id,
    customer: visitor.customer,
    name,
    type,
    group,
    photo: imagePreview,
    driverLicense,
    checkedIn: visitor.checkedIn,
    recentSite: visitor.recentSite,
    fullAddress: {
      streetAddress,
      city,
      state,
      zip,
    },
    sex,
    height,
    weight,
    eyeColor,
    hairColor,
    race,
    DOB,
    aliases,
    marks,
    lastChecked: visitor.lastChecked,
    active,
    createdOn: visitor.createdOn,
    createdBy: visitor.createdBy,
    modifiedOn: new Date().toISOString(),
    modifiedBy: loggedIn._id,
  };

  // ON EDIT VISITOR
  const onEditVisitor = () => {
    if (name && type && driverLicense) {
      // IMG UPDATED
      if (imagePreview || imagePreview === undefined) {
        dispatch(
          updatePerson({
            ...updatedPerson,
            name,
            type,
            group,
            photo: imagePreview,
            driverLicense,
            fullAddress: {
              streetAddress,
              city,
              state,
              zip,
            },
            sex,
            height,
            weight,
            eyeColor,
            hairColor,
            race,
            DOB,
            aliases,
            marks,
            active,
            modifiedOn: new Date().toISOString(),
            modifiedBy: loggedIn._id,
          })
        );
        dispatch(apiUpdatePerson({ updatedPerson }));
        window.alert(`Visitor ${name} Has Been Updated`);
        navigate(-1);
      }
      // NDA UPDATED
      else if (NDAFile) {
        let formData = new FormData();
        formData.append("collection", "Visitors");
        formData.append("id", visitor._id);
        formData.append("type", "files");
        formData.append("file", NDAFile, "NDA.pdf");
        dispatch(
          updatePerson({
            ...visitor,
            name,
            type,
            group,
            driverLicense,
            fullAddress: {
              streetAddress,
              city,
              state,
              zip,
            },
            sex,
            height,
            weight,
            eyeColor,
            hairColor,
            race,
            DOB,
            aliases,
            marks,
            active,
            modifiedOn: new Date().toISOString(),
            modifiedBy: loggedIn._id,
          })
        );
        dispatch(apiUpdatePerson({ updatedPerson, formData }));
        window.alert(`Visitor ${name} Has Been Updated`);
        navigate(-1);
      }
      // PROFILE UPDATED
      else {
        dispatch(
          updatePerson({
            ...visitor,
            name,
            type,
            group,
            driverLicense,
            fullAddress: {
              streetAddress,
              city,
              state,
              zip,
            },
            sex,
            height,
            weight,
            eyeColor,
            hairColor,
            race,
            DOB,
            aliases,
            marks,
            active,
            modifiedOn: new Date().toISOString(),
            modifiedBy: loggedIn._id,
          })
        );
        dispatch(apiUpdatePerson({ updatedPerson, formData: null }));
        window.alert(`Visitor ${name} Has Been Updated`);
        navigate(-1);
      }
    } else {
      window.alert(`Required:\n- Name\n- Type\n- Driver License`);
    }
  };

  // ON IMG DELETE
  const onImgDelete = () => {
    const res = window.confirm(
      `*** DELETE WARNING ***\n\nYou will delete Profile Picture(s) for ${visitor?.name}.\nAre you sure?`
    );

    if (res) {
      dispatch(updatePerson({
        ...visitor,
        photo: undefined,
      }));
      dispatch(apiUpdatePerson({updatedPerson}));
      window.alert(
        `*** DELETE CONFIRMATION ***\n\nProfile Picture(s) for ${visitor?.name} has been archived.\nContact Tech Support if a mistake has been made.`
      );
      navigate(-1)
    } else {
      window.alert("*** DELETE CANCELLED ***");
    }
  };

  // ON NDA UPLOAD
  const onNDAUpload = (e) => {
    const file = e.target.files[0] ? e.target.files[0] : "";
    setNDAFile(file ? file : "");
  };

  // ON NDA DELETE
  const onNDADelete = () => {
    const res = window.confirm(`*** DELETE WARNING ***\n\nYou will delete NDA for ${visitor?.name}.\nAre you sure?`);

    if (res) {
      dispatch(apiDeleteNDA({ id: visitor?._id }));
      window.alert(
        `*** DELETE CONFIRMATION ***\n\nNDA for ${visitor?.name} has been archived.\nContact Tech Support if a mistake has been made.`
      );
      navigate(-1);
    } else {
      window.alert("*** DELETE CANCELLED ***");
    }
  };

  // ON DELETE VISITOR PROFILE
  const onDeleteVisitorProfile = () => {
    const updatedPerson = {
      id: visitor?._id,
      customer: visitor?.customer,
      name: visitor?.name,
      type: visitor?.type,
      group: visitor?.group,
      driverLicense: visitor?.driverLicense,
      checkedIn: visitor?.checkedIn,
      recentSite: visitor?.recentSite,
      fullAddress: {
        streetAddress: visitor?.fullAddress?.streetAddress,
        city: visitor?.fullAddress?.city,
        state: visitor?.fullAddress?.state,
        zip: visitor?.fullAddress?.zip,
      },
      sex: visitor?.sex,
      height: visitor?.height,
      weight: visitor?.weight,
      eyeColor: visitor?.eyeColor,
      hairColor: visitor?.hairColor,
      race: visitor?.race,
      DOB: visitor?.DOB,
      aliases: visitor?.aliases,
      marks: visitor?.marks,
      lastChecked: visitor?.lastChecked,
      active: false,
      createdOn: visitor?.createdOn,
      createdBy: visitor?.createdBy,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn?._id,
    };

    const res = window.confirm(
      `*** DELETE WARNING ***\n\nYou will delete ${visitor?.name}'s entire Visitor Profile.\nAre you sure?`
    );

    if (res) {
      dispatch(apiDeleteProfilePictures({ id: visitor?._id }));
      dispatch(apiDeleteNDA({ id: visitor?._id }));
      dispatch(apiUpdatePerson({ updatedPerson, formData: null }));
      window.alert(
        `*** DELETE CONFIRMATION ***\n\n${visitor?.name}'s Visitor Profile has been archived.\nContact Tech Support if a mistake has been made.`
      );
      navigate(-1);
    } else {
      window.alert("*** DELETE CANCELLED ***");
    }
  };

    // ON IMG UPLOAD
    const photoUpload = (e) => {
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
      if (reader !== undefined && file !== undefined) {
        reader.onloadend = () => {
          setImagePreview(reader.result)
        }
        reader.readAsDataURL(file);
      }
    }

  // RENDER
  return (
    <div className='d-flex flex-fill py-1'>
      <div className='card d-flex flex-fill border border-primary border-5 bg-black text-light shadow-lg '>
        {/* TITLE BAR */}
        <EditTitleBar
          title={name}
          disabled={disabled}
          setDisabled={setDisabled}
          onUpdate={onEditVisitor}
          backLink={-1}
        />

        {/* VISITOR AUDIT LOG BTN */}
        <div className='d-flex centered'>
          <div className='btn-group bg-light rounded w-75 mb-3'>
            <button className={view === "details" ? btnSelected : btnUnselected} onClick={() => setView("details")}>
              Details
            </button>
            <button className={view === "audit" ? btnSelected : btnUnselected} onClick={() => setView("audit")}>
              Visitor Activity Audit
            </button>
            <button className={view === "NDA" ? btnSelected : btnUnselected} onClick={() => setView("NDA")}>
              NDA
            </button>
          </div>
        </div>

        {/* VISITOR FORM */}
        {view === "details" && (
          <div className='card-body overflow-auto' style={{ height: window.innerHeight * 0.6 }}>
            <div className='d-flex centered'>
              {/* LEFT COL */}
              <div className='col me-3'>
                {/* PICTURE */}
                <div className='d-flex flex-column centered mb-3'>
                  <div
                    className='d-flex flex-column mb-3 overflow-auto border border-primary rounded p-3'
                    style={{ height: window.innerHeight * 0.425 }}>
                    {imagePreview || profileImage ? (
                      <img
                        className='img-thumbnail bg-transparent border-0'
                        src={imagePreview || profileImage}
                        alt='...'
                        style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                      />
                    ) : (
                      <>
                        <img
                          src={`https://${s3Link}/Visitors/${visitor._id}/images//user.jpg`}
                          title='.jpg'
                          alt=''
                          className='img-thumbnail bg-transparent border-0'
                          style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                        />
                        <img
                          src={`https://${s3Link}/Visitors/${visitor._id}/images//user.jpeg`}
                          title='.jpeg'
                          alt=''
                          className='img-thumbnail bg-transparent border-0'
                          style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                        />
                        <img
                          src={`https://${s3Link}/Visitors/${visitor._id}/images//user.png`}
                          title='.png'
                          alt=''
                          className='img-thumbnail bg-black border-0'
                          style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                        />
                      </>
                    )}
                  </div>

                  {/* IMG UPLOAD */}
                  <div className='d-flex flex-column w-100' style={{maxWidth: window.innerWidth * 0.3}}>
                    <span className='fw-bold lead'>Profile Picture</span>
                    <div className='input-group'>
                      <input
                        className='form-control form-control-sm'
                        type='file'
                        accept='.jpg,.png,.jpeg'
                        onChange={photoUpload}
                        disabled={visitor._id ? disabled : true}
                      />
                      <button
                        className='btn btn-sm btn-danger'
                        style={{ width: "35%" }}
                        onClick={onImgDelete}
                        disabled={!profileImage || disabled}>
                        Delete Profile Picture(s)
                      </button>
                    </div>
                  </div>
                  <small className='text-white mb-3'>Formats: .jpg .jpeg .png</small>
                </div>
              </div>

              {/* RIGHT COL */}
              <div className='col'>
                {/* KEY INFO */}
                <h1 className='fw-bold fs-3 text-center mb-2'>Key Info</h1>
                <div className='d-flex flex-column'>
                  <div className='d-flex mb-3'>
                    {/* NAME */}
                    <FormInput className='me-3' label='Name *' value={name} setValue={setName} disabled={disabled} />

                    {/* TYPE */}
                    <FormInput
                      className='me-3'
                      type='select'
                      label='Type *'
                      value={type}
                      setValue={setType}
                      options={visitorTypes.map((x) => ({ label: x, value: x }))}
                      disabled={disabled}
                      defaultLabel='Select Visitor Type'
                    />

                    {/* DOB */}
                    <FormInput className='' label='DOB' type='date' value={DOB} setValue={setDOB} disabled={disabled} />
                  </div>
                  <div className='d-flex mb-3'>
                    {/* DRIVERS LICENSE */}
                    <FormInput
                      className='me-3'
                      label='Driver License *'
                      value={driverLicense}
                      setValue={setDriverLicense}
                      disabled={disabled}
                    />

                    {/* GROUP */}
                    <FormInput className='me-3' label='Group' value={group} setValue={setGroup} disabled={disabled} />
                  </div>
                </div>
                {/* BASIC INFO */}
                <h1 className='fw-bold fs-3 text-center mb-2'>Basic Info</h1>
                <div className='d-flex mb-3'>
                  {/* HEIGHT */}
                  <FormInput className='me-3' label='Height' value={height} setValue={setHeight} disabled={disabled} />

                  {/* WEIGHT */}
                  <FormInput className='me-3' label='Weight' value={weight} setValue={setWeight} disabled={disabled} />

                  {/* SEX */}
                  <FormInput className='' label='Sex' value={sex} setValue={setSex} disabled={disabled} />
                </div>
                <div className='d-flex mb-3'>
                  {/* HAIR COLOR */}
                  <FormInput
                    className='me-3'
                    label='Hair Color'
                    value={hairColor}
                    setValue={setHairColor}
                    disabled={disabled}
                  />

                  {/* EYE COLOR */}
                  <FormInput
                    className='me-3'
                    label='Eye Color'
                    value={eyeColor}
                    setValue={setEyeColor}
                    disabled={disabled}
                  />

                  {/* RACE */}
                  <FormInput className='' label='Race' value={race} setValue={setRace} disabled={disabled} />
                </div>

                {/* HOME ADDRESS */}
                <h1 className='fw-bold fs-3 text-center mb-2'>Home Address</h1>
                <div className='d-flex mb-3'>
                  {/* STREET ADDRESS */}
                  <FormInput
                    className='me-3'
                    label='Street Address'
                    value={streetAddress}
                    setValue={setStreetAddress}
                    disabled={disabled}
                  />

                  {/* CITY */}
                  <FormInput className='' label='City' value={city} setValue={setCity} disabled={disabled} />
                </div>

                <div className='d-flex mb-3'>
                  {/* STATE */}
                  <FormInput className='me-3' label='State' value={state} setValue={setState} disabled={disabled} />

                  {/* ZIP CODE */}
                  <FormInput className='' label='Zip' value={zip} setValue={setZip} disabled={disabled} />
                </div>
              </div>
            </div>
            <div className='d-flex'>
              {/* MARKS */}
              <AddMultipleInputs
                disabled={disabled}
                title='Marks'
                label='Mark'
                value={mark}
                setValue={setMark}
                details={marks}
                setDetails={setMarks}
              />
              <div className='col-1' />
              {/* ALIASES */}
              <AddMultipleInputs
                disabled={disabled}
                title='Aliases'
                label='Alias'
                value={alias}
                setValue={setAlias}
                details={aliases}
                setDetails={setAliases}
              />
            </div>

            {/* DELETE ENTIRE PROFILE BTN */}
            <div className='d-grid m-5'>
              <button className='btn btn-danger' onClick={() => onDeleteVisitorProfile()} disabled={disabled}>
                Delete {visitor?.name}'s Profile
              </button>
            </div>
          </div>
        )}

        {/* VISITOR ACTIVITY AUDIT */}
        {view === "audit" && <AuditVisitor visitor={visitor} />}

        {/* NDA */}
        {view === "NDA" && (
          <div className='d-flex flex-column flex-fill'>
            {/* NDA UPLOAD */}
            <div className='d-flex justify-content-around'>
              <h1 className='display-6 fw-bold'>NDA</h1>
              {/* FILE UPLOAD */}
              <div className='d-flex flex-column centered'>
                <input
                  className='form-control form-control-sm '
                  type='file'
                  accept='.pdf'
                  onChange={onNDAUpload}
                  disabled={visitor._id ? disabled : true}
                />
                <small className='text-white'>Formats: .pdf</small>
              </div>

              {/* NDA DELETE BTN */}
              <button
                className='btn btn-sm btn-danger align-self-center'
                onClick={() => onNDADelete()}
                disabled={disabled}>
                Delete NDA
              </button>
            </div>

            <iframe
              src={`https://${s3Link}/Visitors/${visitor._id}/files//NDA.pdf`}
              className='d-flex flex-fill'
              title='NDA'
            />
          </div>
        )}
      </div>
    </div>
  );
}
