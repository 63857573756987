import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../components/Loading'
import { TableTitleBar } from '../../../../components/TableTitleBar'
import { apiFindOneClient } from '../../../../reduxPie/clientsSlice'
import { setClient } from '../../../../reduxPie/selectedSlice'
import { apiFindAllUsers } from '../../../../reduxPie/usersSlice'
import { ProtecteeRow } from './components/ProtecteeRow'
import { ProtectorRow } from './components/ProtectorRow'
import sitesSlice from '../../../../reduxPie/sitesSlice'

export function ClientStatus() {
  const dispatch = useDispatch()
  const { client } = useSelector((state) => state.selected)
  const { site } = useSelector((state) => state.selected)
  const usersSlice = useSelector((state) => state.users)
  const clientsSlice = useSelector((state) => state.clients)
  const [submittedOn, setSubmittedOn] = useState(new Date().toLocaleString())
  const AssignedProtectors = usersSlice.data
    .filter(({ assignedClients }) => {
      return assignedClients.includes(client._id)
    })
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
  // eslint-disable-next-line no-unused-vars
  const [protectors, setProtectors] = useState(AssignedProtectors)
  // ON REFRESH CLIENT ( FIND ONE )
  const onRefreshClient = () => {
    dispatch(apiFindOneClient({ id: client._id }))
  }

  // ON REFRESH PROTECTORS ( FIND MANY )
  const onRefreshProtectors = () => {
    dispatch(apiFindAllUsers())
  }

  const onSubmit = (timeSubmitted) => {
    setSubmittedOn(timeSubmitted)
  }

  // AUTO UPDATE CLIENT
  useEffect(() => {
    dispatch(setClient(clientsSlice.data.find((x) => x._id === client._id)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsSlice.data, client, site, sitesSlice.data])

  // AUTO UPDATE PROTECTORS
  useEffect(() => {
    if (!usersSlice.data.length) dispatch(apiFindAllUsers())
    setProtectors(
      usersSlice.data
        .filter(({ assignedClients }) => {
          return assignedClients.includes(client._id)
        })
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersSlice.data])

  if (usersSlice.isLoading) return <Loading title='Protectors' />
  else if (clientsSlice.isLoading) return <Loading title='Protectees' />

  return (
    <div className='container-fluid'>
      {/* TIME OF STATUS CHANGE */}
      <div className='d-flex centered'>
        {/* SUBMITTED ON */}
        <div className='d-flex flex-column'>
          <h1 className='lead fw-bold text-center'>Time of Status Change</h1>
          <input
            className='form-control form-control-lg border-dark'
            value={submittedOn}
            onChange={(x) => setSubmittedOn(x.target.value)}
          />
        </div>
      </div>

      <div className='row'>
        {/* PROTECTEES */}
        <div className='col'>
          {/* TITLE BAR */}
          <TableTitleBar
            title='Protectees'
            onRefresh={onRefreshClient}
            lastRefresh={clientsSlice.lastRefresh}
            hideSearch={true}
          />

          {/* TABLE HEADER */}
          <table className='table table-dark'>
            <thead>
              <tr className='d-flex'>
                <th className='col-4'>Name</th>
                <th className='col'>Notes</th>
                <th className='col-2'>Status</th>
              </tr>
            </thead>
          </table>

          {/* PROTECTEE TABLE BODY */}
          <table className='table table-dark table-striped'>
            <tbody>
              <ProtecteeRow protectee={client} submittedOn={submittedOn} onSubmit={onSubmit} />
              {client.protectees.map((item, key) => (
                <ProtecteeRow key={key} protectee={item} keyNum={key} submittedOn={submittedOn} onSubmit={onSubmit} />
              ))}
            </tbody>
          </table>
        </div>

        {/* PROTECTORS */}
        <div className='col'>
          {/* TITLE BAR */}
          <TableTitleBar
            title='Assigned Personnel'
            onRefresh={onRefreshProtectors}
            lastRefresh={usersSlice.lastRefresh}
            hideSearch={true}
          />
          {/* TABLE HEADER */}
          <table className='table table-dark'>
            <thead>
              <tr className='d-flex'>
                <th className='col-4'>Name</th>
                <th className='col'>Notes</th>
                <th className='col-3'>Status</th>
              </tr>
            </thead>
          </table>

          {/* PROTECTOR TABLE BODY */}
          <table className='table table-dark table-striped'>
            <tbody>
              {protectors.map((item, key) => (
                <ProtectorRow
                  key={key}
                  id={client._id}
                  selectedSite={site}
                  protector={item}
                  keyNum={key}
                  submittedOn={submittedOn}
                  onSubmit={onSubmit}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
