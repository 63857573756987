import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addPerson, apiAddPerson } from "../../../reduxPie/personsSlice";
import { AddTitleBar } from "../../../components/AddTitleBar";
import { AddMultipleInputs } from "../../../components/AddMultipleInputs";
import { FormInput } from "../../../components/FormInput";

export function AddPursuer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn } = useSelector((state) => state.selected);

  // STATES FOR ALL INPUTS
  const [mark, setMark] = useState("");
  const [marks, setMarks] = useState([]);
  const [alias, setAlias] = useState("");
  const [aliases, setAliases] = useState([]);
  const [name, setName] = useState("");
  const [sex, setSex] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [eyeColor, setEyeColor] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [race, setRace] = useState("");
  const [DOB, setDOB] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [driverLicense, setDriverLicense] = useState("");

  // ON ADD NEW PURSUER
  const onAddPursuer = () => {
    if (name && driverLicense) {
      let newPursuer = {
        name,
        driverLicense,
        checkedIn: false,
        recentSite: null,
        type: "Pursuer",
        fullAddress: {
          streetAddress,
          city,
          state,
          zip,
        },
        sex,
        height,
        weight,
        eyeColor,
        hairColor,
        race,
        DOB,
        aliases,
        marks,
        createdOn: new Date().toLocaleString(),
        createdBy: loggedIn._id ? loggedIn._id : loggedIn.id,
      };
      dispatch(apiAddPerson(newPursuer));
      dispatch(addPerson(newPursuer));
      window.alert(`Pursuer ${name} Has Been Added`);
      navigate("/pursuers");
    } else {
      window.alert("Required:\n- Name\n- Driver License\n\nPlease Check Form.");
    }
  };

  return (
    <div className='d-flex flex-fill py-1'>
      <div className='card d-flex flex-fill border border-danger border-5 bg-black text-light shadow-lg'>
        {/* TITLE BAR */}
        <AddTitleBar onAdd={onAddPursuer} title='Pursuer' backLink='/pursuers' />

        {/* VISITOR FORM */}
        <div className='card-body overflow-auto' style={{ height: window.innerHeight * 0.6 }}>
          <div className='d-flex centered'>
            {/* LEFT COL */}
            <div className='col me-3'>
              {/* PICTURE */}
              <div className='d-flex flex-column centered mb-3'>
                <div
                  className={`d-flex flex-column mb-3 overflow-auto border border-danger rounded p-3`}
                  style={{ height: window.innerHeight * 0.425 }}>
                  {/*{img ? (
                    <img
                      className='img-thumbnail bg-primary border-0'
                      src={img}
                      alt='...'
                      style={{ maxHeight: window.innerHeight * 0.4, maxWidth: window.innerWidth * 0.3 }}
                    />
                  ) : (
                    <></>
                  )}
               
                */}
                </div>
                {/* IMG UPLOAD */}
                {/* <div className='input-group input-group-sm'>
                  <span className='input-group-text'>Profile Picture</span>
                  <input
                    className='form-control'
                    type='file'
                    accept='.jpg,.png,.jpeg'
                    onChange={onImgUpload}
                    disabled={visitor._id ? disabled : true}
                  />
                </div>
                <small className='text-white mb-3'>Formats: .jpg .jpeg .png</small> */}
              </div>
            </div>

            {/* RIGHT COL */}
            <div className='col'>
              {/* KEY INFO */}
              <h1 className='fw-bold fs-3 text-center mb-2'>Key Info</h1>
              <div className='d-flex flex-column'>
                <div className='d-flex mb-3'>
                  {/* NAME */}
                  <FormInput className='me-3' label='Name *' value={name} setValue={setName} />

                  {/* DOB */}
                  <FormInput className='me-3' label='DOB' type='date' value={DOB} setValue={setDOB} />

                  {/* DRIVERS LICENSE */}
                  <FormInput label='Driver License *' value={driverLicense} setValue={setDriverLicense} />
                </div>
              </div>
              {/* BASIC INFO */}
              <h1 className='fw-bold fs-3 text-center mb-2'>Basic Info</h1>
              <div className='d-flex mb-3'>
                {/* HEIGHT */}
                <FormInput className='me-3' label='Height' value={height} setValue={setHeight} />

                {/* WEIGHT */}
                <FormInput className='me-3' label='Weight' value={weight} setValue={setWeight} />

                {/* SEX */}
                <FormInput className='' label='Sex' value={sex} setValue={setSex} />
              </div>
              <div className='d-flex mb-3'>
                {/* HAIR COLOR */}
                <FormInput className='me-3' label='Hair Color' value={hairColor} setValue={setHairColor} />

                {/* EYE COLOR */}
                <FormInput className='me-3' label='Eye Color' value={eyeColor} setValue={setEyeColor} />

                {/* RACE */}
                <FormInput className='' label='Race' value={race} setValue={setRace} />
              </div>

              {/* HOME ADDRESS */}
              <h1 className='fw-bold fs-3 text-center mb-2'>Home Address</h1>
              <div className='d-flex mb-3'>
                {/* STREET ADDRESS */}
                <FormInput className='me-3' label='Street Address' value={streetAddress} setValue={setStreetAddress} />

                {/* CITY */}
                <FormInput className='' label='City' value={city} setValue={setCity} />
              </div>

              <div className='d-flex mb-3'>
                {/* STATE */}
                <FormInput className='me-3' label='State' value={state} setValue={setState} />

                {/* ZIP CODE */}
                <FormInput className='' label='Zip' value={zip} setValue={setZip} />
              </div>
            </div>
          </div>
          <div className='d-flex'>
            {/* MARKS */}
            <AddMultipleInputs
              title='Marks'
              label='Mark'
              value={mark}
              setValue={setMark}
              details={marks}
              setDetails={setMarks}
            />
            <div className='col-1' />
            {/* ALIASES */}
            <AddMultipleInputs
              title='Aliases'
              label='Alias'
              value={alias}
              setValue={setAlias}
              details={aliases}
              setDetails={setAliases}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
