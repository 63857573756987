import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AddMultipleInputs } from "../../../../../../components/AddMultipleInputs";
import { AddTitleBar } from "../../../../../../components/AddTitleBar";
import { FormInput } from "../../../../../../components/FormInput";

export function AddEmailGroup() {
  const { loggedIn } = useSelector((state) => state.selected);
  const regionsSlice = useSelector((state) => state.regions);
  const branchesSlice = useSelector((state) => state.branches);
  const [emails, setEmails] = useState([]);

  // EMAIL GROUP STATE VALUES
  const [region, setRegion] = useState("");
  const [branch, setBranch] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // ON ADD EMAIL GROUP
  const onAddEmailGroup = () => {
    const newEmailGroup = {
      region,
      branch,
      name,
      emails,
      createdOn: new Date().toISOString(),
      createdBy: loggedIn._id,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn._id,
    };

    console.log("REQ: ", newEmailGroup);
  };

  return (
    <div className='d-flex flex-column flex-fill card bg-black text-light my-1'>
      {/* TITLE BAR */}
      <AddTitleBar title='Email Group' backLink={-1} onAdd={onAddEmailGroup} />

      {/* BODY */}
      <div className='d-flex flex-fill align-items-center'>
        {/* COL SPACER */}
        <div className='col-1' />

        {/* CENTER PANEL */}
        <div className='col'>
          <div className='d-flex mb-3'>
            {/* REGION */}
            <FormInput
              type='select'
              label='Region'
              className='me-3'
              value={region}
              setValue={setRegion}
              defaultLabel='Select Region'
              options={regionsSlice?.data?.map((x) => ({ label: x.name, value: x._id }))}
            />

            {/* BRANCH */}
            <FormInput
              type='select'
              label='Branch'
              className=''
              value={branch}
              setValue={setBranch}
              defaultLabel='Select Branch'
              options={branchesSlice?.data?.map((x) => ({ label: x.name, value: x._id }))}
            />
          </div>

          {/* NAME */}
          <div className='pb-3'>
            <FormInput label='Name' placeholder='Enter Name for Email Group' value={name} setValue={setName} />
          </div>

          {/* EMAILS */}
          <AddMultipleInputs
            title='Emails'
            label='Email'
            value={email}
            setValue={setEmail}
            details={emails}
            setDetails={setEmails}
          />
        </div>

        {/* COL SPACER */}
        <div className='col-1' />
      </div>
    </div>
  );
}
