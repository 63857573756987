import React from "react";
import logo from "../media/logo.png";
import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

export function ReportsPDF({ PDFData, site = "", client = "" }) {
  return (
    <Document>
      <Page style={styles.body}>
        {/* DOCUMENT HEADER */}
        <View style={styles.header}>
          {/* LOGO */}
          <View style={styles.headerCol}>
            <Image src={logo} />
          </View>
          {/* DOC TITLE */}
          <View style={{ width: `100%` }}>
            <Text style={[styles.titleCell, { fontSize: 20 }]}>
              {client?.name ? (site?.name ? `${client?.name}'s ${site?.name} ` : `${client?.name}'s `) : "Management "}
              Reports
            </Text>
          </View>
        </View>
        {/* DOCUMENT TITLE */}
        <View style={styles.table}>
          <View style={[styles.titleRow, { backgroundColor: "#DC3545" }]}>
            {!client && (
              <View style={[styles.titleCol, styles.col4]}>
                <Text style={styles.titleCell}>Client</Text>
              </View>
            )}
            {!site && (
              <View style={[styles.titleCol, styles.col4]}>
                <Text style={styles.titleCell}>Site</Text>
              </View>
            )}
            <View style={[styles.titleCol, styles.col4]}>
              <Text style={styles.titleCell}>Form</Text>
            </View>
            <View style={[styles.titleCol, styles.col4]}>
              <Text style={styles.titleCell}>Event</Text>
            </View>
            <View style={[styles.titleCol, styles.col4]}>
              <Text style={styles.titleCell}>Type</Text>
            </View>
            <View style={[styles.titleCol, styles.col4]}>
              <Text style={[styles.titleCell]}>Submitted On</Text>
            </View>
            <View style={[styles.titleCol, styles.col4]}>
              <Text style={[styles.titleCell]}>Submitted By</Text>
            </View>
          </View>
          {/* DOCUMENT DATA  */}
          {PDFData?.map((item, key) => {
            let backgroundColor = key % 2 === 0 ? "rgb(220, 220, 220)" : "rgb(250, 250, 250)";

            return (
              <View key={key} style={[styles.tableRow, { backgroundColor }]} wrap={false}>
                {item?.clientName && (
                  <View style={[styles.tableCol, styles.col4]}>
                    <Text style={styles.tableCell}>{item?.clientName}</Text>
                  </View>
                )}
                {item?.siteName && (
                  <View style={[styles.tableCol, styles.col4]}>
                    <Text style={styles.tableCell}>{item?.siteName}</Text>
                  </View>
                )}
                <View style={[styles.tableCol, styles.col4]}>
                  <Text style={styles.tableCell}>{item.formName}</Text>
                </View>
                <View style={[styles.tableCol, styles.col4]}>
                  <Text style={styles.tableCell}>{item.eventName}</Text>
                </View>
                <View style={[styles.tableCol, styles.col4]}>
                  <Text style={styles.tableCell}>{item.eventType}</Text>
                </View>
                <View style={[styles.tableCol, styles.col4]}>
                  <Text style={styles.tableCell}>
                    {item.submittedOn.includes(",")
                      ? `${item.submittedOn.slice(0, item.submittedOn.indexOf(","))}\n${item.submittedOn.slice(
                          item.submittedOn.indexOf(",") + 1
                        )}`
                      : item.submittedOn}
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.col4]}>
                  <Text style={styles.tableCell}>{item.submittedBy}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
}

// ------------- STYLES
const styles = StyleSheet.create({
  header: {
    height: 80,
    backgroundColor: "black",
    flexDirection: "row",
    color: "whitesmoke",
  },
  headerCol: {
    justifyContent: "center",
    width: "30%",
  },
  titleRow: {
    flexDirection: "row",
    width: "100%",
    color: "white",
  },
  titleCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  titleCell: {
    margin: "auto",
    padding: "5px",
    fontSize: 11,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    padding: "5px",
    fontSize: 9,
  },
  col1: {
    width: `${(1 / 12) * 100}%`,
  },
  col2: {
    width: `${(2 / 12) * 100}%`,
  },
  col3: {
    width: `${(3 / 12) * 100}%`,
  },
  col4: {
    width: `${(4 / 12) * 100}%`,
  },
  col5: {
    width: `${(5 / 12) * 100}%`,
  },
  col6: {
    width: `${(6 / 12) * 100}%`,
  },
  col10: {
    width: `${(10 / 12) * 100}%`,
  },
});
