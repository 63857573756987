/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiLink, logsLink } from './apiConfig'

const initialState = {
  isloading: false,
  logs: [],
  lastRefresh: '',
  data: [],
}
axios.defaults.withCredentials = true

// API FIND ALL DELIVERIES BY CLIENT
export const apiFindAllDeliveriesByClient = createAsyncThunk('deliveries/findAll/clientId', async (clientId) => {
  const data = axios
    .get(`${apiLink}/deliveries/client/${clientId}`)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API FIND ALL DELIVERIES BY CLIENT: ', err))

  return data
})

// API ADD NEW DELIVERY
export const apiAddDelivery = createAsyncThunk('deliveries/add', async ({ newLog, newDelivery }) => {
  // DELIVERY API
  const deliveryRes = await axios
    .post(`${apiLink}/deliveries/add`, newDelivery)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD NEW DELIVERY: ', err))

  // LOG API ( ON SUCCESS )
  if (deliveryRes._id && newLog) {
    const log = {
      ...newLog,
      delivery: deliveryRes._id,
    }
    await axios
      .post(`${apiLink}/logs/add`, log)
      .then((res) => res.data)
      .catch((err) => console.error('ERROR API ADD LOG (DELIVERY): ', err))
  }

  return deliveryRes
})

// API UPDATE DELIVERY
export const apiUpdateDelivery = createAsyncThunk('deliveries/update', async ({ newLog, updatedDelivery }) => {
  // DELIVERY API
  const deliveryRes = await axios
    .put(`${apiLink}/deliveries/update`, updatedDelivery)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API UPDATE DELIVERY: ', err))

  const log = {
    ...newLog,
    delivery: newLog?.delivery ? newLog.delivery : deliveryRes?._id,
  }

  await axios
    .post(`${apiLink}/logs/add`, log)
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API ADD LOG (DELIVERY): ', err))

  return deliveryRes
})

// API DELIVERY AUDIT LOG
export const apiDeliveryAuditLog = createAsyncThunk('deliveries/logs', async (delivery) => {
  const logs = await axios
    .post(logsLink + '/delivery', { delivery })
    .then((res) => res.data)
    .catch((err) => console.error('ERROR API DELIVERY AUDIT LOG: ', err))
  return logs
})

const deliveriesSlice = createSlice({
  name: 'deliveriesSlice',
  initialState,
  reducers: {
    addDelivery(state, action) {
      state.data = [action.payload, ...state.data]
    },
    updateDelivery(state, action) {
      state.data = [action.payload, ...state.data.filter((x) => x._id !== action.payload._id)]
    },
    clearDeliveries(state, action) {
      return initialState
    },
  },
  extraReducers: (builder) =>
    builder
      // API FIND ALL DELIVERIES BY CLIENT
      .addCase(apiFindAllDeliveriesByClient.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(apiFindAllDeliveriesByClient.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : state.data
        state.lastRefresh = new Date().toISOString()
        state.isloading = false
      })
      .addCase(apiFindAllDeliveriesByClient.rejected, (state, action) => {
        state.isloading = false
      })
      // API ADD DELIVERY
      .addCase(apiAddDelivery.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(apiAddDelivery.fulfilled, (state, action) => {
        const { _id } = action.payload
        state.data.forEach((x) => {
          if (!x._id) {
            x._id = _id
          }
        })
        state.isLoading = false
      })
      .addCase(apiAddDelivery.rejected, (state, action) => {
        state.isloading = false
      })
      // API UPDATE DELIVERY
      .addCase(apiUpdateDelivery.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(apiUpdateDelivery.fulfilled, (state, action) => {
        state.isloading = false
      })
      .addCase(apiUpdateDelivery.rejected, (state, action) => {
        state.isloading = false
      })
      // API FIND ALL DELIVERIES BY CLIENT
      .addCase(apiDeliveryAuditLog.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(apiDeliveryAuditLog.fulfilled, (state, action) => {
        state.logs = action.payload
        state.isloading = false
      })
      .addCase(apiDeliveryAuditLog.rejected, (state, action) => {
        state.isloading = false
      }),
})

export const { addDelivery, updateDelivery, clearDeliveries } = deliveriesSlice.actions

export default deliveriesSlice.reducer
