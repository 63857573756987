import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EditTitleBar } from '../../../../components/EditTitleBar'
import { InfoBtn } from '../../../../components/InfoBtn'
import { apiUpdatePassword } from '../../../../reduxPie/usersSlice'
import { VIEW_ICON } from '../../../Login'

export function ChangePassword() {
  const formDefault = 'form-control '
  const formValid = formDefault + 'is-valid '
  const formInvalid = formDefault + 'is-invalid '
  const { user, loggedIn } = useSelector((state) => state.selected)
  const [formPasswordType, setFormPasswordType] = useState('password')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // STATE VALUES
  const [newPassword, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // FORM VALUES
  const [formPassword, setFormPassword] = useState(formDefault)
  const [formConfirmPassword, setFormConfirmPassword] = useState(formDefault)

  // PASSWORD VALIDATION
  useEffect(() => {
    // Atleast One Number
    // Atleast One Lower Case Letter
    // Atleast One Upper Case Letter
    // Atleast One Special Character
    // Atleast 8 Characters
    if (newPassword.match('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}')) {
      setFormPassword(formValid)
    } else if (newPassword.length === 0) {
      setFormPassword(formDefault)
    } else {
      setFormPassword(formInvalid)
    }
  }, [newPassword, formValid, formInvalid])

  // CONFIRM PASSWORD VALIDATION
  useEffect(() => {
    if (confirmPassword === newPassword && confirmPassword.length > 0) {
      setFormConfirmPassword(formValid)
    } else if (confirmPassword !== newPassword) {
      setFormConfirmPassword(formInvalid)
    } else {
      setFormConfirmPassword(formDefault)
    }
  }, [newPassword, formValid, confirmPassword, formInvalid])

  // ON UPDATE PASSWORD
  const onUpdatePassword = () => {
    const reqObject = {
      id: user._id,
      newPassword,
      modifiedOn: new Date().toISOString(),
      modifiedBy: loggedIn?._id,
      newLog: {
        customer: loggedIn?.customer,
        data: `PWD CHANGE: ${loggedIn?.name} changed ${user?.name}'s password.`,
        submittedOn: new Date().toLocaleString(),
        createdOn: new Date().toISOString(),
        createdBy: loggedIn?._id,
      },
    }
    if (formPassword === formValid && formConfirmPassword === formValid) {
      dispatch(apiUpdatePassword(reqObject))
      window.alert(`Password Updated for ${user.name}.`)
      navigate('/manage/users')
    } else {
      window.alert('ERROR: Make sure both inputs are green')
    }
  }

  // ----- ON VIEW PASSWORD
  const onToggleView = () =>
    formPasswordType === 'password' ? setFormPasswordType('text') : setFormPasswordType('password')

  return (
    <div className='d-flex flex-fill'>
      <div className='card border-dark container-fluid shadow-lg my-3'>
        {/* TABLE TITLE BAR */}
        <EditTitleBar
          disabled={false}
          title={`${user.name}'s Password`}
          onUpdate={() => onUpdatePassword()}
          onCancel={() => {
            navigate(-1)
          }}
        />

        {/* BODY */}
        <div className='d-flex centered flex-fill'>
          <div className='col-3' />
          <div className='col'>
            {/* PASSWORD REQUIREMENTS */}
            <div className='d-flex flex-column centered mb-3'>
              <small>Password Must Include:</small>
              <small>One Number : 0-9 </small>
              <small>One Lower Case Letter : a-z </small>
              <small>One Upper Case Letter : A-Z </small>
              <small>One Special Character : ! ) @ # $ % ( ^ & * </small>
              <small>8 Characters Long</small>
            </div>

            {/* PASSWORD  */}
            <div className='input-group mb-3'>
              <span className='input-group-text' title='Enter Password'>
                New Password
              </span>
              <input
                type={formPasswordType}
                className={formPassword}
                value={newPassword}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
              <button
                type='button'
                className={`btn ${formPasswordType === 'text' ? 'btn-dark' : 'btn-outline-secondary'}`}
                onClick={() => onToggleView()}
              >
                {VIEW_ICON}
              </button>
            </div>

            {/* CONFIRM PASSWORD */}
            <div className='input-group mb-3'>
              <span className='input-group-text' title='Passwords Must Match'>
                Confirm New Password
                <InfoBtn />
              </span>
              <input
                className={`form-control ${formConfirmPassword}`}
                type={formPasswordType}
                value={confirmPassword}
                onChange={(x) => setConfirmPassword(x.target.value)}
              />
              <button
                type='button'
                className={`btn ${formPasswordType === 'text' ? 'btn-dark' : 'btn-outline-secondary'}`}
                onClick={() => onToggleView()}
              >
                {VIEW_ICON}
              </button>
            </div>
          </div>
          <div className='col-3' />
        </div>
      </div>
    </div>
  )
}
