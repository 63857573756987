import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TableTitleBar } from '../../../../components/TableTitleBar';
import { setEmailGroup } from '../../../../reduxPie/selectedSlice';

export function EmailGroups() {
  const table = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailGroupsSlice = useSelector((state) => state.emailGroups);
  const [emailGroups, setEmailGroups] = useState([
    // {
    //   _id: "1",
    //   region: "North East",
    //   branch: "New York",
    //   name: "Development Board",
    //   emails: ["Testing@admin.com", "Format@admin.com", "Of@admin.com", "Email@admin.com", "List@admin.com"],
    //   createdOn: new Date().toISOString(),
    //   createdBy: "",
    //   modifiedOn: new Date().toISOString(),
    //   modifiedBy: "",
    // },
  ]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'name', flex: 1, filter: true, sortable: true },
    { field: 'region', flex: 1, filter: true, sortable: true },
    { field: 'branch', flex: 1, filter: true, sortable: true },
    {
      field: 'emails',
      headerName: 'Emails ( Preview )',
      flex: 2,
      filter: true,
      sortable: true,
      valueFormatter: ({ value }) => value.join(' | '),
    },
  ]);

  // ON ROW CLICKED
  const onRowClicked = (item) => {
    dispatch(setEmailGroup(item.data));
    navigate('edit-email-group');
  };

  // ON REFRESH EMAIL GROUPS
  const onRefreshEmailGroups = () => {};

  // AUTO FETCH EMAIL GROUPS
  useEffect(() => {
    onRefreshEmailGroups();
  }, []);

  // AUTO REFRESH REDUX
  useEffect(() => {
    setEmailGroups(emailGroupsSlice.data);
  }, [emailGroupsSlice.data]);

  return (
    <div className="d-flex flex-fill flex-column">
      {/* TITLE BAR */}
      <TableTitleBar title="Email Groups" addLabel="Email Group" addLink="add-email-group" />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
        <AgGridReact
          ref={table}
          rowData={emailGroups}
          defaultColDef={{ resizable: true }}
          columnDefs={columnDefs}
          onRowClicked={onRowClicked}
        />
      </div>
    </div>
  );
}
