import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../../components/Loading";
import { TableTitleBar } from "../../../../components/TableTitleBar";
import { apiFindAllUsers } from "../../../../reduxPie/usersSlice";

export function ClientManagers() {
  const dispatch = useDispatch();
  const { isLoading, lastRefresh, data } = useSelector((state) => state.users);
  const { client } = useSelector((state) => state.selected);
  const [onlyActive, setOnlyActive] = useState(true);
  const [managers, setManagers] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "name", flex: 1, filter: true, sortable: true },
    { field: "email", flex: 1, filter: true, sortable: true },
    { field: "phone", flex: 1, filter: true, sortable: true },
    {
      field: "status",
      flex: 1,
      filter: true,
      sortable: true,
      valueFormatter: ({ data }) => (data.active ? data.status : "Deactivated"),
    },
  ]);

  // ROW CLASS RULES
  const rowClassRules = {
    "bg-danger": "!data.active",
  };

  // ON REFRESH MANAGERS ( USERS )
  const onRefreshManagers = () => {
    dispatch(apiFindAllUsers());
  };

  // AUTO FETCH MANAGERS ( USERS )
  useEffect(() => {
    if (!data.length) onRefreshManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ACTIVE TOGGLE
  useEffect(() => {
    const Managers = data.filter((x) => {
      return x.assignedClients.includes(client._id) && x.level === "1";
    });

    if (onlyActive) {
      setManagers(Managers.filter((x) => x.active));
    } else {
      setManagers(Managers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, onlyActive]);

  // RENDER
  if (isLoading) return <Loading title='Managers' />;
  return (
    <div className='d-flex flex-column flex-fill'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Assigned Managers'
        hideSearch={true}
        onlyActive={onlyActive}
        setOnlyActive={setOnlyActive}
        onRefresh={onRefreshManagers}
        lastRefresh={lastRefresh}
      />

      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact columnDefs={columnDefs} rowData={managers} rowClassRules={rowClassRules} />
      </div>
    </div>
  );
}
