import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const iconSearch = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    fill='currentColor'
    className='bi bi-search'
    viewBox='0 0 16 16'>
    <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
  </svg>
);

export function TableTitleBar({
  title = "",
  addLabel = "",
  addLink = "",
  modalId = "",
  onRefresh = () => {},
  lastRefresh = "",
  view = "",
  setView = "",
  onlyActive = "",
  setOnlyActive = "",
  fromDate = "",
  setFromDate,
  toDate = "",
  setToDate,
  onSearchDateRange,
  miscLabel,
  miscId,
}) {
  const { loggedIn } = useSelector((state) => state.selected);

  // AUTO REFRESH AFTER 15 MIN
  useEffect(() => {
    if ((new Date().getTime() - new Date(lastRefresh).getTime()) / 1000 / 60 > 15) onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // PROTECTOR VIEW
  if ((addLabel === "Client" || addLabel === "Site") && loggedIn.level === "4")
    return (
      <div className='d-flex justify-content-between align-items-center px-3'>
        <div className='d-flex align-items-center'>
          {/* TITLE */}
          <h1 className='display-6 fs-2 fw-bold user-select-none'>{title}</h1>

          {/* ON REFRESH BTN */}
          {onRefresh ? (
            <button className='btn btn-sm btn-outline-dark border-0 ms-3' onClick={() => onRefresh()}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-arrow-clockwise'
                viewBox='0 0 16 16'>
                <path fillRule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z' />
                <path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z' />
              </svg>
              <small className=' p-3'>
                {`${Math.floor((new Date().getTime() - new Date(lastRefresh).getTime()) / 1000 / 60)} mins ${
                  Math.floor((new Date().getTime() - new Date(lastRefresh).getTime()) / 1000) % 60
                } secs ago`}
              </small>
            </button>
          ) : (
            <></>
          )}
        </div>

        {onlyActive || setOnlyActive ? (
          <div>
            <button className='btn btn-sm btn-dark' onClick={() => setOnlyActive(!onlyActive)}>
              {onlyActive ? "Show All" : "Show Active"}
            </button>
          </div>
        ) : (
          <></>
        )}

        {/* DATE RANGE */}
        {onSearchDateRange ? (
          <div className='d-flex'>
            <div className='input-group input-group-sm'>
              <span className='input-group-text'>From</span>
              <input
                type='date'
                className='form-control'
                value={fromDate}
                onChange={(x) => setFromDate(x.target.value)}
              />
              <span className='input-group-text'>To</span>
              <input type='date' className='form-control' value={toDate} onChange={(x) => setToDate(x.target.value)} />
              <button className='btn btn-secondary' onClick={() => onSearchDateRange()}>
                {iconSearch}
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );

  // MANAGER VIEW
  return (
    <div className='d-flex justify-content-between align-items-center px-3 py-1'>
      <div className='d-flex align-items-center'>
        {/* TITLE */}
        <h1 className='display-6 fs-2 fw-bold user-select-none'>{title}</h1>

        {/* ON REFRESH BTN */}
        {onRefresh ? (
          <button className='btn btn-sm btn-outline-dark border-0 ms-3' onClick={() => onRefresh()}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-arrow-clockwise'
              viewBox='0 0 16 16'>
              <path fillRule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z' />
              <path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z' />
            </svg>
            <small className='p-3'>
              {`${Math.floor((new Date().getTime() - new Date(lastRefresh).getTime()) / 1000 / 60)} mins ${
                Math.floor((new Date().getTime() - new Date(lastRefresh).getTime()) / 1000) % 60
              } secs ago`}
            </small>
          </button>
        ) : (
          <></>
        )}
      </div>

      {onlyActive || setOnlyActive ? (
        <div>
          <button className='btn btn-sm btn-dark' onClick={() => setOnlyActive(!onlyActive)}>
            {onlyActive ? "Show All" : "Show Active"}
          </button>
        </div>
      ) : (
        <></>
      )}

      {/* MISC MODAL BTN */}
      {miscId && miscLabel ? (
        <button className='btn btn-sm btn-secondary' data-bs-toggle='modal' data-bs-target={`#${miscId}`}>
          {miscLabel}
        </button>
      ) : (
        <></>
      )}

      {/* DATE RANGE */}
      {onSearchDateRange ? (
        <div className='d-flex'>
          <div className='input-group input-group-sm'>
            <span className='input-group-text'>From</span>
            <input
              type='date'
              className='form-control'
              value={fromDate}
              onChange={(x) => setFromDate(x.target.value)}
            />
            <span className='input-group-text'>To</span>
            <input type='date' className='form-control' value={toDate} onChange={(x) => setToDate(x.target.value)} />
            <button className='btn btn-secondary' onClick={() => onSearchDateRange()}>
              {iconSearch}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* VIEW SETTER */}
      {setView ? (
        <div className='btn-group'>
          <button
            className={`btn btn-sm btn-${view === "table" ? "dark" : "outline-secondary"}`}
            onClick={() => setView("table")}>
            Table
          </button>
          <button
            className={`btn btn-sm btn-${view === "pdf" ? "dark" : "outline-secondary"}`}
            onClick={() => setView("pdf")}>
            PDF
          </button>
          <button
            className={`btn btn-sm btn-${view === "chart" ? "dark" : "outline-secondary"}`}
            onClick={() => setView("chart")}>
            Chart
          </button>
        </div>
      ) : (
        <></>
      )}

      {/* ADD NEW BTN */}
      {addLink && addLabel ? (
        <Link to={addLink} className='btn btn-sm btn-primary'>
          Add New {addLabel}
        </Link>
      ) : (
        <></>
      )}

      {/* ADD MODAL BTN */}
      {modalId && addLabel ? (
        <button className='btn btn-sm btn-primary' data-bs-toggle='modal' data-bs-target={`#${modalId}`}>
          Add New {addLabel}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
