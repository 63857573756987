import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddTitleBar } from '../../../components/AddTitleBar';
import { FormInput } from '../../../components/FormInput';
import { Loading } from '../../../components/Loading';
import { iconSearch } from '../../../components/TableTitleBar';
import { apiFindPersonByName, clearPersons } from '../../../reduxPie/personsSlice';
import { addVehicle, apiAddVehicle } from '../../../reduxPie/vehiclesSlice';

export function AddVehicle() {
  const table = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn } = useSelector((state) => state.selected);
  const personsSlice = useSelector((state) => state.persons);
  const vehicleTypes = ['Staff', 'Vendor', 'Guest', 'Pursuer', 'Suspicious'];
  const [persons, setPersons] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [view, setView] = useState('VEHICLE');

  // ----- FORM STATE VALUES START ----- //
  const [name, setName] = useState('');
  const [type, setType] = useState(null);
  const [person, setPerson] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [color, setColor] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  // ----- FORM STATE VALUES END ----- //

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: 'name', flex: 2, filter: true, sortable: true, checkboxSelection: true },
    { field: 'type', flex: 1, filter: true, sortable: true },
    { field: 'group', flex: 2, filter: true, sortable: true },
    { field: 'driverLicense', flex: 2, filter: true, sortable: true },
  ]);

  // ROW CLASS RULES
  const rowClassRules = useMemo(() => {
    return {
      'bg-danger': 'data.type === "Pursuer"',
    };
  }, []);

  // ON PERSON SEARCH
  const onPersonSearch = () => {
    dispatch(apiFindPersonByName(searchName?.trim()));
  };

  // ON ASSIGN OWNER
  const onAssignOwner = () => {
    const selectedRows = table.current.api.getSelectedRows();
    setPerson(selectedRows[0]);
    setView('VEHICLE');
  };

  // ON ADD NEW VEHICLE
  const onAddNewVehicle = () => {
    if (name && type && make && model && color && licensePlate && vehicleTypes.includes(type)) {
      let newVehicle = {
        customer: loggedIn.customer,
        person: person?._id || '',
        name,
        type,
        make,
        model,
        color,
        licensePlate,
        imgLink: '',
        createdOn: new Date().toISOString(),
        createdBy: loggedIn._id,
        modifiedOn: new Date().toISOString(),
        modifiedBy: loggedIn._id,
      };
      dispatch(addVehicle(newVehicle));
      dispatch(apiAddVehicle(newVehicle));
      window.alert(`${type} - Vehicle - ${name} Has Been Added`);
      navigate('/vehicles');
    } else {
      window.alert('Required:\n- Name\n- Type\n- Make\n- Model\n- Color\n- License Plate\n\nPlease Check Form.');
    }
  };

  // AUTO-CLEAR PERSONS CACHE
  useEffect(() => {
    dispatch(clearPersons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // AUTO REFRESH REDUX
  useEffect(() => {
    setPersons(personsSlice.data);
  }, [personsSlice.data]);

  // PERSON SERACH LOADING
  if (personsSlice.isLoading) return <Loading title="Person Search" />;
  return (
    <div className="d-flex flex-fill py-1">
      <div className="card bg-black text-light d-flex flex-fill shadow-lg">
        {/* TITLE BAR */}
        <AddTitleBar
          onAdd={onAddNewVehicle}
          title={`${type === null || type === 'Select Vehicle Type' ? '' : type} Vehicle`}
          backLink="/vehicles"
        />

        {/* CARD BODY */}
        <div className="card-body d-flex flex-fill flex-column px-5">
          {/* VEHICLE VIEW */}
          {view === 'VEHICLE' && (
            <>
              <div className="d-flex">
                {/* NAME */}
                <FormInput label="Name for Vehicle *" className="me-5" value={name} setValue={setName} />

                {/* MODEL */}
                <FormInput label="Model *" value={model} setValue={setModel} />
              </div>

              <div className="d-flex">
                {/* LICENSE PLATE */}
                <FormInput label="License Plate *" className="me-5" value={licensePlate} setValue={setLicensePlate} />

                {/* MAKE */}
                <FormInput label="Make *" value={make} setValue={setMake} />
              </div>

              <div className="d-flex">
                {/* TYPE */}
                <FormInput
                  type="select"
                  label="Type *"
                  className="me-5"
                  defaultLabel="Select Vehicle Type"
                  defaultValue={null}
                  value={type}
                  setValue={setType}
                  options={vehicleTypes.map((x) => ({ label: x, value: x }))}
                />

                {/* COLOR */}
                <FormInput label="Color *" value={color} setValue={setColor} />
              </div>

              <div className="d-flex align-items-end mt-5">
                {/* PERSON NAME */}
                <FormInput
                  label="Owner's Name"
                  className="me-5"
                  value={person?.name || 'N/A'}
                  setValue={setName}
                  disabled={true}
                />

                {/* PERSON DRIVER LICENSE */}
                <FormInput
                  label="Owner's License Number"
                  className="me-5"
                  value={person?.driverLicense || 'N/A'}
                  setValue={setName}
                  disabled={true}
                />

                {/* TOGGLE BTN */}
                <button className="btn btn-sm btn-primary w-50" onClick={() => setView('OWNER')}>
                  Assign Owner to Vehicle
                </button>
                <div className="d-flex centered"></div>
              </div>
            </>
          )}

          {/* OWNER VIEW */}
          {view === 'OWNER' && (
            <>
              {/* TOGGLE BTN */}
              <div className="d-flex centered mb-5">
                <button className="btn btn-sm btn-secondary" onClick={() => setView('VEHICLE')}>
                  Back to Vehicle Details
                </button>
              </div>

              {/* TITLE BAR */}
              <div className="d-flex flex-fill align-items-center justify-content-between px-2">
                {/* TITLE */}
                <h1 className="display-6 fs-3">Assign Vehicle</h1>

                {/* SEARCH BAR */}
                <form
                  onSubmit={(x) => {
                    x.preventDefault();
                    onPersonSearch();
                  }}
                  className="input-group input-group-sm w-50">
                  <span className="input-group-text">Name Search</span>
                  <input
                    className="form-control"
                    placeholder="Enter Full Name for Search"
                    value={searchName}
                    onChange={(x) => setSearchName(x.target.value)}
                  />
                  <button className="btn btn-secondary" type="submit">
                    {iconSearch}
                  </button>
                </form>

                {/* ASSIGN BTN */}
                <button className="btn btn-sm btn-primary" onClick={() => onAssignOwner()}>
                  Assign as Owner
                </button>
              </div>

              <div style={{ height: `100%`, width: `100%` }} className="ag-theme-alpine-dark">
                <AgGridReact
                  ref={table}
                  rowData={persons}
                  rowClassRules={rowClassRules}
                  columnDefs={columnDefs}
                  rowSelection="single"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
