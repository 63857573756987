import React from "react";
import logo from "../media/logo.png";
import { Document, Image, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

export function DeliveriesPDF({ PDFData, client, site }) {
  const lastLogDate = new Date(PDFData[PDFData.length - 1]?.createdOn).toLocaleDateString();
  const firstLogDate = new Date(PDFData[0]?.createdOn).toLocaleDateString();

  return (
    <Document>
      <Page style={styles.body}>
        {/* DOCUMENT HEADER */}
        <View style={styles.header}>
          {/* LOGO */}
          <View style={styles.headerCol}>
            <Image src={logo} />
          </View>
          {/* DOC TITLE */}
          <View style={{ width: `100%` }}>
            <Text style={[styles.titleCell, { fontSize: 20 }]}>{`${client?.name}'s ${site?.name} `}Deliveries</Text>
            <Text style={{ margin: "auto", fontSize: 14 }}>
              {PDFData?.length ? `${firstLogDate} - ${lastLogDate}` : ""}
            </Text>
          </View>
        </View>
        {/* DOCUMENT TITLE */}
        <View style={styles.table}>
          <View style={[styles.titleRow, { backgroundColor: "red" }]}>
            <View style={[styles.titleCol, styles.col2]}>
              <Text style={[styles.titleCell]}>Bound</Text>
            </View>
            <View style={[styles.titleCol, styles.col3]}>
              <Text style={[styles.titleCell]}>Courier</Text>
            </View>
            <View style={[styles.titleCol, styles.col2]}>
              <Text style={[styles.titleCell]}>Qty</Text>
            </View>
            <View style={[styles.titleCol, styles.col3]}>
              <Text style={[styles.titleCell]}>To</Text>
            </View>
            <View style={[styles.titleCol, styles.col3]}>
              <Text style={[styles.titleCell]}>From</Text>
            </View>
            <View style={[styles.titleCol, styles.col5]}>
              <Text style={[styles.titleCell]}>Last Notes</Text>
            </View>
            <View style={[styles.titleCol, styles.col3]}>
              <Text style={[styles.titleCell]}>Delivered On</Text>
            </View>
            <View style={[styles.titleCol, styles.col3]}>
              <Text style={[styles.titleCell]}>Created By</Text>
            </View>
          </View>
          {/* DOCUMENT DATA  */}
          {PDFData?.map((item, key) => {
            let backgroundColor = !item?.principalRecieved
              ? key % 2 === 0
                ? "rgb(220, 220, 220)"
                : "rgb(250, 250, 250)"
              : "#198754";

            return (
              <View key={key} style={[styles.tableRow, { backgroundColor }]} wrap={false}>
                <View style={[styles.tableCol, styles.col2]}>
                  <Text style={styles.tableCell}>{item.bound ? "In" : "Out"}</Text>
                </View>
                <View style={[styles.tableCol, styles.col3]}>
                  <Text style={styles.tableCell}>{item.courier}</Text>
                </View>
                <View style={[styles.tableCol, styles.col2]}>
                  <Text style={styles.tableCell}>{item.qty}</Text>
                </View>
                <View style={[styles.tableCol, styles.col3]}>
                  <Text style={styles.tableCell}>{item.to}</Text>
                </View>
                <View style={[styles.tableCol, styles.col3]}>
                  <Text style={styles.tableCell}>{item.from}</Text>
                </View>
                <View style={[styles.tableCol, styles.col5]}>
                  <Text style={styles.tableCell}>{item?.notes[item?.notes?.length - 1]?.data}</Text>
                </View>
                <View style={[styles.tableCol, styles.col3]}>
                  <Text style={styles.tableCell}>
                    {item.deliveredOn.includes(",")
                      ? `${item.deliveredOn.slice(0, item.deliveredOn.indexOf(","))}\n${item.deliveredOn.slice(
                          item.deliveredOn.indexOf(",") + 1
                        )}`
                      : item.deliveredOn}
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.col3]}>
                  <Text style={styles.tableCell}>{item.submittedBy}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
}

// ------------- STYLES
const styles = StyleSheet.create({
  header: {
    height: 80,
    backgroundColor: "black",
    flexDirection: "row",
    color: "whitesmoke",
  },
  headerCol: {
    justifyContent: "center",
    width: "30%",
  },
  titleRow: {
    flexDirection: "row",
    width: "100%",
    color: "white",
  },
  titleCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  titleCell: {
    margin: "auto",
    padding: "5px",
    fontSize: 11,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    padding: "5px",
    fontSize: 9,
  },
  col1: {
    width: `${(1 / 12) * 100}%`,
  },
  col2: {
    width: `${(2 / 12) * 100}%`,
  },
  col3: {
    width: `${(3 / 12) * 100}%`,
  },
  col4: {
    width: `${(4 / 12) * 100}%`,
  },
  col5: {
    width: `${(5 / 12) * 100}%`,
  },
  col6: {
    width: `${(6 / 12) * 100}%`,
  },
  col10: {
    width: `${(10 / 12) * 100}%`,
  },
});
