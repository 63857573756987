import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setVehicle } from "../../reduxPie/selectedSlice";
import { Loading } from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { apiFindAllVehicles } from "../../reduxPie/vehiclesSlice";
import { TableTitleBar } from "../../components/TableTitleBar";
import { AgGridReact } from "ag-grid-react";

export function Vehicles() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, lastRefresh, data } = useSelector((state) => state.vehicles);
  const [vehicles, setVehicles] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "name", flex: 1, filter: true, sortable: true },
    { field: "type", flex: 1, filter: true, sortable: true },
    { field: "licensePlate", flex: 1, filter: true, sortable: true },
    { field: "make", flex: 1, filter: true, sortable: true },
    { field: "model", flex: 1, filter: true, sortable: true },
  ]);

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setVehicle(item.data));
    navigate("details");
  };

  // VEHICLE ROW STYLE
  const rowClassRules = {
    "bg-danger": "data.type === 'Pursuer'",
    "bg-warning text-black": "data.type === 'Suspicious'",
  };
  // ON REFRESH VEHICLES
  const onRefreshVehicles = () => {
    dispatch(apiFindAllVehicles());
  };

  // AUTO FETCH VEHICLES
  useEffect(() => {
    if (!data.length) onRefreshVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SEARCH
  useEffect(() => {
    setVehicles(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // RENDER
  if (isLoading) return <Loading title='Vehicles' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar
        title='Vehicles'
        addLabel='Vehicle'
        addLink='add-vehicle'
        hideSearch={true}
        onRefresh={onRefreshVehicles}
        lastRefresh={lastRefresh}
      />
      {/* TABLE */}
      <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
        <AgGridReact
          rowData={vehicles}
          rowClass='bg-primary'
          rowClassRules={rowClassRules}
          columnDefs={columnDefs}
          onRowClicked={onViewDetails}
        />
      </div>
    </div>
  );
}
