import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setReport } from "../../../../reduxPie/selectedSlice";
import { PDFViewer } from "@react-pdf/renderer";
import { TableTitleBar } from "../../../../components/TableTitleBar";
import { apiFindAllReports } from "../../../../reduxPie/reportsSlice";
import { Loading } from "../../../../components/Loading";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { AgGridReact } from "ag-grid-react";
import { ReportsPDF } from "../../../../components/ReportsPDF";
// import { apiFindAllUsers } from "../../../../reduxPie/usersSlice";

ChartJS.register(...registerables);

export function SiteReports() {
  const [view, setView] = useState("table");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { site, client } = useSelector((state) => state.selected);
  const { isLoading, lastRefresh, data } = useSelector((state) => state.reports);
  const usersSlice = useSelector((state) => state.users);
  const [reports, setReports] = useState([]);

  // COLUMN DEFS
  const [columnDefs] = useState([
    { field: "key", headerName: "#", flex: 0.4, filter: true, sortable: true },
    { field: "formName", headerName: "Form", flex: 1, filter: true, sortable: true },
    { field: "eventName", headerName: "Event", flex: 1, filter: true, sortable: true },
    { field: "eventType", headerName: "Type", flex: 0.75, filter: true, sortable: true },
    { field: "submittedBy", flex: 1, filter: true, sortable: true },
    { field: "submittedOn", flex: 1, filter: true, sortable: true },
  ]);

  // CHART DATA
  const chartData = {
    labels: ["Activity", "Incident", "NZR", "Other"],

    datasets: [
      {
        label: "Reports",

        data: [
          reports.filter((x) => x.eventType === "Activity").length,
          reports.filter((x) => x.eventType === "Incident").length,
          reports.filter((x) => x.eventType === "NZR").length,
          reports.filter((x) => x.eventType === "Other").length,
        ],
        backgroundColor: ["rgba(0, 0, 200, 1)", "rgba(200, 0, 0, 1)", "rgba(0, 200, 0, 1)", "rgba(200, 200, 200, 1)"],
        borderColor: ["rgba(0, 0, 0, 1)"],
        borderWidth: 1,
      },
    ],
  };

  // ON REFRESH REPORTS
  const onRefreshReports = () => {
    dispatch(apiFindAllReports());
  };

  // ON VIEW DETAILS
  const onViewDetails = (item) => {
    dispatch(setReport(item.data));
    navigate("details");
  };

  // REDUX FORCED DATA REFRESH
  useEffect(() => {
    setReports(
      data
        .filter((x) => x.site === site._id)
        .map((item, key) => ({
          ...item,
          key,
          submittedBy: usersSlice.data.find((x) => x._id === item.createdBy) ? usersSlice.data.find((x) => x._id === item.createdBy).name : "N/A",
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // AUTO FETCH REPORTS
  useEffect(() => {
    if (!data.length) onRefreshReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RENDER
  if (isLoading) return <Loading title='Reports' />;
  return (
    <div className='d-flex flex-fill flex-column'>
      {/* TITLE BAR */}
      <TableTitleBar title='Site Reports' hideSearch={true} onRefresh={onRefreshReports} lastRefresh={lastRefresh} view={view} setView={setView} />

      {/* TABLE */}
      {view === "table" && (
        <div style={{ height: `100%`, width: `100%` }} className='ag-theme-alpine-dark'>
          <AgGridReact rowData={reports} defaultColDef={{ resizable: true }} columnDefs={columnDefs} onRowClicked={onViewDetails} />
        </div>
      )}

      {/*  PDF VIEWER */}
      {view === "pdf" && (
        <div className='d-flex flex-fill w-100'>
          <PDFViewer style={{ width: "100%" }}>
            <ReportsPDF PDFData={reports} client={client} site={site} />
          </PDFViewer>
        </div>
      )}

      {/* CHARTS */}
      {view === "chart" && (
        <div className='d-flex'>
          <div className='col-3'>
            <Pie data={chartData} className='w-100' />
          </div>
          <div className='col' />
          <div className='col-7'>
            <Bar data={chartData} className='w-100' />
          </div>
        </div>
      )}
    </div>
  );
}
